import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdGenApi } from '../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { WidgetContainerInfo } from '../apiService/classFiles/class.users';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
    isAuthenticated: boolean = false;
    containerInstanceID: string = "";
    currentContainer: WidgetContainerInfo = new WidgetContainerInfo();
    displayNotFoundError: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private pinnacleService: ProdGenApi, private authenticationService: BrowserAuthenticationService) {
        if (authenticationService.AuthenticatePage() == true) {
            this.isAuthenticated = true;
        }
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.containerInstanceID = params['id'];
            this.pinnacleService.getContainerInfo(this.containerInstanceID, "LANDING_PAGE", "LandingPage", this.containerInstanceID).subscribe(res => {
                this.currentContainer = res;
                if (this.currentContainer.containerInstanceId == "00000000-0000-0000-0000-000000000000") {
                    this.displayNotFoundError = true;
                }
            });
            
        });
        window.scroll(0, 0);
    }

}

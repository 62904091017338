import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { LiveEventCalendarComponent } from '../../../liveeventcalendar/liveeventcalendar.component';
import { UpcomingEventsPanelComponent } from '../../upcomingeventspanel/upcomingeventspanel.component';


// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
  selector: 'live-events-widget-component',
    templateUrl: './live-events-widget.component.html',
    styleUrls: ['./live-events-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class LiveEventsWidgetComponent extends WidgetComponent implements OnInit,AfterViewInit {
    static key = "LiveEventsWidgetComponent";     // this key must be set to the name of the Angular component

    widgetLoaded: boolean = false;
    useMobileHeight = true;
    mobileHeight = 3;

    isListDisplay: boolean = false;
    viewType: string = "";
    @ViewChild(LiveEventCalendarComponent, { static: false }) liveEventComponent: LiveEventCalendarComponent;
    @ViewChild(UpcomingEventsPanelComponent, { static: false }) upcomingEventsComponent: UpcomingEventsPanelComponent;
    sizings = {
        6: {
            5: ["68.5%", "60%"],
            6: ["75%", "68.5%"],
            7: ["79.5%", "73.5%"],
            8: ["82.5%, 77.5%"],
            9: ["84.5%", "80.5%"],
            10: ["86.5%", "82.5%"],
            11: ["87.5%", "84.5%"],
            12: ["89%", "85.8%"],
            13: ["89.6%", "86.9%"],
            14: ["90.5%", "88%"],
            15: ["91.3%", "89%"],
            16: ["91.8%", "89.5%"],
            17: ["92.4%", "90.4%"]
        },
        7: {
            5: ["97%", "88.5%"],
            6: ["97.5%", "91%"],
            7: ["98%", "92.5%"],
            8: ["98%, 93%"],
            9: ["94%", "90%"],
            10: ["94%", "90.5%"],
            11: ["95%", "95%"],
            12: ["95%", "95%"],
            13: ["96%", "96%"],
            14: ["96%", "96%"],
            15: ["96.5%", "96.5%"],
            16: ["96.5%", "96.5%"],
            17: ["97%", "97%"]
        },
        8: {
            5: ["86%", "77.5%"],
            6: ["90%", "83%"],
            7: ["91%", "85%"],
            8: ["93%", "88%"],
            9: ["94%", "90%"],
            10: ["94%", "90.5%"],
            11: ["95%", "95%"],
            12: ["95%", "95%"],
            13: ["96%", "96%"],
            14: ["96%", "96%"],
            15: ["96.5%", "96.5%"],
            16: ["96.5%", "96.5%"],
            17: ["97%", "97%"]
        },
        9: {
            5: ["86%", "77.5%"],
            6: ["90%", "83%"],
            7: ["91%", "85%"],
            8: ["93%, 88%"],
            9: ["94%", "90%"],
            10: ["94%", "90.5%"],
            11: ["95%", "95%"],
            12: ["95%", "95%"],
            13: ["96%", "96%"],
            14: ["96%", "96%"],
            15: ["96.5%", "96.5%"],
            16: ["96.5%", "96.5%"],
            17: ["97%", "97%"]
        },
        10: {
            5: ["86%", "77.5%"],
            6: ["90%", "83%"],
            7: ["91%", "85%"],
            8: ["93%, 88%"],
            9: ["94%", "90%"],
            10: ["94%", "90.5%"],
            11: ["95%", "95%"],
            12: ["95%", "95%"],
            13: ["96%", "96%"],
            14: ["96%", "96%"],
            15: ["96.5%", "96.5%"],
            16: ["96.5%", "96.5%"],
            17: ["97%", "97%"]
        }
    }

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 8;
        this.widgetHeight = 12;
        this.displayType = "panel";

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetTitle = "LIVE EVENTS";
        this.widgetName = "Live Events"
        this.widgetDesc = "Displays a calendar or list of upcoming events.";
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 

        if (this.liveEventComponent != undefined) {
            this.liveEventComponent.setShowComponent(true);
            this.setComponentHeights();
        }
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
        if (this.liveEventComponent != undefined) {
            this.liveEventComponent.setShowComponent(true);
            this.setComponentHeights();
        }
    }

    Load() {
        // call the base Widget class
        super.Load();
        this.onResize();
    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);
        
        

        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "LiveEventsWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)
        super.onPropsChangedLocalStorage();

        if (this.liveEventComponent != undefined) {
            this.liveEventComponent.setShowComponent(true);
        }
        this.widgetLoaded = true;
        
        super.widgetArePropertiesLoaded();
    }

    onResize() {
        super.onResize();
        if (this.widgetRenderSize == "xs" || this.widgetRenderSize == "sm") {
            this.viewType = "list";
        }
        else {
            this.viewType = "calendar";
        }
        if (this.liveEventComponent != undefined) {
            this.liveEventComponent.updateCalendar();
            this.setComponentHeights();
        }
        
    }

    setComponentHeights() {
        try {
            let width = this.widgetWidth < 6 ? 6 : this.widgetWidth > 10 ? 10 : this.widgetWidth;
            let height = this.widgetHeight < 5 ? 5 : this.widgetHeight > 17 ? 17 : this.widgetHeight;
            this.liveEventComponent.setHeights(this.sizings[width][height][0], this.sizings[width][height][1]);
        }
        catch (e) {
            console.log(e);
        }
    }

    createProperties() {

        // call widget base class
        super.createProperties();

        // the width and height properties are already created by the base class.
        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        super.createPropertiesDisplayType();
        //Display Title
        super.createPropertiesWidgetTitle("LIVE EVENTS");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS

    calMobileHeight() {
        let currentHeight = this.mobileHeight;

        if (this.upcomingEventsComponent.filteredLiveEvents.length == 0) {
            if (this.widgetTitle.length == 0) {
                this.mobileHeight = 3;
            }
            else {
                this.mobileHeight = 4;
            }
        }
        //When there is one Workgroup
        else if (this.upcomingEventsComponent.filteredLiveEvents.length == 1) {
            if (this.widgetTitle.length == 0) {
                this.mobileHeight = 3
            }
            else {
                this.mobileHeight = 4;
            }
        }
        //Mutliple Workgroups
        else {
            if (this.upcomingEventsComponent.filteredLiveEvents.length > 0) {
                this.mobileHeight = Math.ceil(this.upcomingEventsComponent.filteredLiveEvents.length * 1.2) + 2;
            }

            //else if (this.upcomingEventsComponent.filteredLiveEvents.length > 6) {
            //    this.mobileHeight = Math.ceil(6 * 1.5) + 2;
            //}

            if (this.widgetTitle.length != 0) {
                this.mobileHeight = this.mobileHeight + 1;
            }
        }

        if (currentHeight != this.mobileHeight && window.innerWidth <= 992 && this.widgetLoaded) {
            this.widgetContainer.populateMainandFlyout();
            this.widgetContainer.onResize(null);
        }

    }

    runHeightCheck() {
        this.calMobileHeight();
        this.widgetContainer.populateMainandFlyout();
    }
}

import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { APIV2AccessKey } from './classFiles/class.authorization';

@Injectable()
export class SharedService {
    // Observable string sources
    private emitChangeSource = new Subject<any>();
    private selectedTenantIdSource = new Subject<any>();
    private userAccessKeyFromSamlSource = new Subject<any>();
    private v2BearerTokenSource = new Subject<APIV2AccessKey>();

    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    selectedTenantId$ = this.selectedTenantIdSource.asObservable();
    userAccessKeyFromSaml$ = this.userAccessKeyFromSamlSource.asObservable();
    v2BearerToken$ = this.v2BearerTokenSource.asObservable();

    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    setSelectedTenantId(value: string): void {
        this.selectedTenantIdSource.next(value);
    }

    setUserAccessKeyFromSaml(value: string): void {
        this.userAccessKeyFromSamlSource.next(value);
    }

    setV2BearerToken(value: APIV2AccessKey): void {
        this.v2BearerTokenSource.next(value);
    }
}

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ProdGenApi } from './../../apiService/prodgen.api';
import {
	Content,
	ContentType_T,
	Process,
	Workflow,
	Step, Task,
	DescriptionFilter_T
} from "./../../apiService/classFiles/class.content";
import { TranslationService } from './../../services/TranslationService';
import { DiagramTreeService } from './../../services/diagram-tree.service';
import { share } from "rxjs/operators";
declare var $: any;

@Component({
  selector: 'app-workflowcontent',
  templateUrl: './workflowcontent.component.html',
  styleUrls: ['./workflowcontent.component.css']
})
export class WorkflowContentComponent implements OnInit {
    @Input('contentId') diagramId: string;
    @Input('contentType') diagramType: string;
    @Input('enrollmentId') enrollmentId?: string;
    contentItem: Content = new Content(); // good for any active piece of content..task, step, etc
    diagramItem: Content = new Content(); // always points to info about the current diagram (workflow or process)...so we don't have to reload constantly'
    description: string = "";
    name: string = "";
    steps: Array<Step> = new Array<Step>();
  initialTaskId: string = "";
  initiateStepId: string = "";
    previousTaskId: string = "";
    previousTaskName: string = "";
    procWorkflowName: string = "";
    procWorkflowDescription: string = "";
    copySteps: Array<Step> = new Array<Step>();
    isTask: boolean = false;
    isStep: boolean = false;
    isDeskTop: boolean = false;
    initiallyExpanded = false;
    initialSelectionId: string = "";
    previousSelectionId: string = "";
    previousProcessId: string = "";
    scrollToExpanded: boolean = false;
  type: string = "";
    isTaskCourseItem: boolean = false;
    externalsourcetype: string = "default";
    sourceurl: string = "";
    translatedTitle: string = "";
    translatedNames: { [key: string]: string } = {};

    navigateMode: number = 0;

  constructor(private pinnacleService: ProdGenApi, private _sanitizer: DomSanitizer, private translationService: TranslationService, private diagramTreeInteractionService: DiagramTreeService)
    {
    //this.scrollToExpanded = true;
    }

  ngOnInit() {
      window.scroll(0, 0);

      this.navigateMode = 0;
      this.pinnacleService.getSettingByName("LPWF_DISPLAY").subscribe(res => {
          if (res.settingValue == "Workflow Tree And Diagrm") {
              this.navigateMode = 0;
          }
          else if (res.settingValue == "Workflow Tree Only") {
              this.navigateMode = 1;
          }
          else {
              // in case settings does not exist, revert to user specified
              let navMode = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_lpworkflow_nav_mode"));
              if (navMode != null) {
                  if (navMode as number == 1) {
                      this.navigateMode = 1;
                  }
              }
          }


          $("#navModeList").val(this.navigateMode);
      });
    }

    onNavModeClicked() {
        var navMode = $('#navModeList').val();
        this.navigateMode = navMode;

        try {
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lpworkflow_nav_mode", JSON.stringify(this.navigateMode))
        }
        catch (err) {
            console.log(err);
        }

    }

  ngOnChanges(changes: SimpleChanges) {
      try {
          this.diagramId = changes['contentId'].currentValue;
      } catch (err) { };
      try {
          this.diagramType = changes['contentType'].currentValue;
      } catch (err) { };

    if (this.diagramType == 'workflow' || this.diagramType == 'process') {
      this.isTaskCourseItem = false;
      this.loadViewerContent();
    }
    else {
      this.isTaskCourseItem = true;
      this.loadTaskContent();
    }    

    //this.diagramTreeInteractionService.diagramId$.subscribe(ID => {

    //  this.diagramId = ID;
    //});

    //this.diagramTreeInteractionService.diagramType$.subscribe(type => {

    //  this.diagramType = type;
    //});

    this.diagramTreeInteractionService.selectContent$.pipe(share()).subscribe(content => {
      if (content.contentType == ContentType_T.workflow || content.contentType == ContentType_T.process) {
        this.diagramId = content.contentId.toString();

        if (content.contentType == 5) {
          this.type = "workflow";
        }
        else if (content.contentType == 6) {
          this.type = "process";
        }
        this.diagramType = this.type;
      }

	  else if (content.contentType == ContentType_T.task) {

		  if (this.enrollmentId == null) {
			  console.log("Task A");
			  this.pinnacleService.GetTask(content.contentId, DescriptionFilter_T.none).subscribe(res => {
				  //this.setupTaskDocumentViewer(res);
				  this.pinnacleService.GetProcess(res.processId, DescriptionFilter_T.formatted).subscribe(res2 => {
					  if (this.diagramId != res2.processId) {
						  this.diagramId = res2.processId as string;
						  this.diagramType = ContentType_T.process.toString();
						  this.initialTaskId = content.contentId.toString();
						  //this.trackingDiagramType = ContentType_T.task.toString();

						  this.pinnacleService.GetContentItem(res2.processId, DescriptionFilter_T.formatted).subscribe(res => {
							  this.diagramItem = res;

						  });
					  }
					  else {
						  this.initialTaskId = content.contentId.toString();
						  this.diagramType = ContentType_T.task.toString();
					  }

				  });
			  });
		  }
		  else {
			  console.log("Task Enrollment A");
			  this.pinnacleService.GetEnrollmentTask(this.enrollmentId, content.contentId, DescriptionFilter_T.none).subscribe(res => {
				  //this.setupTaskDocumentViewer(res);
				  this.pinnacleService.getEnrollmentProcess(this.enrollmentId, res.processId, DescriptionFilter_T.formatted).subscribe(res2 => {
					  if (this.diagramId != res2.processId) {
						  this.diagramId = res2.processId as string;
						  this.diagramType = ContentType_T.process.toString();
						  this.initialTaskId = content.contentId.toString();
						  //this.trackingDiagramType = ContentType_T.task.toString();

						  this.pinnacleService.getEnrollmentContentItem(this.enrollmentId, res2.processId, DescriptionFilter_T.formatted).subscribe(res => {
							  this.diagramItem = res;

						  });
					  }
					  else {
						  this.initialTaskId = content.contentId.toString();
						  this.diagramType = ContentType_T.task.toString();
					  }

				  });
			  });
		  }
        
      }

      else {
		  this.previousTaskId = this.initialTaskId;
		  if (this.enrollmentId == null) {
			  console.log("Step A");
			  this.pinnacleService.GetStep(content.contentId, DescriptionFilter_T.none).subscribe(res => {

				  //console.log("Step A");

				  this.pinnacleService.GetTask(res.taskId, DescriptionFilter_T.none).subscribe(res2 => {
					  //this.setupTaskDocumentViewer(res2);
					  if (this.diagramId != res2.processId) {
						  this.diagramId = res2.processId as string;
						  this.diagramType = "process";
						  //this.trackingDiagramType = ContentType_T.task.toString();

						  this.pinnacleService.GetContentItem(res2.processId, DescriptionFilter_T.formatted).subscribe(res => {
							  this.diagramItem = res;

						  });
					  }
					  else {
						  this.diagramType = ContentType_T.step.toString();
					  }
					  this.initialTaskId = res.taskId.toString();
					  this.initiateStepId = content.contentId.toString();
					  //this.diagramType = ContentType_T.task.toString();
				  });
			  });
		  }
		  else {
			  console.log("Step Enrollment A");
			  this.pinnacleService.GetEnrollmentStep(this.enrollmentId, content.contentId, DescriptionFilter_T.none).subscribe(res => {

				  console.log("Step Enrollment Found");

				  this.pinnacleService.GetEnrollmentTask(this.enrollmentId, res.taskId, DescriptionFilter_T.none).subscribe(res2 => {
					  //this.setupTaskDocumentViewer(res2);
					  if (this.diagramId != res2.processId) {
						  this.diagramId = res2.processId as string;
						  this.diagramType = "process";
						  //this.trackingDiagramType = ContentType_T.task.toString();

						  console.log("Task Enrollment Found");

						  this.pinnacleService.getEnrollmentContentItem(this.enrollmentId, res2.processId, DescriptionFilter_T.formatted).subscribe(res => {

							  console.log("Enrollment Content Item Found");

							  this.diagramItem = res;

						  });
					  }
					  else {
						  this.diagramType = ContentType_T.step.toString();
					  }
					  this.initialTaskId = res.taskId.toString();
					  this.initiateStepId = content.contentId.toString();
					  //this.diagramType = ContentType_T.task.toString();
				  });
			  });
		  }
      }
    })
    
  }


  loadViewerContent() {
      this.name = "";
      
      
	  this.steps = new Array<Step>();
	  this.description = this.translationService.getTranslationFileData("TS_Loading");
      if (this.diagramId != null && this.diagramId.length > 0) {
          if (this.enrollmentId == null) {
              this.pinnacleService.GetContentItem(this.diagramId, DescriptionFilter_T.formatted).subscribe(r => {
                  this.contentItem = r;
                  this.contentItem.imageURL = "../assets/images/workflows.svg";
                  this.diagramItem = this.contentItem; // always store the diagram level. the other content item can change with selections
                  this.description = r.description as string;
                  //this.name = this.getContentType(r.contentType) + ": " + r.name as string;
                  this.name = r.name as string;
                  


              });
          }
          else
          {
              this.pinnacleService.getEnrollmentContentItem(this.enrollmentId, this.diagramId, DescriptionFilter_T.formatted).subscribe(r => {
                this.contentItem = r;
                this.contentItem.imageURL = "../assets/images/workflows.svg";
                this.diagramItem = this.contentItem; // always store the diagram level. the other content item can change with selections
                this.procWorkflowDescription = r.description as string;
                  //this.procWorkflowName = this.getContentType(r.contentType) + ": " + r.name as string;
                  this.procWorkflowName = r.name as string;
                  this.getTranslatedTitle();
                this.isTask = false;
                  this.isStep = false;
                  
			  }, err => {
				  if (err.status == 409) {
					  console.log("data unavailable to subscription");
					  this.contentItem.contentId = this.diagramId;	
					  this.contentItem.imageURL = "../assets/images/workflows.svg";
					  this.contentItem.description = "This content is unavailable with your subscription selection.";
				  }
			  });
          }

      }
    }

    getTranslatedTitle() {
        if (this.procWorkflowName != null) {
            if (this.translatedNames[this.procWorkflowName] != null) {
                this.translatedTitle = this.translatedNames[this.procWorkflowName];
            }
            else {
                this.pinnacleService.translateText(this.procWorkflowName, "en", false).subscribe(res => {
                    this.translatedTitle = res;
                    this.translatedNames[this.procWorkflowName] = res;
                });
            }
        }
    }

  loadTaskContent() {
      this.name = "";
      
      
    this.steps = new Array<Step>();
    this.description = "";
    if (this.diagramId != null && this.diagramId.length > 0) {

          this.pinnacleService.getEnrollmentContentItem(this.enrollmentId, this.diagramId, DescriptionFilter_T.formatted).subscribe(r => {
            this.description = r.description as string;
              //this.name = this.getContentType(r.contentType) + ": " + r.name as string;
              this.name = r.name as string;
              

        }, err => {
          if (err.status == 409) {
            console.log("data unavailable to subscription");
            this.contentItem.contentId = this.diagramId;
            this.contentItem.imageURL = "../assets/images/workflows.svg";
            this.contentItem.description = "This content is unavailable with your subscription selection.";
          }
        });
    }

      this.pinnacleService.getEnrollmentTaskSteps(this.enrollmentId, this.diagramId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
        this.steps = stepsRes;
        for (var i = 0; i < this.steps.length; i++) {
            this.pinnacleService.GetStep(this.steps[i].stepId).subscribe(res => {
                //console.log("Step J");
                if (res.isDoc == true) {
                    this.setupMultiStepDocumentViewer(res);
                }
            });
            
        }
    });

  }

  diagramSelectionChanged(event) {
      this.steps = new Array<Step>();

      //if (this.diagramItem.contentId == null || this.diagramItem.contentId.length == 0) {
      //    return;
      //}


      let selItem: Content = event.selectedItem;
    this.processSelChange(selItem);
    //event.stopPropagation();
  }

  processSelChange(selItem: Content) {

      let url: string = "";


      if (selItem.contentId == null || selItem.contentId.length == 0) {
        this.description = ""; //this.diagramItem.description as string;
          this.name = ""; //this.getContentType(this.diagramItem.contentType) + ": " + this.diagramItem.name as string;
          
          
        this.isTask = false;
        this.isStep = false;
      }
      else {
          
          if (this.enrollmentId == null) {
              this.pinnacleService.GetContentItem(selItem.contentId, DescriptionFilter_T.formatted).subscribe(res => {
                  this.description = res.description as string;
                  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
                  this.name = res.name as string;
                  


                  // if this was a task, load steps
                  if (res.contentType == ContentType_T.task) {
                      this.pinnacleService.GetTaskSteps(res.contentId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
                          this.steps = stepsRes;
                          for (var i = 0; i < this.steps.length; i++) {
                              if (this.steps[i].isDoc == true) {
                                  this.setupMultiStepDocumentViewer(this.steps[i]);
                              }
                          }
                      });
                  }
              });
          }
          else
          {
            let changedDiagramView = false;

            if (selItem.contentType == ContentType_T.workflow && selItem.description == "workflow") {
              //this.diagramType = "workflow";
              changedDiagramView = true;
              this.procWorkflowName = "";
                this.procWorkflowDescription = "";
                this.translatedTitle = "";
            }
            else if (selItem.contentType == ContentType_T.process && selItem.description == "process") {
              //this.diagramType = "process";
              changedDiagramView = true;
              this.procWorkflowName = "";
                this.procWorkflowDescription = "";
                this.translatedTitle = "";
            }

            
            if (this.previousTaskId != this.initialTaskId) {
              this.name = "";
                this.description = "";
                
                
			  }

			  if (this.enrollmentId == null) {

				  this.pinnacleService.GetContentItem(selItem.contentId, DescriptionFilter_T.formatted).subscribe(res => {

					  if (res.contentType == ContentType_T.workflow || res.contentType == ContentType_T.process) {
						  this.procWorkflowDescription = res.description as string;
						  //this.procWorkflowName = this.getContentType(res.contentType) + ": " + res.name as string;
						  this.procWorkflowName = res.name as string;
						  this.getTranslatedTitle();
						  this.isTask = false;
						  this.isStep = false;
					  }

					  else {
						  if (res.contentType == ContentType_T.task) {
							  this.description = res.description as string;
							  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
							  this.name = res.name as string;


							  //this.previousTaskName = this.name;

							  this.isTask = true;
							  this.isStep = false;
						  }

						  else {
							  this.isTask = true;
							  this.isStep = true;
						  }

						  if (res.contentType == ContentType_T.task) {
							  this.pinnacleService.GetTask(selItem.contentId, DescriptionFilter_T.formatted).subscribe(res2 => {
								  this.setupTaskDocumentViewer(res2);
								  if (this.diagramId != this.previousProcessId) {
									  this.pinnacleService.GetProcess(res2.processId, DescriptionFilter_T.formatted).subscribe(res3 => {
										  if (selItem.contentType == ContentType_T.task) {
											  this.previousProcessId = res2.processId as string;
											  this.procWorkflowDescription = res3.description as string;
											  //this.procWorkflowName = "Process: " + res3.name as string;
											  this.procWorkflowName = res3.name as string;
											  this.getTranslatedTitle();
										  }

										  else
											  this.procWorkflowDescription = "";

										  //if (selItem.contentType ==)
									  });
								  }
							  });
						  }

						  if (res.contentType == ContentType_T.step) {
							  this.pinnacleService.GetStep(selItem.contentId, DescriptionFilter_T.none).subscribe(res4 => {
								  //console.log("Step I");
								  if (this.previousTaskId != res4.taskId) {
									  this.pinnacleService.GetTask(res4.taskId, DescriptionFilter_T.formatted).subscribe(res5 => {
										  this.setupTaskDocumentViewer(res5);
										  this.name = res5.name as string;



										  this.description = res5.description as string;
										  this.pinnacleService.GetProcess(res5.processId).subscribe(op => {

											  if (res.contentType == ContentType_T.step)
												  this.procWorkflowDescription = op.description as string;
											  else
												  this.procWorkflowDescription = "";

											  //this.procWorkflowName = "Process: " + op.name as string;
											  this.procWorkflowName = op.name as string;
											  this.getTranslatedTitle();
										  });
									  });
								  }

							  });

						  }
						  //this.isTask = true;
					  }


					  // if this was a task, load steps
					  if (res.contentType == ContentType_T.task) {
						  //this.description = res.description as string;
						  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
						  //this.isTask = true;
						  this.copySteps = [];
						  this.pinnacleService.GetTaskSteps(selItem.contentId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
							  this.copySteps = stepsRes;
							  for (var i = 0; i < this.copySteps.length; i++) {
								  if (this.copySteps[i].isDoc == true) {
									  this.setupMultiStepDocumentViewer(this.copySteps[i]);
								  }


							  }
						  });
					  }

					  if (res.contentType == ContentType_T.step) {
						  this.pinnacleService.GetStep(res.contentId, DescriptionFilter_T.none).subscribe(res2 => {
							  //console.log("Step H");
							  if (this.previousTaskId != res2.taskId) {
								  //this.name = this.previousTaskName;
								  this.copySteps = [];
							  }

							  if (this.copySteps.length == 0) {
								  this.pinnacleService.GetTaskSteps(res2.taskId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
									  this.initialSelectionId = res.contentId as string;

									  this.copySteps = stepsRes;
									  for (var i = 0; i < this.copySteps.length; i++) {
										  if (this.copySteps[i].isDoc == true) {
											  this.setupMultiStepDocumentViewer(this.copySteps[i]);
										  }
									  }
									  this.initiallyExpanded = false;
									  if (res.contentId == this.initialTaskId) {
										  this.initialTaskId = "";

									  }
									  else {
										  if (stepsRes.length > 0) {
											  //for (var i = 0; i < stepsRes.length; i++) {
											  //  if (res.contentId == stepsRes[i].stepId) {
											  //    this.initialSelectionId = stepsRes[i].stepId as string;
											  //  }
											  //}
											  this.initialSelectionId = res.contentId as string;
											  //this.previousSelectionId = this.initialSelectionId;
										  }
									  }




								  });
							  }

							  else {
								  //this.steps = this.copySteps;
								  this.initialSelectionId = res.contentId as string;
								  // this.previousSelectionId = this.initialSelectionId;
								  this.initiallyExpanded = false;
								  //this.getDefaultExpandedClass(this.initialSelectionId);
							  }

						  });
					  }
				  }, err => {
					  if (err.status == 409) {
						  console.log("data unavailable to subscription");
						  this.contentItem.contentId = selItem.contentId;
						  this.contentItem.description = "This content is unavailable with your subscription selection.";
					  }
				  });
			  }
			  else {

				  this.pinnacleService.getEnrollmentContentItem(this.enrollmentId, selItem.contentId, DescriptionFilter_T.formatted).subscribe(res => {

					  if (res.contentType == ContentType_T.workflow || res.contentType == ContentType_T.process) {
						  this.procWorkflowDescription = res.description as string;
						  //this.procWorkflowName = this.getContentType(res.contentType) + ": " + res.name as string;
						  this.procWorkflowName = res.name as string;
						  this.getTranslatedTitle();
						  this.isTask = false;
						  this.isStep = false;
					  }

					  else {
						  if (res.contentType == ContentType_T.task) {
							  this.description = res.description as string;
							  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
							  this.name = res.name as string;


							  //this.previousTaskName = this.name;

							  this.isTask = true;
							  this.isStep = false;
						  }

						  else {
							  this.isTask = true;
							  this.isStep = true;
						  }

						  if (res.contentType == ContentType_T.task) {
							  this.pinnacleService.GetEnrollmentTask(this.enrollmentId, selItem.contentId, DescriptionFilter_T.formatted).subscribe(res2 => {
								  this.setupTaskDocumentViewer(res2);
								  if (this.diagramId != this.previousProcessId) {
									  this.pinnacleService.getEnrollmentProcess(this.enrollmentId, res2.processId, DescriptionFilter_T.formatted).subscribe(res3 => {
										  if (selItem.contentType == ContentType_T.task) {
											  this.previousProcessId = res2.processId as string;
											  this.procWorkflowDescription = res3.description as string;
											  //this.procWorkflowName = "Process: " + res3.name as string;
											  this.procWorkflowName = res3.name as string;
											  this.getTranslatedTitle();
										  }

										  else
											  this.procWorkflowDescription = "";

										  //if (selItem.contentType ==)
									  });
								  }
							  });
						  }

						  if (res.contentType == ContentType_T.step) {
							  this.pinnacleService.GetEnrollmentStep(this.enrollmentId, selItem.contentId, DescriptionFilter_T.none).subscribe(res4 => {
								  //console.log("Step I");
								  if (this.previousTaskId != res4.taskId) {
									  this.pinnacleService.GetEnrollmentTask(this.enrollmentId, res4.taskId, DescriptionFilter_T.formatted).subscribe(res5 => {
										  this.setupTaskDocumentViewer(res5);
										  this.name = res5.name as string;



										  this.description = res5.description as string;
										  this.pinnacleService.getEnrollmentProcess(this.enrollmentId, res5.processId).subscribe(op => {

											  if (res.contentType == ContentType_T.step)
												  this.procWorkflowDescription = op.description as string;
											  else
												  this.procWorkflowDescription = "";

											  //this.procWorkflowName = "Process: " + op.name as string;
											  this.procWorkflowName = op.name as string;
											  this.getTranslatedTitle();
										  });
									  });
								  }

							  });

						  }
						  //this.isTask = true;
					  }


					  // if this was a task, load steps
					  if (res.contentType == ContentType_T.task) {
						  //this.description = res.description as string;
						  //this.name = this.getContentType(res.contentType) + ": " + res.name as string;
						  //this.isTask = true;
						  this.copySteps = [];
						  this.pinnacleService.getEnrollmentTaskSteps(this.enrollmentId, selItem.contentId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
							  this.copySteps = stepsRes;
							  for (var i = 0; i < this.copySteps.length; i++) {
								  if (this.copySteps[i].isDoc == true) {
									  this.setupMultiStepDocumentViewer(this.copySteps[i]);
								  }


							  }
						  });
					  }

					  if (res.contentType == ContentType_T.step) {
						  this.pinnacleService.GetEnrollmentStep(this.enrollmentId, res.contentId, DescriptionFilter_T.none).subscribe(res2 => {
							  //console.log("Step H");
							  if (this.previousTaskId != res2.taskId) {
								  //this.name = this.previousTaskName;
								  this.copySteps = [];
							  }

							  if (this.copySteps.length == 0) {
								  this.pinnacleService.getEnrollmentTaskSteps(this.enrollmentId, res2.taskId, - 1, 0, DescriptionFilter_T.formatted).subscribe(stepsRes => {
									  this.initialSelectionId = res.contentId as string;

									  this.copySteps = stepsRes;
									  for (var i = 0; i < this.copySteps.length; i++) {
										  if (this.copySteps[i].isDoc == true) {
											  this.setupMultiStepDocumentViewer(this.copySteps[i]);
										  }
									  }
									  this.initiallyExpanded = false;
									  if (res.contentId == this.initialTaskId) {
										  this.initialTaskId = "";

									  }
									  else {
										  if (stepsRes.length > 0) {
											  //for (var i = 0; i < stepsRes.length; i++) {
											  //  if (res.contentId == stepsRes[i].stepId) {
											  //    this.initialSelectionId = stepsRes[i].stepId as string;
											  //  }
											  //}
											  this.initialSelectionId = res.contentId as string;
											  //this.previousSelectionId = this.initialSelectionId;
										  }
									  }




								  });
							  }

							  else {
								  //this.steps = this.copySteps;
								  this.initialSelectionId = res.contentId as string;
								  // this.previousSelectionId = this.initialSelectionId;
								  this.initiallyExpanded = false;
								  //this.getDefaultExpandedClass(this.initialSelectionId);
							  }

						  });
					  }
				  }, err => {
					  if (err.status == 409) {
						  console.log("data unavailable to enrollment");
						  this.contentItem.contentId = selItem.contentId;
						  this.contentItem.description = "This content is unavailable with your enrollment.";
					  }
				  });


			  }
          }

      }
  }

  expandAllSteps() {
      for (let c of this.steps) {

          $('#collapse' + c.stepId).collapse("show");
      }
  }

  collapseAllSteps() {
      for (let c of this.steps) {

          $('#collapse' + c.stepId).collapse("hide");
      }
  }

  getActiveDesc(): SafeHtml {
      return this._sanitizer.bypassSecurityTrustHtml(this.description);
  }

  getActiveProcWorkflowDesc(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.procWorkflowDescription);
  }

  getStepDesc(step: Step): SafeHtml {
      return this._sanitizer.bypassSecurityTrustHtml(step.description as string);
  }

  getContentType(r: ContentType_T): string {
      let image: string = "";

      switch (r) {
          case ContentType_T.workflow:
              image = "Workflow";
              break;
          case ContentType_T.process:
              image = "Process";
              break;
          case ContentType_T.task:
              image = "Task";
              break;
          case ContentType_T.step:
              image = "Step";
              break;
          case ContentType_T.cheatsheet:
              image = "Cheat Sheet";
              break;
          case ContentType_T.chatLog:
              image = "Saved Chat";
              break;
          case ContentType_T.video:
              image = "Video";
              break;
          case ContentType_T.course:
              image = "Course";
              break;
          case ContentType_T.learningpath:
              image = "Learning Path";
              break;
      }
      return image;
  }

  getExpandedIconClass(elmId: string): string {
      var isExpanded = $(elmId).hasClass("in");
      if (isExpanded == true) {
          return "fa fa-minus";
      }
      return "fa fa-plus";

  }

  getDefaultExpandedClass(id: string): string {
    if (this.initialSelectionId.length > 0 && this.initiallyExpanded == false) {
      if (id == this.initialSelectionId) {
        this.initiallyExpanded = true;
        $('#collapse' + id).collapse("show");

        if (this.scrollToExpanded == true) {
          this.scrollToObject('#collapse' + id);
          this.scrollToExpanded = false;
        }

        if (this.previousSelectionId != "" && this.previousSelectionId != id) {
          $('#collapse' + this.previousSelectionId).collapse("hide");

        }
        this.previousSelectionId = this.initialSelectionId;
      }
    }
      return "";
  }

  scrollToObject(obj: string) {
      $('html, body').animate({
          scrollTop: $(obj).offset().top - 50
      }, 1000);
    }

    setupTaskDocumentViewer(task: Task) {
        
            if (task.isDoc == true && task.docID != "00000000-0000-0000-0000-000000000000") { //check if sheet uses document
                this.pinnacleService.GetCheatSheet(task.docID).subscribe(res => {
                    let attacheddoc = res.externalDocURL;
                    let securekeysplit = attacheddoc.split("?");
                    let extensionsplit = securekeysplit[0].split("."); //get the extension of the external doc url
                    switch (extensionsplit[(extensionsplit.length - 1)]) { //set the externalsourcetype depending on the extension
                        case "pdf":
                            {
                                this.externalsourcetype = "pdf";
                                this.sourceurl = attacheddoc.toString();
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>'
                            }
                            break;
                        case "html":
                        case "htm":
                            {
                                this.externalsourcetype = "html";
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + attacheddoc.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >'
                            }
                            break;
                        case "docx":
                        case "dotx":
                        case "xlsx":
                        case "xlsb":
                        case "xls":
                        case "xlsm":
                        case "ppt":
                        case "pptx":
                        case "ppsx":
                        case "pps":
                        case "potx":
                        case "ppsm":
                            {

                                this.externalsourcetype = "microsoft";
                                this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(attacheddoc.toString()) + "&amp;embedded=true";
                                document.getElementById("viewercontainertask").innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>'
                            }
                            break;
                        default:
                            {
                                if (res.useExternalDoc == true) {
                                    this.externalsourcetype = "default";
                                    document.getElementById("viewercontainertask").innerHTML = '';
                                }
                                else {
                                    this.externalsourcetype = "default";
                                    this.description = res.description.toString();
                                    document.getElementById("viewercontainertask").innerHTML = '';
                                }

                            }
                            break;
                    }

                });

            }
            else {
                this.externalsourcetype = "default"
                document.getElementById("viewercontainertask").innerHTML = '';
            }
       
    }

    setupMultiStepDocumentViewer(step: Step) {
        if (step.isDoc == true && step.docID != "00000000-0000-0000-0000-000000000000") { //check if sheet uses document
            this.pinnacleService.GetCheatSheet(step.docID).subscribe(res => {
                let attacheddoc = res.externalDocURL;
                let securekeysplit = attacheddoc.split("?");
                let extensionsplit = securekeysplit[0].split("."); //get the extension of the external doc url
                switch (extensionsplit[(extensionsplit.length - 1)]) { //set the externalsourcetype depending on the extension
                    case "pdf":
                        {
                            this.externalsourcetype = "pdf";
                            this.sourceurl = attacheddoc.toString();
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>'
                        }
                        break;
                    case "html":
                    case "htm":
                        {
                            this.externalsourcetype = "html";
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + attacheddoc.toString() + '"  style="width: 100%; height:60vh;" marginwidth = "0" marginheight = "0" frameborder = "0" vspace = "0" hspace = "0" >'
                        }
                        break;
                    case "docx":
                    case "dotx":
                    case "xlsx":
                    case "xlsb":
                    case "xls":
                    case "xlsm":
                    case "ppt":
                    case "pptx":
                    case "ppsx":
                    case "pps":
                    case "potx":
                    case "ppsm":
                        {
                            this.externalsourcetype = "microsoft";
                            this.sourceurl = "https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(attacheddoc.toString()) + "&amp;embedded=true";
                            document.getElementById("stepdesc" + step.stepId).innerHTML = '';
                            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '<iframe src="' + this.sourceurl + '" style="width: 100%; height: 60vh;"></iframe>'
                        }
                        break;
                    default:
                        {
                            if (res.useExternalDoc == true) {
                                this.externalsourcetype = "default";
                                step.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
                            }
                            else {
                                this.externalsourcetype = "default";
                                step.description = res.description.toString();
                                //this.description = res.description.toString();
                                document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
                            }
                        }
                        break;
                }
            });
        }
        else {
            this.externalsourcetype = "default"
            document.getElementById("viewercontainerstep" + step.stepId).innerHTML = '';
        }
    }

    stepItemClicked() {
        //console.log("Click Detected");
    }
}

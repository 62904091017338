import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AssetLibrary, AssetLibraryProduct } from '../../../apiService/classFiles/class.assetlibrary';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from './../../../apiService/prodgen.api';
import { WidgetProperties, User, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { AppComponent } from '../../../app.component';
import { Router } from '@angular/router';
import { CorporatepanelComponent } from '../../corporatepanel/corporatepanel.component';
import { WidgetLibraryService } from '../../../services/widget-library.service';

declare var $: any;
@Component({
    selector: 'app-asset-library-widget',
    providers:[CorporatepanelComponent],
  templateUrl: './asset-library-widget.component.html',
  styleUrls: ['./asset-library-widget.component.css']
})





export class AssetLibraryWidgetComponent extends WidgetComponent implements OnInit,AfterViewInit {
    static key = "AssetLibraryWidgetComponent";

    widgetLoaded: boolean = false;

    privateAssetsExist: boolean = false;
    //currentUser: User = new User();
    assetLibrariesData: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibraryLoaded: boolean = false;

    assetLibrariesSubscription: Array<AssetLibrary> = new Array<AssetLibrary>();
    assetLibrarySubscriptionLoaded: boolean = false;

    currentUser: User = new User();

    addfunction: boolean = true;
    
    overlayDisplayClass = "widget-overlay-style-page";


     //update in default prop, and in html

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private router: Router, private corppanel:CorporatepanelComponent, private widgetlibsvc:WidgetLibraryService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 6;
        this.widgetWidth = 2;

        
        this.widgetTitle = "Asset Libraries";
        this.widgetName = "Asset Library";
        this.widgetDesc = "Displays the asset library.";

        //End Widget Defaults

        this.pinnacleService.getCurrentUser().subscribe(u => {
            // try to load up any previous information. this will keep the screen from flickering..?
            try {
                //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
                this.assetLibrariesData = JSON.parse(localStorage.getItem(this.currentUser.userId + "_asset_library"));

            }
            catch (err) {
                console.log(err);
            }

            this.loadAssetLibrary();
        }
        );
    }



    ngOnInit() {
        super.ngOnInit();
        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "panel";
        }
        this.createProperties();
        this.Load();


    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    loadAssetLibrary() {
        this.assetLibrariesData = new Array<AssetLibrary>();;

        //Asset Library
        this.pinnacleService.getAssetLibraries(true, true, false).subscribe(s => {


            let assetLibrariesDatatoRemove = new Array<AssetLibrary>();
            let assetLibrariesDataAddedto = new Array<AssetLibrary>();
            let assetLibraryProdsRemoved = new Array<AssetLibraryProduct>();

            this.assetLibrariesData = s;

            //go through asset libraries and see if any have the same name.
            //If they do we want to combine those asset libraries and only
            //  display distinct products under them.

            for (var a = 0; a < this.assetLibrariesData.length; a++) {
                for (var b = 0; b < this.assetLibrariesData.length; b++) {

                    //if ((this.assetLibrariesData[a].name == "Bluebeam" || this.assetLibrariesData[a].name == "Bluebeam Revu") && (this.assetLibrariesData[b].name == "Bluebeam" || this.assetLibrariesData[b].name == "Bluebeam Revu")) {
                    //    console.log("stopping point");
                    //}

                    if (this.assetLibrariesData[a].name == this.assetLibrariesData[b].name && this.assetLibrariesData[a].assetLibraryId != this.assetLibrariesData[b].assetLibraryId && (this.assetLibrariesData[a].assetLibraryId != "00000000-0000-0000-0000-000000000000" && this.assetLibrariesData[b].assetLibraryId != "00000000-0000-0000-0000-000000000000")) {//makes sure they have the same name but are not the same lib
                        if (this.assetLibrariesData[a].isSubcription == false && this.assetLibrariesData[b].isSubcription == true) {// a is custom b is not, move a into b
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[a]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[b]);
                            for (var c = 0; c < this.assetLibrariesData[a].productCollection.length; c++) {
                                this.assetLibrariesData[b].productCollection.push(this.assetLibrariesData[a].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(a, 1);
                        }
                        else if (this.assetLibrariesData[a].isSubcription == true && this.assetLibrariesData[b].isSubcription == false) {// b is custom a is not, move b into a
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[b]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[a]);
                            for (var c = 0; c < this.assetLibrariesData[b].productCollection.length; c++) {
                                this.assetLibrariesData[a].productCollection.push(this.assetLibrariesData[b].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(b, 1);
                        }
                        else if (this.assetLibrariesData[a].isSubcription == this.assetLibrariesData[b].isSubcription) {// both have same subcription status, direction doesnt matter
                            assetLibrariesDatatoRemove.push(this.assetLibrariesData[a]);
                            assetLibrariesDataAddedto.push(this.assetLibrariesData[b]);
                            for (var c = 0; c < this.assetLibrariesData[a].productCollection.length; c++) {
                                this.assetLibrariesData[b].productCollection.push(this.assetLibrariesData[a].productCollection[c]);
                            }
                            this.assetLibrariesData.splice(a, 1);
                        }
                    }
                }
            }

            sessionStorage.setItem("AssetLibsRemoved", JSON.stringify(assetLibrariesDatatoRemove));
            sessionStorage.setItem("AssetLibsAddedto", JSON.stringify(assetLibrariesDataAddedto));
            //go throught the assetLibrariesDataAddedto and clean up duplicate products.
            //Add the removed products to local storage so it can be accessed once a product is clicked.

            for (var a = 0; a < assetLibrariesDataAddedto.length; a++) {
                for (var b = 0; b < this.assetLibrariesData.length; b++) {
                    if (assetLibrariesDataAddedto[a].assetLibraryId == this.assetLibrariesData[b].assetLibraryId) {
                        for (var c = 0; c < this.assetLibrariesData[b].productCollection.length; c++) {
                            for (var d = 0; d < this.assetLibrariesData[b].productCollection.length; d++) {
                                if (this.assetLibrariesData[b].productCollection[c].name == this.assetLibrariesData[b].productCollection[d].name && this.assetLibrariesData[b].productCollection[c].productId != this.assetLibrariesData[b].productCollection[d].productId) {
                                    assetLibraryProdsRemoved.push(this.assetLibrariesData[b].productCollection[d]);
                                    this.assetLibrariesData[b].productCollection.splice(d, 1);
                                    d = d - 1;
                                }
                            }
                        }
                    }
                }
            }

            sessionStorage.setItem("duplicateProds", JSON.stringify(assetLibraryProdsRemoved));

            //remove from assetLibrariesData items in assetLibrariesDatatoRemove.

            //for (var a = 0; a < assetLibrariesDatatoRemove.length; a++) {
            //  for (var b = 0; b < this.assetLibrariesData.length; b++) {
            //    if (assetLibrariesDatatoRemove[a].assetLibraryId == this.assetLibrariesData[b].assetLibraryId) {
            //      this.assetLibrariesData.splice(b, 1);
            //    }
            //  }
            //}
            for (var i = 0; i < this.assetLibrariesData.length; i++) {
                this.assetLibrariesData[i].productCollection = this.assetLibrariesData[i].productCollection.sort((a: any, b: any) => {
                    var x = a.name;
                    var y = b.name;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
            }
            this.assetLibraryLoaded = true;
            /// write to Local Storage for this user
            try {
                localStorage.setItem(this.currentUser.userId + "_asset_library", JSON.stringify(this.assetLibrariesData))
            }
            catch (err) {
                console.log(err);
            }


            this.assetLibrariesData.forEach(x => {
                if (x.isSubcription) {
                    this.assetLibrarySubscriptionLoaded = true;
                }
                else {
                    this.privateAssetsExist = true;
                }
            });

        }, e => {

        });
    }

    onReloadAssetLibrary() {
        this.pinnacleService.invlidateCacheStringContaining("assetLibraries");
        this.assetLibraryLoaded = false;
        this.loadAssetLibrary();
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "PersonalResourceAccessWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }


    Load() {
        super.Load();

        //The rest of the load function will be widget specific

    }

    createProperties() {

        super.createProperties();
        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle();

        super.createPropertiesMobileOptions();

    }

    //END WIDGET REQUIRED CALLS


    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }


    getExpandedIconClass(elmId: string): string {

        //return "fa fa-minus";
        return "fa fa-plus";
    }

    onFacetHeaderClicked(a: AssetLibrary) {
        this.getExpandedIconClass("#Wcollapse_" + a.assetLibraryId);

        var isExpanded = $("#Wimage_" + a.assetLibraryId).hasClass("fa-minus");
        $("#Wimage_" + a.assetLibraryId).toggleClass("fa-minus");
        $("#Wimage_" + a.assetLibraryId).toggleClass("fa-plus");

        if (this.addfunction == true) {
            $(".collapse").on('shown.bs.collapse', () => {
                this.widgetContainer.onWidgetExpand(this.widgetID);
            });
            $(".collapse").on('hidden.bs.collapse', () => {
                this.widgetContainer.onWidgetCollapse(this.widgetID);
            });
            this.addfunction = false;
        }

        let corppanels = document.getElementsByClassName("corppanel panel-collapse in");
        for (var i = 0; i < corppanels.length; i++) {
            
            let textstring = corppanels[i].id.replace("collapse_", "image_");
            $('#' + textstring).toggleClass("fa-minus");
            $('#' + textstring).toggleClass("fa-plus");
            $('#' + corppanels[i].id).collapse('toggle');
        }

        this.corppanel.onFacetHeaderClicked(a);
        $('#collapse_' + a.assetLibraryId).collapse('toggle');

    }



    assetLibraryProductHREF(product: AssetLibraryProduct, asset: AssetLibrary): string {
        let url: string = "";
        //url += "search?category=" + SearchComponent.encodeSearchParameters(product.name as string);
        url += "product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + this.encodeVarForUrl(product.name) + "&isprod=true" + "&issubscription=" + asset.isSubcription;
        return url;

    }

    assetLibraryAssetHREF(asset: AssetLibrary): string {
        let url: string = "";
        //url += "search?category=" + SearchComponent.encodeSearchParameters(asset.name as string);
        //url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&name=" + "" + "&isprod=true";

        url += "product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + "" + "&isprod=true" + "&issubscription=" + asset.isSubcription;
        return url;

    }

    selectAsset(asset: AssetLibrary) {
        this.router.navigateByUrl("product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + "" + "&isprod=true" + "&issubscription=" + asset.isSubcription);
        this.widgetlibsvc.selectedAssetLibID = asset.assetLibraryId;
        //this.location.go("/product-listing?proid=" + asset.productCollection[0].productId + "&astid=" + asset.assetLibraryId + "&libname=" + asset.name + "&name=" + "" + "&isprod=true");
        //this.loadWorkgroupData(w);
        //this.productlisting.LoadData();

    }
    selectProduct(product: AssetLibraryProduct, asset: AssetLibrary) {
        this.router.navigateByUrl("product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + this.encodeVarForUrl(asset.name) + "&name=" + this.encodeVarForUrl(product.name) + "&isprod=true" + "&issubscription=" + asset.isSubcription);
        this.widgetlibsvc.selectedAssetLibID = product.assetLibraryId;
        // this.location.go("/product-listing?proid=" + product.productId + "&astid=" + product.assetLibraryId + "&libname=" + asset.name + "&name=" + product.name + "&isprod=true");
        //this.loadWorkgroupData(w);
        //this.productlisting.LoadData();

    }

    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }

    encodeVarForUrl(text: string) {
        return text.replace("&", "%26").replace("?", "%3F");
    }


}

import { Component, EventEmitter, Input, AfterViewInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'ep-aichat-prompt',
  templateUrl: './aichat-prompt.component.html',
  styleUrls: ['./aichat-prompt.component.css']
})
export class AIChatPromptComponent implements AfterViewInit {
  @Output() sendChat = new EventEmitter<string>();
  @Output() clearChat = new EventEmitter<string>();

  form = new FormGroup({
    prompt: new FormControl('', null)
  });


    ngAfterViewInit() {
        if (document.getElementById("chat-prompt-area") != null) {
            document.getElementById("chat-prompt-area").focus();
        }
  }


  onSendButtonClicked(): void {
    const prompt = this.form.get('prompt').value;

    this.sendChat.emit(prompt);

    this.form.get('prompt').setValue('');
  }

  onClearButtonClicked(): void {
    this.clearChat.emit('');
}

  checkForEnterKey(event) {
  if (event.which === 13 && !event.shiftKey) {
    if (!event.repeat) {
      this.onSendButtonClicked();
    }

    event.preventDefault(); // Prevents the addition of a new line in the text field
  }
}
}


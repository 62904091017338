import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { WidgetProperties } from '../../../apiService/classFiles/class.users';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetComponent } from '../../widget/widget.component';

declare var $: any;

@Component({
  selector: 'app-image-widget',
  templateUrl: './image-widget.component.html',
    styleUrls: ['./image-widget.component.css'],
    providers: [{provide:WidgetComponent, useExisting:ImageWidgetComponent }]
})
export class ImageWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "ImageWidgetComponent";

    imageURL: string = "https://therightsofnature.org/wp-content/uploads/2018/01/turkey-3048299_1920-1366x550.jpg";
    bgcolor: string = "#FFFFFF";
    


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private _sanitizer: DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
        this.widgetWidth = 2;
        this.widgetHeight = 4;
    }

    ngOnInit() {
        this.widgetName = "Display Image";
        this.widgetDesc = "Shows a sample image with a background color";

        super.ngOnInit();
        this.createProperties();
        this.Load();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        super.Load();
    }

    getImageUrl(): SafeStyle{
        return this._sanitizer.bypassSecurityTrustStyle("url(\""+this.imageURL+"\")");

    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        if (newprops.findIndex(x => x.propName == "Background Color") != -1) {
            this.bgcolor = newprops[newprops.findIndex(x => x.propName == "Background Color")].propValue;
        }
        if (newprops.findIndex(x => x.propName == "Image URL") != -1) {
            this.imageURL = newprops[newprops.findIndex(x => x.propName == "Image URL")].propValue;
        }

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "ImageWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        super.widgetArePropertiesLoaded();

    }
    removeWidget(id: string) {
        this.widgetContainer.containerInfo.widgets.splice(this.widgetContainer.containerInfo.widgets.findIndex(x => x.id == id));
        this.widgetContainer.containerWidgets.splice(this.widgetContainer.containerWidgets.findIndex(x => x.id == id));

    }

    FauxDBLoad() {

        let img: WidgetProperties = new WidgetProperties();
        img.propID = "78931321";
        img.propName = "Image URL"; 
        img.propType = "string";
        img.propValue = this.imageURL;

        this.widgetProps.push(img);

        let c: WidgetProperties = new WidgetProperties();
        c.propID = "12356456";
        c.propName = "Background Color";
        c.propType = "color";
        c.propValue = this.bgcolor;

        this.widgetProps.push(c);
    }

    createProperties() {

        super.createProperties();

        let img: WidgetProperties = new WidgetProperties();
        img.propID = "IMG_URL";
        img.propName = "Image URL";
        img.propType = "string";
        img.propValue = this.imageURL;

        this.widgetProps.push(img);

        let c: WidgetProperties = new WidgetProperties();
        c.propID = "BG_COLOR";
        c.propName = "Background Color";
        c.propType = "color";
        c.propValue = this.bgcolor;

        this.widgetProps.push(c);
    }
}

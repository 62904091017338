import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { Observable } from 'rxjs';
import { CompanyNewsItem, Theme, ThemeItem } from '../apiService/classFiles/class.organizations';
import { PartnerNewsItem } from '../apiService/classFiles/class.partners';
import { apiContainerWidgets, Role, RoleLayoutAssignment, UserPermissions, UserSettingPost, WidgetContainerBasicInfo, WidgetContainerInfo, WidgetLayoutInfo, WidgetLayoutPage } from '../apiService/classFiles/class.users';
import { AppComponent, currenttheme, defpreviewtheme } from '../app.component';
import { BrandingUpdateServiceService } from '../services/branding-update-service.service';
import { DialogService } from '../services/dialog.service';
import { TranslationService } from '../services/TranslationService';
import { WidgetContainerComponent } from '../templates/widget-container/widget-container.component';
import { SettingType_T } from './../apiService/classFiles/class.users';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { WidgetLibraryEntry, WidgetLibraryService } from './../services/widget-library.service';

declare var $: any;

@Component({
  selector: 'branding',
  templateUrl: './branding.component.html',
  providers: [ProdGenApi, DialogService],
    styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnInit {
    @ViewChild('companyContainer', { static: false }) WidContainerCompany: WidgetContainerComponent;
    @ViewChild('partnerContainer', { static: false }) WidContainerPartner: WidgetContainerComponent;
    @ViewChild('liteContainer', { static: false }) WidContainerLite: WidgetContainerComponent;
    @ViewChild('landingPageContainer', { static: false }) WidLandingPageContainer: WidgetContainerComponent;
    currentLiteNewsImage: string = "";
    currentLiteLogo: string = "";
    selectedLitenewsItem: PartnerNewsItem = new PartnerNewsItem();
    litenewsitems: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    selectedLitenewsItemid: string = "";
    @Input('type') type: string = "";
    @Input('userPerms') userPermissions: UserPermissions = new UserPermissions();

	isLoading: boolean = true;
    isAuthenticated: boolean = false;

    //isAssigning: boolean = false;
    isAssigningCompany: boolean = false;
    isAssigningPartner: boolean = false;
    isAssigningLite: boolean = false;

    isEditMode: boolean = false;

    currentLogo: string = "";
    currentPartnerLogo: string = "";
    currentNewsImage: string = "";
    currentPartnerNewsImage: string = "";
    currentDocsImage: string = "";
    currentMyDocsImage: string = "";
    currentVidsImage: string = "";
    currentLPsImage: string = "";
    currentCourseImage: string = "";
    currentQuizImage: string = "";
    currentWFsImage: string = "";
    currentProcessImage: string = "";
    currentdefWorkgroupImage: string = "";
    currentEnrollmentImage: string = "";

    bUseWidgets: boolean = false;

    //widgetPages: Array<WidgetLayoutPage> = new Array<WidgetLayoutPage>();
    widgetPagesCompany: Array<WidgetLayoutPage> = new Array<WidgetLayoutPage>();
    widgetPagesPartner: Array<WidgetLayoutPage> = new Array<WidgetLayoutPage>();
    widgetPagesLite: Array<WidgetLayoutPage> = new Array<WidgetLayoutPage>();

    //currentLayout: WidgetLayoutInfo = new WidgetLayoutInfo();
    currentLayoutCompany: WidgetLayoutInfo = new WidgetLayoutInfo();
    currentLayoutPartner: WidgetLayoutInfo = new WidgetLayoutInfo();
    currentLayoutLite: WidgetLayoutInfo = new WidgetLayoutInfo();

    //currentContainerInfo: WidgetContainerInfo = new WidgetContainerInfo();
    currentContainerInfoCompany: WidgetContainerInfo = new WidgetContainerInfo();
    currentContainerInfoPartner: WidgetContainerInfo = new WidgetContainerInfo();
    currentContainerInfoLite: WidgetContainerInfo = new WidgetContainerInfo();

    //widgetLayouts: Array<WidgetLayoutInfo> = new Array<WidgetLayoutInfo>();
    widgetLayoutsCompany: Array<WidgetLayoutInfo> = new Array<WidgetLayoutInfo>();
    widgetLayoutsPartner: Array<WidgetLayoutInfo> = new Array<WidgetLayoutInfo>();
    widgetLayoutsLite: Array<WidgetLayoutInfo> = new Array<WidgetLayoutInfo>();

    //currentPage: WidgetLayoutPage = new WidgetLayoutPage();
    currentPageCompany: WidgetLayoutPage = new WidgetLayoutPage();
    currentPagePartner: WidgetLayoutPage = new WidgetLayoutPage();
    currentPageLite: WidgetLayoutPage = new WidgetLayoutPage();

    //selectedPage: string = "";
    selectedPageCompany: string = "";
    selectedPagePartner: string = "";
    selectedPageLite: string = "";

    //selectedLayout: string = "";
    selectedLayoutCompany: string = "";
    selectedLayoutPartner: string = "";
    selectedLayoutLite: string = "";

    appliedLayoutCompany: string = "";
    appliedLayoutCompanyObj: WidgetLayoutInfo = new WidgetLayoutInfo();

    appliedLayoutPartner: string = "";
    appliedLayoutPartnerObj: WidgetLayoutInfo = new WidgetLayoutInfo();

    appliedLayoutLite: string = "";
    appliedLayoutLiteObj: WidgetLayoutInfo = new WidgetLayoutInfo();

    //homeContainer: WidgetContainerInfo = new WidgetContainerInfo();
    homeContainerCompany: WidgetContainerInfo = new WidgetContainerInfo();
    homeContainerPartner: WidgetContainerInfo = new WidgetContainerInfo();
    homeContainerLite: WidgetContainerInfo = new WidgetContainerInfo();
    //learningcenterContainer: WidgetContainerInfo = new WidgetContainerInfo();
    learningcenterContainerCompany: WidgetContainerInfo = new WidgetContainerInfo();
    learningcenterContainerPartner: WidgetContainerInfo = new WidgetContainerInfo();
    learningcenterContainerLite: WidgetContainerInfo = new WidgetContainerInfo();

    widgetLibCompany: Array<WidgetLibraryEntry> = new Array<WidgetLibraryEntry>();
    widgetLibPartner: Array<WidgetLibraryEntry> = new Array<WidgetLibraryEntry>();
    widgetLibLite: Array<WidgetLibraryEntry> = new Array<WidgetLibraryEntry>();

    tenantRoleAssignments: Array<RoleLayoutAssignment> = new Array<RoleLayoutAssignment>();

    currentTenantID: string = ProdGenApi.getCurrentTenantId().replace(/"/g, "");

    canEdit: boolean = false;
    themename: string = "";
    valuesarray: Theme = new Theme();
    currentTenantThemes: Array<Theme> = new Array<Theme>();
    selectedTheme: Theme = new Theme();
    tenantcurrentlyappliedtheme: Theme = new Theme();
    PartnercurrentlyappliedthemeID: string = "";
    Partnercurrentlyappliedtheme: Theme = new Theme();
    LitecurrentlyappliedthemeID: string = "";
    Litecurrentlyappliedtheme: Theme = new Theme();
    newsitems: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    partnernewsitems: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();
    selectednewsItem: CompanyNewsItem = new CompanyNewsItem();
    selectedPartnernewsItem: PartnerNewsItem = new PartnerNewsItem();

    jobRoles: Array<Role> = new Array<Role>();

    selectednewsItemid: string = "";
    selectedPartnernewsItemid: string = "";
    newsitemtitle: string = "";
    newsitemdetail: string = "";
    newsitemurl: string = "";
    newsitemId: string = "00000000-0000-0000-0000-000000000000";
    uniqueImageQuery: number = Date.now();
    
    logochange: boolean = false;
    newschange: boolean = false;

    docchange: boolean = false;
    mydocchange: boolean = false;
    vidchange: boolean = false;
    lpchange: boolean = false;
    coursechange: boolean = false;
    quizchange: boolean = false;
    wfchange: boolean = false;
    processchange: boolean = false;
    defworkgroupchange: boolean = false;
    enrollmentchange: boolean = false;
    //taskchange: boolean = false;
    //stepchange: boolean = false;
    //milestonechange: boolean = false;

    logoURL: string = "";

    isEdit: boolean = false;
    isNew: boolean = false;

    isNewLayout: boolean = false;
    newLayoutID: string = "";

    hasHomeScreenVisited: boolean = false;
    hasLearningCenterVisited: boolean = false;

    showLogo: boolean = true;
    
    editingTheme: string = "";
    dropdownthemecompany: string = "";
    dropdownthemepartner: string = "";
    dropdownthemelite: string = "";
    stylekeys: Array<string> = ["font_face", //0
        "navbar_foreground_color",//1
        "navbar_background_color",//2
        "page_text_color",//3
        "page_background_color",//4
        "page_separator_color",//5
        "page_separator_width",//6
        "large_card_foreground_color1",//7
        "large_card_background_color1",//8
        "large_card_foreground_color2",//9
        "large_card_background_color2",//10
        "large_card_foreground_color3",//11
        "large_card_background_color3",//12
        "large_card_border_color",//13
        "large_card_border_width",//14
        "large_card_border_radius",//15
        "small_card_foreground_color1",//16
        "small_card_background_color1",//17
        "small_card_foreground_color2",//18
        "small_card_background_color2",//19
        "small_card_foreground_color3",//20
        "small_card_background_color3",//21
        "small_card_border_color",//22
        "small_card_border_width",//23
        "small_card_border_radius",//24
        "panel_border_color",//25
        "panel_border_width",//26
        "panel_border_radius",//27
        "panel_body_foreground_color",//28
        "panel_body_background_color",//29
        "panel_title_foreground_color",//30
        "panel_title_background_color",//31
        "hsl_foreground_color",//32
        "hsl_background_color",//33
        "hsl_border_color",//34
        "hsl_border_width",//35
        "hsr_foreground_color",//36
        "hsr_background_color",//37
        "hsr_border_color",//38
        "hsr_border_width",//39
        "document_image",//40
        "video_image",//41
        "learning_path_image",//42
        "course_image",//43
        "quiz_image",//44
        "workflow_image",//45
        "process_image",//46
        "my_document_image",//47
        "workgroup_image",//48
        "enrollment_image",//49
        "button_background",//50
        "button_foreground"];//51

    previewFont: string = "";
    navbackgroundcolor: string = "#000000";
    navforegroundcolor: string = "#000000";
    pageforegroundcolor: string = "#000000";
    pagebackgroundcolor: string = "#000000";
    pagebordercolor: string = "#000000";
    pageborderwidth: number = 0;
    largecardforegroundcolor1: string = "#000000";
    largecardbackgroundcolor1: string = "#000000";
    largecardforegroundcolor2: string = "#000000";
    largecardbackgroundcolor2: string = "#000000";
    largecardforegroundcolor3: string = "#000000";
    largecardbackgroundcolor3: string = "#000000";
    largecardbordercolor: string = "#000000";
    largecardborderwidth: number = 0;
    largecardborderradius: number = 0;
    smallcardforegroundcolor1: string = "#000000";
    smallcardbackgroundcolor1: string = "#000000";
    smallcardforegroundcolor2: string = "#000000";
    smallcardbackgroundcolor2: string = "#000000";
    smallcardforegroundcolor3: string = "#000000";
    smallcardbackgroundcolor3: string = "#000000";
    smallcardbordercolor: string = "#000000";
    smallcardborderwidth: number = 0;
    smallcardborderradius: number = 0;
    paneltitleforeground: string = "#000000";
    paneltitlebackground: string = "#000000";
    panelbodyforeground: string = "#000000";
    panelbodybackground: string = "#000000";
    panelbordercolor: string = "#000000";
    panelborderwidth: number = 0;
    panelborderradius: number = 0;
    leftsideforeground: string = "#000000";
    leftsidebackground: string = "#000000";
    leftsidebordercolor: string = "#000000";
    leftsideborderwidth: number = 0;
    rightsideforeground: string = "#000000";
    rightsidebackground: string = "#000000";
    rightsidebordercolor: string = "#000000";
    rightsideborderwidth: number = 0;
    buttonforeground: string = "#000000";
    buttonbackground: string = "#000000";

    previewtheme = defpreviewtheme;

    landingPageContainers: Array<WidgetContainerBasicInfo> = new Array<WidgetContainerBasicInfo>();
    selectedLandingPage: string = "";
    currentLandingPage: WidgetContainerInfo = new WidgetContainerInfo();
    editingLandingPage: WidgetContainerInfo = new WidgetContainerInfo();
    isEditingLandingPage: boolean = false;
    isNewLandingPage: boolean = false;
    URLText: string = "";

    shouldEnterEdit = false;

    constructor(private pinnacleService: ProdGenApi, private widgetLibraryService: WidgetLibraryService, private authenticationService: BrowserAuthenticationService, private brandingservice: BrandingUpdateServiceService, private changedetectorref: ChangeDetectorRef, public dialogService: DialogService, private router: Router, private translationService: TranslationService) {
        
	  if (authenticationService.AuthenticatePage() == true) {
		  this.isAuthenticated = true;
      }

        try {
            if (JSON.parse(localStorage.getItem(this.currentTenantID + "_company_logo")) != "") {
                this.currentLogo = this.getUniqueImageSource(JSON.parse(localStorage.getItem(this.currentTenantID + "_company_logo")));
               // document.getElementById("companylogo").style.display = "block";
            }
            else {
                this.currentLogo = "";
               // document.getElementById("companylogo").style.display = "none";
            }
      }
      catch (err) {
          console.log(err)
        }
        try {
            if (JSON.parse(localStorage.getItem(this.currentTenantID + "_partner_logo")) != "") {
                this.currentPartnerLogo = this.getUniqueImageSource(JSON.parse(localStorage.getItem(this.currentTenantID + "_partner_logo")));
               // document.getElementById("partnerlogo").style.display = "block";
            }
            else {
                this.currentPartnerLogo = "";
               // document.getElementById("partnerlogo").style.display = "none";
            }
        }
        catch (err) {
            console.log(err)
        }

        try {
            if (JSON.parse(localStorage.getItem(this.currentTenantID + "_lite_logo")) != "") {
                this.currentLiteLogo = this.getUniqueImageSource(JSON.parse(localStorage.getItem(this.currentTenantID + "_lite_logo")));
               // document.getElementById("litelogo").style.display = "block";
            }
            else {
                this.currentLiteLogo = "";
               // document.getElementById("litelogo").style.display = "none";
            }
        }
        catch (err) {
            console.log(err)
        }

        try {
            let i = JSON.parse(localStorage.getItem(this.currentTenantID + "_company_news_image"));
            if (i != null) {
                if (i != "") {
                    this.currentNewsImage = this.getUniqueImageSource(i);
                   // document.getElementById("companynewsimage").style.display = "block";
                }
                else {
                    this.currentNewsImage = "";
                   // document.getElementById("companynewsimage").style.display = "none";
                }
            }
        }
        catch (err) {
            console.log(err)
          }

        try {
            let i = JSON.parse(localStorage.getItem(this.currentTenantID + "_partner_news_image"));
            if (i != null) {
                if (i != "") {
                    this.currentPartnerNewsImage = this.getUniqueImageSource(i);
                   // document.getElementById("partnernewsimage").style.display = "block";
                }
                else {
                    this.currentPartnerNewsImage = "";
                   // document.getElementById("partnernewsimage").style.display = "none";
                }
            }
        }
        catch (err) {
            console.log(err)
        }

        try {
            let i = JSON.parse(localStorage.getItem(this.currentTenantID + "_lite_news_image"));
            if (i != null) {
                if (i != "") {
                    this.currentLiteNewsImage = this.getUniqueImageSource(i);
                   // document.getElementById("litenewsimage").style.display = "block";
                }
                else {
                    this.currentLiteNewsImage = "";
                   // document.getElementById("litenewsimage").style.display = "none";
                }
            }
        }
        catch (err) {
            console.log(err)
        }

        this.pinnacleService.getJobRoles().subscribe(res => {
            this.jobRoles = res;
        });

  }

    ngOnInit() {
        $('#loadingmodal').modal('show');

        //window.addEventListener("beforeunload", (ev) => {
        //    this.canDeactivate();
        //    ev.returnValue = true;
        //});

        this.pinnacleService.getCurrentUserPermissions().subscribe(res => {
            this.canEdit = (res.administrator || res.canManageBranding);
        });


        this.pinnacleService.GetCurrentTenantNews().subscribe(res => {
            this.newsitems = res;
        });

        this.pinnacleService.GetPartnerNewsItems().subscribe(res => {
            this.partnernewsitems = res;
        });

        this.pinnacleService.GetLiteNewsItems().subscribe(res => {
            this.litenewsitems = res;
        });

        this.pinnacleService.GetCurrentTenantSettings().subscribe(res => {
            for (var i = 0; i < res.length; i++) {
                if (res[i].name == "COMPANY_LOGO_NAVIGATE_URL") {
                    this.logoURL = res[i].settingValue.toString();
                }
                if (res[i].name == "USE_WIDGETS") {
                    this.bUseWidgets = res[i].settingValue.toString().toLowerCase() == 'true';
                }
                if (res[i].name == "HIDE_LOGO") {
                    this.showLogo = res[i].settingValue.toString().toLowerCase() == 'true';
                }
            }
            this.changedetectorref.detectChanges();
        });


        this.pinnacleService.getCurrentTenantThemes(this.type).subscribe(res => {
            this.currentTenantThemes = res;
            //this.currentTenantThemes.sort((a: Theme, b: Theme) => {
            //    if (a.themeName < b.themeName) {
            //        return -1;
            //    }
            //    if (a.themeName > b.themeName ) {
            //        return 1;
            //    }
            //    return 0;
            //});
            this.pinnacleService.getCurrentPartnerThemeID().subscribe(res => {
                this.PartnercurrentlyappliedthemeID = res;
                this.Partnercurrentlyappliedtheme = this.currentTenantThemes[this.currentTenantThemes.findIndex(x => x.themeID == this.PartnercurrentlyappliedthemeID)];
            });
            this.pinnacleService.getCurrentLiteThemeID().subscribe(res => {
                this.LitecurrentlyappliedthemeID = res;
                this.Litecurrentlyappliedtheme = this.currentTenantThemes[this.currentTenantThemes.findIndex(x => x.themeID == this.LitecurrentlyappliedthemeID)];
            });

            if (this.currentTenantThemes.length >= 1) {

                let useremail = localStorage.getItem("userEmail");

                this.pinnacleService.GetCurrentTenantThemeSettingAtLogin(ProdGenApi.getCurrentTenantId().replace(/"/g, ""), useremail).subscribe(res2 => {
                    if (res2.themeID != "00000000-0000-0000-0000-000000000000") {
                        this.tenantcurrentlyappliedtheme = res2;
                        this.selectedTheme = res2;
                    }
                    else {
                        this.tenantcurrentlyappliedtheme = res2;
                        this.selectedTheme = this.currentTenantThemes.find(x => x.themeID == res2.themeID);
                    }

                    //sets the preview css vars to the selected theme vars
                    Object.keys(this.previewtheme.properties).forEach(property => {
                        if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                            //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                            this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                                function (e) {
                                    return "--preview-" + e.itemName.replace(/_/g, "-")
                                }).indexOf(property)].itemValue + "px";

                        }
                        else if (property.indexOf("font-face") != -1) {
                            //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                            this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                                function (e) {
                                    return "--preview-" + e.itemName.replace(/_/g, "-")
                                }).indexOf(property)].itemValue;

                        }
                        else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                            //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                            this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                                function (e) {
                                    return "--preview-" + e.itemName.replace(/_/g, "-")
                                }).indexOf(property)].itemValue;

                        }

                        if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                            document.documentElement.style.setProperty(
                                property,
                                this.previewtheme.properties[property]
                            );
                        }
                    });

                    //combine the preview fields with the current fields because IE is a problem child

                    cssVars({
                        rootElement: document,
                        variables: {
                            "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                            "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                            "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                            "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                            "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                            "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                            "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                            "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                            "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                            "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                            "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                            "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                            "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                            "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                            "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                            "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                            "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                            "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                            "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                            "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                            "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                            "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                            "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                            "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                            "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                            "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                            "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                            "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                            "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                            "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                            "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                            "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                            "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                            "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                            "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                            "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                            "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                            "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                            "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                            "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                            "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                            "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                            "--font-face": currenttheme.properties["--font-face"],
                            "--page-background-color": currenttheme.properties["--page-background-color"],
                            "--page-text-color": currenttheme.properties["--page-text-color"],
                            "--page-separator-color": currenttheme.properties["--page-separator-color"],
                            "--page-separator-width": currenttheme.properties["--page-separator-width"],
                            "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                            "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                            "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                            "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                            "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                            "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                            "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                            "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                            "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                            "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                            "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                            "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                            "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                            "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                            "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                            "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                            "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                            "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                            "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                            "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                            "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                            "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                            "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                            "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                            "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                            "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                            "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                            "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                            "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                            "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                            "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                            "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                            "--panel-border-color": currenttheme.properties["--panel-border-color"],
                            "--panel-border-width": currenttheme.properties["--panel-border-width"],
                            "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                            "--button-background": currenttheme.properties["--button-background"],
                            "--button-foreground": currenttheme.properties["--button-foreground"]
                        }
                    });

                      //updates the theme editor color bindings
                      this.themename = this.selectedTheme.themeName;
                      //this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[0])].itemValue;
                      this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[0])].itemValue;
                      //this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[2])].itemValue;
                      this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[2])].itemValue;
                      //this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[1])].itemValue;
                      this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[1])].itemValue;
                      //this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[3])].itemValue;
                      this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[3])].itemValue;
                      //this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[4])].itemValue;
                      this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[4])].itemValue;
                      //this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[5])].itemValue;
                      this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[5])].itemValue;
                      //this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[6])].itemValue;
                      this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[6])].itemValue;
                      //this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[7])].itemValue;
                      this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[7])].itemValue;
                      //this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[8])].itemValue;
                      this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[8])].itemValue;
                      //this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[9])].itemValue;
                      this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[9])].itemValue;
                      //this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[10])].itemValue;
                      this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[10])].itemValue;
                      //this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[11])].itemValue;
                      this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[11])].itemValue;
                      //this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[12])].itemValue;
                      this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[12])].itemValue;
                      //this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[13])].itemValue;
                      this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[13])].itemValue;
                      //this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[14])].itemValue;
                      this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[14])].itemValue;
                      //this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[15])].itemValue;
                      this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[15])].itemValue;
                      //this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[16])].itemValue;
                      this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[16])].itemValue;
                      //this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[17])].itemValue;
                      this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[17])].itemValue;
                      //this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[18])].itemValue;
                      this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[18])].itemValue;
                      //this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[19])].itemValue;
                      this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[19])].itemValue;
                      //this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[20])].itemValue;
                      this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[20])].itemValue;
                      //this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[21])].itemValue;
                      this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[21])].itemValue;
                      //this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[22])].itemValue;
                      this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[22])].itemValue;
                      //this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[23])].itemValue;
                      this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[23])].itemValue;
                      //this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[24])].itemValue;
                      this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[24])].itemValue;
                      //this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[30])].itemValue;
                      this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[30])].itemValue;
                      //this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[31])].itemValue;
                      this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[31])].itemValue;
                      //this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[28])].itemValue;
                      this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[28])].itemValue;
                      //this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[29])].itemValue;
                      this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[29])].itemValue;
                      //this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[25])].itemValue;
                      this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[25])].itemValue;
                      //this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[26])].itemValue;
                      this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[26])].itemValue;
                      //this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[27])].itemValue;
                      this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[27])].itemValue;
                      //this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[32])].itemValue;
                      this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[32])].itemValue;
                      //this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[33])].itemValue;
                      this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[33])].itemValue;
                      //this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[34])].itemValue;
                      this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[34])].itemValue;
                      //this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[35])].itemValue;
                      this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[35])].itemValue;
                      //this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[36])].itemValue;
                      this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[36])].itemValue;
                      //this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[37])].itemValue;
                      this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[37])].itemValue;
                      //this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[38])].itemValue;
                      this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[38])].itemValue;
                      //this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[39])].itemValue;
                      this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[39])].itemValue;

                      this.currentDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[40])].itemValue;
                      this.currentMyDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[47])].itemValue;
                      this.currentVidsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[41])].itemValue;
                      this.currentLPsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[42])].itemValue;
                      this.currentCourseImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[43])].itemValue;
                      this.currentWFsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[45])].itemValue;
                      this.currentProcessImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[46])].itemValue;
                      this.currentQuizImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[44])].itemValue;
                      this.currentdefWorkgroupImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                          }).indexOf(this.stylekeys[48])].itemValue;
                      this.currentEnrollmentImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                          function (e) {
                              return e.itemName
                        }).indexOf(this.stylekeys[49])].itemValue;
                    this.buttonbackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return e.itemName
                        }).indexOf(this.stylekeys[50])].itemValue;
                    this.buttonforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return e.itemName
                        }).indexOf(this.stylekeys[51])].itemValue;
                    this.dropdownthemecompany = this.selectedTheme.themeID;
                    $("#themeDropdown" + this.type).val(this.selectedTheme.themeID);
                    if ((this.selectedTheme.themeproperties.find(x => x.itemName == "is_public").itemValue.toLowerCase() == "true" && this.type == "company") || (this.selectedTheme.themeproperties.find(x => x.itemName == "tenantid").itemValue.toLowerCase() != this.currentTenantID.toLowerCase())) {
                        $("#editbtn" + this.type).prop("disabled", true);
                        $("#deletebtn" + this.type).prop("disabled", true);
                    }
                    else {
                        $("#editbtn" + this.type).attr("disabled", false);
                        $("#deletebtn" + this.type).attr("disabled", false);

                    }
                    $("#applybtn" + this.type).attr("disabled", false);

                    for (var i = 1; i < 10; i++) {
                        this.getCardImage(i);
                    }
                    $('#loadingmodal').modal('hide');
                });
            }
        });

        
        if (this.type == "company") {
            this.pinnacleService.getWidgetLayoutPages().subscribe(res => {
                this.widgetPagesCompany = res;
                this.selectedPageCompany = this.widgetPagesCompany[0].pageName;
                this.currentPageCompany = this.widgetPagesCompany[0];
            });
        }
        if (this.type == "partner") {
            this.pinnacleService.getWidgetLayoutPages().subscribe(res => {
                this.widgetPagesPartner = res;
                this.selectedPagePartner = this.widgetPagesPartner[0].pageName;
                this.currentPagePartner = this.widgetPagesPartner[0];
            });
        }
        if (this.type == "lite") {
            this.pinnacleService.getWidgetLayoutPages().subscribe(res => {
                this.widgetPagesLite = res;
                this.selectedPageLite = this.widgetPagesLite[0].pageName;
                this.currentPageLite = this.widgetPagesLite[0];
            });
        }

        this.loadLandingPages();
        
        if (this.type == "company") {
            this.pinnacleService.getWidgetLayouts().subscribe(res => {
                this.widgetLayoutsCompany = res;
                this.widgetLayoutsCompany.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
              
                this.pinnacleService.GetCurrentTenantSettings().subscribe(res => {
                  
                    let layout = res.find(x => x.name == "COMPANY_LAYOUT");
                    let settingLayoutIndex = -1;
                    if (layout) {
                        settingLayoutIndex = this.widgetLayoutsCompany.findIndex(x => x.layoutId == layout.settingValue.toString());
                    }
                    if (layout && settingLayoutIndex != -1) {
                        this.currentLayoutCompany = this.widgetLayoutsCompany[settingLayoutIndex];
                        this.selectedLayoutCompany = this.currentLayoutCompany.name;
                        this.appliedLayoutCompany = this.currentLayoutCompany.name;
                        this.appliedLayoutCompanyObj = this.currentLayoutCompany;
                        
                        if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
                            $('#' + this.type + 'btneditlayout').attr("disabled", true);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        }
                        else {
                            $('#' + this.type + 'btneditlayout').attr("disabled", false);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        }
                    }
                    else {
                        //check the prodgen.api to see if we are a lite company. if so we should look at what the partner settings are for the lite layout.
                        //if we dont find partner lite settings we can default to the EP default

                        this.pinnacleService.GetPartnerSettings().subscribe(res2 => {
                            let layoutlite = res2.find(x => x.name == "PARTNER_LAYOUT");
                            if (layoutlite && this.widgetLayoutsCompany.findIndex(x => x.layoutId == layoutlite.settingValue.toString()) != -1) {
                                this.currentLayoutCompany = this.widgetLayoutsCompany[this.widgetLayoutsCompany.findIndex(x => x.layoutId == layoutlite.settingValue.toString())];
                                this.selectedLayoutCompany = this.currentLayoutCompany.name;
                                this.appliedLayoutCompany = this.currentLayoutCompany.name;
                                this.appliedLayoutCompanyObj = this.currentLayoutCompany;
                                if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
                                    $('#' + this.type + 'btneditlayout').attr("disabled", true);
                                    $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                                }
                                else {
                                    $('#' + this.type + 'btneditlayout').attr("disabled", false);
                                    $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                                }
                            }
                            else {
                                this.currentLayoutCompany = this.widgetLayoutsCompany[0];
                                this.selectedLayoutCompany = this.currentLayoutCompany.name;
                                this.appliedLayoutCompany = "";
                                if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
                                    $('#' + this.type + 'btneditlayout').attr("disabled", true);
                                    $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                                }
                                else {
                                    $('#' + this.type + 'btneditlayout').attr("disabled", false);
                                    $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                                }
                            }
                        });

                        //this.selectedLayoutCompany = this.widgetLayoutsCompany[0].name;
                        //this.appliedLayoutCompany = this.widgetLayoutsCompany[0].name;
                        //this.currentLayoutCompany = this.widgetLayoutsCompany[0];
                        //if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
                        //    $('#' + this.type + 'btneditlayout').attr("disabled", true);
                        //    $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        //}
                        //else {
                        //    $('#' + this.type + 'btneditlayout').attr("disabled", false);
                        //    $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        //}
                    }
                    
                });
                if (this.widgetLayoutsCompany.length > 0) {

                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "HOME_SCREEN_CONTAINER", "Layout", this.currentLayoutCompany.layoutId).subscribe(res1 => {
                        this.homeContainerCompany = res1;
                        this.currentContainerInfoCompany = this.homeContainerCompany;
                    });
                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "LEARNING_CENTER_CONTAINER", "Layout", this.currentLayoutCompany.layoutId).subscribe(res2 => {
                        this.learningcenterContainerCompany = res2;
                    });
                }
            });
            this.widgetLibCompany = this.widgetLibraryService.getWidgetLibrary(["Layout"]);
        }
        if (this.type == "partner") {
            this.pinnacleService.getWidgetLayouts().subscribe(res => {              
                let ogArray = res;

                this.widgetLayoutsPartner = res;

                for (var i = 0; i < this.widgetLayoutsPartner.length; i++) {
                    if (this.widgetLayoutsPartner[i].isPublic == false) {//if the layout isnt public we shouldnt display it on the partner tab
                        this.widgetLayoutsPartner.splice(i, 1);
                        i--
                    }
                }
                this.pinnacleService.GetPartnerSettings().subscribe(res => {
                    let layout = res.find(x => x.name == "PARTNER_LAYOUT");
                    let settingLayoutIndex = this.widgetLayoutsPartner.findIndex(x => x.layoutId == layout.settingValue.toString());
                    if (layout && settingLayoutIndex != -1) {
                        this.currentLayoutPartner = this.widgetLayoutsPartner[settingLayoutIndex];
                        this.selectedLayoutPartner = this.currentLayoutPartner.name;
                        this.appliedLayoutPartner = this.currentLayoutPartner.name;
                        this.appliedLayoutPartnerObj = this.currentLayoutPartner;
                        if (this.currentLayoutPartner.layoutId.toUpperCase() == "5DBEFB1E-D628-4671-89F0-261816AAD6F3" && this.currentTenantID.toLocaleUpperCase() != "A572ABCE-E296-4D90-A5E8-17309E3BAB01") {//eagle points layout id
                            $('#' + this.type + 'btneditlayout').attr("disabled", true);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        }
                        else {
                            $('#' + this.type + 'btneditlayout').attr("disabled", false);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        }
                    }
                    else {
                        this.currentLayoutPartner = this.widgetLayoutsPartner[0];
                        this.selectedLayoutPartner = this.currentLayoutPartner.name;
                        this.appliedLayoutPartner = "";
                        
                        if (this.currentLayoutPartner.layoutId.toUpperCase() == "5DBEFB1E-D628-4671-89F0-261816AAD6F3" && this.currentTenantID.toLocaleUpperCase() != "A572ABCE-E296-4D90-A5E8-17309E3BAB01") {//eagle points layout id
                            $('#' + this.type + 'btneditlayout').attr("disabled", true);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        }
                        else {
                            $('#' + this.type + 'btneditlayout').attr("disabled", false);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        }
                    }
                    
                });
                if (this.widgetLayoutsPartner.length > 0) {

                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "HOME_SCREEN_CONTAINER", "Layout", this.currentLayoutPartner.layoutId).subscribe(res1 => {
                        this.homeContainerPartner = res1;
                        this.currentContainerInfoPartner = this.homeContainerPartner;
                    });
                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "LEARNING_CENTER_CONTAINER", "Layout", this.currentLayoutPartner.layoutId).subscribe(res2 => {
                        this.learningcenterContainerPartner = res2;
                    });
                }
            });
            this.widgetLibPartner = this.widgetLibraryService.getWidgetLibrary(["Layout"]);
        }
        if (this.type == "lite") {
            this.pinnacleService.getWidgetLayouts().subscribe(res => {
                this.widgetLayoutsLite = res;
                for (var i = 0; i < this.widgetLayoutsLite.length; i++) {
                    if (this.widgetLayoutsLite[i].isPublic == false) {//if the layout isnt public we shouldnt display it on the ltie tab
                        this.widgetLayoutsLite.splice(i, 1);
                        i--
                    }
                }
               
                this.pinnacleService.GetPartnerSettings().subscribe(res => {
                    let layout = res.find(x => x.name == "PARTNER_LITE_LAYOUT");
                    let settingLayoutIndex = this.widgetLayoutsLite.findIndex(x => x.layoutId == layout.settingValue.toString());
                    if (layout && settingLayoutIndex != -1) {
                        this.currentLayoutLite = this.widgetLayoutsLite[settingLayoutIndex];
                        this.selectedLayoutLite = this.currentLayoutLite.name;
                        this.appliedLayoutLite = this.currentLayoutLite.name;
                        this.appliedLayoutLiteObj = this.currentLayoutLite;
                        
                        if (this.currentLayoutLite.layoutId.toUpperCase() == '5DBEFB1E-D628-4671-89F0-261816AAD6F3' && this.currentTenantID != 'A572ABCE-E296-4D90-A5E8-17309E3BAB01') {//eagle points layout id
                            $('#' + this.type + 'btneditlayout').attr("disabled", true);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        }
                        else {
                            $('#' + this.type + 'btneditlayout').attr("disabled", false);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        }
                    }
                    else {
                        this.currentLayoutLite = this.widgetLayoutsLite[0];
                        this.selectedLayoutLite = this.currentLayoutLite.name;
                        this.appliedLayoutLite = "";
                        
                        if (this.currentLayoutLite.layoutId.toUpperCase() == '5DBEFB1E-D628-4671-89F0-261816AAD6F3' && this.currentTenantID != 'A572ABCE-E296-4D90-A5E8-17309E3BAB01') {//eagle points layout id
                            $('#' + this.type + 'btneditlayout').attr("disabled", true);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
                        }
                        else {
                            $('#' + this.type + 'btneditlayout').attr("disabled", false);
                            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
                        }
                    }
                   
                });
                if (this.widgetLayoutsLite.length > 0) {


                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "HOME_SCREEN_CONTAINER", "Layout", this.currentLayoutLite.layoutId).subscribe(res1 => {
                        this.homeContainerLite = res1;
                        this.currentContainerInfoLite = this.homeContainerLite;
                    });
                    this.pinnacleService.getContainerInfo(ProdGenApi.EMPTY_GUID, "LEARNING_CENTER_CONTAINER", "Layout", this.currentLayoutLite.layoutId).subscribe(res2 => {
                        this.learningcenterContainerLite = res2;
                    });
                }
            });
            this.widgetLibLite = this.widgetLibraryService.getWidgetLibrary(["Layout"]);
        }  

        this.pinnacleService.getTenantLayoutAssignments().subscribe(res => {
            this.tenantRoleAssignments = res;
        });

        window.scroll(0, 0);
        
        this.changedetectorref.detectChanges();
  }

    UpdateItemStyle(event: any, itemToUpdate: string) {

        document.documentElement.style.setProperty(itemToUpdate, event.toString());
        let cssitem = { itemToUpdate: event.toString() };

        let obj = {};
        let objvar = itemToUpdate;
        obj[objvar] = event.toString();

        //updates individual variables in the preview
        Object.keys(this.previewtheme.properties).forEach(property => {
            if (property == itemToUpdate) {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    this.previewtheme.properties[property] = (<HTMLInputElement>event.target).value + "px";
                }
                else if (property.indexOf("font-face") != -1) {
                    this.previewtheme.properties[property] = this.previewFont;
                }
                else {
                    this.previewtheme.properties[property] = event;
                }
                document.documentElement.style.setProperty(
                    property,
                    this.previewtheme.properties[property]
                );
            }
        });
        //cssVars({
        //    rootElement: document,
        //    variables: this.previewtheme.properties
        //});
        cssVars({
            rootElement: document,
            variables: {
                "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                "--font-face": currenttheme.properties["--font-face"],
                "--page-background-color": currenttheme.properties["--page-background-color"],
                "--page-text-color": currenttheme.properties["--page-text-color"],
                "--page-separator-color": currenttheme.properties["--page-separator-color"],
                "--page-separator-width": currenttheme.properties["--page-separator-width"],
                "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                "--panel-border-color": currenttheme.properties["--panel-border-color"],
                "--panel-border-width": currenttheme.properties["--panel-border-width"],
                "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                "--button-background": currenttheme.properties["--button-background"],
                "--button-foreground": currenttheme.properties["--button-foreground"]
            }
        });
    }

    ChangePreviewTheme(event: any) {
        if (event.target.value != "emptytheme") {

            $("#editbtn" + this.type).prop("disabled", false);
            $("#deletebtn" + this.type).prop("disabled", false);
            $("#applybtn" + this.type).prop("disabled", false);
            this.dropdownthemecompany = event.target.value;
            this.selectedTheme = this.currentTenantThemes.find(x => x.themeID == event.target.value);
            if ((this.selectedTheme.themeproperties.find(x => x.itemName == "is_public").itemValue.toLowerCase() == "true" && this.type == "company") || (this.selectedTheme.themeproperties.find(x => x.itemName == "tenantid").itemValue.toLowerCase() !=  this.currentTenantID.toLowerCase())) {
                $("#editbtn" + this.type).prop("disabled", true);
                $("#deletebtn" + this.type).prop("disabled", true);
            }
            //sets the preview css vars to the selected theme vars
            Object.keys(this.previewtheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        this.previewtheme.properties[property]
                    );
                }
            });

            //combine the preview fields with the current fields because IE is a problem child
            
            cssVars({
                rootElement: document,
                variables: {
                    "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                    "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                    "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                    "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                    "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                    "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                    "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                    "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                    "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                    "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                    "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                    "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                    "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                    "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                    "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                    "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                    "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                    "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                    "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                    "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                    "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                    "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                    "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                    "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                    "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                    "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                    "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                    "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                    "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                    "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                    "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                    "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                    "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                    "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                    "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                    "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                    "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                    "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                    "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                    "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                    "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                    "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                    "--font-face": currenttheme.properties["--font-face"],
                    "--page-background-color": currenttheme.properties["--page-background-color"],
                    "--page-text-color": currenttheme.properties["--page-text-color"],
                    "--page-separator-color": currenttheme.properties["--page-separator-color"],
                    "--page-separator-width": currenttheme.properties["--page-separator-width"],
                    "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                    "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                    "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                    "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                    "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                    "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                    "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                    "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                    "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                    "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                    "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                    "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                    "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                    "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                    "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                    "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                    "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                    "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                    "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                    "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                    "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                    "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                    "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                    "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                    "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                    "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                    "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                    "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                    "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                    "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                    "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                    "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                    "--panel-border-color": currenttheme.properties["--panel-border-color"],
                    "--panel-border-width": currenttheme.properties["--panel-border-width"],
                    "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                    "--button-background": currenttheme.properties["--button-background"],
                    "--button-foreground": currenttheme.properties["--button-foreground"]
                }
            });


            //updates the theme editor color bindings
            this.themename = this.selectedTheme.themeName;
            //this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[0])].itemValue;
            this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[0])].itemValue;
            //this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[2])].itemValue;
            this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[2])].itemValue;
            //this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[1])].itemValue;
            this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[1])].itemValue;
            //this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[3])].itemValue;
            this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[3])].itemValue;
            //this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[4])].itemValue;
            this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[4])].itemValue;
            //this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[5])].itemValue;
            this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[5])].itemValue;
            //this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[6])].itemValue;
            this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[6])].itemValue;
            //this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[7])].itemValue;
            this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[7])].itemValue;
            //this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[8])].itemValue;
            this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[8])].itemValue;
            //this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[9])].itemValue;
            this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[9])].itemValue;
            //this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[10])].itemValue;
            this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[10])].itemValue;
            //this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[11])].itemValue;
            this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[11])].itemValue;
            //this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[12])].itemValue;
            this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[12])].itemValue;
            //this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[13])].itemValue;
            this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[13])].itemValue;
            //this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[14])].itemValue;
            this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[14])].itemValue;
            //this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[15])].itemValue;
            this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[15])].itemValue;
            //this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[16])].itemValue;
            this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[16])].itemValue;
            //this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[17])].itemValue;
            this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[17])].itemValue;
            //this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[18])].itemValue;
            this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[18])].itemValue;
            //this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[19])].itemValue;
            this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[19])].itemValue;
            //this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[20])].itemValue;
            this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[20])].itemValue;
            //this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[21])].itemValue;
            this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[21])].itemValue;
            //this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[22])].itemValue;
            this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[22])].itemValue;
            //this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[23])].itemValue;
            this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[23])].itemValue;
            //this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[24])].itemValue;
            this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[24])].itemValue;
            //this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[30])].itemValue;
            this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[30])].itemValue;
            //this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[31])].itemValue;
            this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[31])].itemValue;
            //this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[28])].itemValue;
            this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[28])].itemValue;
            //this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[29])].itemValue;
            this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[29])].itemValue;
            //this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[25])].itemValue;
            this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[25])].itemValue;
            //this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[26])].itemValue;
            this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[26])].itemValue;
            //this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[27])].itemValue;
            this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[27])].itemValue;
            //this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[32])].itemValue;
            this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[32])].itemValue;
            //this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[33])].itemValue;
            this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[33])].itemValue;
            //this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[34])].itemValue;
            this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[34])].itemValue;
            //this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[35])].itemValue;
            this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[35])].itemValue;
            //this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[36])].itemValue;
            this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[36])].itemValue;
            //this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[37])].itemValue;
            this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[37])].itemValue;
            //this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[38])].itemValue;
            this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[38])].itemValue;
            //this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[39])].itemValue;
            this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[39])].itemValue;

            this.currentDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[40])].itemValue;
            this.currentMyDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[47])].itemValue;
            this.currentVidsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[41])].itemValue;
            this.currentLPsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[42])].itemValue;
            this.currentCourseImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[43])].itemValue;
            this.currentWFsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[45])].itemValue;
            this.currentProcessImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[46])].itemValue;
            this.currentQuizImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[44])].itemValue;
            this.currentdefWorkgroupImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[48])].itemValue;
            this.currentEnrollmentImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[49])].itemValue;
            this.buttonbackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[50])].itemValue;
            this.buttonforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf(this.stylekeys[51])].itemValue;
            this.changedetectorref.detectChanges();
            for (var i = 1; i < 10; i++) {
                this.getCardImage(i);
            }
        }
        else {
            $("#editbtn" + this.type).attr("disabled", true);
            $("#deletebtn" + this.type).attr("disabled", true);
            $("#applybtn" + this.type).attr("disabled", true);
        }

    }

    onImageChanged(event: any) {
        if (event.type == "logo") {
            if (this.type == "company") {
                if (event.imageURL != "") {
                    this.currentLogo = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentLogo = "";                   
                }
                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_logo", JSON.stringify(this.currentLogo));
                    this.brandingservice.logoimgemitter.emit(this.currentLogo);
                }
                catch (err) {
                    console.log(err);
                }
            }
            else if (this.type == "partner") {
                if (event.imageURL != "") {
                    this.currentPartnerLogo = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentPartnerLogo = "";
                }
                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_logo", JSON.stringify(this.currentPartnerLogo));
                    
                }
                catch (err) {
                    console.log(err);
                }
            }
            else if (this.type == "lite") {
                if (event.imageURL != "") {
                    this.currentLiteLogo = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentLiteLogo = "";
                }
                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_logo", JSON.stringify(this.currentLiteLogo));
                    
                }
                catch (err) {
                    console.log(err);
                }
            }

        }
        else if (event.type == "news") {
            if (this.type == "company") {
                if (event.imageURL != "") {
                    this.currentNewsImage = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentNewsImage = "";
                }

                try {
                    this.brandingservice.newsimgemitter.emit(this.currentNewsImage);
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news_image", JSON.stringify(this.currentNewsImage));
                }
                catch (err) {
                    console.log(err);
                }
            }
            else if (this.type == "partner") {
                if (event.imageURL != "") {
                    this.currentPartnerNewsImage = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentPartnerNewsImage = "";
                }

                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image", JSON.stringify(this.currentPartnerNewsImage));
                }
                catch (err) {
                    console.log(err);
                }
            }
            else if (this.type == "lite") {
                if (event.imageURL != "") {
                    this.currentLiteNewsImage = this.getUniqueImageSource(event.imageURL);
                }
                else {
                    this.currentLiteNewsImage = "";
                }

                try {
                   
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news_image", JSON.stringify(this.currentLiteNewsImage));
                }
                catch (err) {
                    console.log(err);
                }
            }
            
        }
        else if (event.type == "docs") {
            this.currentDocsImage = event.imageURL;
            try {
                if (this.currentDocsImage == "") {
                    //this.brandingservice.docimgemitter.emit("../../assets/images/default_cheatsheet.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_DocsImage", JSON.stringify("../../assets/images/default_cheatsheet.jpg"));
                    this.currentDocsImage = "../../assets/images/default_cheatsheet.jpg";
                }
                else {
                    //this.brandingservice.docimgemitter.emit(this.currentDocsImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_DocsImage", JSON.stringify(this.currentDocsImage));
                }
               
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "mydocs") {
            this.currentMyDocsImage = event.imageURL;
            try {
                if (this.currentMyDocsImage == "") {
                    //this.brandingservice.mydocimgemitter.emit("../../assets/images/My Cheat Sheets Graphic.png");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_MyDocsImage", JSON.stringify("../../assets/images/default_cheatsheet.jpg"));
                    this.currentMyDocsImage = "../../assets/images/default_cheatsheet.jpg";
                }
                else {
                    //this.brandingservice.mydocimgemitter.emit(this.currentMyDocsImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_MyDocsImage", JSON.stringify(this.currentMyDocsImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "vids") {
            this.currentVidsImage = event.imageURL;
            try {
                if (this.currentVidsImage == "") {
                    //this.brandingservice.vidimgemitter.emit("../../assets/images/default_video.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_VidsImage", JSON.stringify("../../assets/images/default_video.jpg"));
                    this.currentVidsImage = "../../assets/images/default_video.jpg";
                }
                else {
                    //this.brandingservice.vidimgemitter.emit(this.currentVidsImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_VidsImage", JSON.stringify(this.currentVidsImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "lps") {
            this.currentLPsImage = event.imageURL;
            try {
                if (this.currentLPsImage == "") {
                    //this.brandingservice.lpimageemitter.emit("../../assets/images/default_learningpath.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_LPImage", JSON.stringify("../../assets/images/default_learningpath.jpg"));
                    this.currentLPsImage = "../../assets/images/default_learningpath.jpg";
                }
                else {
                    //this.brandingservice.lpimageemitter.emit(this.currentLPsImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_LPImage", JSON.stringify(this.currentLPsImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "course") {
            this.currentCourseImage = event.imageURL;
            try {
                if (this.currentCourseImage == "") {
                    //this.brandingservice.courseimgemitter.emit("../../assets/images/default_course.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_CourseImage", JSON.stringify("../../assets/images/default_course.jpg"));
                    this.currentCourseImage = "../../assets/images/default_course.jpg";
                }
                else {
                    //this.brandingservice.courseimgemitter.emit(this.currentCourseImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_CourseImage", JSON.stringify(this.currentCourseImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "quiz") {
            this.currentQuizImage = event.imageURL;
            try {
                if (this.currentQuizImage == "") {
                    //this.brandingservice.quizimgemitter.emit("../../assets/images/default_quiz.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_QuizImage", JSON.stringify("../../assets/images/default_quiz.jpg"));
                    this.currentQuizImage = "../../assets/images/default_quiz.jpg";
                }
                else {
                    //this.brandingservice.quizimgemitter.emit(this.currentQuizImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_QuizImage", JSON.stringify(this.currentQuizImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "wfs") {
            this.currentWFsImage = event.imageURL;
            try {
                if (this.currentWFsImage == "") {
                    //this.brandingservice.wfimageemitter.emit("../../assets/images/default_workflow.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_WFImage", JSON.stringify("../../assets/images/default_workflow.jpg"));
                    this.currentWFsImage = "../../assets/images/default_workflow.jpg";
                }
                else {
                    //this.brandingservice.wfimageemitter.emit(this.currentWFsImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_WFImage", JSON.stringify(this.currentWFsImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "process") {
            this.currentProcessImage = event.imageURL;
            try {
                if (this.currentProcessImage == "") {
                    //this.brandingservice.processimgemitter.emit("../../assets/images/default_process.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_ProcessImage", JSON.stringify("../../assets/images/default_process.jpg"));
                    this.currentProcessImage = "../../assets/images/default_process.jpg";
                }
                else {
                    //this.brandingservice.processimgemitter.emit(this.currentProcessImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_ProcessImage", JSON.stringify(this.currentProcessImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "defworkgroup") {
            this.currentdefWorkgroupImage = event.imageURL;
            try {
                if (this.currentdefWorkgroupImage == "") {
                    //this.brandingservice.defworkgroupemitter.emit("../../assets/images/default_prodject.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage", JSON.stringify("../../assets/images/default_prodject.jpg"));
                    this.currentdefWorkgroupImage = "../../assets/images/default_prodject.jpg";
                }
                else {
                    //this.brandingservice.defworkgroupemitter.emit(this.currentdefWorkgroupImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage", JSON.stringify(this.currentdefWorkgroupImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else if (event.type == "enrollment") {
            this.currentEnrollmentImage = event.imageURL;
            try {
                if (this.currentEnrollmentImage == "") {
                    //this.brandingservice.enrollmentemitter.emit("../../assets/images/default_course.jpg");
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_EnrollmentImage", JSON.stringify("../../assets/images/default_course.jpg"));
                    this.currentEnrollmentImage = "../../assets/images/default_course.jpg";
                }
                else {
                    //this.brandingservice.enrollmentemitter.emit(this.currentEnrollmentImage);
                    //localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_EnrollmentImage", JSON.stringify(this.currentEnrollmentImage));
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        for (var i = 1; i < 10; i++) {
            this.getCardImage(i);
        }
    }

    newTheme_click() {
        //create new empty theme
        this.isNew = true;
        this.editingTheme = "00000000-0000-0000-0000-000000000000";

        //if a theme is selected, do a loop to set the name of the new theme with incremented number.
        if (this.themename != "--Select a theme to preview--") {
            let loopcontrol = true;
            let counter = 1;
            do {
                if (this.currentTenantThemes.map(
                    function (e) {
                        return e.themeName
                    }).indexOf(this.themename + counter.toString()) == -1) { //this.currentTenantThemes.indexOf(x => x.themeName == this.themename + counter.toString()

                    this.themename = this.themename + counter.toString();
                    loopcontrol = false;
                }
                else {
                    counter = counter + 1;
                }
            } while (loopcontrol == true);
        }
        $("#themeEditPanel" + this.type).attr("style", "display:block");
        $("#companyBrandingPanel" + this.type).attr("style", "display:none");
        window.scroll(0, 0);
    }

    editTheme_click() {
        //set editing theme, show edit panel, hide branding
        this.themename = $("#themeDropdown"+ this.type +" option:selected").text();
        this.isEdit = true;
        this.editingTheme = this.selectedTheme.themeID;
        $("#themeEditPanel" + this.type).attr("style", "display:block");
        $("#companyBrandingPanel" + this.type).attr("style", "display:none");
        window.scroll(0, 0);
    }

    CancelTheme() {
        //hide edit, show branding, scroll to top
        $("#themeEditPanel" + this.type).attr("style", "display:none");
        $("#companyBrandingPanel" + this.type).attr("style", "display:normal");
        this.isNew = false;
        this.isEdit = false;
        Object.keys(this.previewtheme.properties).forEach(property => {
            if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                    function (e) {
                        return "--preview-" + e.itemName.replace(/_/g, "-")
                    }).indexOf(property)].itemValue + "px";

            }
            else if (property.indexOf("font-face") != -1) {
                //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                    function (e) {
                        return "--preview-" + e.itemName.replace(/_/g, "-")
                    }).indexOf(property)].itemValue;

            }
            else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                    function (e) {
                        return "--preview-" + e.itemName.replace(/_/g, "-")
                    }).indexOf(property)].itemValue;

            }

            if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                document.documentElement.style.setProperty(
                    property,
                    this.previewtheme.properties[property]
                );
            }
        });

        //combine the preview fields with the current fields because IE is a problem child

        cssVars({
            rootElement: document,
            variables: {
                "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                "--font-face": currenttheme.properties["--font-face"],
                "--page-background-color": currenttheme.properties["--page-background-color"],
                "--page-text-color": currenttheme.properties["--page-text-color"],
                "--page-separator-color": currenttheme.properties["--page-separator-color"],
                "--page-separator-width": currenttheme.properties["--page-separator-width"],
                "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                "--panel-border-color": currenttheme.properties["--panel-border-color"],
                "--panel-border-width": currenttheme.properties["--panel-border-width"],
                "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                "--button-background": currenttheme.properties["--button-background"],
                "--button-foreground": currenttheme.properties["--button-foreground"]
            }
        });


        //updates the theme editor color bindings
        this.themename = this.selectedTheme.themeName;
        //this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[0])].itemValue;
        this.previewFont = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[0])].itemValue;
        //this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[2])].itemValue;
        this.navbackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[2])].itemValue;
        //this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[1])].itemValue;
        this.navforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[1])].itemValue;
        //this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[3])].itemValue;
        this.pageforegroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[3])].itemValue;
        //this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[4])].itemValue;
        this.pagebackgroundcolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[4])].itemValue;
        //this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[5])].itemValue;
        this.pagebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[5])].itemValue;
        //this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[6])].itemValue;
        this.pageborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[6])].itemValue;
        //this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[7])].itemValue;
        this.largecardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[7])].itemValue;
        //this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[8])].itemValue;
        this.largecardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[8])].itemValue;
        //this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[9])].itemValue;
        this.largecardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[9])].itemValue;
        //this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[10])].itemValue;
        this.largecardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[10])].itemValue;
        //this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[11])].itemValue;
        this.largecardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[11])].itemValue;
        //this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[12])].itemValue;
        this.largecardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[12])].itemValue;
        //this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[13])].itemValue;
        this.largecardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[13])].itemValue;
        //this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[14])].itemValue;
        this.largecardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[14])].itemValue;
        //this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[15])].itemValue;
        this.largecardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[15])].itemValue;
        //this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[16])].itemValue;
        this.smallcardforegroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[16])].itemValue;
        //this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[17])].itemValue;
        this.smallcardbackgroundcolor1 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[17])].itemValue;
        //this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[18])].itemValue;
        this.smallcardforegroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[18])].itemValue;
        //this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[19])].itemValue;
        this.smallcardbackgroundcolor2 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[19])].itemValue;
        //this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[20])].itemValue;
        this.smallcardforegroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[20])].itemValue;
        //this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[21])].itemValue;
        this.smallcardbackgroundcolor3 = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[21])].itemValue;
        //this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[22])].itemValue;
        this.smallcardbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[22])].itemValue;
        //this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[23])].itemValue;
        this.smallcardborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[23])].itemValue;
        //this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[24])].itemValue;
        this.smallcardborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[24])].itemValue;
        //this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[30])].itemValue;
        this.paneltitleforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[30])].itemValue;
        //this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[31])].itemValue;
        this.paneltitlebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[31])].itemValue;
        //this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[28])].itemValue;
        this.panelbodyforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[28])].itemValue;
        //this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[29])].itemValue;
        this.panelbodybackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[29])].itemValue;
        //this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[25])].itemValue;
        this.panelbordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[25])].itemValue;
        //this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[26])].itemValue;
        this.panelborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[26])].itemValue;
        //this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[27])].itemValue;
        this.panelborderradius = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[27])].itemValue;
        //this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[32])].itemValue;
        this.leftsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[32])].itemValue;
        //this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[33])].itemValue;
        this.leftsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[33])].itemValue;
        //this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[34])].itemValue;
        this.leftsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[34])].itemValue;
        //this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[35])].itemValue;
        this.leftsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[35])].itemValue;
        //this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[36])].itemValue;
        this.rightsideforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[36])].itemValue;
        //this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[37])].itemValue;
        this.rightsidebackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[37])].itemValue;
        //this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[38])].itemValue;
        this.rightsidebordercolor = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[38])].itemValue;
        //this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => x.itemName == this.stylekeys[39])].itemValue;
        this.rightsideborderwidth = +this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[39])].itemValue;

        this.currentDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[40])].itemValue;
        this.currentMyDocsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[47])].itemValue;
        this.currentVidsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[41])].itemValue;
        this.currentLPsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[42])].itemValue;
        this.currentCourseImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[43])].itemValue;
        this.currentWFsImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[45])].itemValue;
        this.currentProcessImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[46])].itemValue;
        this.currentQuizImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[44])].itemValue;
        this.currentdefWorkgroupImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[48])].itemValue;
        this.currentEnrollmentImage = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[49])].itemValue;
        this.buttonbackground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[50])].itemValue;
        this.buttonforeground = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
            function (e) {
                return e.itemName
            }).indexOf(this.stylekeys[51])].itemValue;

        for (var i = 1; i < 10; i++) {
            this.getCardImage(i);
        }

        localStorage.setItem(this.currentTenantID + "_DocsImage", this.currentDocsImage);
        localStorage.setItem(this.currentTenantID + "_MyDocsImage", this.currentMyDocsImage);
        localStorage.setItem(this.currentTenantID + "_VidsImage", this.currentVidsImage);
        localStorage.setItem(this.currentTenantID + "_CourseImage", this.currentCourseImage);
        localStorage.setItem(this.currentTenantID + "_LPImage", this.currentLPsImage);
        localStorage.setItem(this.currentTenantID + "_WFImage", this.currentWFsImage);
        localStorage.setItem(this.currentTenantID + "_ProcessImage", this.currentProcessImage);
        localStorage.setItem(this.currentTenantID + "_QuizImage", this.currentQuizImage);
        localStorage.setItem(this.currentTenantID + "_defWorkgroupImage", this.currentdefWorkgroupImage);
        localStorage.setItem(this.currentTenantID + "_EnrollmentImage", this.currentEnrollmentImage);
        window.scroll(0, 0);
    }

    deleteTheme_click() {
        switch (this.type) {
            case "company":
                if (this.tenantcurrentlyappliedtheme.themeID != this.selectedTheme.themeID) {
                    $('#deletingmodal' + this.type).modal('show');
                    this.pinnacleService.deleteCurrentTenantTheme(this.selectedTheme.themeID).subscribe(res => {
                        this.pinnacleService.getCurrentTenantThemes(this.type).subscribe(res => {
                            this.currentTenantThemes = res;
                            $('#deletingmodal' + this.type).modal('hide');
                        });
                    });
                }
                else {
                    $('#deletingerrmodal' + this.type).modal('show');
                }
                break;
            case "partner":
                if (this.PartnercurrentlyappliedthemeID != this.selectedTheme.themeID) {
                    $('#deletingmodal' + this.type).modal('show');
                    this.pinnacleService.deleteCurrentTenantTheme(this.selectedTheme.themeID).subscribe(res => {
                        this.pinnacleService.getCurrentTenantThemes(this.type).subscribe(res => {
                            this.currentTenantThemes = res;
                            $('#deletingmodal' + this.type).modal('hide');
                        });
                    });
                }
                else {
                    $('#deletingerrmodal' + this.type).modal('show');
                }
                break;
            case "lite":
                if (this.LitecurrentlyappliedthemeID != this.selectedTheme.themeID) {
                    $('#deletingmodal' + this.type).modal('show');
                    this.pinnacleService.deleteCurrentTenantTheme(this.selectedTheme.themeID).subscribe(res => {
                        this.pinnacleService.getCurrentTenantThemes(this.type).subscribe(res => {
                            this.currentTenantThemes = res;
                            $('#deletingmodal' + this.type).modal('hide');
                        });
                    });
                }
                else {
                    $('#deletingerrmodal' + this.type).modal('show');
                }
                break;

            default:
                break;
        }
        
    }

    SaveTheme(id:string) {
        this.valuesarray.themeproperties = new Array<ThemeItem>();

        //builds a new theme object based on selected choices.
        for (var i = 0; i < this.stylekeys.length; i++) {
            let valuesItem = new ThemeItem();

            switch (this.stylekeys[i]) {
                case "font_face":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.previewFont;
                    break;
                case "navbar_foreground_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.navforegroundcolor;
                    break;
                case "navbar_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.navbackgroundcolor;
                    break;
                case "page_text_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.pageforegroundcolor;
                    break;
                case "page_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.pagebackgroundcolor;
                    break;
                case "page_separator_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.pagebordercolor;
                    break;
                case "page_separator_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.pageborderwidth.toString();
                    break;
                case "large_card_foreground_color1":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardforegroundcolor1;
                    break;
                case "large_card_background_color1":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardbackgroundcolor1;
                    break;
                case "large_card_foreground_color2":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardforegroundcolor2;
                    break;
                case "large_card_background_color2":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardbackgroundcolor2;
                    break;
                case "large_card_foreground_color3":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardforegroundcolor3;
                    break;
                case "large_card_background_color3":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardbackgroundcolor3;
                    break;
                case "large_card_border_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardbordercolor;
                    break;
                case "large_card_border_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardborderwidth.toString();
                    break;
                case "large_card_border_radius":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.largecardborderradius.toString();
                    break;
                case "small_card_foreground_color1":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardforegroundcolor1;
                    break;
                case "small_card_background_color1":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardbackgroundcolor1;
                    break;
                case "small_card_foreground_color2":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardforegroundcolor2;
                    break;
                case "small_card_background_color2":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardbackgroundcolor2;
                    break;
                case "small_card_foreground_color3":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardforegroundcolor3;
                    break;
                case "small_card_background_color3":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardbackgroundcolor3;
                    break;
                case "small_card_border_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardbordercolor;
                    break;
                case "small_card_border_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardborderwidth.toString();
                    break;
                case "small_card_border_radius":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.smallcardborderradius.toString();
                    break;
                case "panel_border_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.panelbordercolor;
                    break;
                case "panel_border_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.panelborderwidth.toString();
                    break;
                case "panel_border_radius":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.panelborderradius.toString();
                    break;
                case "panel_body_foreground_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.panelbodyforeground;
                    break;
                case "panel_body_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.panelbodybackground;
                    break;
                case "panel_title_foreground_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.paneltitleforeground;
                    break;
                case "panel_title_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.paneltitlebackground;
                    break;
                case "hsl_foreground_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.leftsideforeground;
                    break;
                case "hsl_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.leftsidebackground;
                    break;
                case "hsl_border_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.leftsidebordercolor;
                    break;
                case "hsl_border_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.leftsideborderwidth.toString();
                    break;
                case "hsr_foreground_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.rightsideforeground;
                    break;
                case "hsr_background_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.rightsidebackground;
                    break;
                case "hsr_border_color":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.rightsidebordercolor;
                    break;
                case "hsr_border_width":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.rightsideborderwidth.toString();
                    break; 
                case "button_background":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.buttonbackground;
                    break;
                case "button_foreground":
                    valuesItem.itemName = this.stylekeys[i];
                    valuesItem.itemValue = this.buttonforeground;
                    break; 

                default:
            }
            this.valuesarray.themeproperties.push(valuesItem);
        }
        if (id == this.tenantcurrentlyappliedtheme.themeID && (this.isNew == false && this.isEdit == true)) {
            if (this.selectedTheme.themeproperties != null) {
                for (var i = 0; i < this.selectedTheme.themeproperties.length; i++) {
                    if (this.selectedTheme.themeproperties[i].itemName != "themeid" && this.selectedTheme.themeproperties[i].itemName != "tenantid" && this.selectedTheme.themeproperties[i].itemName != "is_public" && this.selectedTheme.themeproperties[i].itemName.indexOf("_image") == -1) {

                        try {
                            this.selectedTheme.themeproperties[i].itemValue = this.valuesarray.themeproperties[this.valuesarray.themeproperties.map(
                                function (e) {
                                    return e.itemName
                                }).indexOf(this.selectedTheme.themeproperties[i].itemName)].itemValue;
                        }
                        catch (err) {
                        }

                    }
                }
                this.ApplySelectedTheme();
            }
        }
        this.pinnacleService.setTheme(this.editingTheme, this.themename, this.valuesarray, this.type).subscribe(res => {
            this.editingTheme = res.toString();
            let filelocationlist = [
                this.currentDocsImage.split("?")[0],
                this.currentVidsImage.split("?")[0],
                this.currentLPsImage.split("?")[0],
                this.currentCourseImage.split("?")[0],
                this.currentQuizImage.split("?")[0],
                this.currentWFsImage.split("?")[0],
                this.currentProcessImage.split("?")[0],
                this.currentMyDocsImage.split("?")[0],
                this.currentdefWorkgroupImage.split("?")[0],
                this.currentEnrollmentImage.split("?")[0]
            ];
            //move cloud generic location to normal location and update the theme record
            $('#savingmodal' + this.type).modal('show');
            this.pinnacleService.MoveImagesToThemeLocation(res.toString(), filelocationlist, this.type).subscribe(res2 => { 
                
                this.valuesarray.themeproperties = new Array<ThemeItem>();
                this.valuesarray = new Theme();

                this.pinnacleService.getCurrentTenantThemes(this.type).subscribe(res3 => {
                    $("#themeEditPanel" + this.type).attr("style", "display:none");
                    $("#companyBrandingPanel" + this.type).attr("style", "display:normal");

                    window.scroll(0, 0);
                    this.isNew = false;
                    this.isEdit = false;

                    this.currentTenantThemes = res3;
                    (<HTMLSelectElement>document.getElementById("themeDropdown" + this.type)).selectedIndex = this.currentTenantThemes.findIndex(x => x.themeID == this.editingTheme);
                    $("#themeDropdown" + this.type).val(this.editingTheme);
                    this.selectedTheme = this.currentTenantThemes[this.currentTenantThemes.findIndex(x => x.themeID == this.editingTheme)];
                });

                $('#savingmodal' + this.type).modal('hide');
            },
                err => {
                    console.log(err);
                    $('#savingmodal' + this.type).modal('hide');
                });

            this.brandingservice.enrollmentemitter.emit(this.currentEnrollmentImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_EnrollmentImage", this.currentEnrollmentImage);
            this.brandingservice.defworkgroupemitter.emit(this.currentdefWorkgroupImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage", this.currentdefWorkgroupImage);
            this.brandingservice.processimgemitter.emit(this.currentProcessImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_ProcessImage", this.currentProcessImage);
            this.brandingservice.wfimageemitter.emit(this.currentWFsImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_WFImage", this.currentWFsImage);
            this.brandingservice.quizimgemitter.emit(this.currentQuizImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_QuizImage", this.currentQuizImage);
            this.brandingservice.courseimgemitter.emit(this.currentCourseImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_CourseImage", this.currentCourseImage);
            this.brandingservice.lpimageemitter.emit(this.currentLPsImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_LPImage", this.currentLPsImage);
            this.brandingservice.vidimgemitter.emit(this.currentVidsImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_VidsImage", this.currentVidsImage);
            this.brandingservice.mydocimgemitter.emit(this.currentMyDocsImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_MyDocsImage", this.currentMyDocsImage);
            this.brandingservice.docimgemitter.emit(this.currentDocsImage);
            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_DocsImage", this.currentDocsImage);

            if (this.type == "company") {
                this.dropdownthemecompany = res.toString();
            }
            else if (this.type == "partner") {
                this.dropdownthemepartner = res.toString();
            }
            else if (this.type == "lite") {
                this.dropdownthemelite = res.toString();
            }
        },
            err => {
                console.log(err);

            });

        this.isNew = false;
        this.isEdit = false;
    }

    ApplySelectedTheme() {
        //first set the company setting
        let setting = new UserSettingPost();
        setting.isValid = true;
        if (this.type == "company") {
            setting.settingName = "COMPANY_THEME";
        }
        else if (this.type == "partner") {
            setting.settingName = "PARTNER_THEME";
        }
        else if (this.type == "lite") {
            setting.settingName = "PARTNER_LITE_THEME";
        }   
        setting.settingType = SettingType_T.string;
        setting.settingValue = this.selectedTheme.themeID;

        if (this.type == "company") {
            this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
            this.tenantcurrentlyappliedtheme = this.selectedTheme;
        }
        else if (this.type == "partner") {
            this.pinnacleService.SetCurrentPartnerSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
            this.Partnercurrentlyappliedtheme = this.selectedTheme;
        }
        else if (this.type == "lite") {
            this.pinnacleService.SetCurrentPartnerSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
            this.Litecurrentlyappliedtheme = this.selectedTheme;
        } 
        

        //next apply all the settings to the current CSS file if the type is company.
        if (this.type == "company") {
            Object.keys(currenttheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    //currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1 ) {
                    document.documentElement.style.setProperty(
                        property,
                        currenttheme.properties[property]
                    );
                }
            });
            //cssVars({
            //    rootElement: document,
            //    variables: currenttheme.properties
            //});
            cssVars({
                rootElement: document,
                variables: {
                    "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                    "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                    "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                    "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                    "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                    "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                    "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                    "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                    "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                    "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                    "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                    "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                    "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                    "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                    "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                    "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                    "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                    "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                    "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                    "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                    "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                    "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                    "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                    "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                    "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                    "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                    "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                    "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                    "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                    "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                    "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                    "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                    "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                    "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                    "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                    "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                    "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                    "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                    "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                    "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                    "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                    "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                    "--font-face": currenttheme.properties["--font-face"],
                    "--page-background-color": currenttheme.properties["--page-background-color"],
                    "--page-text-color": currenttheme.properties["--page-text-color"],
                    "--page-separator-color": currenttheme.properties["--page-separator-color"],
                    "--page-separator-width": currenttheme.properties["--page-separator-width"],
                    "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                    "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                    "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                    "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                    "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                    "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                    "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                    "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                    "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                    "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                    "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                    "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                    "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                    "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                    "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                    "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                    "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                    "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                    "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                    "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                    "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                    "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                    "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                    "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                    "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                    "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                    "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                    "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                    "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                    "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                    "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                    "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                    "--panel-border-color": currenttheme.properties["--panel-border-color"],
                    "--panel-border-width": currenttheme.properties["--panel-border-width"],
                    "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                    "--button-background": currenttheme.properties["--button-background"],
                    "--button-foreground": currenttheme.properties["--button-foreground"]
                }
            });

            localStorage.setItem(this.currentTenantID + "_DocsImage", this.currentDocsImage);
            localStorage.setItem(this.currentTenantID + "_MyDocsImage", this.currentMyDocsImage);
            localStorage.setItem(this.currentTenantID + "_VidsImage", this.currentVidsImage);
            localStorage.setItem(this.currentTenantID + "_CourseImage", this.currentCourseImage);
            localStorage.setItem(this.currentTenantID + "_LPImage", this.currentLPsImage);
            localStorage.setItem(this.currentTenantID + "_WFImage", this.currentWFsImage);
            localStorage.setItem(this.currentTenantID + "_ProcessImage", this.currentProcessImage);
            localStorage.setItem(this.currentTenantID + "_QuizImage", this.currentQuizImage);
            localStorage.setItem(this.currentTenantID + "_defWorkgroupImage", this.currentdefWorkgroupImage);
            localStorage.setItem(this.currentTenantID + "_EnrollmentImage", this.currentEnrollmentImage);
        }
    }

    logoChange() {
        this.logochange = true;
        this.newschange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    newsChange() {
        this.newschange = true;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }

    ChangeSelectedNewsItem(event: any, type: string) {
        $("#btnnewsadd" + this.type).attr("disabled", false);
        $("#btnnewsedit" + this.type).attr("disabled", false);
        $("#btnnewsdelete" + this.type).attr("disabled", false);

        switch (type) {
            case "company":
                this.selectednewsItemid = event.target.value;

                this.selectednewsItem = this.newsitems[this.newsitems.map(function (e) {
                    return e.newsId
                }).indexOf(this.selectednewsItemid)];
                break;
            case "partner":
                this.selectedPartnernewsItemid = event.target.value;

                this.selectedPartnernewsItem = this.partnernewsitems[this.partnernewsitems.map(function (e) {
                    return e.newsId
                }).indexOf(this.selectedPartnernewsItemid)];
                break;               
            case "lite":
                this.selectedLitenewsItemid = event.target.value;

                this.selectedLitenewsItem = this.litenewsitems[this.litenewsitems.map(function (e) {
                    return e.newsId
                }).indexOf(this.selectedLitenewsItemid)];
                break;
            default:
                break;
        }
        
    }

    addnews_click() {
        this.selectednewsItem = new CompanyNewsItem();
    }
    editnews_click() {
        
    }
    deletenews_click() {
        let newsid = "";
        switch (this.type) {
            case "company":
                newsid = this.selectednewsItem.newsId.toString();
                break;
            case "partner":
                newsid = this.selectedPartnernewsItem.newsId.toString();
                break;
            case "lite":
                newsid = this.selectedLitenewsItem.newsId.toString();
                break;

            default:
                break;
        }
        $('#deletingmodal' + this.type).modal('show');
        this.pinnacleService.DeleteNewsItem(newsid, this.type).subscribe(res => {
            switch (this.type) {
                case "company":
                    this.pinnacleService.GetCurrentTenantNews().subscribe(res => {
                        $('#deletingmodal' + this.type).modal('hide');
                        this.newsitems.splice(0, this.newsitems.length);
                        this.newsitems = res;
                        if (this.newsitems.length == 0) {

                        }
                        try {
                            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news", JSON.stringify(this.newsitems));
                            this.brandingservice.newsitemsemitter.emit(this.newsitems);
                        }
                        catch (err) {
                            console.log(err);
                        }
                    });
                    break;
                case "partner":
                    this.pinnacleService.GetPartnerNewsItems().subscribe(res => {
                        $('#deletingmodal' + this.type).modal('hide');

                        this.partnernewsitems.splice(0, this.partnernewsitems.length);
                        this.partnernewsitems = res;
                        if (this.partnernewsitems.length == 0) {

                        }
                        try {
                            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news", JSON.stringify(this.partnernewsitems));
                            this.brandingservice.newsitemsemitter.emit(this.partnernewsitems);
                        }
                        catch (err) {
                            console.log(err);
                        }
                    });

                    break;
                case "lite":
                    this.pinnacleService.GetLiteNewsItems().subscribe(res => {
                        $('#deletingmodal' + this.type).modal('hide');

                        this.litenewsitems.splice(0, this.litenewsitems.length);
                        this.litenewsitems = res;
                        if (this.litenewsitems.length == 0) {

                        }
                        try {
                            localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news", JSON.stringify(this.litenewsitems));

                        }
                        catch (err) {
                            console.log(err);
                        }
                    });

                    break;
                default:
                    break;
            }
           



        });
    }
    saveNewsItem() {
        switch (this.type) {
            case "company":
                if (this.selectednewsItem.name) { 
                    this.pinnacleService.AddEditCurrentTenantNews(this.selectednewsItem).subscribe(res => {
                        this.pinnacleService.GetCurrentTenantNews().subscribe(res => {          
                            this.newsitems.splice(0, this.newsitems.length);
                            this.newsitems = res;
                            $('#addEditNewsItem' + this.type).modal('hide');
                            try {
                                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_company_news", JSON.stringify(this.newsitems));
                                this.brandingservice.newsitemsemitter.emit(this.newsitems);

                            }
                            catch (err) {
                                console.log(err);
                            }
                        }, err => {
                            console.log(err);
                        });
                        this.selectednewsItem = new CompanyNewsItem();
                        });
                }
                else {
                    window.alert("Please enter a title");
                }
                break;
            case "partner":
                if (this.selectedPartnernewsItem.name != "") {
                    this.pinnacleService.AddEditCurrentPartnerNews(this.selectedPartnernewsItem).subscribe(res => {
                        this.pinnacleService.GetPartnerNewsItems().subscribe(res => {
                            this.partnernewsitems.splice(0, this.partnernewsitems.length);
                            this.partnernewsitems = res;
                            $('#addEditNewsItem' + this.type).modal('hide');
                            try {
                                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news", JSON.stringify(this.partnernewsitems));
                                this.brandingservice.newsitemsemitter.emit(this.partnernewsitems);

                            }
                            catch (err) {
                                console.log(err);
                            }
                        });
                        this.selectedPartnernewsItem = new PartnerNewsItem();
                    });
                }
                else {
                    window.alert("Please enter a title");
                }
                break;
            case "lite":
                if (this.selectedLitenewsItem.name != "") {
                    this.pinnacleService.AddEditCurrentLiteNews(this.selectedLitenewsItem).subscribe(res => {
                        this.pinnacleService.GetLiteNewsItems().subscribe(res => {
                            this.litenewsitems.splice(0, this.litenewsitems.length);
                            this.litenewsitems = res;
                            $('#addEditNewsItem' + this.type).modal('hide');
                            try {
                                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news", JSON.stringify(this.litenewsitems));


                            }
                            catch (err) {
                                console.log(err);
                            }
                        });
                        this.selectedLitenewsItem = new PartnerNewsItem();
                    });
                }
                else {
                    window.alert("Please enter a title");
                }
                
                break;
            default:
        }
        
    }
    cancelNewsItem() {
        //this.selectednewsItem = this.newsitems[this.newsitems.map(function (e) {
        //    return e.newsId
        //}).indexOf(this.selectednewsItemid)];
        this.selectedLitenewsItem = new PartnerNewsItem();
        this.selectedPartnernewsItem = new PartnerNewsItem();
        this.selectednewsItem = new CompanyNewsItem();
    }

    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery + Math.floor((Math.random() * 10000) + 1);
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery + Math.floor((Math.random() * 10000) + 1);
                }
            }
        }
        return newSource;
    }

    docChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = true;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;


    }
    mydocChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = true;


    }
    vidChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = true;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    lpChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = true;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    courseChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = true;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    quizChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = true;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    wfChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = true;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;



    }
    processChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = true;
        this.defworkgroupchange = false;
        this.enrollmentchange = false;
        this.mydocchange = false;


    }
    workgroupChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = true;
        this.enrollmentchange = false;
        this.mydocchange = false;

    }
    enrollmentChange() {
        this.newschange = false;
        this.logochange = false;
        this.docchange = false;
        this.vidchange = false;
        this.lpchange = false;
        this.coursechange = false;
        this.quizchange = false;
        this.wfchange = false;
        this.processchange = false;
        this.defworkgroupchange = false;
        this.enrollmentchange = true;
        this.mydocchange = false;

    }

    getCardImage(count:number) {
        switch (count) {
            case 1: //Workgroup
                if (this.currentdefWorkgroupImage != "") {
                    this.currentdefWorkgroupImage= this.getUniqueImageSource(this.currentdefWorkgroupImage);
                }
                else {
                    this.currentdefWorkgroupImage= this.getUniqueImageSource("../../assets/images/default_project.jpg");
                }
                
            case 2://Course
                if (this.currentCourseImage != "") {
                    this.currentCourseImage= this.getUniqueImageSource(this.currentCourseImage);
                }
                else {
                    this.currentCourseImage= this.getUniqueImageSource("../../assets/images/default_course.jpg");
                }
            case 3://NOT USED
                
            case 4://Workflow
                if (this.currentWFsImage != "") {
                    this.currentWFsImage= this.getUniqueImageSource(this.currentWFsImage);
                }
                else {
                    this.currentWFsImage= this.getUniqueImageSource("../../assets/images/default_workflow.jpg");
                }
            case 5://Process
                if (this.currentProcessImage != "") {
                    this.currentProcessImage= this.getUniqueImageSource(this.currentProcessImage);
                }
                else {
                    this.currentProcessImage= this.getUniqueImageSource("../../assets/images/default_process.jpg");
                }
            case 6://Video
                if (this.currentVidsImage != "") {
                    this.currentVidsImage= this.getUniqueImageSource(this.currentVidsImage);
                }
                else {
                    this.currentVidsImage= this.getUniqueImageSource("../../assets/images/default_video.jpg");
                }
            case 7://Document
                if (this.currentDocsImage != "") {
                    this.currentDocsImage= this.getUniqueImageSource(this.currentDocsImage);
                }
                else {
                    this.currentDocsImage= this.getUniqueImageSource("../../assets/images/default_cheatsheet.jpg");
                }
            case 8://MyDocument
                if (this.currentMyDocsImage != "") {
                    this.currentMyDocsImage= this.getUniqueImageSource(this.currentMyDocsImage);
                }
                else {
                    this.currentMyDocsImage= this.getUniqueImageSource("../../assets/images/My Cheat Sheets Graphic.png");
                }
            case 9://LearningPath
                if (this.currentLPsImage != "") {
                    this.currentLPsImage= this.getUniqueImageSource(this.currentLPsImage);
                }
                else {
                    this.currentLPsImage= this.getUniqueImageSource("../../assets/images/default_learningpath.jpg");
                }
            case 10://Enrollment
                if (this.currentEnrollmentImage != "") {
                    this.currentEnrollmentImage= this.getUniqueImageSource(this.currentEnrollmentImage);
                }
                else {
                    this.currentEnrollmentImage= this.getUniqueImageSource("../../assets/images/default_course.jpg");
                }

            default:
        }

    }
    //taskChange() {

    //}
    //stepChange() {

    //}
    //milestoneChange() {

    //}

    getBrandingTitle() {
        if (this.type == "company") {
            //return "Company Branding";
            return this.translationService.getTranslationFileData("BRANDING.CompanyBrandingLabel");
        }
        else if (this.type == "partner") {
            //return "Partner Branding";
            return this.translationService.getTranslationFileData("BRANDING.PartnerBrandingLabel");
        }
        else if (this.type == "lite") {
            //return "Pinnacle Lite Branding";
            return this.translationService.getTranslationFileData("BRANDING.PinnacleLiteBrandingLabel");
        }

    }


    translatedButtonText(value: string) {

        if (value == "New") {
            return this.translationService.getTranslationFileData("BRANDING.New");
        }
        else if (value == "Edit") {
            return this.translationService.getTranslationFileData("BRANDING.Edit");
        }
        else if (value == "Delete") {
            return this.translationService.getTranslationFileData("BRANDING.Delete");
        }
        else if (value == "Change Image") {
            return this.translationService.getTranslationFileData("BRANDING.ChangeImage");
        }
        else if (value == "Apply URL") {
            return this.translationService.getTranslationFileData("BRANDING.ApplyURL");
        }
        else if (value == "Group Layout Assignments") {
            return this.translationService.getTranslationFileData("BRANDING.GroupLayoutAssignments");
        }
        else if (value == "Apply") {
            return this.translationService.getTranslationFileData("BRANDING.Apply");
        }
        else if (value == "Copy URL") {
            return this.translationService.getTranslationFileData("BRANDING.CopyURL");
        }
        return value;
    }

    translatedOption(value: string) {

        if (value == "Home Screen") {
            return this.translationService.getTranslationFileData("BRANDING.HomeScreen");
        }
        else if (value == "Learning Center") {
            return this.translationService.getTranslationFileData("BRANDING.LearningCenter");
        }
        return value;
    }

    gettarget() {
        return "#addEditNewsItem" + this.type;
    }

    moveNewsUp() {
        let currentindex = 0;
        let previousindex = 0;
        switch (this.type) {
            case "company":
                var opt = $('#newsitemdropdown option:selected');
                if (this.newsitems.length > 0) {
                    if (opt.is(':first-child')) {

                    }
                    else {
                        opt.insertBefore(opt.prev());

                        currentindex = this.newsitems.indexOf(this.selectednewsItem);
                        previousindex = this.newsitems.indexOf(this.selectednewsItem) - 1;

                        this.newsitems[currentindex].seq = this.newsitems[currentindex].seq - 1;
                        this.newsitems[previousindex].seq = this.newsitems[previousindex].seq + 1;

                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentTenantNews(this.newsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentTenantNews(this.newsitems[previousindex]).subscribe(res2 => {
                                this.newsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                $('#savingmodal' + this.type).modal('hide');
                            });
                        });


                        //this.selectednewsItem.seq = this.selectednewsItem.seq - 1;
                    }
                }
                
                break;
            case "partner":
                var opt = $('#partnernewsitemdropdown option:selected');

                if (this.partnernewsitems.length > 0) {
                    if (opt.is(':first-child')) {

                    }
                    else {
                        opt.insertBefore(opt.prev());

                        currentindex = this.partnernewsitems.indexOf(this.selectedPartnernewsItem);
                        previousindex = this.partnernewsitems.indexOf(this.selectedPartnernewsItem) - 1;

                        this.partnernewsitems[currentindex].seq = this.partnernewsitems[currentindex].seq - 1;
                        this.partnernewsitems[previousindex].seq = this.partnernewsitems[previousindex].seq + 1;



                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentPartnerNews(this.partnernewsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentPartnerNews(this.partnernewsitems[previousindex]).subscribe(res2 => {
                                this.partnernewsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                $('#savingmodal' + this.type).modal('hide');
                            });

                        });

                    }
                }
               
                break;
            case "lite":
                var opt = $('#litenewsitemdropdown option:selected');
                if (this.litenewsitems.length > 0) {
                    if (opt.is(':first-child')) {

                    }
                    else {
                        opt.insertBefore(opt.prev());

                        currentindex = this.litenewsitems.indexOf(this.selectedLitenewsItem);
                        previousindex = this.litenewsitems.indexOf(this.selectedLitenewsItem) - 1;

                        this.litenewsitems[currentindex].seq = this.litenewsitems[currentindex].seq - 1;
                        this.litenewsitems[previousindex].seq = this.litenewsitems[previousindex].seq + 1;



                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentLiteNews(this.litenewsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentLiteNews(this.litenewsitems[previousindex]).subscribe(res2 => {
                                this.litenewsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                $('#savingmodal' + this.type).modal('hide');
                            });
                        });


                    }
                }
              
                break;
            default:
                break;
        }
    }
    moveNewsDown() {
        let currentindex = 0;
        let nextindex = 0;
        switch (this.type) {
            case "company":
                var opt = $('#newsitemdropdown option:selected');
                if (this.newsitems.length > 0) {
                
                    if (opt.is(':last-child')) {
                       
                    }
                    else {
                        opt.insertAfter(opt.next());

                        currentindex = this.newsitems.indexOf(this.selectednewsItem);
                        nextindex = this.newsitems.indexOf(this.selectednewsItem) + 1;

                        this.newsitems[currentindex].seq = this.newsitems[currentindex].seq + 1;
                        this.newsitems[nextindex].seq = this.newsitems[nextindex].seq - 1;

                       

                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentTenantNews(this.newsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentTenantNews(this.newsitems[nextindex]).subscribe(res2 => {
                                this.newsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                $('#savingmodal' + this.type).modal('hide');
                            });
                        });
                        
                    }
                }
                
                break;
            case "partner":
                var opt = $('#partnernewsitemdropdown option:selected');

                if (this.partnernewsitems.length > 0) {
                    if (opt.is(':last-child')) {
                        
                    }
                    else {
                        opt.insertAfter(opt.next());

                        currentindex = this.partnernewsitems.indexOf(this.selectedPartnernewsItem);
                        nextindex = this.partnernewsitems.indexOf(this.selectedPartnernewsItem) + 1;

                        this.partnernewsitems[currentindex].seq = this.partnernewsitems[currentindex].seq + 1;
                        this.partnernewsitems[nextindex].seq = this.partnernewsitems[nextindex].seq - 1;

                     

                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentPartnerNews(this.partnernewsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentPartnerNews(this.partnernewsitems[nextindex]).subscribe(res2 => {
                                this.partnernewsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                $('#savingmodal' + this.type).modal('hide');
                            });
                        });

                    }
                }
               
                break;
            case "lite":
                var opt = $('#litenewsitemdropdown option:selected');

                if (this.litenewsitems.length > 0) {
                
                    if (opt.is(':last-child')) {
                       
                    }
                    else {
                        opt.insertAfter(opt.next());

                        currentindex = this.litenewsitems.indexOf(this.selectedLitenewsItem);
                        nextindex = this.litenewsitems.indexOf(this.selectedLitenewsItem) + 1;

                        this.litenewsitems[currentindex].seq = this.litenewsitems[currentindex].seq + 1;
                        this.litenewsitems[nextindex].seq = this.litenewsitems[nextindex].seq - 1;


                        $('#savingmodal' + this.type).modal('show');
                        this.pinnacleService.AddEditCurrentLiteNews(this.litenewsitems[currentindex]).subscribe(res => {
                            this.pinnacleService.AddEditCurrentLiteNews(this.litenewsitems[nextindex]).subscribe(res2 => {
                                this.litenewsitems.sort((a, b) => {
                                    if (a.seq > b.seq) {
                                        return 1;
                                    }
                                    if (a.seq < b.seq) {
                                        return -1;
                                    }
                                    return 0;
                                })

                                $('#savingmodal' + this.type).modal('hide');
                            });
                        });
                        

                    }
                }
               
                break;
            default:
                break;
        }
    }

    checkMaxVal(item: string, val: number, max: number, min: number) {

        if (val == null) {
            this.updateNumberElementValue(item, 0);
        }
        if (val > max) {
            this.updateNumberElementValue(item, max);
        }
        if (val < min) {
            this.updateNumberElementValue(item, min);
        }
    }

    onKeyLimitChar(cLimit: number, item: string, val: number) { // without type info

        if (val !== null) {
            if (val.toString().length >= cLimit) {
                this.updateNumberElementValue(item, Number(val.toString().substr(0, cLimit)));
            }
            else
                { this.updateNumberElementValue(item, val); }
        }
    }


    updateNumberElementValue(itemString: string, newValue: number) {
        $('#' + itemString).val(newValue);
        var element = document.getElementById(itemString);
        var event = document.createEvent('Event');
        event.initEvent('change', true, true);
        element.dispatchEvent(event);
    }
    
    applyLogoURL() {
        let setting = new UserSettingPost();
        setting.settingName = "COMPANY_LOGO_NAVIGATE_URL";
        setting.settingType = SettingType_T.string;
        setting.settingValue = this.logoURL;
        setting.isValid = true;
        this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(),setting).subscribe();

        $("#company_link_url").attr('href', this.logoURL).css({ 'cursor': 'pointer', 'pointer-events': 'auto' });

        if (this.logoURL.includes(window.location.origin.replace(window.location.protocol, "")) == true) {
            $("#company_link_url").attr('target', '_self');
        }
        else {
            $("#company_link_url").attr('target', '_blank');
        }
    }

    dashboardClick(selector: string) {
        this.changedetectorref.detectChanges();
        setTimeout(() => { 
            if (true) {          
                if (selector == "company") {
                    this.WidContainerCompany.clearWidgets();
                    this.WidContainerCompany.loadContainerFramework();
                    this.WidContainerCompany.adjustLayout();
                    this.WidContainerCompany.onResize(null);
                }
                else if (selector == "partner") {
                    this.WidContainerPartner.clearWidgets();
                    this.WidContainerPartner.loadContainerFramework();
                    this.WidContainerPartner.adjustLayout();
                    this.WidContainerPartner.onResize(null);
                }
                else if (selector == "lite") {
                    this.WidContainerLite.clearWidgets();
                    this.WidContainerLite.loadContainerFramework();
                    this.WidContainerLite.adjustLayout();
                    this.WidContainerLite.onResize(null);
                }
                else if (selector == "landingpage") {
                    this.WidLandingPageContainer.clearWidgets();
                    this.WidLandingPageContainer.loadContainerFramework();
                    this.WidLandingPageContainer.adjustLayout();
                    this.WidLandingPageContainer.onResize(null);
                }
            }
        });
        
    }

    showLayoutsAssignment() {
        if (this.type == "company") {
            if (this.isAssigningCompany == false) {
                this.isAssigningCompany = true;
            }
            else {
                this.isAssigningCompany = false;
            }
        }
        if (this.type == "partner") {
            if (this.isAssigningPartner == false) {
                this.isAssigningPartner = true;
            }
            else {
                this.isAssigningPartner = false;
            }
        }
        if (this.type == "lite") {
            if (this.isAssigningLite == false) {
                this.isAssigningLite = true;
            }
            else {
                this.isAssigningLite = false;
            }
        }
        setTimeout(() => {
            this.onLayoutChange(false);
        }, 0);
        setTimeout(() => {
            for (var i = 0; i < this.tenantRoleAssignments.length; i++) {
                if ((<HTMLSelectElement>document.getElementById(this.type + "layoutlistrole" + this.tenantRoleAssignments[i].roleid))) {
                    (<HTMLSelectElement>document.getElementById(this.type + "layoutlistrole" + this.tenantRoleAssignments[i].roleid)).value = this.tenantRoleAssignments[i].layoutid;
                }
            }
        },10);
    }

    addLayoutClick() {
        
    }

    addLayoutFromCurrent() {
        this.hasHomeScreenVisited = false;
        this.hasLearningCenterVisited = false;
        this.isNewLayout = true;

        if (this.type == "company") {
            
            if (this.selectedPageCompany = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }

            this.WidContainerCompany.clearWidgets();
            
            //make call to copy the current layout.
            this.pinnacleService.CopyWidgetLayout(false, this.currentLayoutCompany).subscribe(newitem => {
                //take the newitem returned from this call and add it to the layout array and make it the selected layout.
                this.newLayoutID = newitem.layoutId;
                this.widgetLayoutsCompany.push(newitem);
                this.widgetLayoutsCompany.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
                this.selectedLayoutCompany = newitem.name;
                this.onLayoutChange(true);

                this.shouldEnterEdit = true;

                               
            });


        }
        if (this.type == "partner") {
            if (this.selectedPagePartner = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }

            this.WidContainerPartner.clearWidgets();

            //make call to copy the current layout.
            this.pinnacleService.CopyWidgetLayout(true, this.currentLayoutPartner).subscribe(newitem => {
                //take the newitem returned from this call and add it to the layout array and make it the selected layout.
                this.newLayoutID = newitem.layoutId;
                this.widgetLayoutsPartner.push(newitem);
                this.selectedLayoutPartner = newitem.name;
                this.onLayoutChange(true);
                this.shouldEnterEdit = true;

            });
        }
        if (this.type == "lite") {
            if (this.selectedPageLite = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }

            this.WidContainerLite.clearWidgets();

            //make call to copy the current layout.
            this.pinnacleService.CopyWidgetLayout(false, this.currentLayoutLite).subscribe(newitem => {
                //take the newitem returned from this call and add it to the layout array and make it the selected layout.
                this.newLayoutID = newitem.layoutId;
                this.widgetLayoutsLite.push(newitem);
                this.selectedLayoutLite = newitem.name;
                this.onLayoutChange(true);
                this.shouldEnterEdit = true;

            });
        }
         
    }

    addBlankLayout() {
        this.hasHomeScreenVisited = false;
        this.hasLearningCenterVisited = false;
        this.isNewLayout = true;

        let newlayout = new WidgetLayoutInfo();
        newlayout.layoutId = ProdGenApi.EMPTY_GUID;
        newlayout.name = "New Layout";

        if (this.type == "company") {
            if (this.selectedPageCompany = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }
            this.WidContainerCompany.clearWidgets();
            newlayout.isPublic = false;
            this.pinnacleService.createWidgetLayout(newlayout).subscribe(res => {
                newlayout.layoutId = res;
                this.newLayoutID = newlayout.layoutId;
                this.widgetLayoutsCompany.push(newlayout);
                this.widgetLayoutsCompany.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
                this.currentLayoutCompany = newlayout;

                let newHomeContainerInfo = new WidgetContainerInfo();
                newHomeContainerInfo.bindingId = res;
                newHomeContainerInfo.bindingType = "Layout";
                newHomeContainerInfo.containerid = "HOME_SCREEN_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newHomeContainerInfo).subscribe(set1 => {
                    newHomeContainerInfo.containerInstanceId = set1;
                    this.homeContainerCompany = newHomeContainerInfo;
                });

                let newLearningCenterContainerInfo = new WidgetContainerInfo();
                newLearningCenterContainerInfo.bindingId = res;
                newLearningCenterContainerInfo.bindingType = "Layout";
                newLearningCenterContainerInfo.containerid = "LEARNING_CENTER_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newLearningCenterContainerInfo).subscribe(set2 => {
                    newLearningCenterContainerInfo.containerInstanceId = set2;
                    this.learningcenterContainerCompany = newLearningCenterContainerInfo;
                });
            });
        }
        if (this.type == "partner") {
            if (this.selectedPagePartner = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }
            this.WidContainerPartner.clearWidgets();
            newlayout.isPublic = true;
            this.pinnacleService.createWidgetLayout(newlayout).subscribe(res => {
                newlayout.layoutId = res;
                this.newLayoutID = newlayout.layoutId;
                this.widgetLayoutsPartner.push(newlayout);
                this.currentLayoutPartner = newlayout;

                let newHomeContainerInfo = new WidgetContainerInfo();
                newHomeContainerInfo.bindingId = res;
                newHomeContainerInfo.bindingType = "Layout";
                newHomeContainerInfo.containerid = "HOME_SCREEN_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newHomeContainerInfo).subscribe(set1 => {
                    newHomeContainerInfo.containerInstanceId = set1;
                    this.homeContainerPartner = newHomeContainerInfo;
                });

                let newLearningCenterContainerInfo = new WidgetContainerInfo();
                newLearningCenterContainerInfo.bindingId = res;
                newLearningCenterContainerInfo.bindingType = "Layout";
                newLearningCenterContainerInfo.containerid = "LEARNING_CENTER_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newLearningCenterContainerInfo).subscribe(set2 => {
                    newLearningCenterContainerInfo.containerInstanceId = set2;
                    this.learningcenterContainerPartner = newLearningCenterContainerInfo;
                });
            });
        }
        if (this.type == "lite") {
            if (this.selectedPageLite = "Home Screen") {
                this.hasHomeScreenVisited = true;
            }
            else {
                this.hasLearningCenterVisited = true;
            }
            this.WidContainerLite.clearWidgets();
            newlayout.isPublic = true;
            this.pinnacleService.createWidgetLayout(newlayout).subscribe(res => {
                newlayout.layoutId = res;
                this.newLayoutID = newlayout.layoutId;
                this.widgetLayoutsLite.push(newlayout);
                this.currentLayoutLite = newlayout;

                let newHomeContainerInfo = new WidgetContainerInfo();
                newHomeContainerInfo.bindingId = res;
                newHomeContainerInfo.bindingType = "Layout";
                newHomeContainerInfo.containerid = "HOME_SCREEN_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newHomeContainerInfo).subscribe(set1 => {
                    newHomeContainerInfo.containerInstanceId = set1;
                    this.homeContainerLite = newHomeContainerInfo;
                });

                let newLearningCenterContainerInfo = new WidgetContainerInfo();
                newLearningCenterContainerInfo.bindingId = res;
                newLearningCenterContainerInfo.bindingType = "Layout";
                newLearningCenterContainerInfo.containerid = "LEARNING_CENTER_CONTAINER";
                //leave the rest of the new container as default

                this.pinnacleService.createWidgetContainer(newLearningCenterContainerInfo).subscribe(set2 => {
                    newLearningCenterContainerInfo.containerInstanceId = set2;
                    this.learningcenterContainerLite = newLearningCenterContainerInfo;
                });
            });
        }
        

        this.isEditMode = true;
    }

    EnableEditMode() {
        if (this.isEditMode == true) {
            this.isEditMode = false;          
        }
        else {
            this.isEditMode = true;
            this.hasLearningCenterVisited = true;
            this.hasHomeScreenVisited = true;
        }
        this.changedetectorref.detectChanges();
    }

    SaveLayout(newlayoutflag: boolean = false, checkduplicatenames:boolean = true) {
        let layoutsavename = (<HTMLInputElement>document.getElementById(this.type + "layoutname")).value;
        let layoutlistcompare;
        let layoutid;
        if (this.type == "company") {
            layoutlistcompare = this.widgetLayoutsCompany;
            layoutid = this.currentLayoutCompany.layoutId;
        }
        else if (this.type == "partner") {
            layoutlistcompare = this.widgetLayoutsPartner;
            layoutid = this.currentLayoutPartner.layoutId;
        }
        else if (this.type == "lite") {
            layoutlistcompare = this.widgetLayoutsLite;
            layoutid = this.currentLayoutLite.layoutId;
        }

        if ((this.hasHomeScreenVisited == false || this.hasLearningCenterVisited == false) && newlayoutflag == false) {
            //show modal
            $('#confirmSaveBlankPage' + this.type).modal('show');
        }
        else if (this.isUniqueName(layoutsavename, layoutid, layoutlistcompare) == false && checkduplicatenames == true) {
            //show modal
            $('#duplicateNameErrorModal' + this.type).modal('show');
        }
        else {
            if (this.type == "company") {
                this.WidContainerCompany.saveWidgetPositions();
                this.WidContainerCompany.DeleteWidgetCleanup();
                this.currentLayoutCompany.name = layoutsavename;
                this.pinnacleService.modifyWidgetLayout(this.currentLayoutCompany).subscribe(res => { });
                this.selectedLayoutCompany = this.currentLayoutCompany.name;
            }
            if (this.type == "partner") {
                this.WidContainerPartner.saveWidgetPositions();
                this.WidContainerPartner.DeleteWidgetCleanup();
                this.currentLayoutPartner.name = layoutsavename;
                this.pinnacleService.modifyWidgetLayout(this.currentLayoutPartner).subscribe(res => { });
                this.selectedLayoutPartner = this.currentLayoutPartner.name;
            }
            if (this.type == "lite") {
                this.WidContainerLite.saveWidgetPositions();
                this.WidContainerLite.DeleteWidgetCleanup();
                this.currentLayoutLite.name = layoutsavename;
                this.pinnacleService.modifyWidgetLayout(this.currentLayoutLite).subscribe(res => { });
                this.selectedLayoutLite = this.currentLayoutLite.name;

            }
            
            if (newlayoutflag == false) {
                this.hasHomeScreenVisited = false;
                this.hasLearningCenterVisited = false;
                this.isNewLayout = false;
                this.newLayoutID = "";
                this.EnableEditMode();
            }
            else {
                this.isNewLayout = true;
            }

        }
    }

    blankConfirm() {
        this.hasLearningCenterVisited = true;
        this.hasHomeScreenVisited = true;
        this.SaveLayout();
    }

    DiscardLayout() {
        if (this.type == "company") {
            this.WidContainerCompany.resetWidgetContainer();
            this.WidContainerCompany.DiscardCleanup();
            //for (let i = 0; i < this.widgetLayoutsCompany.length; i++) {
            //    if (this.widgetLayoutsCompany[i].name=="tempblank" || this.widgetLayoutsCompany[i].name=="New Layout") {
            //        this.widgetLayoutsCompany.splice(i, 1);
            //        i = i - 1;
            //    }
            //}
        }
        if (this.type == "partner") {
            this.WidContainerPartner.resetWidgetContainer();
            this.WidContainerPartner.DiscardCleanup();
            //for (let i = 0; i < this.widgetLayoutsPartner.length; i++) {
            //    if (this.widgetLayoutsPartner[i].name=="tempblank" || this.widgetLayoutsPartner[i].name=="New Layout") {
            //        this.widgetLayoutsPartner.splice(i, 1);
            //        i = i - 1;
            //    }
            //}
        }
        if (this.type == "lite") {
            this.WidContainerLite.resetWidgetContainer();
            this.WidContainerLite.DiscardCleanup();
            //for (let i = 0; i < this.widgetLayoutsLite.length; i++) {
            //    if (this.widgetLayoutsLite[i].name =="tempblank" || this.widgetLayoutsLite[i].name == "New Layout") {
            //        this.widgetLayoutsLite.splice(i, 1);
            //        i = i - 1;
            //    }
            //}
        }
        if (this.isNewLayout == true) {
            //cleanup not saved layout
            
            let localNewLayoutID = this.newLayoutID;
            if (this.type == "company") {
                this.pinnacleService.deleteWidgetLayout(this.newLayoutID).subscribe(res => {
                    this.widgetLayoutsCompany.splice(this.widgetLayoutsCompany.findIndex(x => x.layoutId == localNewLayoutID), 1);
                });
            }
            if (this.type == "partner") {
                this.pinnacleService.deleteWidgetLayout(this.newLayoutID).subscribe(res => {
                    this.widgetLayoutsPartner.splice(this.widgetLayoutsPartner.findIndex(x => x.layoutId == localNewLayoutID), 1);
                });
            }
            if (this.type == "lite") {
                this.pinnacleService.deleteWidgetLayout(this.newLayoutID).subscribe(res => {
                    this.widgetLayoutsLite.splice(this.widgetLayoutsLite.findIndex(x => x.layoutId == localNewLayoutID), 1);
                });
            }
            this.newLayoutID = "";
        }
        //WidgetContainerComponent.thisFromOutside.resetWidgetContainer();
        this.EnableEditMode();
        if (this.isNewLayout == true) {
            this.onLayoutChange(true);
        }
        this.isNewLayout = false;
        
    }

    onPageChange() {
        if (this.isEditMode == true) {//we are changing pages from edit mode, we need to save the page we are leaving
            this.SaveLayout(true,false);
        }
        if (this.type == "company") {
            this.currentPageCompany = this.widgetPagesCompany.find(x => x.pageName == this.selectedPageCompany);
            if (this.selectedPageCompany == "Home Screen") {
                this.hasHomeScreenVisited = true;
                this.currentContainerInfoCompany = this.homeContainerCompany;
            }
            else if (this.selectedPageCompany == "Learning Center") {
                this.hasLearningCenterVisited = true;
                this.currentContainerInfoCompany = this.learningcenterContainerCompany;
            }
        //need to clear widgets out of the dom
        }
        if (this.type == "partner") {
            this.currentPagePartner = this.widgetPagesPartner.find(x => x.pageName == this.selectedPagePartner);
            if (this.selectedPagePartner == "Home Screen") {
                this.hasHomeScreenVisited = true;
                this.currentContainerInfoPartner = this.homeContainerPartner;
            }
            else if (this.selectedPagePartner == "Learning Center") {
                this.hasLearningCenterVisited = true;
                this.currentContainerInfoPartner = this.learningcenterContainerPartner;
            }
            //need to clear widgets out of the dom
        }
        if (this.type == "lite") {
            this.currentPageLite = this.widgetPagesLite.find(x => x.pageName == this.selectedPageLite);
            if (this.selectedPageLite == "Home Screen") {
                this.hasHomeScreenVisited = true;
                this.currentContainerInfoLite = this.homeContainerLite;
            }
            else if (this.selectedPageLite == "Learning Center") {
                this.hasLearningCenterVisited = true;
                this.currentContainerInfoLite = this.learningcenterContainerLite;
            }
            //need to clear widgets out of the dom
        }

        
    }

    onLayoutChange(clearWidgets:boolean=true) {
        if (this.type == "company") {
            if (clearWidgets == true) { 
                this.WidContainerCompany.clearWidgets();
            }
            this.currentLayoutCompany = this.widgetLayoutsCompany.find(x => x.name == this.selectedLayoutCompany);

            if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
                $('#' + this.type + 'btneditlayout').attr("disabled", true);
                $('#' + this.type + 'btndeletelayout').attr("disabled", true);
            }
            else {
                $('#' + this.type + 'btneditlayout').attr("disabled", false);
                $('#' + this.type + 'btndeletelayout').attr("disabled", false);
            }
            
        //need to clear widgets out of the dom
        }
        if (this.type == "partner") {
            if (clearWidgets == true) {
                this.WidContainerPartner.clearWidgets();
            }
            this.currentLayoutPartner = this.widgetLayoutsPartner.find(x => x.name == this.selectedLayoutPartner);

            if (this.currentLayoutPartner.layoutId.toUpperCase() == '5DBEFB1E-D628-4671-89F0-261816AAD6F3' && this.currentTenantID != 'A572ABCE-E296-4D90-A5E8-17309E3BAB01') {//eagle points layout id
                $('#' + this.type + 'btneditlayout').attr("disabled", true);
                $('#' + this.type + 'btndeletelayout').attr("disabled", true);
            }
            else {
                $('#' + this.type + 'btneditlayout').attr("disabled", false);
                $('#' + this.type + 'btndeletelayout').attr("disabled", false);
            }
            //need to clear widgets out of the dom
        }
        if (this.type == "lite") {
            if (clearWidgets == true) {
                this.WidContainerLite.clearWidgets();
            }
            this.currentLayoutLite = this.widgetLayoutsLite.find(x => x.name == this.selectedLayoutLite);

            if (this.currentLayoutLite.layoutId.toUpperCase() == '5DBEFB1E-D628-4671-89F0-261816AAD6F3' && this.currentTenantID != 'A572ABCE-E296-4D90-A5E8-17309E3BAB01') {//eagle points layout id
                $('#' + this.type + 'btneditlayout').attr("disabled", true);
                $('#' + this.type + 'btndeletelayout').attr("disabled", true);
            }
            else {
                $('#' + this.type + 'btneditlayout').attr("disabled", false);
                $('#' + this.type + 'btndeletelayout').attr("disabled", false);
            }
            //need to clear widgets out of the dom
        }
    }

    deleteLayoutClick() {

        if (this.type == "company") {
            if (this.currentLayoutCompany.layoutId == this.appliedLayoutCompanyObj.layoutId) {
                $('#deleteApplied' + this.type).modal('show');
            }
            else {
                $('#confirmDeleteLayoutModal' + this.type).modal('show');
            }
        }
        if (this.type == "partner") {
            if (this.currentLayoutPartner.layoutId == this.appliedLayoutPartnerObj.layoutId) {
                $('#deleteApplied' + this.type).modal('show');
            }
            else {
                $('#confirmDeleteLayoutModal' + this.type).modal('show');
            }
        }
        if (this.type == "lite") {
            if (this.currentLayoutLite.layoutId == this.appliedLayoutLiteObj.layoutId) {
                $('#deleteApplied' + this.type).modal('show');
            }
            else {
                $('#confirmDeleteLayoutModal' + this.type).modal('show');
            }
        }

    }

    deleteLayout() {

        if (this.type == "company") {
            this.pinnacleService.deleteWidgetLayout(this.currentLayoutCompany.layoutId).subscribe(res => {
                this.widgetLayoutsCompany.splice(this.widgetLayoutsCompany.findIndex(x => x.layoutId == this.currentLayoutCompany.layoutId), 1);
                this.selectedLayoutCompany = this.widgetLayoutsCompany[0].name;
                this.onLayoutChange();
            });
        }
        if (this.type == "partner") {
            this.pinnacleService.deleteWidgetLayout(this.currentLayoutPartner.layoutId).subscribe(res => {
                this.widgetLayoutsPartner.splice(this.widgetLayoutsPartner.findIndex(x => x.layoutId == this.currentLayoutPartner.layoutId), 1);
                this.selectedLayoutPartner = this.widgetLayoutsPartner[0].name;
                this.onLayoutChange();
            });
        }
        if (this.type == "lite") {
            this.pinnacleService.deleteWidgetLayout(this.currentLayoutLite.layoutId).subscribe(res => {
                this.widgetLayoutsLite.splice(this.widgetLayoutsLite.findIndex(x => x.layoutId == this.currentLayoutLite.layoutId), 1);
                this.selectedLayoutLite = this.widgetLayoutsLite[0].name;
                this.onLayoutChange();
            });
        }
        
    }

    ApplySelectedLayout() {
        let setting = new UserSettingPost();
        setting.isValid = true;
        if (this.type == "company") {
            setting.settingName = "COMPANY_LAYOUT";
            setting.settingValue = this.currentLayoutCompany.layoutId;
            this.appliedLayoutCompany = this.currentLayoutCompany.name;
            this.appliedLayoutCompanyObj = this.currentLayoutCompany;
        }
        else if (this.type == "partner") {
            setting.settingName = "PARTNER_LAYOUT";
            setting.settingValue = this.currentLayoutPartner.layoutId;
            this.appliedLayoutPartner = this.currentLayoutPartner.name;
            this.appliedLayoutPartnerObj = this.currentLayoutPartner;
        }
        else if (this.type == "lite") {
            setting.settingName = "PARTNER_LITE_LAYOUT";
            setting.settingValue = this.currentLayoutLite.layoutId;
            this.appliedLayoutLite = this.currentLayoutLite.name;
            this.appliedLayoutLiteObj = this.currentLayoutLite;
        }
        setting.settingType = SettingType_T.string;
        
        if (this.type == "company") {
            this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
        }
        else if (this.type == "partner") {
            this.pinnacleService.SetCurrentPartnerSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
        }
        else if (this.type == "lite") {
            this.pinnacleService.SetCurrentPartnerSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
        } 

        
    }


    saveLayoutsAssignment() {
        let groupassignments = new Array<RoleLayoutAssignment>();
        //this is going to be a call to write the saved records to the yet-to-be created table
        for (let assignment of this.jobRoles) {
            let groupassignment = new RoleLayoutAssignment();
            let dropdown = <HTMLSelectElement>document.getElementById(this.type + "layoutlistrole" + assignment.roleId);
            groupassignment.layoutid = dropdown.value;
            groupassignment.roleid = assignment.roleId.toString();
            groupassignments.push(groupassignment);

        }

        this.pinnacleService.saveLayoutAssignments(groupassignments).subscribe(res => {
            this.pinnacleService.getTenantLayoutAssignments().subscribe(res => {
                this.tenantRoleAssignments = res;
            });
        });
        this.showLayoutsAssignment();
    }

   canDeactivate(): Observable<boolean> | boolean {
        // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
        if (this.isEditMode == false) {
            return true;
        }
        // Otherwise ask the user with the dialog service and return its
        // observable which resolves to true or false when the user decides
        return this.dialogService.confirm('Discard changes?');
    }

    onUseWidgetsChange(ev) {
        if (ev) {
            ev.preventDefault();
        }
        else {
            this.bUseWidgets = true;
        }
        //open modal
        if (this.bUseWidgets == true) {
            $('#enablePageLayoutsModal' + this.type).modal('show');

        }
        else {
            this.bUseWidgets = false;
            this.changedetectorref.detectChanges();
            let setting = new UserSettingPost();
            setting.isValid = true;
            setting.settingName = "USE_WIDGETS";
            setting.settingType = SettingType_T.string;
            setting.settingValue = this.bUseWidgets;
            this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
            localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());
            this.widgetLibraryService.setIsUsingWidget(this.bUseWidgets);
        }
    }

    useWidgetsModalConfirm() {
        this.bUseWidgets = true;
        this.changedetectorref.detectChanges();
        let setting = new UserSettingPost();
        setting.isValid = true;
        setting.settingName = "USE_WIDGETS";
        setting.settingType = SettingType_T.string;
        setting.settingValue = this.bUseWidgets;
        this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
        localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());
        this.widgetLibraryService.setIsUsingWidget(this.bUseWidgets);

        if (this.currentLayoutCompany.isPublic == true) {//layout is public so we shouldnt be able to edit it on the company tab
            $('#' + this.type + 'btneditlayout').attr("disabled", true);
            $('#' + this.type + 'btndeletelayout').attr("disabled", true);
        }
        else {
            $('#' + this.type + 'btneditlayout').attr("disabled", false);
            $('#' + this.type + 'btndeletelayout').attr("disabled", false);
        }
    }

    useWidgetsModalDeny() {
        //uncheck box
        this.bUseWidgets = false;
        this.changedetectorref.detectChanges();
        let setting = new UserSettingPost();
        setting.isValid = true;
        setting.settingName = "USE_WIDGETS";
        setting.settingType = SettingType_T.string;
        setting.settingValue = this.bUseWidgets;
        this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe(res => { });
        localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), this.bUseWidgets.toString());
        this.widgetLibraryService.setIsUsingWidget(this.bUseWidgets);
    }

    isUniqueName(testname: string, testid: string, comparelist: Array<WidgetLayoutInfo>): boolean {
        //let foundindex = comparelist.findIndex(x => x.name == testname);
        //if (foundindex == -1) {
        //    return true;
        //}
        //else {
        //    if (comparelist[foundindex].layoutId == testid) {
        //        //this is the same layout so its ok for the name to be non-unique. return true
        //        return true;
        //    }
        //    else {
        //        return false;
        //    }
        //}

        let matchlist = new Array<WidgetLayoutInfo>();
        for (var i = 0; i < comparelist.length; i++) {
            if (comparelist[i].name == testname) {
                matchlist.push(comparelist[i]);
            }
        }

        if (matchlist.length == 0) {
            // no matches exist, return true
            return true;
        }
        else {
            for (var i = 0; i < matchlist.length; i++) {
                if (matchlist[i].layoutId != testid) {
                    //a layout exists with the same name and different id, we can kill the loop here and return false
                    return false;
                }
            }
            //if we get out of the above for loop without returning it means the item(s) in the matchlist array is the same item we are testing for. return true in this case
            return true;
        }

        
    }

    onShowLogoChanged(ev: any) {
        // write the tenant setting to not display the logo. then update our current setting to hide all existing logos.
        let setting = new UserSettingPost();
        setting.settingName = "HIDE_LOGO";
        setting.settingValue = this.showLogo;
        setting.settingType = SettingType_T.string;

        localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_show_logo", JSON.stringify(this.showLogo));
        this.pinnacleService.SetCurrentTenantSettingsWithKey(ProdGenApi.getUserAccessKey(), setting).subscribe();

        AppComponent.thisFromOutside.isLogoVisible = this.showLogo;
    }

    loadLandingPages(initialPage: string = "") {
        this.pinnacleService.getLandingPageContainers().subscribe(res => {
            this.landingPageContainers = res;
            let firstitem = new WidgetContainerBasicInfo();
            if (initialPage == "") {
                firstitem = this.landingPageContainers[0];
            }
            else {
                firstitem = this.landingPageContainers[this.landingPageContainers.findIndex(x => x.containerInstanceId == initialPage)];
            }
            //load up the first container in the list
            if (firstitem) {
                this.selectedLandingPage = firstitem.containerInstanceId;
                this.URLText = window.location.origin + "/#/landingpage?id=" + firstitem.containerInstanceId;
                this.pinnacleService.getContainerInfo(firstitem.containerInstanceId, firstitem.containerId, firstitem.bindingType, firstitem.bindingId).subscribe(res2 => {
                    this.currentLandingPage = res2;
                    this.editingLandingPage = this.currentLandingPage;
                    this.changedetectorref.detectChanges();
                    document.getElementById("outsideDiv4").style.visibility = "visible";
                    document.getElementById("three-panel-left-column4").style.visibility = "hidden";
                    document.getElementById("three-panel-right-column4").style.visibility = "hidden";
                    
                });
            }

        });
    }

    addLandingPageClick() {
        this.isEditingLandingPage = true;    
        this.isNewLandingPage = true;
        let counter = 0;
        for (var landingPage of this.landingPageContainers) {
            if (landingPage.containerName.startsWith("New Landing Page")) {
                counter++;
            }
        }

        let newLandingPage = new WidgetContainerInfo();
        newLandingPage.bindingType = "LandingPage";
        newLandingPage.bindingId = "";
        newLandingPage.containerid = "LANDING_PAGE";

        if (counter == 0) {
            newLandingPage.containerName = "New Landing Page";
        }
        else {
            newLandingPage.containerName = "New Landing Page" + counter;
        }      

        newLandingPage.edit_x = 0;
        newLandingPage.edit_y = 0;
        newLandingPage.flyoutTitle = "";
        newLandingPage.layout = "";
        newLandingPage.widgets = new Array<apiContainerWidgets>();
        this.pinnacleService.createWidgetContainer(newLandingPage).subscribe(res => {
            this.loadLandingPages(res);
        });
    }

    addLandingPageFromCurrent() {
        this.pinnacleService.copyWidgetContainer(this.selectedLandingPage).subscribe(res => {
            this.isEditingLandingPage = true; 
            this.isNewLandingPage = true;
            this.loadLandingPages(res);
        });
    }

    editLandingPageClick() {
        this.isEditingLandingPage = true;  
        this.isNewLandingPage = false;
        this.editingLandingPage = this.currentLandingPage;
        this.changedetectorref.detectChanges();
    }
    deleteLandingPageClick() {
        //new endpoint to delete container and widgets and widget properties

        this.pinnacleService.deleteLandingPageContainer(this.selectedLandingPage).subscribe(res => {
            this.loadLandingPages();
        });
    }
    saveLandingPageClick() {
        let newName = (<HTMLInputElement>document.getElementById(this.type + "landingPageName")).value;
        this.WidLandingPageContainer.containerInfo.containerName = newName;
        this.WidLandingPageContainer.saveWidgetPositions();
        this.WidLandingPageContainer.DeleteWidgetCleanup();

        this.editingLandingPage.containerName = newName;
        this.landingPageContainers[this.landingPageContainers.findIndex(x => x.containerInstanceId == this.selectedLandingPage)].containerName = newName;
        this.isEditingLandingPage = false;
        this.isNewLandingPage = false;
        this.changedetectorref.detectChanges();
    }
    cancelLandingPageClick() {
        this.isEditingLandingPage = false;  
        this.editingLandingPage = this.currentLandingPage;
        if (this.isNewLandingPage == true) {
            this.deleteLandingPageClick();
        }
        this.isNewLandingPage = false;
        this.onLandingPageChange();
        this.changedetectorref.detectChanges();
    }
    copyURLClick() {
        var aux = document.createElement("div");
        aux.setAttribute("contentEditable", "true");
        aux.innerHTML = this.URLText;
        aux.setAttribute("style", "background-color:white;");
        document.getElementById("URLDiv").appendChild(aux);
        window.getSelection().selectAllChildren(aux);
        aux.focus();
        document.execCommand("copy");
        document.getElementById("URLDiv").removeChild(aux);
    }
    onLandingPageChange() {
        this.currentLandingPage = new WidgetContainerInfo();
        this.editingLandingPage = new WidgetContainerInfo();

        let basicSelectedLandingPage = this.landingPageContainers[this.landingPageContainers.findIndex(x => x.containerInstanceId == this.selectedLandingPage)];
        this.URLText = window.location.origin + "/#/landingpage?id=" + basicSelectedLandingPage.containerInstanceId;
        this.pinnacleService.getContainerInfo(basicSelectedLandingPage.containerInstanceId, basicSelectedLandingPage.containerId, basicSelectedLandingPage.bindingType, basicSelectedLandingPage.bindingId).subscribe(res2 => {
            this.currentLandingPage = res2;
            this.editingLandingPage = this.currentLandingPage;
            this.selectedLandingPage = this.currentLandingPage.containerInstanceId;
            this.changedetectorref.detectChanges();
            document.getElementById("outsideDiv4").style.visibility = "visible";
            document.getElementById("three-panel-left-column4").style.visibility = "hidden";
            document.getElementById("three-panel-right-column4").style.visibility = "hidden";
        });
    }

    showDeleteModal() {
        $('#landingPageDeleteModal').modal('show');
    }

    widgetsOnGridEvent() {
        if (this.shouldEnterEdit == true) {
            this.shouldEnterEdit = false;
            this.EnableEditMode();   

        }
    }
}

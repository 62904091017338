import { Component, OnInit } from '@angular/core';
import { Content, ContentProduct, ContentType_T, DescriptionFilter_T } from '../apiService/classFiles/class.content';
import { Enrollment } from '../apiService/classFiles/class.enrollments';
import { Playlist, PlaylistContent, User } from '../apiService/classFiles/class.users';
import { ProdGenApi } from '../apiService/prodgen.api';
import { AppComponent } from '../app.component';
import { AppControlService, MsgBxStringVals } from '../AppControlService';
import { BrowserAuthenticationService } from '../BrowserAuthenticationService';
import { TranslationService } from '../services/TranslationService';

declare function doesFontExist(fontName: string): boolean;
declare var $: any;

declare var nicEditor: any;


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css'],

})
export class PlaylistComponent implements OnInit {

  currentuser: User = new User();
  playlist: Playlist = new Playlist();
  content: Array<PlaylistContent> = new Array<PlaylistContent>();
  clonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();
  enrollmentarray: Array<Enrollment> = new Array<Enrollment>();
    enrollmentobject: Object;
  contentcheatsheet: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentlearningpath: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentcourse: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentquiz: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentvideo: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentworkflow: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentprocess: Array<PlaylistContent> = new Array<PlaylistContent>();
  contenttask: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentstep: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentchatLog: Array<PlaylistContent> = new Array<PlaylistContent>();
  contentworkgroup: Array<PlaylistContent> = new Array<PlaylistContent>();
  contenttrainingclass: Array<PlaylistContent> = new Array<PlaylistContent>();

  externalvids: Array<PlaylistContent> = new Array<PlaylistContent>();


  ContentItem: Content = new Content();
  displayEdit: boolean = false;
  allselected: boolean = false;
  newtitletext: string = "";
  assestsLoaded: boolean = false;
  enrollmentsLoaded: boolean = false;
  filterText: string = "";
  isDefault: boolean = true;
  downloadmode: boolean = false;
  iswindowsdevice: boolean = false;
  dlExpirationDays: number = 0;

  deletedcheat: string = "";

  Editid: string = "";
  Editname: string = "";
  Editdesc: string = "";
  Editprods: Array<ContentProduct> = new Array<ContentProduct>();
  isNew: boolean = true;
  modalVisible = false;

  pendingDeletedMyCheat: PlaylistContent = new PlaylistContent();

  constructor(private pinnacleService: ProdGenApi, private authenticationService: BrowserAuthenticationService, private tranService: TranslationService, private controlService: AppControlService) {
    if (authenticationService.AuthenticatePage() == true) { 
      if (window.navigator.userAgent.indexOf("Windows ") != -1) {
        this.iswindowsdevice = true;
      }
      this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
        this.enrollmentarray = res;
        this.enrollmentobject = this.ConverttoObject(this.enrollmentarray);
        this.enrollmentsLoaded = true;
      });
      this.pinnacleService.getCurrentUser().subscribe(user => {
          this.currentuser = user;

          this.GetPlaylist();

        });
    }

    }

    ngOnInit() {
    
    }

    get isPinnacleLite(){
        return AppComponent.isPinnacleLite;
    }

    ConverttoObject(enrollments: Array<Enrollment>): Object[] {
      let v_obj = new Array<Object>();
      for(var i = 0; i<enrollments.length; i++) {
      v_obj[enrollments[i].learningPathId.toString()] = enrollments[i];

    }
    return v_obj;
  
  }

  GetEnrollmentObject(v_key: string) {
   
    return this.enrollmentobject[v_key];

    //let keyedenrollments = new Array<Enrollment>();
    //for (var i = 0; i < this.enrollmentarray.length; i++) {
    //  if (v_key == this.enrollmentarray[i].learningPathId) {
    //    keyedenrollments.push(this.enrollmentarray[i]);
    //  }
    //}
   
    //return keyedenrollments;
  }

  CreatePlaylist() {
    this.pinnacleService.CreatePlaylist().subscribe(res => {
      
    });
  }

  GetPlaylist() {
    try {
      this.playlist = JSON.parse(localStorage.getItem(this.currentuser.userId + "_playlist"));
    }
    catch (err) {
      console.log(err)
    }

    this.pinnacleService.GetPlaylist(this.currentuser.userId).subscribe(res => {
      this.playlist = res;
      localStorage.setItem(this.currentuser.userId + "_playlist", JSON.stringify(this.playlist));
      this.GetPlaylistContent();
      

    });
  }

  GetPlaylistContent() {
    try {
      if (JSON.parse(localStorage.getItem(this.currentuser.userId + "_playlistcontent")) != null) {
        this.content = JSON.parse(localStorage.getItem(this.currentuser.userId + "_playlistcontent"));

        this.clonedcontent = this.getClonedAssets();

        this.contentcheatsheet = this.getClonedCS();
        this.contentchatLog = this.getClonedCHL();
        this.contentcourse = this.getClonedCRS();
        this.contentlearningpath = this.getClonedLP();
        this.contentprocess = this.getClonedPRO();
        this.contentquiz = this.getClonedQZ();
        this.contentstep = this.getClonedST();
        this.contenttask = this.getClonedTA();
        this.contenttrainingclass = this.getClonedTRC();
        this.contentvideo = this.getClonedVID();
        this.contentworkflow = this.getClonedWF();
        this.contentworkgroup = this.getClonedWG();

        this.assestsLoaded = true;
      }
     
    }
    catch (err) {
      console.log(err)
    }

    this.pinnacleService.GetPlaylistContent(this.playlist.plid, "00000000-0000-0000-0000-000000000000").subscribe(res => {
      this.content = res;
      localStorage.setItem(this.currentuser.userId + "_playlistcontent", JSON.stringify(this.content));
      //for (var i = 0; i < this.content.length; i++) {
      //  this.clonedcontent.push(this.content[i]);
      //}

      this.clonedcontent = this.getClonedAssets();
      
      this.contentcheatsheet = this.getClonedCS();
      this.contentchatLog = this.getClonedCHL();
      this.contentcourse = this.getClonedCRS();
      this.contentlearningpath = this.getClonedLP();
      this.contentprocess = this.getClonedPRO();
      this.contentquiz = this.getClonedQZ();
      this.contentstep = this.getClonedST();
      this.contenttask = this.getClonedTA();
      this.contenttrainingclass = this.getClonedTRC();
      this.contentvideo = this.getClonedVID();
      this.contentworkflow = this.getClonedWF();
      this.contentworkgroup = this.getClonedWG();

      this.assestsLoaded = true;
     
    });
  }

  RemoveItems(content: PlaylistContent) {
   
    //let x = <any> document.getElementsByClassName('selectbox');
    //let i = 0;
    //for (let item of x) {
      

      //if (item.checked == true) {
        this.pinnacleService.RemovePlaylistContent(this.playlist.plid, content.plcontentid).subscribe(res => {
       

        },
          err => {
            console.log(err);
          });
        
        switch (content.contentType) {

          case ContentType_T.cheatsheet: {
            for (var a = 0; a < this.contentcheatsheet.length; a++) {
              if (content.plcontentid == this.contentcheatsheet[a].plcontentid) {
                this.contentcheatsheet.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.chatLog: {
            for (var a = 0; a < this.contentchatLog.length; a++) {
              if (content.plcontentid == this.contentchatLog[a].plcontentid) {
                this.contentchatLog.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.course: {
            for (var a = 0; a < this.contentcourse.length; a++) {
              if (content.plcontentid == this.contentcourse[a].plcontentid) {
                this.contentcourse.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.learningpath: {
            for (var a = 0; a < this.contentlearningpath.length; a++) {
              if (content.plcontentid == this.contentlearningpath[a].plcontentid) {
                this.contentlearningpath.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.process: {
            for (var a = 0; a < this.contentprocess.length; a++) {
              if (content.plcontentid == this.contentprocess[a].plcontentid) {
                this.contentprocess.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.quiz: {
            for (var a = 0; a < this.contentquiz.length; a++) {
              if (content.plcontentid == this.contentquiz[a].plcontentid) {
                this.contentquiz.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.step: {
            for (var a = 0; a < this.contentstep.length; a++) {
              if (content.plcontentid == this.contentstep[a].plcontentid) {
                this.contentstep.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.task: {
            for (var a = 0; a < this.contenttask.length; a++) {
              if (content.plcontentid == this.contenttask[a].plcontentid) {
                this.contenttask.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.trainingclass: {
            for (var a = 0; a < this.contenttrainingclass.length; a++) {
              if (content.plcontentid == this.contenttrainingclass[a].plcontentid) {
                this.contenttrainingclass.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.video: {
            for (var a = 0; a < this.contentvideo.length; a++) {
              if (content.plcontentid == this.contentvideo[a].plcontentid) {
                this.contentvideo.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.workflow: {
            for (var a = 0; a < this.contentworkflow.length; a++) {
              if (content.plcontentid == this.contentworkflow[a].plcontentid) {
                this.contentworkflow.splice(a, 1);
                a--;
              }
            }
            break;
          }
          case ContentType_T.workgroup: {
            for (var a = 0; a < this.contentworkgroup.length; a++) {
              if (content.plcontentid == this.contentworkgroup[a].plcontentid) {
                this.contentworkgroup.splice(a, 1);
                a--;
              }
            }
            break;
          }

          default:
        }

    for (var a = 0; a < this.content.length; a++) {
      if (content.plcontentid == this.content[a].plcontentid) {
        this.content.splice(a, 1);
        a--;
      }
    }
    for (var a = 0; a < this.clonedcontent.length; a++) {
      if (content.plcontentid == this.clonedcontent[a].plcontentid) {
        this.clonedcontent.splice(a, 1);
        a--;
      }
    }
      
     // }
     // else {
     //   i++;
     // }

    //}
    
    //this.pinnacleService.RemovePlaylistContent(this.playlist.plid, contentid).subscribe(res => {
   
    //  this.content.splice(index, 1);
    //});
  }

  DownloadItems() {
    let x = <any>document.getElementsByClassName('selectbox');
    let queuedcontent = new Array<PlaylistContent>();


    for (var i = 0; i < x.length; i++)  {


      if (x[i].checked == true) {


        for (var c = 0; c < this.content.length; c++) {
          if (this.content[c].plcontentid == x[i].value) {
            queuedcontent.push(this.content[c]);
          }
        }
       
      }
      else {
        
      }

    }
    
    let z = <any>document.getElementsByClassName('selectboxnested');

    for (var i = 0; i < z.length; i++) {

      if (z[i].checked == true) {


        for (var c = 0; c < this.enrollmentarray.length; c++) {
          if (this.enrollmentarray[c].courseId == z[i].value) {
            let newqueue = new PlaylistContent();
            newqueue.contentType = ContentType_T.course;
            newqueue.contentId = this.enrollmentarray[c].courseId.toString();
            queuedcontent.push(newqueue);
          }
        }

      }
      else {

      }

    }

    if (window.navigator.userAgent.indexOf("Windows ") != -1) {
      // check to see if they have installed protocol already. Done by checking if font exists that is installed with it
      if (doesFontExist("Xenotron Broadstroke") == true) {
        

        this.pinnacleService.AddtoDownloadQueue(queuedcontent).subscribe(batch => {
          let cmdUrl: string = "pinnaclecommand://DOWNLOAD?batchid=" + batch;
          window.location.href = cmdUrl;
        });
        this.pinnacleService.GetDownloadExpirationDays().subscribe(days => {
          this.dlExpirationDays = days;
          $('#downloadingDialog').modal('show');
        });
        
      }

    }
    this.downloadmode = false;
  }

  //DeletePlaylist() {

  //  this.pinnacleService.DeletePlaylist(this.playlist.plid).subscribe(res => {

  //    this.content = new Array<PlaylistContent>();
  //  });
  //}

  //EmptyPlaylist() {
  //  this.pinnacleService.EmptyPlaylist(this.playlist.plid).subscribe(res => {

  //    this.content = new Array<PlaylistContent>();
  //  });
  //}

  //DownloadPlaylist() {
    
  //}

  showEditFields() {
    if (this.displayEdit == false) {
//      $('.TrashIcon').attr('style', 'margin-right:10px;margin-top:5px;display:inline-block;font-size:20px;');
//      $('.DownloadIcon').attr('style', 'margin-right:10px;margin-top:5px;display:inline-block;font-size:20px;');
//      $('.EditTitleIcon').attr('style', 'margin-left:10px;margin-top:5px;display:inline-block');
//      $('.selectallbox').attr('style', 'height:20px;width:20px;margin-right:10px;margin-bottom:10px;display:inline-block');
//      $('.selectall').attr('style', 'display:inline-block');
////      $('.selectbox').attr('style', 'display:inline-block;height:15px;width:15px;margin-right:5px;float:left;');
//      $('.EditButton').attr('disabled', 'true');
//      $('#stretchwide').attr('style', 'margin-bottom:0px');
//      $('.DoneButton').attr('style', 'display:inline-block');
      this.displayEdit = true;
    }
    else {
      let x = <any>document.getElementsByClassName('selectbox');
      
      for (let item of x) {

        if (item.checked == true) {
          item.checked = false;
        } 

      }
      //$('.TrashIcon').attr('style', 'display:none');
      //$('.DownloadIcon').attr('style', 'display:none');
      //$('.EditTitleIcon').attr('style', 'display:none');
      //$('.selectallbox').removeAttr('checked');
      this.allselected = false;
 //     $('.selectallbox').attr('style', 'display:none');
 //     $('.selectall').attr('style', 'display:none');
 ////     $('.selectbox').attr('style', 'display:none');
 //     $('.EditButton').removeAttr('disabled');
 //     $('.DoneButton').attr('style', 'display:none');

 //     $('.EditTitleIcon').attr('style', 'display:none');
 //     $('.PlaylistTitle').attr('style', 'display:inline-block;font-size:30px');
 //     $('.SaveTitleIcon').attr('style', 'display:none');
 //     $('.EditPLTitleTB').attr('style', 'display:none');
 //     $('#stretchwide').attr('style', 'margin-bottom:20px');
      this.displayEdit = false;
    }

  }

  showDownloadFields() {
    if (window.navigator.userAgent.indexOf("Windows ") != -1) {
      // check to see if they have installed protocol already. Done by checking if font exists that is installed with it
      if (doesFontExist("Xenotron Broadstroke") == true) {

        this.downloadmode = true;
      }

      if (doesFontExist("Xenotron Broadstroke") == false) {
        // if they have not, give them a message showing the download link


          var v_Msg = new MsgBxStringVals();
          v_Msg.body = `The <b>Pinnacle User Tools</b> add-on is required to download offline files.<BR><BR>
<a href="https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacletools.msi" target="_blank"><b>Click Here</b></a> to download and install this add-on.<BR><BR>

<b>After the installation you must restart your browser.</b>`;
          v_Msg.title = ``;
          this.controlService.openMessageBoxPopUp(v_Msg);

        
        event.preventDefault();
        return;
      }
    }
    
  }

  SelectAll() {
    if (this.allselected == false) {
      //select all and set to true
      let x = <any>document.getElementsByClassName('checkbox-inline');

      for (let item of x) {
          item.checked = true;
      }
      this.allselected = true;
      $('.detailheader').removeClass('collapsed');
      $('.panel-collapse').removeClass('collapse');
      $('.panel-collapse').addClass('in');
    }
    else {
      //unselect all and set to false
      let x = <any>document.getElementsByClassName('checkbox-inline');

      for (let item of x) {
        item.checked = false;
      }
      this.allselected = false;
    }
  }

  UnselectAll() {
    let x = <any>document.getElementsByClassName('selectallbox');
    for (let item of x) {
      item.checked = false;
    }
    this.allselected = false;
  }

  UnselectLP(id:string) {
    let x = <any>document.getElementsByClassName('selectbox'+id);
    for (let item of x) {
      item.checked = false;
    }
    this.allselected = false;
    this.UnselectAll();
  }

  SelectChildCourses(id:string) {
    let x = <any>document.getElementsByClassName('selectboxchild' + id);
    
    for (let item of x) {
      if ($('.selectbox' + id).prop('checked')) {
        item.checked = true;
      }
      else {
        item.checked = false;
      }
      
    }
  }

  EditPlaylistName() {
    $('.EditTitleIcon').attr('style', 'display:none');
    $('.PlaylistTitle').attr('style', 'display:none');
    $('.SaveTitleIcon').attr('style', 'margin-left:10px;margin-top:5px;display:inline-block');
    $('.EditPLTitleTB').attr('style', 'font-size: 30px; width: 300px; height: 37px;margin-bottom:5px;display:inline-block');
    
  }

  SavePlaylistName() {

    //update database and playlist var
    let querytext = new String();
    querytext = this.newtitletext;
    //querytext = querytext.replace("'", "''");
    this.pinnacleService.updatePlaylistName(querytext.toString(), this.playlist.plid).subscribe(res => {
      this.playlist.name = res;
    });

    $('.EditTitleIcon').attr('style', 'margin-left:10px;margin-top:5px;display:inline-block');
    $('.PlaylistTitle').attr('style', 'display:inline-block;font-size:30px');
    $('.SaveTitleIcon').attr('style', 'display:none');
    $('.EditPLTitleTB').attr('style', 'display:none');
  }

  getContentUrl(type: ContentType_T, id:string): string {
    let url: string = "#";
    if (type == 5) {
      url += "/workflowviewer?id=" + id + "&contentType=workflow";
    }
    else if (type == 6) {
      url += "/workflowviewer?id=" + id + "&contentType=process";
    }
    else if (type == 7) {
      url += "/workflowviewer?id=" + id + "&contentType=task";
    }
    else if (type == 8) {
      url += "/workflowviewer?id=" + id + "&contentType=step";
    }
    else if (type == 0) {
      url += "/cheatsheetviewer?id=" + id;
    }
    else if (type == 4) {
      url += "/videoviewer?id=" + id;
    }
    else if (type == 1) {
      url += "/learningcenter/series?learningPathId=" + id;
    }
    else if (type == 2) {
      if (this.enrollmentarray.length > 0) {
        if (this.enrollmentarray.findIndex(eid => eid.courseId == id) == -1) {
          url += "/learningcenter/series?courseId=" + id;
        }
        else {
          url += "/learningcenter/courseviewer?enrollmentId=" + this.enrollmentarray[this.enrollmentarray.findIndex(eid => eid.courseId == id)].enrollmentId;
        }
      }
    }

    return url;
  }

  getContentImage(r: PlaylistContent, b = false): string {
    let image: string = "";

      image = "assets/images/";

    if (b == false) {
        if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            if (r.docURL.includes(".pdf")) {
                image += "PDF Graphic.png"
            }
            else if (r.docURL.includes(".doc")) {
                image += "Word Graphic.png"
            }
            else if (r.docURL.includes(".xls")) {
                image += "Excel Graphic.png"
            }
            else if (r.docURL.includes(".ppt")) {
                image += "PowerPoint Graphic.png"
            }
            else if (r.docURL.includes(".htm")) {
                image += "HTML Graphic.png"
            }
        }
        else {
            image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
            if (image.indexOf("default") == -1) {
                image = image.replace("assets/images/", "");
            }
        }
    }
    else {
        try {
            image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
        } catch (e) {

        }
        if (image == "" || image == null) {
            image = "assets/images/My Cheat Sheets Graphic.png";
        }
    }
      

    return image;
  }

    getContentImagetype(r: ContentType_T, b = false): string {
        let image: string = "";

        image = "assets/images/";

        if (b == false) {
            image += ProdGenApi.getLargeDefaultImageFromType(r);
        }
        else {
            image += "My Cheat Sheets Graphic.png";
        }


        return image;
    }

  getClonedAssets() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      tempclonedcontent.push(c);
    }

    tempclonedcontent.sort((a: any, b: any) => {
      var x = a.contentname.toLowerCase();
      var y = b.contentname.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    });
    return tempclonedcontent;
  }

  filterTextChanged() {
    this.clonedcontent = this.getClonedAssets();

    this.contentcheatsheet = this.getClonedCS();
    this.contentchatLog = this.getClonedCHL();
    this.contentcourse = this.getClonedCRS();
    this.contentlearningpath = this.getClonedLP();
    this.contentprocess = this.getClonedPRO();
    this.contentquiz = this.getClonedQZ();
    this.contentstep = this.getClonedST();
    this.contenttask = this.getClonedTA();
    this.contenttrainingclass = this.getClonedTRC();
    this.contentvideo = this.getClonedVID();
    this.contentworkflow = this.getClonedWF();
    this.contentworkgroup = this.getClonedWG();

    var splitFilterText = this.filterText.split(" ");

    for (var d = 0; d < splitFilterText.length; d++) {
      for (var i = 0; i < this.clonedcontent.length; i++) {
        if (this.clonedcontent[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.clonedcontent.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentcheatsheet.length; i++) {
        if (this.contentcheatsheet[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentcheatsheet.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentchatLog.length; i++) {
        if (this.contentchatLog[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentchatLog.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentcourse.length; i++) {
        if (this.contentcourse[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentcourse.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentlearningpath.length; i++) {
        if (this.contentlearningpath[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentlearningpath.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentprocess.length; i++) {
        if (this.contentprocess[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentprocess.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentquiz.length; i++) {
        if (this.contentquiz[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentquiz.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentstep.length; i++) {
        if (this.contentstep[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentstep.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contenttask.length; i++) {
        if (this.contenttask[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contenttask.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contenttrainingclass.length; i++) {
        if (this.contenttrainingclass[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contenttrainingclass.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentvideo.length; i++) {
        if (this.contentvideo[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentvideo.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentworkflow.length; i++) {
        if (this.contentworkflow[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentworkflow.splice(i, 1);
          i--;
        }
      }

      for (var i = 0; i < this.contentworkgroup.length; i++) {
        if (this.contentworkgroup[i].contentname.toLowerCase().indexOf(splitFilterText[d].toLowerCase()) == -1) {
          this.contentworkgroup.splice(i, 1);
          i--;
        }
      }

    }
  }

  onChange(event): void {
    const val = event.target.value;
    if (val == "Asset Name") {
      this.isDefault = true;
      setTimeout(() => {
        if (this.allselected == true) {
          let x = <any>document.getElementsByClassName('selectbox');

          for (let item of x) {
            item.checked = true;
          }
          
          $('.detailheader').removeClass('collapsed');
          $('.panel-collapse').removeClass('collapse');
          $('.panel-collapse').addClass('in');
          this.clonedcontent.sort((a: any, b: any) => {
            var x = a.contentname.toLowerCase();
            var y = b.contentname.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
          });
        }
      }, 0);
       
      
    }
    else if (val == "Asset Type") {
      this.isDefault = false;
      setTimeout(() => {
        if (this.allselected == true) {
          let x = <any>document.getElementsByClassName('selectbox');

          for (let item of x) {
            item.checked = true;
          }
          
          $('.detailheader').removeClass('collapsed');
          $('.panel-collapse').removeClass('collapse');
          $('.panel-collapse').addClass('in');
        }
      }, 0);
       
      
    }
  }

  getClonedCS() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.cheatsheet) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedLP() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.learningpath) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedCRS() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.course) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedQZ() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.quiz) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedVID() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.video) {
        tempclonedcontent.push(c);
      
      this.pinnacleService.GetVideo(c.contentId).subscribe(res => {
        if (res.videoURL.toLowerCase().includes(".mp4") || res.videoURL.toLowerCase().includes(".wmp") || res.videoURL.toLowerCase().includes(".avi")) {
          this.externalvids.push(c);
        }
        });
      }
    }


    return tempclonedcontent;
  }
  getClonedWF() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.workflow) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedPRO() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.process) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedTA() {
    let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.task) {
        tempclonedcontent.push(c);
      }
    }


    return tempclonedcontent;
  }
  getClonedST() {
      let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.step) {
        tempclonedcontent.push(c);
      }
    }


      return tempclonedcontent;
    }
  getClonedCHL() {
      let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.chatLog) {
        tempclonedcontent.push(c);
      }
    }


      return tempclonedcontent;
    }
  getClonedWG() {
      let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.workgroup) {
        tempclonedcontent.push(c);
      }
    }


      return tempclonedcontent;
    }
  getClonedTRC() {
      let tempclonedcontent: Array<PlaylistContent> = new Array<PlaylistContent>();

    for (let c of this.content) {
      if (c.contentType == ContentType_T.trainingclass) {
        tempclonedcontent.push(c);
      }
    }


      return tempclonedcontent;
  }

  isEnrolled(cid: string) {
    if (this.enrollmentarray.length > 0) {
      return this.enrollmentarray.findIndex(id => id.courseId == cid);
    }
  }

  isExternalVid(id: string) {
    if (this.externalvids.length > 0) {
      return this.externalvids.findIndex(vid => vid.contentId == id);
    }
  }

  ConfirmDeleteCheatShow(content: PlaylistContent) {
    this.pendingDeletedMyCheat = content
    $('#deletecheatverify').modal('show');
  }

  DeleteCheat(content: PlaylistContent) {
    this.pinnacleService.DeleteCheat(content.contentId).subscribe(r => {
      this.deletedcheat = content.contentname;
      $('#deletecheatconfirm').modal('show');
      this.pendingDeletedMyCheat = new PlaylistContent();
      this.GetPlaylist();
    });
  }

  NewCheat() {
    this.modalVisible = true;

    setTimeout(function () {
      $('#newcheat').modal('show');
    }, 1);
  }

  EditCheat(content: PlaylistContent) {
    this.pinnacleService.GetCheatSheet(content.contentId, DescriptionFilter_T.formatted).subscribe(r => {
      this.Editid = r.cheatId.toString();
      this.Editname = r.name.toString();
      this.Editdesc = r.description.toString();
      //this.Editdesc = this.FormatHTMLDesc(r.description.toString());
      this.isNew = false;
      this.pinnacleService.GetContentUsedProducts(r.cheatId, ContentType_T.cheatsheet.toString()).subscribe(r => {
        this.Editprods = r;
        this.modalVisible = true;

        setTimeout(function () {
          $('#newcheat').modal('show');
        }, 1);

        
        

        
      });
    //this.Editprods    //Need to Get associated products
    });

  }

  FormatHTMLDesc(desc: string): string {
    let returndesc = "";

    returndesc = desc.substring(desc.indexOf("<head>"), desc.indexOf("</body>") + 7);
    return returndesc;
  }

  onClose(event: any) {
    this.Editid = "";
    this.Editname = "";
    this.Editdesc = "";
    this.isNew = true;
    this.Editprods = new Array<ContentProduct>();
    this.modalVisible = false;
    if (event == "Save") {
      this.GetPlaylist();
    }
  }


  //isExternal(id: string): boolean {
  //  this.pinnacleService.GetVideo(id).subscribe(res => {
  //    if (res.videoURL.toLowerCase().includes(".mp4") || res.videoURL.toLowerCase().includes(".wmp") || res.videoURL.toLowerCase().includes(".avi")) {
  //      return false;
  //    }
  //    else {
  //      return true;
  //    }
  //  });
  //  return;
  //}

  //getLPenrolledcourses(id: string)  {
  //  this.pinnacleService.getLearningPathUserEnrollments([EnrollmentFilter_T.assigned, EnrollmentFilter_T.inProgress], id).subscribe(res => {
  //    this.enrollmentarray = res;
  //  });
  //}
}

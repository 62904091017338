import { Component, OnInit, ViewChild, ViewChildren, QueryList, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ProdGenApi } from '../apiService/prodgen.api';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

    userId: string = "";
    constructor(private pinnacleService: ProdGenApi, private changeDetector: ChangeDetectorRef) { }

    ngOnInit() {
        this.pinnacleService.getCurrentUser().subscribe(res => { this.userId = res.userId; });
    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log("hit28");
        try {
            try {
                if (changes['userId'] && changes['userId'].currentValue != changes['userId'].previousValue) {
                    this.userId = changes['userId'].currentValue;
                }
            } catch (err) { console.log(err); };
        } catch (err) { console.log(err); };

    }



}

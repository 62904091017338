import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';

import { Enrollment, EnrollmentFilter_T, EnrollmentStatus_T, QuizTrackingItem, QuizSessionItem, QuizSessionTracking } from './../../../apiService/classFiles/class.enrollments';
import { Course, Content, DescriptionFilter_T, QuizStatus_T, Quiz } from './../../../apiService/classFiles/class.content';
import { User } from './../../../apiService/classFiles/class.users';
import { TranslationService } from '../../../services/TranslationService';

declare var $: any; // JQuery workaround


// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
    selector: 'enrollment-history-widget-component',
    templateUrl: './enrollment-history-widget.component.html',
    styleUrls: ['./enrollment-history-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class EnrollmentHistoryWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "EnrollmentHistoryWidgetComponent";     // this key must be set to the name of the Angular component
    showingAllCompleted: boolean = false;
    completedEnrollments: Array<Enrollment> = new Array<Enrollment>(); 
    completedEnrollmentsLoaded: boolean = false;
    selectedSort: string = "sortLP";
    courseFinishedStatus: string = "";
    sortStatusLP: number = 0;
    sortStatusCourse: number = 0;
    sortStatusStatus: number = 0;
    sortStatusDate: number = 1;
    sortStatusCert: number = 0;

    widgetLoaded: boolean = false;
    
    

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private translationService: TranslationService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 8;
        this.widgetHeight = 8;

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetTitle = "ENROLLMENT HISTORY";
        this.widgetName = "Enrollment History Widget"
        this.widgetDesc = "Review your enrollment history."; 
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        // call the base Widget class
        super.Load();
        this.loadHistory();

    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        
        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, EnrollmentHistoryWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)
        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }


    loadHistory() {
        this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed, EnrollmentFilter_T.dropped]).subscribe(res => {
            for (var i = 0; i < res.length; i++) {
                if (res[i].imageURL == null || res[i].imageURL == "") {
                    res[i].imageURL = "../assets/images/default_course.jpg";
                }
            }
            this.completedEnrollments = res.sort((r1, r2) => {
                if (r1.statusDate < r2.statusDate) {
                    return 1;
                }
                else if (r1.statusDate > r2.statusDate) {
                    return -1;
                }
                return 0;
            });
            this.completedEnrollmentsLoaded = true;
        });

    }

    createProperties() {

        // call widget base class
        super.createProperties();

        // the width and height properties are already created by the base class.
        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        
        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle("ENROLLMENT HISTORY");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS




    toggleShowAllCompleted() {
        this.showingAllCompleted = !this.showingAllCompleted;

        setTimeout(() => {


            if (this.showingAllCompleted) {
                this.widgetContainer.onWidgetExpand(this.widgetID);
            }
            else {
                this.widgetContainer.onWidgetCollapse(this.widgetID);
            }
        }, 0);
    }


    headerClick(type: string) {

        //default sort is by date

        switch (type) {
            case "LP": {
                switch (this.sortStatusLP) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 1;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.learningPathName;
                                var y = b.learningPathName;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 2;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.learningPathName;
                            var y = b.learningPathName;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "C": {
                switch (this.sortStatusCourse) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 1;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.courseName;
                                var y = b.courseName;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 2;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.courseName;
                            var y = b.courseName;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "S": {
                switch (this.sortStatusStatus) {
                    case 0:
                    case 2:
                        { //set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 1;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.enrollmentStatus;
                                var y = b.enrollmentStatus;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 2;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.enrollmentStatus;
                            var y = b.enrollmentStatus;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "DT": {
                switch (this.sortStatusDate) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 1;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 0;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.statusDate;
                                var y = b.statusDate;
                                if (x < y) { return 1; }
                                if (x > y) { return -1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 2;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 0;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.statusDate;
                            var y = b.statusDate;
                            if (x < y) { return -1; }
                            if (x > y) { return 1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            case "Cert": {
                switch (this.sortStatusCert) {
                    case 0:
                    case 2:
                        {//set the sort to decending
                            this.sortStatusLP = 0;
                            this.sortStatusStatus = 0;
                            this.sortStatusDate = 0;
                            this.sortStatusCourse = 0;
                            this.sortStatusCert = 1;

                            this.completedEnrollments.sort((a: any, b: any) => {
                                var x = a.enrollmentStatus;
                                var y = b.enrollmentStatus;
                                if (x < y) { return -1; }
                                if (x > y) { return 1; }
                                return 0;
                            });
                            break;
                        }
                    case 1: { //set the sort to acsending
                        this.sortStatusLP = 0;
                        this.sortStatusStatus = 0;
                        this.sortStatusDate = 0;
                        this.sortStatusCourse = 0;
                        this.sortStatusCert = 2;

                        this.completedEnrollments.sort((a: any, b: any) => {
                            var x = a.enrollmentStatus;
                            var y = b.enrollmentStatus;
                            if (x < y) { return 1; }
                            if (x > y) { return -1; }
                            return 0;
                        });
                        break;

                    }
                    //case 2: { //third click, set back to the default date sorting
                    //  this.sortStatusLP = 0;
                    //  this.sortStatusStatus = 0;
                    //  this.sortStatusDate = 1;
                    //  this.sortStatusCourse = 0;
                    //  this.sortStatusCert = 0;

                    //  this.completedEnrollments.sort((a, b) => {
                    //    var x = a.statusDate;
                    //    var y = b.statusDate;
                    //    if (x < y) { return 1; }
                    //    if (x > y) { return -1; }
                    //    return 0;
                    //  });
                    //  break;
                    //}
                    default:
                }
                break;
            }
            default:
        }


        //this.completedEnrollments.sort((a: any, b: any) => {
        //  var x = a.percentComplete;
        //  var y = b.percentComplete;
        //  if (x < y) { return -1; }
        //  if (x > y) { return 1; }
        //  return 0;
        //});

    }


    getStatusText(status: EnrollmentStatus_T): string {
        if (status == EnrollmentStatus_T.dropped) {
            return "Dropped"
        }
        else if (status == EnrollmentStatus_T.completed) {
            return "Completed"
        }
        return "";
    }

    getRowClass(e: Enrollment): string {
        if (e.enrollmentStatus == EnrollmentStatus_T.dropped) {
            return "danger";
        }
        else if (e.enrollmentStatus == EnrollmentStatus_T.completed) {

            return "success";
        }

        return "";
    }

    onPrintTranscript() {
        this.pinnacleService.getUserTranscript().subscribe(res => {
            let url: string = res.replace("http://", "https://");
            if (url.length > 0) {
                window.location.href = url;
            }
            else {
            }
        });
    }

    onPrintCertficate() {
        this.pinnacleService.getEnrollmentCertificate(this.sc.enrollmentId).subscribe(res => {
            let url: string = res.replace("http://", "https://");
            if (url.length > 0 && (url.toString() != "FailQuiz" && url.toString() != "NotComplete")) {
                this.hasCertificate = true;
                this.certificateURL = url;
            }
            else if (url.length > 0 && url.toString() == "FailQuiz") {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_QuizFailed");
            }
            else if (url.length > 0 && url.toString() == "NotComplete") {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_ItemsSkipped");
            }
            else {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_NoCertificate");
            }
        },
            err => {
                this.hasCertificate = false;
                this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_Error");
            });
    }


    /// Course Review Modal Start
    trackingItemsLoaded: boolean = false;
    trackingItemsError: boolean = false;

    successColor = "rgb(223, 240, 216)";
    failureColor = "rgb(252, 229, 229)";
    correctColor = "darkgreen";
    incorrectColor = "darkred";
    neutralColor = "rgb(219, 219, 219)";
    sectionBorder = "1px solid #dbdbdb";
    separatorBorder = "1px solid";

    hasCertificate = false;
    certificateURL = "";

    sc = new Enrollment();
    scAssignedBy = "";
    scPercentComplete = "0%";
    scStatusColor = this.neutralColor;
    scPercentBorder = "none";

    passMessage = "";

    ss = new QuizSessionItem();

    quizTrackingItems = new Array<QuizTrackingItem>();
    quizSessionItems: { [key: string]: QuizSessionItem; } = {};

    answerExplanations: { [key: string]: string; } = {};

    currentUser: User = null;
    currentUserLoaded: boolean = false;

    selectCourse(event) {

        this.trackingItemsLoaded = false;
        this.trackingItemsError = false;

        let scIndex = this.completedEnrollments.findIndex(e => {
            return e.enrollmentId == event.target.id as string;
        });

        if (!this.currentUserLoaded) {
            this.pinnacleService.getCurrentUser(false).subscribe(res => {
                this.currentUser = res;
                this.currentUserLoaded = true;
            });
        }

        if (scIndex != -1) {
            this.sc = this.completedEnrollments[scIndex];
            this.getCourseOverview();

            this.quizTrackingItems = new Array<QuizTrackingItem>(); // Clear previous enrollment content

            this.pinnacleService.getAllQuizEnrollmentTracking(this.sc.enrollmentId as string).subscribe(res => {

                for (let r of res) {
                    this.quizTrackingItems.push(r);

                    for (let s of r.quizSessionItem) {
                        this.quizSessionItems[s.quizSessionId as string] = s; // Easier to store a reference list of sessions than to navigate HTML to find out which session was clicked
                    }
                }

                this.trackingItemsLoaded = true;
            });

            // Bootstrap adds the class 'modal-open' to the body when showing
            // a modal in order to prevent scrolling content behind the modal.
            // Since we may possibly open 2 modals, we add our own version
            // to prevent the second modal from prematurely reenabling scrolling
            // when closing.
            (document.body as HTMLBodyElement).classList.add("my-modal-open");
            $("#courseReviewModal").on("hide.bs.modal", function (e) {
                $("body").removeClass("my-modal-open");
            });

            // Firefox apparently cares about scrollable elements behind the
            // focused element, so we remove the ability to scroll the course
            // review modal when the quiz details modal opens, and restore 
            // when the quiz details modal closes (button or clicking outside)
            $("#quizDetailModal").on("shown.bs.modal", function (e) {
                $("#courseReviewBody").css("overflow-y", "hidden");
            });

            $("#quizDetailModal").on("hide.bs.modal", function (e) {
                $("#courseReviewBody").css("overflow-y", "auto");
                $("#detailsModalBody").scrollTop(0); // Also scroll back to the top
            });

            // Used in a title, cannot be translated in html
            this.passMessage = this.translationService.getTranslationFileData("LEARNINGCENTER.PassMessage");
        }
        else {
            this.trackingItemsError = true;
        }
    }

    getCourseOverview() {
        this.hasCertificate = false;
        this.pinnacleService.getUser(this.sc.assignerUserId).subscribe(res => {
            this.scAssignedBy = res.name;
        });

        this.scPercentComplete = this.sc.percentComplete.toString() + "%";
        if (this.sc.percentComplete > 0
            && this.sc.percentComplete < 100) {
            this.scPercentBorder = this.separatorBorder;
        }
        else {
            this.scPercentBorder = "none";
        }

        if (this.sc.enrollmentStatus == EnrollmentStatus_T.dropped) {
            this.scStatusColor = this.failureColor;
            this.hasCertificate = false;
            this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.NC_CourseDropped");
        }
        else {
            this.scStatusColor = this.successColor;
            this.onPrintCertficate();
            this.certificateURL = this.translationService.getTranslationFileData("LEARNINGCENTER.Loading") + "...";
        }
    }

    getQuizStatusText(status): string {
        if (status == QuizStatus_T.pass
            || status == "PASS") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Pass");
        }
        if (status == QuizStatus_T.fail
            || status == "FAIL") {
            return this.translationService.getTranslationFileData("LEARNINGCENTER.Fail");
        }
        return "";
    }

    getStatusColor(status: any): string {
        if (status == "PASS"
            || status == EnrollmentStatus_T.completed
            || status == QuizStatus_T.pass) {
            return this.successColor;
        }
        if (status == "FAIL"
            || status == EnrollmentStatus_T.dropped
            || status == QuizStatus_T.fail) {
            return this.failureColor;
        }
        return "";
    }

    getScorePercentage(correct: number, possible: number): string {
        if (possible == null || possible < 1)
            return "0%";
        return ((correct / possible) * 100).toFixed(2).toString() + "%";
    }

    getCorrectBorder(correct: number, pass: number): string {
        if (correct > 0 && correct < pass) {
            return this.separatorBorder;
        }
        return "none";
    }

    getPassWidth(correct: number, possible: number, pass: number): string {
        return (((pass - correct) / possible) * 100).toFixed(2).toString() + "%";
    }

    getPassPercentage(q: QuizSessionItem): string {
        if (q.quizAnswersPossible == null || q.quizAnswersPossible < 1)
            return "0%";
        return ((q.quizAnswersPass / q.quizAnswersPossible) * 100).toFixed(2).toString() + "%";
    }

    toggleIcon($event) {
        let childElement = (event.target as HTMLElement).querySelector('i');
        if (childElement.classList.contains("glyphicon-menu-down")) {
            childElement.classList.add("glyphicon-menu-up");
            childElement.classList.remove("glyphicon-menu-down");
        }
        else if (childElement.classList.contains("glyphicon-menu-up")) {
            childElement.classList.add("glyphicon-menu-down");
            childElement.classList.remove("glyphicon-menu-up");
        }
    }

    selectQuizSession(event) {
        this.pinnacleService.getEmailLanguagePreference(this.currentUser.email, ProdGenApi.getCurrentTenantId()).subscribe(res => {
            let langPref = res;
            this.ss = this.quizSessionItems[event.target.id];
            for (let q of this.ss.quizSessionTracking) {
                if (q.explanation != null && q.explanation != "") {
                    if (langPref != "en") {
                        this.pinnacleService.translateText(q.explanation as string, "en", false).subscribe(res => {
                            this.answerExplanations[q.explanation as string] = res as string;
                        });
                    }
                    else {
                        this.answerExplanations[q.explanation as string] = q.explanation as string;
                    }
                }
            }
        });
    }

    detectNavigation(ans: string): boolean {
        if (ans != null && (ans.indexOf("quizQ") != -1)) {
            return true;
        }
        return false;
    }

    splitMultiAnswer(ans: string, isFillInBlank: boolean, isCorrectAns: boolean): string[] {
        let answers = ans.split('|');
        if (answers.length > 0) {
            if (!isFillInBlank
                || (isFillInBlank && !isCorrectAns && answers.length > 1)) {
                for (let i = 0; i < answers.length; i++) {
                    answers[i] = "- " + answers[i];
                }
            }
            if (isFillInBlank && isCorrectAns) {
                let pipedAns = "";
                for (let a of answers) {
                    pipedAns += (a + " | ");
                }
                pipedAns = pipedAns.slice(0, -3);
                answers = [pipedAns];
            }
        }
        return answers;
    }

}



import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ChatMessage  } from '../models/aichat';

@Component({
  selector: 'ep-aichat-message',
  templateUrl: './aichat-message.component.html',
  styleUrls: ['./aichat-message.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AIChatMessageComponent implements OnChanges, OnInit {
    @Input() message: ChatMessage;
    @Input() userImageUrl: string;

  constructor(private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']) {
      this.message = changes['message'].currentValue;
    }
    
  }


}

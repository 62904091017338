import { ContentType_T} from "./class.content"
import { WidgetComponent } from "../../templates/widget/widget.component";

export class User {
	public userId: string;
    public name: string;
    public email: string;
    public imageURL: string;
}

export class PinnacleLiteUser {
    public userId: string;
    public userName: string;
    public userEmail: string;
    public isAdmin: boolean;
}

export class PermissionUser {
    public userId: string;
    public name: string;
    public email: string;
    public imageURL: string;

    public permissions: UserPermissions;
}


export class Comment {
    public commentId: String;
    public contentId: String;
    public contentType: ContentType_T;
    public userId: String;
    public name: String;
    public commentText: String;
    public publishDate: Date;
}

export class UserFilter {
    public limit: number = -1;
    public offset: number = 0;
}

export class UserPermissions {
    public administrator: boolean;
    public administerContent: boolean;
    public publishContent: boolean;
    public authorContent: boolean;
    public enrollUsers: boolean;
    public viewReports: boolean;
    public signClientAccount: boolean;
    public canCreateExternalUsers: boolean;
    public canManageBranding: boolean;
    public disabledEPSupport: boolean;
    public workgroupAdmin: boolean;
    public workgroupCreate: boolean;
    public isAssignor: boolean;

}

export class UserHistoryPost {
    public contentId: String;
    public contentType: ContentType_T;
    public referrer: string = "";
}
export class UserHistory {
    public contentId: String;
    public contentType: ContentType_T;
    public referrer: string = "";
    public name: string = "";
    public userId: String;
    public histDate: Date;
}

export enum RoleColorFill_T {
    solid,
    vertical,
    verticalFull,
    horzizontal,
    horzizontalFull
}

export class Role {
    public roleId: String;
    public roleName: String;
    public fillColor1: number;
    public fillcolor2: number;
    public textColor: number;
    public outlineColor: number;
    public fillMode: RoleColorFill_T;
}

export class UserPasswordPost {
	public oldPassword: String;
	public newPassword: String;
}

export enum SettingType_T {
	string,
    number
}

export class UserSettingPut {
	public settingName: string;
	public settingType: SettingType_T;
	public settingValue: any;
}

export class UserPut {
    public email: string;
    public name: string;
}

export class UserSettingPost {
    public settingName: string;
    public settingType: SettingType_T;
    public settingValue: any;
    public isValid: boolean;
}

export class Playlist {
  public plid: string;
  public tenantid: string;
  public userid: string;
  public name: string; 
    }

export class PlaylistContent {
  public plcontentid: string;
  public plid: string;
  public tenantid: string;
  public contentId: string;
  public contentType: ContentType_T;
  public addeddatetime: Date;
  public bisdownloadable: boolean;
  public contentname: string;
  public contentdesc: string;
    public biscustom: boolean;
    public docURL: string;
}

export class ExternalUserPost {
    public email: string;
    public displayName: string;
    public imageData: any;
}


export class WidgetPropertyOptions {
    public opName: string;
    public opValue: string;
}

export class WidgetProperties {
    public propID: string;
    public propName: string;
    public propType: string;
    public propValue: string;
    public propDefaultValue: string;
    public propOptions: Array<WidgetPropertyOptions> = new Array<WidgetPropertyOptions>();
}

export class WidgetLayoutPage {
    public pageName: string;
    public containerId: string;
}

export class WidgetLayoutInfo {
    public layoutId: string;
    public name: string;
    public isPublic: boolean;
}

export class WidgetContainerInfo {
    containerInstanceId: string;
    containerid: string;
    bindingType: string;
    bindingId: string;
    layout: string;
    edit_x: number;
    edit_y: number;
    widgets: Array<apiContainerWidgets> = new Array<apiContainerWidgets>();
    flyoutTitle: string;
    containerName: string = "";
}

export class WidgetContainerBasicInfo {
    containerInstanceId: string;
    containerId: string;
    bindingType: string;
    bindingId: string;
    containerName: string = "";
}

export class ContainerWidgets {
    id: string = "";
    component: string = "";
    width: number = 2;
    height: number = 2;
    x: number = 0;
    y: number = 0;

    xs_x: number = 0;
    xs_y: number = 0;
    sm_x: number = 0;
    sm_y: number = 0;
    md_x: number = 0;
    md_y: number = 0;
    lg_x: number = 0;
    lg_y: number = 0;

    xs_width: number = 0;
    xs_height: number = 0;
    sm_width: number = 0;
    sm_height: number = 0;
    md_width: number = 0;
    md_height: number = 0;
    lg_width: number = 0;
    lg_height: number = 0;

    obj: WidgetComponent;
}

export class apiContainerWidgets {
    id: string = "";
    component: string = "";
    width: number = 2;
    height: number = 2;
    x: number = 0;
    y: number = 0;
}

export class ExternalUserData {

    userID: string = "";
    email: string = "";
    display: string = "";
    imageURL: string = "";
    organizationName: string = "";
    organizationRole: string = "";
    phone: string = "";
}

export class ExternalUserPut {
    organizationName: string = "";
    organizationRole: string = "";
    phone: string = "";
}

export class RoleLayoutAssignment {
    roleid: string = "";
    rolename: string = "";
    layoutid: string = "";
}

export class UserMultiSelect {
    id: string = "";
    itemName: string = "";
}
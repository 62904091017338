import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetProperties, User, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { Workgroup, PermissionLevel_T } from '../../../apiService/classFiles/class.workgroups';
import { Content, ContentType_T } from '../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentStatus_T } from '../../../apiService/classFiles/class.enrollments';
declare var $: any;

@Component({
  selector: 'app-trending-widget',
  templateUrl: './trending-widget.component.html',
  styleUrls: ['./trending-widget.component.css']
})
export class TrendingWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "TrendingWidgetComponent";

    widgetLoaded: boolean = false;

    trendingContent: Array<Content> = new Array<Content>();
    currentUser: User = new User();
    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    activeEnrollments: Array<Enrollment> = new Array<Enrollment>();

    isListDisplay: boolean = false;
    rowCardCount: number = 5;

    displayType = "overlay";

    //For Share-dropdown Component
    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';
    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();



    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 5;
        this.widgetWidth = 8;

        this.widgetTitle = "TRENDING ASSETS";
        this.widgetName = "Trending Widget"
        this.widgetDesc = "Displays trending content.";
        //End Widget Defaults

        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.currentUser = u;


            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
            });

            try {
                this.trendingContent = JSON.parse(localStorage.getItem(this.currentUser.userId + "_trending"));
            }
            catch (err) {
                console.log(err)
            }

            // Get Trending Content
            this.pinnacleService.getCurrentTenantTrendingContent(6).subscribe(res => {
                this.trendingContent = res;
                if (this.trendingContent.length == 0) {
                    // If there's no trending content, hide trending section
                }

                /// write to Local Storage for this user
                try {
                    localStorage.setItem(this.currentUser.userId + "_trending", JSON.stringify(this.trendingContent))
                }
                catch (err) {
                    console.log(err);
                }

            }, e => {
                // Some Error Response
            });
        });
    }

    ngOnInit() {
        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.createProperties();
        this.Load();

        this.isListDisplay = this.decideListorCards();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    onResize() {
        super.onResize();
        this.getEnrollCardCount();
        this.isListDisplay = this.decideListorCards();

    }
    onWidgetResized() {
        super.onWidgetResized();
        this.getEnrollCardCount();

        this.isListDisplay = this.decideListorCards();
    }

    decideListorCards(): boolean {
        if (this.widgetWidth >= 4) {
            if (this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") {
                return true;
            }
            else {
                return false;
            }

        }
        else {
            return true;
        }
    }

    getEnrollCardCount() {
        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

            let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).clientWidth - 47;

            this.rowCardCount = Math.floor(pxwidth / 162);//Integer is the full width of the card, including margins, borders, and padding


            let pxheight = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).clientHeight - 51;
            let heightMultiplier = Math.floor(pxheight / 136);
            if (heightMultiplier == 0) {
                heightMultiplier = 1;
            }
            this.rowCardCount = this.rowCardCount * heightMultiplier;

        }
        else {
            this.rowCardCount = 0;
        }
    }

    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";
        $('#' + someId + "trendingContentBlockContainer").removeClass("small-card-container-singlerow");

        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        $('#' + someId + "trendingContentBlockContainer").addClass("small-card-container-singlerow");
        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == 5) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == 6) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == 7) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == 8) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == 0) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == 4) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == 1) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == 2) {
            if (this.currentEnrollments != null) {
                if (this.currentEnrollments.findIndex(course => course.courseId == r.contentId) == -1) {
                    url += "/learningcenter/series?courseId=" + r.contentId;
                }
                else {
                    url += "/learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[this.currentEnrollments.findIndex(course => course.courseId == r.contentId)].enrollmentId;
                }
            }

        }
        return url;
    }

    getContentImage(r: Content): string {
        let image: string = "";

        image += ProdGenApi.getIconImageFromType(r.contentType);

        //image = "assets/images/";

        if (r.bisCustom == false) {
            //      if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            //          if (r.docURL.includes(".pdf")) {
            //              image += "PDF Icon.png"
            //          }
            //          else if (r.docURL.includes(".doc")) {
            //              image += "Word Icon.png"
            //          }
            //          else if (r.docURL.includes(".xls")) {
            //              image += "Excel Icon.png"
            //          }
            //          else if (r.docURL.includes(".ppt")) {
            //              image += "PowerPoint Icon.png"
            //          }
            //          else if (r.docURL.includes(".htm")) {
            //              image += "HTML Icon.png"
            //          }
            //      }
            //      else {
            //          image += ProdGenApi.getIconImageFromType(r.contentType);
            //      }
        }
        else {
            image = "fas fa-id-badge";
        }


        return image;
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "PersonalResourceAccessWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
    }



    Load() {
        super.Load();

        //The rest of the load function will be widget specific
        this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
            this.currentEnrollments = res;
            this.activeEnrollments = res.filter(x => x.enrollmentStatus == EnrollmentStatus_T.inProgress);
        });
    }

    createProperties() {

        super.createProperties();

        super.createPropertiesDisplayType();

        super.createPropertiesWidgetTitle("TRENDING ASSETS");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS



    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }

    //For Share DropDown
    hoverElem(content: Content) {

        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }

    //For Share DropDown
    shareClick(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + this.widgetID);

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();


        let reservedSpotShare = document.getElementById('shareIconPlacment' + this.widgetID + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (20) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.shareAdjustmentY = ((-1 * y) + 70) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }

    }
}

import { Component, OnInit, ChangeDetectorRef, SecurityContext, ViewEncapsulation, Input, AfterViewInit } from '@angular/core'; 
import { WidgetComponent } from '../../widget/widget.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { error } from 'protractor';

//import * as Parser from "rss-parser";

declare var $: any;


@Component({
  selector: 'app-rss-feed-widget',
  templateUrl: './rss-feed-widget.component.html',
    styleUrls: ['./rss-feed-widget.component.css'],
  encapsulation:ViewEncapsulation.None
})


export class RssFeedWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "RssFeedWidgetComponent";
    static CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
    feedloaded: boolean = false;
    feed: any;
    RSSUrl: string = "https://www.eaglepoint.com/feed/";
    
    overlayDisplayClass = "widget-overlay-style-page";

    isAtom: boolean = false;
    isRSS: boolean = false;
    isUnrecognized: boolean = false;
    errorReturned: boolean = false;

    

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private HTTP: HttpClient, private htmlSanitizer:DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        this.widgetHeight = 10;
        this.widgetWidth = 2;
        this.widgetTitle = "RSS Feed"; 

        this.widgetName = "RSS Feed"
        this.widgetDesc = "Delivers the specified RSS feed"; 

    }

    ngOnInit() {
        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType  == null) {
            this.displayType  = "panel";
        }


        this.createProperties();

        this.Load(); 
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        if (newprops.findIndex(x => x.propID == "RSS_URL") != -1) {
            this.RSSUrl = newprops[newprops.findIndex(x => x.propID == "RSS_URL")].propValue;
        }
        
        


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "RssFeedWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }


        super.onPropsChangedLocalStorage();


        this.refreshFeed();
        
        
        //let parser = new Parser({

        //});


        //parser.parseURL(RssFeedWidgetComponent.CORS_PROXY + this.RSSUrl).then((res) => {
        //    this.feedloaded = true;
        //    this.feed = res;
        //})
        //.catch(
        //    error => console.log(error)
        //);
        super.widgetArePropertiesLoaded();
    }


    Load() {
        super.Load();
 
        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq) != null) {
            document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).style.overflowY = "auto";
        }
    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        //let range = new WidgetProperties();
        //range.propName = "Range (in days)"
        //range.propValue = this.timeSpan.toString();
        //range.propType = "number";
        //range.propID = "CHART_RANGE";
        //this.widgetProps.push(range);

        super.createPropertiesDisplayType();
        super.createPropertiesWidgetTitle("Pinnacle Series Blog");


        let rssurl = new WidgetProperties();
        rssurl.propName = "RSS Feed"
        rssurl.propValue = this.RSSUrl;
        rssurl.propType = "string";
        rssurl.propID = "RSS_URL";
        this.widgetProps.push(rssurl);


        super.createPropertiesMobileOptions();

    }

    //END WIDGET REQUIRED CALLS

    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }

    refreshFeedClick() {
        this.feedloaded = false;
        this.isAtom = false;
        this.isRSS = false;
        this.isUnrecognized = false;

        this.refreshFeed();
    }

    refreshFeed() {
        try {
            this.HTTP.get(RssFeedWidgetComponent.CORS_PROXY + this.RSSUrl)
                //this.HTTP.get(this.RSSUrl) //testing to force a CORS error
                .pipe(
                    catchError(err => {
                        this.isAtom = false;
                        this.isRSS = false;
                        this.isUnrecognized = false;
                        this.feedloaded = true;
                        this.errorReturned = true;

                        console.log("The below error is safe to ignore. It was thrown during retrieval of the RSS feed and cannot be caught by its triggering subscription.");
                        return err;
                    })
                ).subscribe((res:Response) =>  {
                    if ((<any>res.text()).includes("</rss>") || (<any>res.text()).includes("</RSS>")) {
                        this.isAtom = false;
                        this.isRSS = true;
                        this.isUnrecognized = false;
                    }
                    else if ((<any>res.text()).includes("</feed>") || (<any>res.text()).includes("</FEED>")) {
                        this.isAtom = true;
                        this.isRSS = false;
                        this.isUnrecognized = false;
                    }
                    else {//unrecognized format
                        this.isAtom = false;
                        this.isRSS = false;
                        this.isUnrecognized = true;
                    }

                    if (this.isUnrecognized == false) {
                        let parseString = require('xml2js').parseString;
                        parseString(res.text(), (err, resParsed) => {
                            if (this.isRSS == true) {
                                this.feedloaded = true;
                                this.feed = resParsed.rss.channel[0];
                            }
                            else if (this.isAtom == true) {
                                this.feedloaded = true;
                                this.feed = resParsed.feed;
                            }
                        });
                    }
                    else {
                        this.feedloaded = true;
                    }

            },
                err => {
                    this.isAtom = false;
                    this.isRSS = false;
                    this.isUnrecognized = false;
                    this.errorReturned = true;
                });
        } catch (e) {
            this.errorReturned = true;
        }

    }
}

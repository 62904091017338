import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'embed-webpage-widget',
    templateUrl: './embed-webpage-widget.component.html',
    styleUrls: ['./embed-webpage-widget.component.css']
})



export class EmbedWebpageWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "EmbedWebpageWidgetComponent";     
    
    websiteUrl: string = "";

    widgetLoaded: boolean;
    urlSafe: SafeResourceUrl = null;
    isValidUrl: boolean = false;
    


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetWidth = 4;
        this.widgetHeight = 5;
        this.widgetTitle = "";
        
        this.widgetName = "Embed Webpage"
        this.widgetDesc = "Display a secure external webpage on the page";

        this.widgetLoaded = false;
    }

    ngOnInit() {
        // call the base Widget class
        this.widgetLoaded = false;
        super.ngOnInit();

        // create widget specific properties that can be configured & load the data needed for the widget
        this.createProperties();
        this.Load();
        //window.scroll(0, 0);
        
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        // call the base Widget class
        super.Load();
    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);
        
        // write the new properties to the database      
       

        if (newprops.findIndex(x => x.propID == "WEBSITE_URL") != -1)
        {
            this.websiteUrl = newprops[newprops.findIndex(x => x.propID == "WEBSITE_URL")].propValue;
            this.validateURLProtocol();
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.websiteUrl);
            this.isValidUrl = this.validURL(this.websiteUrl);
        }

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, EmbedWebpageWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
            
        }

        super.onPropsChangedLocalStorage();

        try {
            localStorage.setItem(this.widgetID + "_websiteUrl", JSON.stringify(this.websiteUrl));
            }
            catch (err) {
                console.log(err);
        }
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
        
    }



    createProperties() {
        super.createProperties();

        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle();
        

        //Website Address
            let websiteUrl = new WidgetProperties();
            websiteUrl.propName = "Website URL";
            websiteUrl.propValue = this.websiteUrl;
            websiteUrl.propType = "string";
            websiteUrl.propID = "WEBSITE_URL";
            this.widgetProps.push(websiteUrl);

        super.createPropertiesMobileOptions();
    }


    //END WIDGET REQUIRED CALLS


    validURL(str): boolean {
        var pattern = new RegExp('^(https:\\/\\/)' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        return pattern.test(str);
    }

    validateURLProtocol() {
        if (this.websiteUrl.startsWith("http://")) {
            this.websiteUrl = this.websiteUrl.replace("http://", "https://");
        }
        else if (this.websiteUrl.startsWith("https://")) {
            //do nothing
        }
        else {
            this.websiteUrl = "https://" + this.websiteUrl;
        }
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { User, Playlist, PlaylistContent } from '../../apiService/classFiles/class.users';
import { Content, ContentProduct, ContentType_T } from '../../apiService/classFiles/class.content';
import { Workgroup, PermissionLevel_T } from '../../apiService/classFiles/class.workgroups';
import { Enrollment } from '../../apiService/classFiles/class.enrollments';
declare var $: any;

@Component({
  selector: 'template-playlistpanel',
  templateUrl: './playlistpanel.component.html',
  styleUrls: ['./playlistpanel.component.css']
})
export class PlaylistpanelComponent implements OnInit {
  @Input('currentUser') currentUser: User = new User();
  playlist: Playlist = new Playlist();
  playlistcontent: Array<PlaylistContent> = new Array<PlaylistContent>();
  playlistcontentitem: Content = new Content();
  currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
  enrollmentsLoaded: boolean = false;
  playlistLoaded: boolean = false;

  Editid: string = "";
  Editname: string = "";
  Editdesc: string = "";
  isNew: boolean = true;
  Editprods: Array<ContentProduct> = new Array<ContentProduct>();

    modalVisible = false;


    //For Share-dropdown Component
    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';

    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();



  constructor(private pinnacleService: ProdGenApi, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService) {
    if (authenticationService.AuthenticatePage() == true) {
      //this.pinnacleService.getCurrentUser().subscribe(u => {
        try {
          this.currentEnrollments = JSON.parse(localStorage.getItem(this.currentUser.userId + "_enrollments"));
        }
        catch (err) {
          console.log(err)
        }

        //this.currentUser = u;

        try {
          this.playlist = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlist"));
          this.playlistcontent = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlistcontent"));
        }
        catch (err) {
          console.log(err)
        }
        

        
        this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {

            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
                });


          for (var i = 0; i < res.length; i++) {
            if (res[i].imageURL == null || res[i].imageURL == "") {

              res[i].imageURL = "../assets/images/default_course.jpg";
            }
          }
          this.currentEnrollments = res;

          //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
          //copy and sort the array.
          //let condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();

          this.enrollmentsLoaded = true;
          /// write to Local Storage for this user
          try {
            localStorage.setItem(this.currentUser.userId + "_enrollments", JSON.stringify(this.currentEnrollments))
          }
          catch (err) {
            console.log(err);
          }

        }
        );
     // });
    }
  }

    ngOnInit() {
        try {
            this.playlist = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlist"));
            this.playlistcontent = JSON.parse(sessionStorage.getItem(this.currentUser.userId + "_playlistcontent"));
        }
        catch (err) {
            console.log(err)
        }

        this.pinnacleService.GetPlaylist(this.currentUser.userId).subscribe(pl => {
            this.playlist = pl;
            sessionStorage.setItem(this.currentUser.userId + "_playlist", JSON.stringify(this.playlist));
            this.pinnacleService.GetPlaylistContent(this.playlist.plid, "00000000-0000-0000-0000-000000000000").subscribe(plc => {
                this.playlistcontent = plc.sort((a: PlaylistContent, b: PlaylistContent) => {
                    if (a.contentname.toLowerCase() < b.contentname.toLowerCase())
                        return -1;
                    else if (a.contentname.toLowerCase() > b.contentname.toLowerCase())
                        return 1;
                    else
                        return 0;
                });

                sessionStorage.setItem(this.currentUser.userId + "_playlistcontent", JSON.stringify(this.playlistcontent));
                this.playlistLoaded = true;
            });
        });
  }

  getContentUrl(r: PlaylistContent): string {
    let url: string = "#";
    if (r.contentType == 5) {
      url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
    }
    else if (r.contentType == 6) {
      url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
    }
    else if (r.contentType == 7) {
      url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
    }
    else if (r.contentType == 8) {
      url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
    }
    else if (r.contentType == 0) {
      url += "/cheatsheetviewer?id=" + r.contentId;
    }
    else if (r.contentType == 4) {
      url += "/videoviewer?id=" + r.contentId;
    }
    else if (r.contentType == 1) {
      url += "/learningcenter/series?learningPathId=" + r.contentId;
    }
    else if (r.contentType == 2) {
      if (this.currentEnrollments != null) {
        if (this.currentEnrollments.findIndex(course => course.courseId == r.contentId) == -1) {
          url += "/learningcenter/series?courseId=" + r.contentId;
        }
        else {
          url += "/learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[this.currentEnrollments.findIndex(course => course.courseId == r.contentId)].enrollmentId;
        }
      }
     

    }

    return url;
  }


  getContentImage(r: PlaylistContent): string {
    let image: string = "";

    image += ProdGenApi.getIconImageFromType(r.contentType);

    //image = "assets/images/";

      if (r.biscustom == false) {
    //      if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
    //          if (r.docURL.includes(".pdf")) {
    //              image += "PDF Icon.png"
    //          }
    //          else if (r.docURL.includes(".doc")) {
    //              image += "Word Icon.png"
    //          }
    //          else if (r.docURL.includes(".xls")) {
    //              image += "Excel Icon.png"
    //          }
    //          else if (r.docURL.includes(".ppt")) {
    //              image += "PowerPoint Icon.png"
    //          }
    //          else if (r.docURL.includes(".htm")) {
    //              image += "HTML Icon.png"
    //          }
    //      }
    //      else {
    //          image += ProdGenApi.getIconImageFromType(r.contentType);
    //      }
    }
    else {
      image = "fas fa-id-badge";
    }
    

    return image;
  }

  onClose(event: any) {
    
    this.Editid = "";
    this.Editname = "";
    this.Editdesc = "";
    this.isNew = true;
    this.Editprods = new Array<ContentProduct>();
    this.modalVisible = false;

    if (event == "Save") {
      this.pinnacleService.GetPlaylist(this.currentUser.userId).subscribe(pl => {
        this.playlist = pl;
        this.pinnacleService.GetPlaylistContent(this.playlist.plid, "00000000-0000-0000-0000-000000000000").subscribe(plc => {
          this.playlistcontent = plc;
          this.playlistLoaded = true;
        });
      });
    }
  }

  NewCheat() {
    this.modalVisible = true;

    setTimeout(function () {
      $('#newcheat').modal('show');
    }, 1);
  }



    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }


    //For Share DropDown
    hoverElem(content: PlaylistContent) {
         this.constructedContent = this.buildItem(content.contentId.toString(), content.contentname.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }



    //For Share DropDown
    shareClick(content: PlaylistContent) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.contentname.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }


        let tempdiv = document.getElementById('contentScrollArea' + 'Playlist');

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();


        let reservedSpotShare = document.getElementById('shareIconPlacment' + 'Playlist' + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (15) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.shareAdjustmentY = ((-1 * y) + 15) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }
    }

}

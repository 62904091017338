import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../../BrowserAuthenticationService';
import { User, Playlist, PlaylistContent, WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { Content, ContentProduct, ContentType_T } from '../../../apiService/classFiles/class.content';
import { WidgetComponent } from '../../widget/widget.component';
import { Workgroup, PermissionLevel_T } from '../../../apiService/classFiles/class.workgroups';
declare var $: any;

@Component({
  selector: 'app-my-workgroups-widget',
    templateUrl: './my-workgroups-widget.component.html',
    styleUrls: ['./my-workgroups-widget.component.css']
})
export class MyWorkgroupsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "MyWorkgroupsWidgetComponent";

    widgetLoaded: boolean = false;
    widgetProps: Array<WidgetProperties> = new Array<WidgetProperties>();

    workGroups: Array<Workgroup> = new Array<Workgroup>();
    isPinnacleLite:boolean = false;
    workgroupsLoaded: boolean = false;
    currentUser: User = new User();
    wgcardcount: number = 0;
    cardType: string = "large";
    rowCardCount: number = 5;
    widgetWidthSize4Under: boolean = true;

    workgroupImage: string = "";

    useMobileHeight = true;
    mobileHeight = 3;
    displayType = "overlay";


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private router: Router, private _sanitizer: DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        let wgImg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.workgroup);

        if (wgImg.indexOf("default") == -1) { this.workgroupImage = wgImg; }
        else { this.workgroupImage = "../../assets/images/" + wgImg; }
        this.mobileHeight = 3;
        this.widgetHeight = 7;
        this.widgetWidth = 8;
        this.widgetTitle = "MY WORK GROUPS";
    }

    ngOnInit() {
        super.ngOnInit();

        try {
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.widgetName = "MY WORK GROUPS"
        this.widgetDesc = "Displays the Work Groups of which you are currently a member. ";

        this.createProperties();
        this.Load();

    }
    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        super.Load();
        try {
            this.workGroups = JSON.parse(localStorage.getItem(this.currentUser.userId + "_workgroups"));
            if (this.workGroups) {
                for (let w of this.workGroups) {
                    w.imageURL = "";
                }
            }

        }
        catch (err) {
            console.log(err)
        }

        try {

            this.pinnacleService.getCurrentUser().subscribe(u => {

                this.currentUser = u;

                this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], -1).subscribe(res => {
                    for (var i = 0; i < res.length; i++) {

                        if (res[i].imageURL == null || res[i].imageURL == "") {
                            //get default workgroup image from storage
                            let defaultwgimage = "";

                            try {
                                defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                            }
                            catch (err) {
                            }

                            if (defaultwgimage != null && defaultwgimage != "") {
                                res[i].imageURL = defaultwgimage;
                            }
                            else {
                                res[i].imageURL = "../../assets/images/default_project.jpg";
                            }
                        }
                    }
                    this.workgroupsLoaded = true;
                    
                    //this.getWorkGroupCardCount(window.innerWidth);
                    this.workGroups = res;
                    this.calcCardSize();
                    this.calMobileHeight();
                    //console.log("My workgroups - super cal");
                    //super.widgetMobileHeightCalucaled(); //Let's Widget Container know that the right Mobile Height has been calculated
                    /// write to Local Storage for this user
                    try {
                        localStorage.setItem(this.currentUser.userId + "_workgroups", JSON.stringify(this.workGroups))
                    }
                    catch (err) {
                        console.log(err);
                    }

                }
                );
            });


        }
        catch (err) {
            console.log(err)
        }


        try {
        }
        catch (err) {
            console.log(err)
        }

    }

    // this is for screen resize
    onResize() {
        super.onResize(); 
        this.calcCardSize();
    }

    onWidgetResized() {
        super.onWidgetResized();
        this.calcCardSize();
    }


    calcCardSize() {
        this.cardType = "large";
        if (this.widgetRenderSize == "lg" || this.widgetRenderSize == "md") {
            this.cardType = "large";
        }
        if ((this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") || window.innerWidth <= 992) {
            this.cardType = "small";
        }
        setTimeout(() => {
            this.rowCardCount = this.getWorkGroupCardCount();
        });
    }

    calMobileHeight() {       
        //console.log(this.widgetTitle + '-' + this.widgetTitle.length + '-' + this.displayType);

        let currentHeight = this.mobileHeight;
        if (this.workGroups) {

            if (this.workGroups.length == 0) {
                if (this.widgetTitle.length == 0) {
                    this.mobileHeight = 5;
                }
                else {
                    this.mobileHeight = 6;
                }
            }
            //When there is one Workgroup
            else if (this.workGroups.length == 1) {
                this.mobileHeight = 3;
            }
            //Mutliple Workgroups
            else {
                if (this.workGroups.length > 6) {
                    this.mobileHeight = Math.ceil(6 * 1.9);
                }
                else {
                    this.mobileHeight = Math.ceil((this.workGroups.length) * 1.9);
                }

                if (this.widgetTitle.length != 0) {
                    this.mobileHeight = this.mobileHeight + 1;
                }
            }

            if (currentHeight != this.mobileHeight && window.innerWidth <= 992 && this.widgetLoaded) {
                this.widgetContainer.populateMainandFlyout();
                this.widgetContainer.onResize(null);
            }

        }
    }

    onClose(event: any) {
    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "MyWorkgroupsWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }
      

        super.onPropsChangedLocalStorage();
        if (this.widgetWidth <= 4) {
            this.widgetWidthSize4Under = false;
        }
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);

    }



    getWorkGroupCardCount() {
        if (this.workGroups) {
            if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

                let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).offsetWidth;

                if (this.cardType == "small") {
                    //take the mobile height of the widget, convert that to pixels, then divide by the height of the small cards to get the correct card count
                    //let pxheight = this.widgetContainer.calcWidgetHeight(this.mobileHeight);
                    //let widgetheaderpx = 40;
                    //let smcardheight = 102;
                    //return Math.floor((pxheight - widgetheaderpx) / smcardheight);
                    //return this.workGroups.length;
                    if (this.workGroups.length > 6) {
                        return 6;
                    }
                    else {
                        return this.workGroups.length;
                    }
                    
                }
                return Math.floor(pxwidth / 250);//If WorkGroup card width changes update the equation to read: Math.floor(((width / 12) * 8) / (Adjust this number))
            }
            else {
                return 0;
                }
        }
    }

    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'workGroupsBlockShowMoreButton').style.display = "none";
        document.getElementById(someId + 'workGroupsBlockShowLessButton').style.display = "block";
        document.getElementById(someId + 'workGroupsLargeBlock').style.display = "block";

        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'workGroupsBlockShowLessButton').style.display = "none";
        document.getElementById(someId + 'workGroupsBlockShowMoreButton').style.display = "block";
        document.getElementById(someId + 'workGroupsLargeBlock').style.display = "none";

        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        //let range = new WidgetProperties();
        //range.propName = "Range (in days)"
        //range.propValue = this.timeSpan.toString();
        //range.propType = "number";
        //range.propID = "CHART_RANGE";
        //this.widgetProps.push(range);

        super.createPropertiesDisplayType();

        super.createPropertiesWidgetTitle("My Work Groups");
        
        super.createPropertiesMobileOptions();

    }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DomSanitizer } from '@angular/platform-browser';
import { User, WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { Content, ContentProduct, ContentType_T } from '../../../apiService/classFiles/class.content';
import { WidgetComponent } from '../../widget/widget.component';
import { DxChartComponent } from "devextreme-angular";
import { DxPieChartComponent } from "devextreme-angular";
import chart from 'devextreme/viz/chart';

declare var $: any;

@Component({
  selector: 'personal-resource-access-widget',
    templateUrl: './personal-resource-access-widget.component.html',
    styleUrls: [ './personal-resource-access-widget.component.css']
})
export class PersonalResourceAccessWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "PersonalResourceAccessWidgetComponent";

    currentUser: User = new User();
    @ViewChild(DxChartComponent, { static: false }) myChart: DxChartComponent;
    @ViewChild(DxPieChartComponent, { static: false }) myPieChart: DxPieChartComponent;

    timeSpan: number = 15;
    chartType: string = "myusage";
    canShow: boolean = false;

    myusage = [
        { resourceType: "Workflows", me: 18, company: 22  },
        { resourceType: "Videos", me: 84, company: 94  },
        { resourceType: "Documents", me: 54, company: 43 },
        { resourceType: "Courses", me: 8, company: 6 },
    ];

    mylearningtime = [
        { resourceType: "10/15/2019", count: 22 },
        { resourceType: "10/16/2019", count: 45 },
        { resourceType: "10/17/2019", count: 12 },
        { resourceType: "10/18/2019", count: 8 },
        { resourceType: "10/19/2019", count: 14 },
        { resourceType: "10/20/2019", count: 9 },
        { resourceType: "10/21/2019", count: 0 },
        { resourceType: "10/22/2019", count: 0 },
        { resourceType: "10/23/2019", count: 86 },
        { resourceType: "10/24/2019", count: 44 },
        { resourceType: "10/25/2019", count: 35 },
        { resourceType: "10/26/2019", count: 37 },
        { resourceType: "10/27/2019", count: 14 },
        { resourceType: "10/28/2019", count: 12 },
    ];

    enrollmentstatus = [
        { resourceType: "Dropped", count: 3 },
        { resourceType: "Not Started", count: 5},
        { resourceType: "In Progress", count: 3},
        { resourceType: "Completed", count: 10},
    ];

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private _sanitizer: DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        this.widgetHeight = 6;
        this.widgetWidth = 3; 
        this.widgetName = "My Asset Usage"
        this.widgetDesc = "View the types of resources you use the most.";


    }


    ngOnInit() {

        super.ngOnInit();
         

        this.createProperties();
        this.Load();
    }

    onResize() {
        super.onResize();

        this.renderChart();
    }


    createProperties() {

        super.createProperties();

        let chartType = new WidgetProperties();

        let option1: WidgetPropertyOptions = new WidgetPropertyOptions();
        option1.opName = "My Asset Usage";
        option1.opValue = "myusage";
        chartType.propOptions.push(option1);

        let option2: WidgetPropertyOptions = new WidgetPropertyOptions();
        option2.opName = "My Learning Time";
        option2.opValue = "mylearningtime";
        chartType.propOptions.push(option2);

        let option3: WidgetPropertyOptions = new WidgetPropertyOptions();
        option3.opName = "Enrollment Status";
        option3.opValue = "enrollmentstatus";
        chartType.propOptions.push(option3);

        chartType.propName = "Chart";
        chartType.propType = "select";
        chartType.propValue = this.chartType;
        chartType.propID = "CHART_TYPE";
        this.widgetProps.push(chartType);

        let range = new WidgetProperties();
        range.propName = "Range (in days)"
        range.propValue = this.timeSpan.toString();
        range.propType = "number";
        range.propID = "CHART_RANGE";
        this.widgetProps.push(range);

    }

    Load() {
        super.Load();

        try {

            this.pinnacleService.getCurrentUser().subscribe(u => {

                this.currentUser = u;

             });


        }
        catch (err) {
            console.log(err)
            this.canShow = true;
        }


        try {
        }
        catch (err) {
            console.log(err)
            this.canShow = true;
        }

    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    onClose(event: any) {
    }

    renderChart() {
        setTimeout(() => {
            var renderOptions = { force: true };
            if (this.myChart != null) {
                this.myChart.instance.render(renderOptions);
            }
            if (this.myPieChart != null) {
                this.myPieChart.instance.render(renderOptions);
            }

            this.canShow = true;

        }, 400);//set to 400 after trial and error

    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        if (newprops.findIndex(x => x.propName == "Range (in days)") != -1) {
            this.timeSpan = +newprops[newprops.findIndex(x => x.propName == "Range (in days)")].propValue;
        }
        if (newprops.findIndex(x => x.propName == "Chart") != -1) {
            let chartProps: WidgetProperties = newprops[newprops.findIndex(x => x.propName == "Chart")];
            this.chartType = chartProps.propValue;

            
            for (let op of this.widgetProps[this.widgetProps.findIndex(x => x.propName == "Chart")].propOptions) {
                if (this.chartType == op.opValue) {
                    this.widgetName = op.opName;
                }
            }
            if (chartProps.propOptions != null) {
                //for (let op of chartProps.propOptions) {
                //    if (op.opValue == chartProps.propValue) {
                //        this.widgetName = op.opName;
                //    }
                //}
            }

        }

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "PersonalResourceAccessWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }


        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        this.renderChart();
        super.widgetArePropertiesLoaded();
    }




}

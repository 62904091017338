import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChatCitation } from '../models/aichat';
import { ContentType } from '../../apiService/classFiles/v2-content';
import { Apiv2Service } from '../../apiService/apiv2.service';

@Component({
    selector: 'ep-aichat-citation',
  templateUrl: './aichat-citation.component.html',
  styleUrls: ['./aichat-citation.component.css'],
})
export class AIChatCitationComponent implements OnChanges {
  @Input() citation: ChatCitation;
  @Input() citationNumber = 0;
  innerCitation = "";

  contentTypeImage: string = "";
  contentType = "";

    constructor(private cdr: ChangeDetectorRef,
        private apiv2Service: Apiv2Service
    )
//    private contentTypeService: ContentTypesService,
      //    private dialogService: DialogService) 
      {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['citationNumber']) {
      this.citationNumber = changes["citationNumber"].currentValue;
    }
    if (changes['citation']) {
      this.citation = changes["citation"].currentValue;
        if (this.citation.contentType != null) {
            this.contentType = this.apiv2Service.contentTypeToStringV2(this.citation.contentType);
        this.contentTypeImage = this.getIconImageFromType(this.citation.contentType);
      }

      this.innerCitation = "<a href=\"" + this.citation.url + "\" title= \"" + this.citation.title + "\">" + this.citation.title + "</a>";
    }
    
  }

    onCardClick() {
        let url = this.getContentUrl(this.citation.contentType, this.citation.url)
        window.open(url, "_blank");
  }

    getContentUrl(contentType:ContentType, contentId:string): string {
        let url: string = "#";
        if (contentType == ContentType.Workflow) {
            url += "/workflowviewer?id=" + contentId + "&contentType=workflow";
        }
        else if (contentType == ContentType.Process) {
            url += "/workflowviewer?id=" + contentId + "&contentType=process";
        }
        else if (contentType == ContentType.Task) {
            url += "/workflowviewer?id=" + contentId + "&contentType=task";
        }
        else if (contentType == ContentType.Step) {
            url += "/workflowviewer?id=" + contentId + "&contentType=step";
        }
        else if (contentType == ContentType.Document) {
            url += "/cheatsheetviewer?id=" + contentId;
        }
        else if (contentType == ContentType.Video) {
            url += "/videoviewer?id=" + contentId;
        }
        else if (contentType == ContentType.LearningPath) {
            url += "/learningcenter/series?learningPathId=" + contentId;
        }
        else if (contentType == ContentType.Course) {
            url += "/learningcenter/series?courseId=" + contentId;
        }

        return url;
    }

     getIconImageFromType(contentType: ContentType): string {
        let image = "";
        switch (contentType) {
            case ContentType.Workflow:
            case ContentType.Process:
            case ContentType.Task:
            case ContentType.Step:
                image += "fas fa-sitemap";
                break;
            case ContentType.Document:
                image += "fas fa-file-alt";
                break;
            case ContentType.Video:
                image += "fas fa-play-circle";
                break;
            case ContentType.Course:
                image += "fas fa-book";
                break;
            case ContentType.LiveEvent:
                image += "fas fa-book-reader";
                break;
            case ContentType.LearningPath:
                image += "fas fa-graduation-cap ";
                break;
            case ContentType.Quiz:
                image += "fas fa-clipboard-check";
                break;
            case ContentType.ScormPackage:
                image += "fas fa-play-circle";
                break;
        }
        return image;

    }



}

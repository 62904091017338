import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { ProdGenApi } from '../apiService/prodgen.api';
import { BrandingComponent } from '../branding/branding.component';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { Observable } from 'rxjs';
import { UserPermissions } from '../apiService/classFiles/class.users';

declare var $: any;

@Component({
  selector: 'app-companybranding',
    templateUrl: './companybranding.component.html',
  styleUrls: ['./companybranding.component.css']
})
export class CompanybrandingComponent implements OnInit {
    componentname: string = "CompanybrandingComponent";
    isPartner: boolean = false;
    partnerthemeid: string = "";
    litethemeid: string = "";
    isOnCompany: boolean = true;
    isOnPartner: boolean = false;
    isOnLite: boolean = false;

    clickedCompany: boolean = false;
    clickedPartner: boolean = false;
    clickedLite: boolean = false;

    isAdmin: boolean = false;
    hasDeterminedAdmin: boolean = false;

    userPermissions: UserPermissions = new UserPermissions();

    @ViewChild('companybranding', { static: false }) coBrand: BrandingComponent;
    @ViewChild('partnerbranding', { static: false }) partBrand: BrandingComponent;
    @ViewChild('litebranding', { static: false }) liteBrand: BrandingComponent;

    constructor(private pinnacleService: ProdGenApi, private changeref: ChangeDetectorRef, private authenticationService: BrowserAuthenticationService, ) {
        if (this.authenticationService.AuthenticatePage() == true) { }
    }

    ngOnInit() {
        window.addEventListener("beforeunload", (ev) => {//event to prompt them that changes will be lost
            if (this.canDeactivate() != true) {
                ev.preventDefault();
                ev.returnValue = '';
            }            
        });
        window.addEventListener('unload', () => {//if they answer yes on the previous question and cause the page to be unloaded
            //clean up layout if it is a brand new one, if not we dont need to do anything
        });



        this.pinnacleService.IsPartner().subscribe(res => {
            this.isPartner = res;
        });
        this.pinnacleService.getCurrentUserPermissions().subscribe(res => {
            this.userPermissions = res;
            this.isAdmin = (res.administrator || res.canManageBranding);
            this.hasDeterminedAdmin = true;
        });
        
    }
    companyclick(e: any) {
        this.clickedCompany = true;

        this.clickedLite = false;
        this.clickedPartner = false;

        this.setBrandingPropToFalse();

        //check if any tabs are in edit mode and prompt ok cancel to lose changes

        if (this.isOnLite == true) {
            if (this.liteBrand.isEdit == true || this.liteBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectCompanyTab(true);
            }
        }
        else if (this.isOnPartner == true) {
            if (this.partBrand.isEdit == true || this.partBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectCompanyTab(true);
            }
        }
        else if (this.isOnCompany == true) {
            //do nothing
        }


     
    }
    partnerclick(e: any) {
        this.clickedPartner = true;

        this.clickedCompany = false;
        this.clickedLite = false;
        
        this.setBrandingPropToFalse();

        //check if any tabs are in edit mode and prompt ok cancel to lose changes
        if (this.isOnLite == true) {
            if (this.liteBrand.isEdit == true || this.liteBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectPartnerTab(true);
            }
        }
        else if (this.isOnPartner == true) {
            //do nothing
        }
        else if (this.isOnCompany == true) {
            if (this.coBrand.isEdit == true || this.coBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectPartnerTab(true);
            }
        }


        
    }
    liteclick(e: any) {
        this.clickedLite = true;
        this.clickedCompany = false;
        this.clickedPartner = false;

        this.setBrandingPropToFalse();

        //check if any tabs are in edit mode and prompt ok cancel to lose changes
        if (this.isOnLite == true) {
            //do nothing
        }
        else if (this.isOnPartner == true) {
            if (this.partBrand.isEdit == true || this.partBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectLiteTab(true);
            }
        }
        else if (this.isOnCompany == true) {
            if (this.coBrand.isEdit == true || this.coBrand.isNew == true) {
                $('#confirmNavModal').modal('show');
            }
            else {
                this.selectLiteTab(true);
            }
        }
    }

    setBrandingPropToFalse() {
        this.liteBrand.logochange = false;
        this.liteBrand.newschange = false;
        this.liteBrand.docchange = false;
        this.liteBrand.vidchange = false;
        this.liteBrand.lpchange = false;
        this.liteBrand.coursechange = false;
        this.liteBrand.quizchange = false;
        this.liteBrand.wfchange = false;
        this.liteBrand.processchange = false;
        this.liteBrand.mydocchange = false;
        this.liteBrand.defworkgroupchange = false;
        this.liteBrand.enrollmentchange = false;

        this.partBrand.logochange = false;
        this.partBrand.newschange = false;
        this.partBrand.docchange = false;
        this.partBrand.vidchange = false;
        this.partBrand.lpchange = false;
        this.partBrand.coursechange = false;
        this.partBrand.quizchange = false;
        this.partBrand.wfchange = false;
        this.partBrand.processchange = false;
        this.partBrand.mydocchange = false;
        this.partBrand.defworkgroupchange = false;
        this.partBrand.enrollmentchange = false;

        this.coBrand.logochange = false;
        this.coBrand.newschange = false;
        this.coBrand.docchange = false;
        this.coBrand.vidchange = false;
        this.coBrand.lpchange = false;
        this.coBrand.coursechange = false;
        this.coBrand.quizchange = false;
        this.coBrand.wfchange = false;
        this.coBrand.processchange = false;
        this.coBrand.mydocchange = false;
        this.coBrand.defworkgroupchange = false;
        this.coBrand.enrollmentchange = false;
    }

    cancelClick() {
        if (this.isOnCompany == true) {
            this.selectCompanyTab(false);
        }
        else if (this.isOnLite == true) {
            this.selectLiteTab(false);
        }
        else if (this.isOnPartner == true) {
            this.selectPartnerTab(false);
        }
    }

    okClick() {
        this.coBrand.CancelTheme();
        this.partBrand.CancelTheme();
        this.liteBrand.CancelTheme();

        if (this.clickedCompany == true) {
            this.selectCompanyTab(true);
        }
        else if (this.clickedLite == true) {
            this.selectLiteTab(true);
        }
        else if (this.clickedPartner == true) {
            this.selectPartnerTab(true);
        }
    }

    selectCompanyTab(fullscope: boolean) {
        document.getElementById("companyTab").classList.add("active");
        document.getElementById("partnerTab").classList.remove("active");
        document.getElementById("liteTab").classList.remove("active");

        if (fullscope == true) {
            document.getElementById("company").classList.add("in");
            document.getElementById("company").classList.add("active");
            document.getElementById("partner").classList.remove("in");
            document.getElementById("partner").classList.remove("active");
            document.getElementById("lite").classList.remove("in");
            document.getElementById("lite").classList.remove("active");

            //select the partner theme from the theme dropdown. this should trigger the onchange event of that dropdown and do all the heavy lifting for us

            var element = document.getElementById('themeDropdowncompany');
            var event = document.createEvent('Event');
            event.initEvent('change', true, true);
            element.dispatchEvent(event);

            this.isOnCompany = true;
            this.isOnLite = false;
            this.isOnPartner = false;
        }
    }
    selectPartnerTab(fullscope: boolean) {
        document.getElementById("companyTab").classList.remove("active");
        document.getElementById("partnerTab").classList.add("active");
        document.getElementById("liteTab").classList.remove("active");

        if (fullscope == true) {
            document.getElementById("company").classList.remove("in");
            document.getElementById("company").classList.remove("active");
            document.getElementById("partner").classList.add("in");
            document.getElementById("partner").classList.add("active");
            document.getElementById("lite").classList.remove("in");
            document.getElementById("lite").classList.remove("active");

            //select the partner theme from the theme dropdown. this should trigger the onchange event of that dropdown and do all the heavy lifting for us
            this.pinnacleService.getCurrentPartnerThemeID().subscribe(res => {
                this.partnerthemeid = res;
                $("#themeDropdownpartner").val(this.partnerthemeid);

                var element = document.getElementById('themeDropdownpartner');
                var event = document.createEvent('Event');
                event.initEvent('change', true, true);
                element.dispatchEvent(event);
            });

            this.isOnCompany = false;
            this.isOnLite = false;
            this.isOnPartner = true;
        }
    }
    selectLiteTab(fullscope: boolean) {
        document.getElementById("companyTab").classList.remove("active");
        document.getElementById("partnerTab").classList.remove("active");
        document.getElementById("liteTab").classList.add("active");

        if (fullscope == true) {
            document.getElementById("company").classList.remove("in");
            document.getElementById("company").classList.remove("active");
            document.getElementById("partner").classList.remove("in");
            document.getElementById("partner").classList.remove("active");
            document.getElementById("lite").classList.add("in");
            document.getElementById("lite").classList.add("active");

            //select the partner theme from the theme dropdown. this should trigger the onchange event of that dropdown and do all the heavy lifting for us
            this.pinnacleService.getCurrentLiteThemeID().subscribe(res => {
                this.litethemeid = res;
                $("#themeDropdownlite").val(this.litethemeid);

                var element = document.getElementById('themeDropdownlite');
                var event = document.createEvent('Event');
                event.initEvent('change', true, true);
                element.dispatchEvent(event);
            });

            this.isOnCompany = false;
            this.isOnLite = true;
            this.isOnPartner = false;
        }
    }


    canDeactivate(): Observable<boolean> | boolean {
        if (this.isOnCompany == true) {
            return this.coBrand.canDeactivate();
        }
        else if (this.isOnPartner == true) {
            return this.partBrand.canDeactivate();
        }
        else if (this.isOnLite == true) {
            return this.liteBrand.canDeactivate();
        }
        else {
            return true;
        }
    }
}

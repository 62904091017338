import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { Workgroup, PermissionLevel_T } from '../../../apiService/classFiles/class.workgroups'

declare var $: any;

// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
  selector: 'public-workgroups-widget',
    templateUrl: './public-workgroups-widget.component.html',
    styleUrls: ['./public-workgroups-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class PublicWorkgroupsComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "PublicWorkgroupsComponent";     // this key must be set to the name of the Angular component

    widgetLoaded: boolean = false;

    workgroupList: Array<Workgroup> = new Array<Workgroup>();
    workgroupListFilterCopy: Array<Workgroup> = new Array<Workgroup>();
    selectedWorkgroup: Workgroup = new Workgroup();
    filterText: string = "";

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);
        
        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 2;
        this.widgetHeight = 4;

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetTitle = "Available Work Groups";
        this.widgetName = "Default Template Widget"
        this.widgetDesc = "Let this widget guide you..."; 
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    Load() {
        // call the base Widget class
        super.Load();


        this.pinnacleService.getPublicWorkgroups().subscribe(res => {

            res.forEach(w => {
                if (w.imageURL.trim() == "") {

                    let defaultwgimage = "";

                    try {
                        defaultwgimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_defWorkgroupImage");
                    }
                    catch (err) {
                    }

                    if (defaultwgimage != null && defaultwgimage != "") {
                        w.imageURL = defaultwgimage;
                    }
                    else {
                        w.imageURL = "../assets/images/default_project.jpg";
                    }
                }
            })

            this.workgroupList = res;
            this.workgroupListFilterCopy = res;

            //document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).style.overflowY = "auto";
        });
    }


    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);


        

        if (writeSettings) {
            // write the new properties to the database
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "RssFeedWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)

        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }



    createProperties() {

        // call widget base class
        super.createProperties();

        // the width and height properties are already created by the base class.
        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        //DispType
        super.createPropertiesDisplayType("panel");

        super.createPropertiesWidgetTitle("Available Work Groups");

        super.createPropertiesMobileOptions();
    }



    //END WIDGET REQUIRED CALLS

    getClassList(index: number): string {
        let classes: string = "";

        
        let themeNum: number = (index % 3);


        if (themeNum == 0) {
            themeNum = 1;
        }
        else if (themeNum == 1) {
            themeNum = 2;
        }
        else if (themeNum == 2) {
            themeNum = 3;
        }

        classes += "largecardcolortheme" + themeNum;
        return classes;
    }

    onClickItem(w: Workgroup) {
        this.selectedWorkgroup = w;

        $('#selfInviteModal').modal('show');
    }

    onSubmit() {
        let userid = ProdGenApi.getSessionUser().userId;
        this.pinnacleService.SetWorkgoupMember(this.selectedWorkgroup.workgroupId, userid, PermissionLevel_T.user).subscribe(
            res => {
                
                let index = this.workgroupList.indexOf(this.selectedWorkgroup);
                this.workgroupList.splice(index, 1);
                this.workgroupListFilterCopy = new Array<Workgroup>();
                for (var i = 0; i < this.workgroupList.length; i++) {
                    this.workgroupListFilterCopy.push(this.workgroupList[i]);
                }                   
                

                let indexz = this.widgetContainer.containerWidgets.findIndex(x => x.component == "MyWorkgroupsWidgetComponent");
                if (indexz != -1) {
                    //console.log("theres a myworkgroups widgets so im gonna tell it to reload");
                    this.widgetContainer.containerWidgets[indexz].obj.ngOnInit();
                }
            },
            err => { console.log(err);});

    }

    filterWorkgroups(ev:any) {

        this.workgroupListFilterCopy = new Array<Workgroup>();
        for (var i = 0; i < this.workgroupList.length; i++) {
            this.workgroupListFilterCopy.push(this.workgroupList[i]);
        }

        for (var j = 0; j < this.workgroupListFilterCopy.length; j++) {
            if (this.workgroupListFilterCopy[j].name.toLowerCase().indexOf(this.filterText.toLowerCase()) == -1) {
                this.workgroupListFilterCopy.splice(j, 1);
                j--;
            }
        }
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Content, ContentType_T } from '../../../apiService/classFiles/class.content';
import { Enrollment, EnrollmentStatus_T } from '../../../apiService/classFiles/class.enrollments';
import { User, WidgetProperties } from '../../../apiService/classFiles/class.users';
import { PermissionLevel_T, Workgroup } from '../../../apiService/classFiles/class.workgroups';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetComponent } from '../../widget/widget.component';

declare var $: any;

@Component({
  selector: 'app-frequently-used-widget',
  templateUrl: './frequently-used-widget.component.html',
  styleUrls: ['./frequently-used-widget.component.css']
})
export class FrequentlyUsedWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "FrequentlyUsedWidgetComponent";

    widgetLoaded: boolean = false;

    frequentlyUsed: Array<Content> = new Array<Content>();
    currentUser: User = new User();
    rowCardCount: number = 5;
    isListDisplay: boolean = false;

    //For Share-dropdown Component
    constructedContent: Content = new Content();
    currentHover: string = "";
    shareClickId: string = "";
    shareAdjustmentX: string = '0';
    shareAdjustmentY: string = '0';
    availibleWorkgroups: Array<Workgroup> = new Array<Workgroup>();
    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    activeEnrollments: Array<Enrollment> = new Array<Enrollment>();
    
    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 5;
        this.widgetWidth = 8;
        this.widgetTitle = "MY FREQUENTLY USED ASSETS";

        this.widgetName = "Frequently Used"
        this.widgetDesc = "Displays frequently accessed content.";

        //End Widget Defaults

    }

    ngOnInit() {
        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.isListDisplay = this.decideListorCards();

        this.createProperties();

    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
        this.Load();
        this.changedetectorref.detectChanges();

    }

    Load() {
        super.Load();

        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.currentUser = u;

            this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
                this.availibleWorkgroups = res;
            });

            try {
                this.frequentlyUsed = JSON.parse(localStorage.getItem(this.currentUser.userId + "_frequent"));
            }
            catch (err) {
                console.log(err)
            }

            // Get Frequently Used Content
            this.pinnacleService.getCurrentUserFrequentContent(16, 0).subscribe(res => {
                let newArray = new Array<Content>();
                for (var i = 0; i < res.length; i++) {
                    if (res[i].contentType == ContentType_T.workflow ||
                        res[i].contentType == ContentType_T.process ||
                        res[i].contentType == ContentType_T.cheatsheet ||
                        res[i].contentType == ContentType_T.video) {
                        newArray.push(res[i]);
                    }
                }
                this.frequentlyUsed = newArray;

                /// write to Local Storage for this user
                try {
                    localStorage.setItem(this.currentUser.userId + "_frequent", JSON.stringify(this.frequentlyUsed))
                }
                catch (err) {
                    console.log(err);
                }
            }
            );

            this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
                this.currentEnrollments = res;
                this.activeEnrollments = res.filter(x => x.enrollmentStatus == EnrollmentStatus_T.inProgress);
            });
        });

    }

    onResize() {
        super.onResize();
        this.getEnrollCardCount();
        this.isListDisplay = this.decideListorCards();

    }
    onWidgetResized() {
        super.onWidgetResized();
        this.getEnrollCardCount();

        this.isListDisplay = this.decideListorCards();
    }

    decideListorCards(): boolean {
        if (this.widgetWidth >= 4) {
            if (this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") {
                //$('#freqwrapper').height("100%");
                //$('#freqwrapperin').height("100%");
                return true;
            }
            else {
                //$('#freqwrapper').height("auto");
                //$('#freqwrapperin').height("auto");
                return false;
            }
            
        }
        else {
            //$('#freqwrapper').height("100%");
            //$('#freqwrapperin').height("100%");
            return true;
        }
    }

    getEnrollCardCount() {
        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

            let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).clientWidth - 47;
            this.rowCardCount = Math.floor(pxwidth / 162);//Integer is the full width of the card, including margins, borders, and padding

            let heightMultiplier = 1;      
            let pxheight = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).clientHeight - 51;
            heightMultiplier = Math.floor(pxheight / 136);
            if (heightMultiplier == 0) {
                heightMultiplier = 1;
            }
            this.rowCardCount = this.rowCardCount * heightMultiplier;
        }
        else {
            this.rowCardCount =  0;
        }
    }

    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";
        $('#' + someId + "frequentUsedBlockContainer").removeClass("small-card-container-singlerow");

        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        $('#' + someId + "frequentUsedBlockContainer").addClass("small-card-container-singlerow");
        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    getContentUrl(r: Content): string {
        let url: string = "#";
        if (r.contentType == 5) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=workflow";
        }
        else if (r.contentType == 6) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=process";
        }
        else if (r.contentType == 7) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=task";
        }
        else if (r.contentType == 8) {
            url += "/workflowviewer?id=" + r.contentId + "&contentType=step";
        }
        else if (r.contentType == 0) {
            url += "/cheatsheetviewer?id=" + r.contentId;
        }
        else if (r.contentType == 4) {
            url += "/videoviewer?id=" + r.contentId;
        }
        else if (r.contentType == 1) {
            url += "/learningcenter/series?learningPathId=" + r.contentId;
        }
        else if (r.contentType == 2) {
            //if (this.currentEnrollments != null) {
            //    if (this.currentEnrollments.findIndex(course => course.courseId == r.contentId) == -1) {
            //        url += "/learningcenter/series?courseId=" + r.contentId;
            //    }
            //    else {
            //        url += "/learningcenter/courseviewer?enrollmentId=" + this.currentEnrollments[this.currentEnrollments.findIndex(course => course.courseId == r.contentId)].enrollmentId;
            //    }
            //}


        }

        return url;
    }

    getContentImage(r: Content): string {
        let image: string = "";

        image += ProdGenApi.getIconImageFromType(r.contentType);

        //image = "assets/images/";

        if (r.bisCustom == false) {
            //      if (r.contentType == ContentType_T.cheatsheet && r.docURL != null && r.docURL != "") {
            //          if (r.docURL.includes(".pdf")) {
            //              image += "PDF Icon.png"
            //          }
            //          else if (r.docURL.includes(".doc")) {
            //              image += "Word Icon.png"
            //          }
            //          else if (r.docURL.includes(".xls")) {
            //              image += "Excel Icon.png"
            //          }
            //          else if (r.docURL.includes(".ppt")) {
            //              image += "PowerPoint Icon.png"
            //          }
            //          else if (r.docURL.includes(".htm")) {
            //              image += "HTML Icon.png"
            //          }
            //      }
            //      else {
            //          image += ProdGenApi.getIconImageFromType(r.contentType);
            //      }
        }
        else {
            image = "fas fa-id-badge";
        }


        return image;
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);


        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "PersonalResourceAccessWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage
        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }




    createProperties() {

        super.createProperties();


        super.createPropertiesDisplayType("overlay");

        super.createPropertiesWidgetTitle("MY FREQUENTLY USED ASSETS");
        super.createPropertiesMobileOptions();


        //let range = new WidgetProperties();
        //range.propName = "Range (in days)"
        //range.propValue = this.timeSpan.toString();
        //range.propType = "number";
        //range.propID = "CHART_RANGE";
        //this.widgetProps.push(range);
    }

    //END WIDGET REQUIRED CALLS


    //For Share DropDown
    buildItem(itemID: string, itemName: string, itemType: ContentType_T): Content {
        let constructedContent = new Content();
        constructedContent.contentId = itemID;
        constructedContent.name = itemName;
        constructedContent.contentType = itemType;

        return constructedContent;
    }

    //For Share DropDown
    hoverElem(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        this.currentHover = content.contentId.toString();

        if (this.currentHover != this.shareClickId) {
            this.shareClickId = "";
        }
    }

    //For Share DropDown
    shareClick(content: Content) {
        this.constructedContent = this.buildItem(content.contentId.toString(), content.name.toString(), content.contentType);
        if (this.shareClickId == content.contentId.toString()) {
            this.shareClickId = "";
        }
        else {
            this.shareClickId = content.contentId.toString();
        }

        let tempdiv = document.getElementById('contentScrollArea' + this.widgetID);

        let box = { left: 0, top: 0 };
        box = tempdiv.getBoundingClientRect();


        let reservedSpotShare = document.getElementById('shareIconPlacment' + this.widgetID + content.contentId);

        let box2 = { left: 0, top: 0 };
        box2 = reservedSpotShare.getBoundingClientRect();

        let x = (box.left - box2.left);
        let y = (box.top - box2.top);
        //console.log('x : ' + x + '   y : ' + y);


        if (x > -250) {
            this.shareAdjustmentX = (20) + 'px';
        }
        else {
            this.shareAdjustmentX = ((-1 * x) - 220) + 'px';
        }

        this.shareAdjustmentY = ((-1 * y) + 70) + 'px';

    }

    //For Share DropDown
    onHideShareDropDown(event) {
        this.shareClickId = "";
    }

    //For Share DropDown
    exitHoverElem() {
        if (this.shareClickId == "") {
            this.currentHover = "";
        }
    }
}

import { Component, ChangeDetectorRef, ApplicationRef  } from '@angular/core';
import { Injectable } from '@angular/core';
import { OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { ProdGenApi } from './../apiService/prodgen.api';
import {
    SearchResults,
    SearchResult,
    SearchFacet,
  FacetValue,
  SearchConnector
} from './../apiService/classFiles/class.search';

import {Content, ContentType_T} from './../apiService/classFiles/class.content';
import { Workgroup, WorkgroupMember, PermissionLevel_T } from './../apiService/classFiles/class.workgroups';
import { Enrollment, EnrollmentFilter_T } from '../apiService/classFiles/class.enrollments';


declare var $: any;


@Component({
	selector: 'search-screen',
	templateUrl: 'search.component.html',
	styleUrls: ['search.component.css'],
    providers: [ProdGenApi],
    moduleId: module.id,
})
export class SearchComponent implements OnInit, AfterViewInit {
    public searchTerms: string = "";
    public searchResults: SearchResults = new SearchResults();
    public static savedResults: SearchResults = new SearchResults();
    public static savedSearchTerms: string = "";
    public initialCategory: string = "";
    public initialPublisher: string = "";
    public initialTypes: string[] = null;
    public lastFacetClicked: string = "";
  public mustStartSpinner = false;
  public searchConnectors: Array<SearchConnector> = new Array<SearchConnector>();
    editorWorkgroups: Array<Workgroup> = new Array<Workgroup>();
	nestedResults: Array<SearchResultTree> = new Array<SearchResultTree>();
    isAuthenticated: boolean = false;
    hasSearchBeenRun: boolean = false;
  useExtendedSearch: boolean = false;
  hasChildren: boolean = false;
  extendedSearchTitle: string = "";
	extendedSearchDescription: string = "";
    extendedSearchURL: string = "";

    resizeID: NodeJS.Timer;

    completedEnrollmentsForUser: Array<Enrollment> = new Array<Enrollment>();
  
  constructor(private service: ProdGenApi, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef, private location: Location, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private appref: ApplicationRef) {
		if (authenticationService.AuthenticatePage() == true) {
			this.isAuthenticated = true;
			this.searchResults.results = new Array<SearchResult>();
            this.searchResults.facets = new Array<SearchFacet>();
            
			this.service.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor]).subscribe(res => {
				this.editorWorkgroups = res;
            });

            //this.service.getCurrentUser().subscribe(user => { 
            //let bAllowApps = this.service.getAllowApplicationSearch();
            //if (bAllowApps == true) {
            //    this.service.getSearchConnectors().subscribe(res => {
            //        this.searchConnectors = res;
            //    });
            //    }
            //});

      }
	}

    ngOnInit(): void {
         
        this.route.queryParams.subscribe(queryParams => {
            this.initialCategory = queryParams['category'];
            this.searchTerms = queryParams['searchTerms'];
			this.initialTypes = queryParams['contentType'];
            this.initialPublisher = queryParams['publisher'];

			this.service.GetCurrentTenantSettings().subscribe(res => {
                this.service.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(completedenrollres => {
                    this.completedEnrollmentsForUser = completedenrollres;                    
                
				    let v_UseExtendedSearch = false;
				    let v_Title = "";
				    let v_Desc = "";
				    let v_URL = "";

				    res.forEach((setting) => {
				    	if (setting.name == "USE_EXT_SEARCH") {
                            let settingVal: string = setting.settingValue as string;
                            this.useExtendedSearch = (settingVal.toLowerCase()=="true");
				    	}
				    	else if (setting.name == "EXT_SEARCH_TITLE") {
				    		this.extendedSearchTitle = setting.settingValue as string;
				    	}
				    	else if (setting.name == "EXT_SEARCH_DESC") {
				    		this.extendedSearchDescription = setting.settingValue as string;
				    	}
				    	else if (setting.name == "EXT_SEARCH_URL") {
				    		this.extendedSearchURL = setting.settingValue as string;
				    	}


				    });		


                    if (this.initialTypes != null && this.initialTypes.length > 0)
                    {
                        let facet: SearchFacet = new SearchFacet();
                        facet.name = "Assets";
                        facet.field = "restype";
                        facet.values = new Array<FacetValue>();

                        this.mustStartSpinner = true;

                        for (var i = 0; i < this.initialTypes.length; i++)
                        {
                            let f = new FacetValue();
                            f.name = this.initialTypes[i];
                            f.fieldValue = this.initialTypes[i];
                            f.isActive = true;
                            facet.values.push(f);
                        }

                        let facets: SearchFacet[] = [facet];


                        this.service.searchContent(this.fixSearchTerms(this.searchTerms), facets).subscribe(results => {
                            this.hasSearchBeenRun = true;
				        	
                              this.searchResults = new SearchResults();
                              this.searchResults.facets = this.weedFacets(results.facets);

                              // group the results by workflow or learning path. this fills in the this.searchResults.results
                              this.groupResults(results);
                            
                              SearchComponent.savedResults = this.searchResults;
                              SearchComponent.savedSearchTerms = this.searchTerms;


                              for (var i = 0; i < results.facets.length; i++)
                              {
                                  if (results.facets[i].field == "restype")
                                  {
                                      for (var j = 0; j < results.facets[i].values.length; j++) {
                                          if (this.initialTypes.indexOf(results.facets[i].values[j].fieldValue)!= -1)
                                          {
                                              results.facets[i].values[j].isActive = true;
                                          }
                                      }
                                  }
                              }
				        	  this.stopSpinner();
                        });
                         
                    }

                    else if (this.initialCategory != null && this.initialCategory.length > 0) {
                        let facet: SearchFacet = new SearchFacet();
                        facet.name = "Libraries";
                        facet.field = "products";
                        facet.values = new Array<FacetValue>();
                        let f = new FacetValue();
                        f.name = this.initialCategory;
                        f.fieldValue = this.initialCategory;
                        f.isActive = true;
                        facet.values.push(f);


                        let facets: SearchFacet[] = [facet];

                        if (this.initialPublisher != null && this.initialPublisher.length > 0) {
                            let facetP: SearchFacet = new SearchFacet();
                            facetP.name = "Publisher";
                            facetP.field = "publisher";
                            facetP.values = new Array<FacetValue>();
                            let f = new FacetValue();
                            f.name = this.initialPublisher;
                            f.fieldValue = this.initialPublisher;
                            f.isActive = true;
                            facetP.values.push(f);
                            facets.push(facetP)

                        }

                        this.mustStartSpinner = true;
                        this.service.searchContent(this.fixSearchTerms(this.searchTerms), facets).subscribe(results => {
                            this.hasSearchBeenRun = true;
                            this.searchResults = new SearchResults();
                            this.searchResults.facets = this.weedFacets(results.facets);

                            // group the results by workflow or learning path. this fills in the this.searchResults.results
                            this.groupResults(results);

                            SearchComponent.savedResults = this.searchResults;
                            SearchComponent.savedSearchTerms = this.searchTerms;

                            for (var i = 0; i < results.facets.length; i++)
                            {
                                if (results.facets[i].field == "products")
                                {
                                    for (var j = 0; j < results.facets[i].values.length; j++)
                                    {
                                        if (results.facets[i].values[j].fieldValue == this.initialCategory)
                                        {
                                            results.facets[i].values[j].isActive = true;
                                        }
                                    }

                                }
                            }
                            this.stopSpinner();
                        });
                  
                    }
                    else if (this.searchTerms != null && this.searchTerms.length > 0) {
                         if (this.searchTerms == SearchComponent.savedSearchTerms && SearchComponent.savedResults != null && SearchComponent.savedResults) {
                             this.searchResults = SearchComponent.savedResults;
                             this.searchTerms = SearchComponent.savedSearchTerms;
                         }
                         else {
                             this.mustStartSpinner = true;
                             this.onSearch();
                         }
                    }
                    else
                    {
                         if (SearchComponent.savedResults != null) {
                             this.searchResults = SearchComponent.savedResults;
                             this.searchTerms = SearchComponent.savedSearchTerms;
                         }
                    }
                });     
         
            });
        });

     

    }

    ngAfterViewInit() {
        var searchbox = document.getElementById('search-box');
        if (searchbox != null) {
            searchbox.focus();
        }
        if (this.mustStartSpinner == true) {
            this.startSpinner();
        }

    }

    fixSearchTerms(terms: string): string {
        if (terms == null)
        {
            return terms;
        }

        return terms.replace("/", " ").replace("\\", " ");
    }

    static encodeSearchParameters(params: string): string
    {

        if (params == null) {
            return params;
        }
        params = params.trim()
            .replace("/", " ")
            .replace("\\", " ")

        let url = encodeURI(params);

        //let url = params.trim()
        //    .replace("&", "%26")
        //    .replace("/", " ")
        //    .replace("\\", " ")
        //    .replace("*", " ")
        //    .replace(" ", "%20");
            
        return url;
    }

    static clearSavedResults()
    {
        SearchComponent.savedResults = new SearchResults();
        SearchComponent.savedSearchTerms = "";
    }

    weedFacets(facets:SearchFacet[]):SearchFacet[]
    {
        let i = 0;
        let wfcount = 0;
        let lpcount = 0;


        i = facets.findIndex(r => r.field == 'restype');
        if (i != -1) {
            let facet = facets[i];

            i = facet.values.findIndex(f => f.fieldValue == "PS_Step");
            if (i != -1) {
                wfcount += facet.values[i].count;
            }
            i = facet.values.findIndex(f => f.fieldValue == "PS_Task");
            if (i != -1) {
                wfcount += facet.values[i].count;
            }
            i = facet.values.findIndex(f => f.fieldValue == "PS_Process");
            if (i != -1) {
                wfcount += facet.values[i].count;
            }

            i = facet.values.findIndex(f => f.fieldValue == "PS_Course");
            if (i != -1) {
                lpcount += facet.values[i].count;
            }

            // now see if we have a workflow
            i = facet.values.findIndex(f => f.fieldValue == "PS_Workflow");
            if (i != -1) {
                facet.values[i].count += wfcount;
            }
            else if (wfcount > 0) {
                let newVal = new FacetValue();
                newVal.count = wfcount;
                newVal.fieldValue = "PS_Workflow";
                newVal.name = "Workflow";
                newVal.indentLevel = 0;
                facet.values.push(newVal);

            }

            i = facet.values.findIndex(f => f.fieldValue == "PS_LearningPath");
            if (i != -1) {
                facet.values[i].count += lpcount;
            }
            else if (lpcount > 0) {
                let newVal = new FacetValue();
                newVal.count = lpcount;
                newVal.fieldValue = "PS_LearningPath";
                newVal.name = "Learning Path";
                newVal.indentLevel = 0;
                facet.values.push(newVal);

            }
        }

        let sourceIndex = facets.findIndex(r => r.field == 'subid');
        if (sourceIndex != -1) {
            let facetSource = facets[sourceIndex];
            if (facetSource != null && facetSource.values.length < 2) {
                facets.splice(sourceIndex, 1);
            }
        }

        // if anything was passed in initially, we want to know out those facet types since they are locked in
        if (this.initialCategory != null && this.initialCategory.length > 0)
        {
            i = facets.findIndex(r => r.field == 'products');
            if (i != -1)
            {
                // remove any product not this one
                for (var v = 0; v < facets[i].values.length; v++)
                {
                    if (facets[i].values[v].fieldValue.startsWith(this.initialCategory) == false)
                    {
                        facets[i].values.splice(v, 1);
                        v--;
                    }
                }
            }
        }
        if (this.initialTypes != null && this.initialTypes.length > 0)
        {
            i = facets.findIndex(r => r.field == 'restype');
            if (i != -1) {
                facets.splice(i, 1);
            }
        }

        for (var a = 0; a < facets.length; a++) {
            for (var b = 0; b < facets[a].values.length; b++) {
                if (facets[a].values[b].name == "Cheat Sheet") {
                    facets[a].values[b].name = "Document";
                }
                
            }
        }

        return facets;
    }

    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }

    getLockedFacets(): SearchFacet[] 
    {
        if (this.initialTypes != null && this.initialTypes.length > 0) {
            let facet: SearchFacet = new SearchFacet();
            facet.name = "Assets";
            facet.field = "restype";
            facet.values = new Array<FacetValue>();


            for (var i = 0; i < this.initialTypes.length; i++) {
                let f = new FacetValue();
                f.name = this.initialTypes[i];
                f.fieldValue = this.initialTypes[i];
                f.isActive = true;
                facet.values.push(f);
            }

            let facets: SearchFacet[] = [facet];
            return facets;
        }
        else if (this.initialCategory != null && this.initialCategory.length > 0) {
            let facet: SearchFacet = new SearchFacet();
            facet.name = "Libraries";
            facet.field = "products";
            facet.values = new Array<FacetValue>();
            let f = new FacetValue();
            f.name = this.initialCategory;
            f.fieldValue = this.initialCategory;
            f.isActive = true;
            facet.values.push(f);

            let facets: SearchFacet[] = [facet];

            if (this.initialPublisher != null && this.initialPublisher.length > 0) {
                let facet: SearchFacet = new SearchFacet();
                facet.name = "Publisher";
                facet.field = "publisher";
                facet.values = new Array<FacetValue>();
                let f = new FacetValue();
                f.name = this.initialPublisher;
                f.fieldValue = this.initialPublisher;
                f.isActive = true;
                facet.values.push(f);
                facets.push(facet);
            }
            return facets;
        }



        return null;
    }

    onSearch(): void {
        let url: string = "/";
        //if (this.searchTerms == null || this.searchTerms.trim().length == 0)
        //{
        //    return;
        //}
        if (this.searchTerms == null)
        {
            this.searchTerms = "";
        }
        this.startSpinner();


        url += "search?";
        let nParams = 0;
        if (this.searchTerms.trim().length > 0) {
            nParams++;
            url += "searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim());
        }
        if (this.initialTypes != null && this.initialTypes.length > 0)
        {
            for (let it of this.initialTypes)
            {
                if (nParams > 0) {
                    url += "&";
                }
                url += "contentType=" + it;
                nParams++;
            }
        }

        if (this.initialCategory != null && this.initialCategory.length > 0) {
            if (nParams > 0) {
              url += "&";
            }
            url += "category=" + SearchComponent.encodeSearchParameters(this.initialCategory);
            nParams++;
        }
        //url = decodeURI(url);
        //url = encodeURI(url);
        this.location.go(url);


        this.service.searchContent(this.fixSearchTerms(this.searchTerms), this.getLockedFacets()).subscribe(results => {
            this.hasSearchBeenRun = true;
            this.searchResults = new SearchResults();
            this.searchResults.facets = this.weedFacets(results.facets);
            // group the results by workflow or learning path. this fills in the this.searchResults.results
            this.groupResults(results);
            SearchComponent.savedResults = this.searchResults;
          SearchComponent.savedSearchTerms = this.searchTerms;
          
            this.stopSpinner();
            this.onFacetDivChange();
        }, (err => {
                console.log(err);
			this.stopSpinner();
		}));
      ;




      
    }


    getParentResult(parentId: string, parentType: ContentType_T, parentName: string, childResult:SearchResult, createIfMissing = true): SearchResultTree {
        // check if the task this is a part of exists in the results. if it does, move it above this result. if it does not, create it above this result
        let parentIndex: number = this.nestedResults.findIndex(f => f.resultInfo.contentid == parentId);
        if (parentIndex != -1) {
            // found it

            if (parentType == ContentType_T.learningpath) {
                for (let product of childResult.products) {
                    if (this.nestedResults[parentIndex].resultInfo.products.indexOf(product) == -1) {
                        this.nestedResults[parentIndex].resultInfo.products.push(product);
                    }
                }
            }
            return this.nestedResults[parentIndex];

        }
        else if (createIfMissing==true){
            // create the task
            let r: SearchResultTree = new SearchResultTree();
            r.resultInfo = new SearchResult();
            r.resultInfo.contentType = parentType;
            r.resultInfo.indent = 0;
            r.resultInfo.contentid = parentId;

            r.resultInfo.name = parentName;
            r.resultInfo.wfid = childResult.wfid;
            r.resultInfo.prid = childResult.prid;
            r.resultInfo.taskid = childResult.taskid;
            r.resultInfo.lpid = childResult.lpid;

            r.resultInfo.wf_name = childResult.wf_name;
            r.resultInfo.pr_name = childResult.pr_name;
            r.resultInfo.task_name = childResult.task_name;
            r.resultInfo.lp_name = childResult.lp_name;
            r.resultInfo.products = childResult.products;

            r.resultInfo.orig_rank = 9999;

            r.origRank = 9999;
            r.curRank = 9999;
            r.children = new Array<SearchResultTree>();
            r.resultInfo.publisher = childResult.publisher;
            this.nestedResults.push(r);
            return r;
        }

        return null;
    }

    getRelevenceCount(index:number): number[]
    {
        let counts = new Array<number>();

        if (this.searchResults.results[index].contentType != ContentType_T.workflow &&
            this.searchResults.results[index].contentType != ContentType_T.process &&
            this.searchResults.results[index].contentType != ContentType_T.task &&
            this.searchResults.results[index].contentType != ContentType_T.step &&
            this.searchResults.results[index].contentType != ContentType_T.course &&
            this.searchResults.results[index].contentType != ContentType_T.learningpath)
        {
            return counts;
        }

        let count = 0;
        let rank:number = this.searchResults.results.length - this.searchResults.results[index].orig_rank;
        let levels: number = this.searchResults.results.length / 5.0;
        count = Math.round(rank / levels);
        for (var i = 0; i < count; i++)
        {
            counts.push(i);
        }
        return counts;
    }

    needsResultSpacer(resultIndex: number): boolean
    {
        let prevIndex = resultIndex - 1;
        if (resultIndex == 0) { return false; }

        if ((this.searchResults.results[resultIndex].indent == 0) ||
            (this.searchResults.results[resultIndex].indent < this.searchResults.results[prevIndex].indent) )
        {
            return true;
        }

        return false;
    }

    groupResults(results:SearchResults) {
        // we will group workflow items together
        let res = results.results;
        this.nestedResults = new Array<SearchResultTree>();

        // go through and set current rankings
        for (var i = 0; i < res.length; i++) {
            let cur: SearchResultTree = new SearchResultTree();
            cur.children = new Array<SearchResultTree>();
            cur.origRank = i;
            cur.curRank = i;
            cur.resultInfo = res[i];
            cur.resultInfo.indent = 0;
            cur.resultInfo.orig_rank = i;

            if (cur.resultInfo.contentType == ContentType_T.course) {
                let itemindex = -1;
                if (this.completedEnrollmentsForUser.findIndex(x => x.courseId == cur.resultInfo.contentid) != -1) {
                    itemindex = this.completedEnrollmentsForUser.findIndex(x => x.courseId == cur.resultInfo.contentid);
                }
                if (itemindex != -1) {
                    cur.resultInfo.isCompleted = true;                    
                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    let date = new Date(this.completedEnrollmentsForUser[itemindex].statusDate);
                    cur.resultInfo.completedDate = date.toLocaleDateString(undefined, options);
                } 
            }

            this.nestedResults.push(cur); 
        }
        
        // now go through and place all steps into tasks. create if necessary
        // STEPS
        for (var i = 0; i < this.nestedResults.length; i++) {
            if (this.nestedResults[i].resultInfo.contentType == ContentType_T.step) {
                let parent = this.getParentResult(this.nestedResults[i].resultInfo.taskid, ContentType_T.task, this.nestedResults[i].resultInfo.task_name, this.nestedResults[i].resultInfo, true);
                if (parent != null)
                {
                    // add as child

                    //clear publisher in children
                    this.nestedResults[i].resultInfo.publisher = "";

                    parent.children.push(this.nestedResults[i]);
                    parent.curRank = Math.min(parent.curRank, this.nestedResults[i].curRank); 
                    // remove the current item from the array
                    this.nestedResults.splice(i, 1);

                    i--;
                }
                else
                {
                    
                }
            }
        }
        // TASKS
        for (var i = 0; i < this.nestedResults.length; i++) {
            if (this.nestedResults[i].resultInfo.contentType == ContentType_T.task) {
                let parent = this.getParentResult(this.nestedResults[i].resultInfo.prid, ContentType_T.process, this.nestedResults[i].resultInfo.pr_name, this.nestedResults[i].resultInfo, true);
                if (parent != null) {
                    // add as child

                    //clear publisher in children
                    this.nestedResults[i].resultInfo.publisher = "";

                    parent.children.push(this.nestedResults[i]);
                    parent.curRank = Math.min(parent.curRank, this.nestedResults[i].curRank);
                    // remove the current item from the array
                    this.nestedResults.splice(i, 1);
                    i--;
                }
            }
        }
        // PROCESSES
        for (var i = 0; i < this.nestedResults.length; i++) {
            if (this.nestedResults[i].resultInfo.contentType == ContentType_T.process) {
                let parent = this.getParentResult(this.nestedResults[i].resultInfo.wfid, ContentType_T.workflow, this.nestedResults[i].resultInfo.wf_name, this.nestedResults[i].resultInfo, false);
                if (parent != null) {
                    // add as child

                    //clear publisher in children
                    this.nestedResults[i].resultInfo.publisher = "";

                    parent.children.push(this.nestedResults[i]);
                    parent.curRank = Math.min(parent.curRank, this.nestedResults[i].curRank);
                    // remove the current item from the array
                    this.nestedResults.splice(i, 1);
                    i--;
                }
            }
        }

        // COURSES
        for (var i = 0; i < this.nestedResults.length; i++) {
            if (this.nestedResults[i].resultInfo.contentType == ContentType_T.course) {
                let parent = this.getParentResult(this.nestedResults[i].resultInfo.lpid, ContentType_T.learningpath, this.nestedResults[i].resultInfo.lp_name, this.nestedResults[i].resultInfo, true);
                if (parent != null) {
                    // add as child

                    //clear publisher in children
                    this.nestedResults[i].resultInfo.publisher = "";

                    parent.children.push(this.nestedResults[i]);
                    parent.curRank = Math.min(parent.curRank, this.nestedResults[i].curRank);
                    // remove the current item from the array
                    this.nestedResults.splice(i, 1);
                    i--;
                }
            }
        }

        // sort the array based on the ranking
        this.nestedResults = this.nestedResults.sort((r1, r2) => { return r1.curRank - r2.curRank });
        this.searchResults.results = new Array<SearchResult>();

        if (this.useExtendedSearch == true) {
			var v_ExtendedVal = new SearchResult();
			v_ExtendedVal.desc = this.extendedSearchDescription;
			v_ExtendedVal.name = this.extendedSearchTitle;
			//v_ExtendedVal.name = `<span style="background-color:#fffdb5; font-weight:700; pointer-events:none;">${this.extendedSearchTitle}</span>`;
			//v_ExtendedVal.name = `<mark style="background-color:#fffdb5; font-weight:700; color:#337AB7">${this.extendedSearchTitle}</mark>`;
			v_ExtendedVal.path = this.extendedSearchURL;
			v_ExtendedVal.contentType = ContentType_T.extendedSearch;
			//v_ExtendedVal.imageUrl = "../assets/images/quiz.svg";

			this.searchResults.results.push(v_ExtendedVal);
		}


		//ForTesting
		//if (this.nestedResults.length > 0) {
		//}


        // now build the actual result list
        for (var i = 0; i < this.nestedResults.length; i++)
        {
            this.searchResults.results.push(this.nestedResults[i].resultInfo);
            if (this.nestedResults[i].children.length > 0)
            {
              this.nestedResults[i].resultInfo.indent == 0 ? this.nestedResults[i].resultInfo.hasChildren = true :
                                                             this.nestedResults[i].resultInfo.hasChildren = false;
              this.addChildResults(this.nestedResults[i].children, 1);
            }
        }
    }

  addChildResults(r: Array<SearchResultTree>, nestLevel: number)
  {
    // first sort children by name (number should be out front)
      //var reN = /\D/g;
      //r = r.sort(function(r1,r2) {
    //return parseInt(r1.resultInfo.name.replace(reN, ""), 10) - parseInt(r2.resultInfo.name.replace(reN, ""), 10);
      r = r.sort((r1, r2) => {
        if (r1.resultInfo.name > r2.resultInfo.name) {
          return 1;
        }
        else if (r1.resultInfo.name < r2.resultInfo.name) {
          return -1;
        }
          return 0;
      });

      
      // 
    for (var i = 0; i < r.length; i++) {
      r[i].resultInfo.indent = nestLevel;
      this.searchResults.results.push(r[i].resultInfo);
      if (r[i].children.length > 0) {
          this.addChildResults(r[i].children, nestLevel + 1);
        }
      }
  }

  displayToggleResults(r: Array<SearchResult>, index: number): string {
    var stringArr = new Array<string>();

    if (r[index].indent == 0) {
      for (var i = index + 1; i < r.length; i++) {
        if (r[i].indent == 0)
            break;
        stringArr.push("#searchresult" + i);
      }
      return stringArr.join();
    }
  }
  

    getResultAsContentItem(r: SearchResult): Content {
        let c: Content = new Content();
        c.contentId = r.contentid;
        c.contentType = r.contentType;
        c.name = r.name;
        c.name = String(c.name).replace(/<[^>]+>/gm, '')
        return c;
    }

    getResultUrl(r: SearchResult): string { 
        let url: string = "./#/";
		if (r.contentType == ContentType_T.workflow) {
			url += "workflowviewer?id=" + r.contentid + "&contentType=workflow";
		}
		else if (r.contentType == ContentType_T.process) {
			url += "workflowviewer?id=" + r.contentid + "&contentType=process";
		}
		else if (r.contentType == ContentType_T.task) {
			url += "workflowviewer?id=" + r.contentid + "&contentType=task";
		}
		else if (r.contentType == ContentType_T.step) {
			url += "workflowviewer?id=" + r.contentid + "&contentType=step";
		}
		else if (r.contentType == ContentType_T.cheatsheet) {
			url += "cheatsheetviewer?id=" + r.contentid;
		}
		else if (r.contentType == ContentType_T.video) {
			url += "videoviewer?id=" + r.contentid;
		}
		else if (r.contentType == ContentType_T.course) {
			url += "learningcenter/series?courseId=" + r.contentid;
		}
		else if (r.contentType == ContentType_T.learningpath) {
			url += "learningcenter/series?learningPathId=" + r.contentid;
		}
		else if (r.contentType == ContentType_T.chatLog) {
			url += "chatviewer?id=" + r.contentid;
		}
		else if (r.contentType == ContentType_T.extendedSearch) {
			url = r.path + this.searchTerms;
		}

        return url;
    }

    getResultType(r: SearchResult): string {
        let image: string = "";

        switch (r.contentType) {
            case ContentType_T.workflow:
                image = "Workflow";
                break;
            case ContentType_T.process:
                image = "Process";
                break;
            case ContentType_T.task:
                image = "Task";
                break;
            case ContentType_T.step:
                image = "Step";
                break;
            case ContentType_T.cheatsheet:
                image = "Document";
                break;
            case ContentType_T.chatLog:
                image = "Saved Chat";
                break;
            case ContentType_T.video:
                image = "Video";
                break;
            case ContentType_T.course:
                image = "Course";
                break;
            case ContentType_T.learningpath:
                image = "Learning Path";
				break;
			case ContentType_T.extendedSearch:
				image = "Extended Search";
				break;
        }
        return image;
    }


    getResultImage(r: SearchResult): string {
        let image: string = "";

        image = "assets/images/";

        if (r.biscustom == false) {
            if (r.contentType == ContentType_T.cheatsheet && r.url != null && r.url != "") {
                if (r.url.includes(".pdf")) {
                    image += "PDF Graphic.png"
                }
                else if (r.url.includes(".doc")) {
                    image += "Word Graphic.png"
                }
                else if (r.url.includes(".xls")) {
                    image += "Excel Graphic.png"
                }
                else if (r.url.includes(".ppt")) {
                    image += "PowerPoint Graphic.png"
                }
                else if (r.url.includes(".htm")) {
                    image += "HTML Graphic.png"
                }
            }
            else {
                image += ProdGenApi.getLargeDefaultImageFromType(r.contentType);
                if ((image.indexOf("default") == -1) && (image.indexOf("extended_search.jpg") == -1)) {
                    image = image.replace("assets/images/", "");
                }
            }
        }
        else {
            try {
                image = localStorage.getItem(ProdGenApi.getCurrentTenantId + "_MyDocsImage");
            } catch (e) {

            }
            if (image == "" || image == null) {
                image = "assets/images/My Cheat Sheets Graphic.png";
            }
        }
        return image;
    }


	getResultTarget(r: SearchResult): string {
		let target: string = "_self";
		if (r.contentType == ContentType_T.extendedSearch) {
			target = "_blank";
		}

		return target;
	}

    getFacetValueChildIndexes(facet: SearchFacet, value: FacetValue): Array<number> {
        let indices = new Array<number>();
        let start = this.getFacetValueIndex(facet, value);
        if (start != -1 && start < (facet.values.length-1))
        {
            for (var i = start+1; i < facet.values.length; i++)
            {
                if (facet.values[i].indentLevel == 1)
                {
                    indices.push(i);
                }
                else
                {
                    break;
                }
            }
        }
        return indices;
    }

    getFacetValueParentIndex(facet: SearchFacet, value: FacetValue): number {
        let index = -1;
        let start = this.getFacetValueIndex(facet, value);
        if (start > 0 && facet.values[start].indentLevel ==1) {
            for (var i = start - 1; i >= 0; i--) {
                if (facet.values[i].indentLevel == 0) {
                    return i;
                }
            }
        }
        return index;
    }

    getFacetValueIndex(facet: SearchFacet, value: FacetValue): number
    {
        let index = facet.values.findIndex(v => v.fieldValue == value.fieldValue);
        return index;
    }

    setFacetChildrenCheck(facet: SearchFacet, value: FacetValue, checked: boolean)
    {
        if (value.indentLevel == 0)
        {
            let children = this.getFacetValueChildIndexes(facet, value);
            for (let i of children)
            {
                facet.values[i].isActive = checked;
            }
        }
    }

    uncheckParentFacets()
    {
        for (let f of this.searchResults.facets) {
            for (let v of f.values) {
                if (v.indentLevel == 1 && v.isActive == true) {
                    let index = this.getFacetValueParentIndex(f, v);
                    if (index != -1) {
                        f.values[index].isActive = false;
                    }
                }
            }
        }
    }

    checkParentFacets()
    {
        for (let f of this.searchResults.facets)
        {
            for (let v of f.values)
            {
                if (v.indentLevel == 1 && v.isActive == true)
                {
                    let index = this.getFacetValueParentIndex(f, v);
                    if (index != -1)
                    {
                        f.values[index].isActive = true;
                    }
                }
            }
        }
    }

    onFacetClicked(facet: SearchFacet, value: FacetValue, event: any): void {

        this.lastFacetClicked = facet.field;
        // if a parent is checked on, we should check on all children
        // if a parent is checked off, we should uncheck on all children
        this.setFacetChildrenCheck(facet, value, value.isActive);

        this.checkParentFacets();
        // if a child facet was clicked off, we should check to see if siblings are checked...
        // if there are none, we should add the parent back into the search if it is still checked
        this.uncheckParentFacets();
        this.startSpinner();
        if (value.fieldValue == "PS_Workflow") {
            let i = facet.values.findIndex(f => f.fieldValue == "PS_Step");
            if (i == -1) {
                let newVal = new FacetValue();
                newVal.count = 0;
                newVal.fieldValue = "PS_Step";
                newVal.isActive = value.isActive;
                newVal.name = "Step";
                facet.values.push(newVal);
            }
            else {
                facet.values[i].isActive = value.isActive;
            }

            i = facet.values.findIndex(f => f.fieldValue == "PS_Task");
            if (i == -1) {
                let newVal = new FacetValue();
                newVal.count = 0;
                newVal.fieldValue = "PS_Task";
                newVal.isActive = value.isActive;
                newVal.name = "Task";
                facet.values.push(newVal);
            }
            else {
                facet.values[i].isActive = value.isActive;
            }

            i = facet.values.findIndex(f => f.fieldValue == "PS_Process");
            if (i == -1) {
                let newVal = new FacetValue();
                newVal.count = 0;
                newVal.fieldValue = "PS_Process";
                newVal.isActive = value.isActive;
                newVal.name = "Process";
                facet.values.push(newVal);
            }
            else {
                facet.values[i].isActive = value.isActive;
            }

        }

        if (value.fieldValue == "PS_LearningPath") {
            let i = facet.values.findIndex(f => f.fieldValue == "PS_Course");
            if (i == -1) {
                let newVal = new FacetValue();
                newVal.count = 0;
                newVal.fieldValue = "PS_Course";
                newVal.isActive = value.isActive;
                newVal.name = "Course";
                facet.values.push(newVal);
            }
            else {
                facet.values[i].isActive = value.isActive;
            }
        }

        let allFacets = new Array<SearchFacet>();
        // first add in locked facets
        let lockedFacets = this.getLockedFacets();
        if (lockedFacets != null) {
            for (let lf of lockedFacets) {
                allFacets.push(lf);
            }
        }
        if (this.searchResults.facets != null) {
            for (let lf of this.searchResults.facets) {
                allFacets.push(lf);
            }
        }

        this.service.searchContent(this.fixSearchTerms(this.searchTerms), allFacets).subscribe(results =>
        {
            this.hasSearchBeenRun = true;
            let origFacets: SearchFacet[] = this.searchResults.facets;
            this.searchResults = new SearchResults();

            this.searchResults.facets = origFacets;
            // update facet value counts
            results.facets = this.weedFacets(results.facets);
            this.updateFacetCounts(this.searchResults.facets, results.facets, facet.field);

            // turn on all that should be based on last search

            this.checkParentFacets();

            // group the results by workflow or learning path. this fills in the this.searchResults.results
            this.groupResults(results);

            SearchComponent.savedResults = this.searchResults;
            SearchComponent.savedSearchTerms = this.searchTerms; 

            this.stopSpinner();

        }, (err => {
                this.stopSpinner();
                this.checkParentFacets();
        }));
        ;

    }

  connectorSearch(connector: SearchConnector) {
    // get the active command
    this.service.getSearchConnectors().subscribe(res => {
      for (let c of res) {
        if (c.appName == connector.appName) {
          if (c.searchTerm.length > 0) {
            this.searchTerms = c.searchTerm;
            this.onSearch();
          }
        }
      }
      this.searchConnectors = res;
    });
  }

  getFacetValueCount(facets: SearchFacet[], facetField, fieldValue): number {
        let count = 0;

        let facetIndex = facets.findIndex(f => f.field == facetField);
        if (facetIndex != -1)
        {
            let valIndex = facets[facetIndex].values.findIndex(i => i.fieldValue == fieldValue);
            if (valIndex != -1)
            {
                count = facets[facetIndex].values[valIndex].count;
            }

        }
        return count;
    }

    updateFacetCounts(origFacets: SearchFacet[], newFacets: SearchFacet[], clickedFacet:string)
    {
        let clickedIndex = origFacets.findIndex(c => c.field == clickedFacet);
        let count = 0;

        let areAnyActive = false;
        for (let origFacetValue of origFacets[clickedIndex].values) {
            if (origFacetValue.isActive == true) {
                areAnyActive = true;
                break;
            }
        }

        // for each original facet, see if it exist in new
        for (let origFacet of origFacets)
        {

            if (count <= clickedIndex)
            {
                if (count == clickedIndex) {
                    if (areAnyActive == true) {
                        count++;
                        continue;
                    }
                }
                else {
                    count++;
                    continue;
                }
            }
            // go through each value
            for (let origFacetValue of origFacet.values)
            {
                origFacetValue.count = this.getFacetValueCount(newFacets, origFacet.field, origFacetValue.fieldValue);
            }
            count++;
        }
    }


    onFacetDivChange()
    {
        if ($('searchResultsDiv') != null)
        {
            $('#searchResultDiv').css("min-height", $('#facet-panels').css("height"));
        }
    } 


    startSpinner() {

        if (!$("#spinnerModal").hasClass("in")) {

            ($("#spinnerModal") as any).modal("show"); 
        }
            

    }

    stopSpinner() {

        ($("#spinnerModal") as any).modal("hide");
        scroll(0, 0);
    }

  getSafeHtml(htmlText: string): SafeHtml {
        if (htmlText == null)
        {
            htmlText = "";
        }
        return this._sanitizer.bypassSecurityTrustHtml(htmlText);
    }

    onFacetHeaderClicked(f: SearchFacet)
    {
        this.getExpandedIconClass("#collapse_" + f.field);

        var isExpanded = $("#image_" + f.field).hasClass("fa-minus");
        $("#image_" + f.field).toggleClass("fa-minus");
        $("#image_" + f.field).toggleClass("fa-plus");
    }

    getExpandedIconClass(elmId: string): string {
        //var isExpanded = $(elmId).hasClass("in");
        //if (isExpanded == true) {
        //    return "fa fa-minus";
        //}
        return "fa fa-minus";

    }

    ShowMore(i: number) {
        if ($("#tagcontainertoggle" + i.toString()).text() == "Show More") {
            $("#tagcontainer" + i.toString()).css({
                'display': 'inline-block',
                'margin-left': '10px',
                'overflow': 'unset',
                'width': '37vw'
            });
            $("#tagcontainertoggle" + i.toString()).text("Show Less");
        }
        else {
            $("#tagcontainer" + i.toString()).css({
                'display': 'inline-block',
                'margin-left': '10px',
                'overflow': 'hidden',
                'width': '37vw',
                'height': '19px'
            });
            $("#tagcontainertoggle" + i.toString()).text("Show More");
        }
    }

    CanToggle(i: number): boolean {
        if ($("#tagcontainer" + i.toString()).prop("scrollHeight") <= 20) {
            return false;
        }
        else {
            return true;
        }
        
    }

    onResize(event) {
        //this.cdr.detectChanges();
        //this.appref.tick();

        clearTimeout(this.resizeID);
        this.resizeID = setTimeout(this.doneResizing, 500);
    }

    doneResizing() {
        //whatever we want to do
       
        
    }
}



export class SearchResultTree
{
    resultInfo: SearchResult;
    children: Array<SearchResultTree>
    origRank: number;
    curRank: number;
}


import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetProperties, User, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { Enrollment } from '../../../apiService/classFiles/class.enrollments';
import cssVars from 'css-vars-ponyfill';
import { AppComponent, Themes, currenttheme, defpreviewtheme } from '../../../app.component';
import { ContentType_T } from '../../../apiService/classFiles/class.content';
declare var $: any;


@Component({
  selector: 'app-my-courses-widget',
  templateUrl: './my-courses-widget.component.html',
  styleUrls: ['./my-courses-widget.component.css']
})
export class MyCoursesWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "MyCoursesWidgetComponent";

    widgetLoaded: boolean = false;

    currentEnrollments: Array<Enrollment> = new Array<Enrollment>();
    currentUser: User = new User();
    enrollmentsLoaded: boolean = false;
    displaycardcount: number = 0;
    condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
    selectedSort: string = "sortLP";
    cardType: string = "large";
    rowCardCount: number = 5;
    isExpanded: boolean = false;
    courseImage: string = "";
    widgetWidthSize4Under: boolean = true;
    

    showAllCourses: boolean = false;

    previewtheme = defpreviewtheme;
    useMobileHeight = true;
    mobileHeight = 3;

    displayType = "overlay";

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        let cImg = ProdGenApi.getLargeDefaultImageFromType(ContentType_T.course);

        if (cImg.indexOf("default") == -1) { this.courseImage = cImg; }
        else { this.courseImage = "../../assets/images/" + cImg; }

        //Widget Defaults
        this.widgetHeight = 7;
        this.widgetWidth = 8;
        this.widgetTitle= "MY COURSES";
        this.widgetName = "MY COURSES"
        this.widgetDesc = "Displays all the cards for the Courses in which you are enrolled.";
        //End Widget Defaults

    }

    ngOnInit() {
        super.ngOnInit();

        
        try {
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }
        
        this.createProperties();
        this.Load();
        window.addEventListener("resize", this.onResize.bind(this));
    }
    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings,doresequence);
        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.

        

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "MyCoursesWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }


        super.onPropsChangedLocalStorage();
        if (this.widgetWidth <= 4) {
            this.widgetWidthSize4Under = false;
        }
        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
    }


    Load() {
        super.Load();

        //The rest of the load function will be widget specific
        this.pinnacleService.getCurrentUser().subscribe(u => {
            this.currentUser = u;

            try {
                this.currentEnrollments = JSON.parse(localStorage.getItem(this.currentUser.userId + "_enrollments"));
            }
            catch (err) {
                console.log(err)
            }

            this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].imageURL == null || res[i].imageURL == "") {
                        let defaultenrollmentimage = "";

                        try {
                            defaultenrollmentimage = localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_EnrollmentImage");
                        }
                        catch (err) {
                        }

                        if (defaultenrollmentimage != null && defaultenrollmentimage != "") {
                            res[i].imageURL = defaultenrollmentimage;
                        }
                        else {
                            res[i].imageURL = "../assets/images/default_course.jpg";
                        }

                    }
                }
                this.currentEnrollments = res;
                //this.getEnrollCardCount(window.innerWidth);
                //copy and sort the array. flag the lowest squences with the number of other items with the same learning path. remove non-lowest sequence.
                //copy and sort the array.
                //let condensedEnrollments: Array<Enrollment> = new Array<Enrollment>();
                this.CondenseEnrollments();

                this.enrollmentsLoaded = true;


                /// write to Local Storage for this user
                try {
                    localStorage.setItem(this.currentUser.userId + "_enrollments", JSON.stringify(this.currentEnrollments))
                }
                catch (err) {
                    console.log(err);
                }
                //console.log("My courses - super cal");
                //super.widgetMobileHeightCalucaled(); //Let's Widget Container know that the right Mobile Height has been calculated
            });
        });

    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        //let range = new WidgetProperties();
        //range.propName = "Range (in days)"
        //range.propValue = this.timeSpan.toString();
        //range.propType = "number";
        //range.propID = "CHART_RANGE";
        //this.widgetProps.push(range);


        super.createPropertiesDisplayType();
        super.createPropertiesWidgetTitle("MY COURSES");


        super.createPropertiesMobileOptions();

    }

    //END WIDGET REQUIRED CALLS
    onResize() {
        super.onResize();
        this.calcCardSize();

    }
    onWidgetResized() {
        super.onWidgetResized();
        this.calcCardSize();
    }


    calcCardSize() {
        this.cardType = "large";
        if (this.widgetRenderSize == "lg" || this.widgetRenderSize == "md") {
            this.cardType = "large";
        }

        if ((this.widgetRenderSize == "sm" || this.widgetRenderSize == "xs") || window.innerWidth <= 992) {
            this.cardType = "small";
            
        }

        setTimeout(() => {
            this.rowCardCount = this.getEnrollCardCount();
        });
        

    }

    getEnrollCardCount() {

        if (document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq)) {

            let pxwidth = document.getElementById("widget_" + this.widgetID + "_" + this.widgetContainer.seq).offsetWidth;

            if (this.cardType == "small") {
                //take the mobile height of the widget, convert that to pixels, then divide by the height of the small cards to get the correct card count
                //let pxheight = this.widgetContainer.calcWidgetHeight(this.mobileHeight);
                //let widgetheaderpx = 40;
                //let smcardheight = 100;
                //return Math.floor((pxheight - widgetheaderpx) / smcardheight);
                if (this.condensedEnrollments.length > 6 && !this.showAllCourses) {
                    return 6;
                }
                else {
                    return this.condensedEnrollments.length;
                }
            }
            return Math.floor(pxwidth / 250);//Integer is the full width of the card, including margins, borders, and padding
        }
        else {
            return 0;
        }
    }

    calMobileHeight() {
        let currentHeight = this.mobileHeight;

        if (this.condensedEnrollments.length == 0) {
            if (this.widgetTitle.length == 0) {
                this.mobileHeight = 5;
            }
            else {
                this.mobileHeight = 6;
            }
        }
        //When there is one Workgroup
        else if (this.condensedEnrollments.length == 1) {
            if (this.widgetTitle.length == 0) {
                this.mobileHeight = 3
            }
            else {
                this.mobileHeight = 4;
            }
        }
        //Mutliple Workgroups
        else {
            if (this.condensedEnrollments.length <= 6 || this.showAllCourses) {
                this.mobileHeight = Math.ceil(this.condensedEnrollments.length * 1.9) + 2;
            }

            else if (this.condensedEnrollments.length > 6 && !this.showAllCourses) {
                    this.mobileHeight = Math.ceil(6 * 1.9) + 2;
            }            

            if (this.widgetTitle.length != 0) {
                this.mobileHeight = this.mobileHeight + 1;
            }
        }

        if (currentHeight != this.mobileHeight && window.innerWidth <= 992 && this.widgetLoaded) {
                this.widgetContainer.populateMainandFlyout();
                this.widgetContainer.onResize(null);
        }

        //console.log(this.mobileHeight);
    }


 

    mobileShowAllCourses(displayAllCourses:boolean) {
        this.showAllCourses = displayAllCourses;
        
        this.CondenseEnrollments();  
        if (window.innerWidth <= 992) {
            this.widgetContainer.populateMainandFlyout();
            this.widgetContainer.onResize(null);
        }

    }

    onCourseDropped(event) {
        let e: Enrollment = event.enrollment;
        if (e != null) {
            let index = this.currentEnrollments.findIndex(i => i.enrollmentId == e.enrollmentId);
            if (index != -1) {
                this.currentEnrollments.splice(index, 1);
                this.CondenseEnrollments();
            }
        }

    }

    CondenseEnrollments() {

        let copiedEnrollment: Enrollment = new Enrollment();
        this.condensedEnrollments.splice(0);
        for (var i = 0; i < this.currentEnrollments.length; i++) {
            copiedEnrollment = this.currentEnrollments[i];
            copiedEnrollment.childCourses = 0;
            this.condensedEnrollments.push(copiedEnrollment);
        }

        this.condensedEnrollments.sort((a: any, b: any) => {
            var x = a.learningPathId;
            var y = b.learningPathId;
            var w = a.sequence;
            var z = b.sequence;
            if (x < y) { return 1; }
            if (x > y) { return -1; }
            if (x == y) { //if the values are equal do a second level sort 

                if (w < z) { return -1; }
                if (w > z) { return 1; }
                return 0;
            }
        });

        // flag the lowest squences with the number of other items with the same learning path.

        for (var n = 0; n < this.condensedEnrollments.length; n++) {
            let d: number = 1;
            let childCourses: number = 1;
            if (n == 0) { //first item
                if (this.condensedEnrollments.length == 1) {
                    childCourses = 1;
                }
                else {
                    try {
                        while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {
                            childCourses++;
                            d++;
                        }
                    }
                    catch (err) { }
                }
            }
            else if ((n == this.condensedEnrollments.length - 1) && (this.condensedEnrollments[n].learningPathId != this.condensedEnrollments[n - 1].learningPathId)) { //last item. this is either a part of another path and has already been accounted for. otherwise it is its own
                childCourses = 1;
            }
            else if (this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n - 1].learningPathId) {
                childCourses = 0;
            }
            else {

                while ((n + d) < this.condensedEnrollments.length && this.condensedEnrollments[n].learningPathId == this.condensedEnrollments[n + d].learningPathId) {

                    childCourses++;
                    d++;
                }
            }

            this.condensedEnrollments[n].childCourses = childCourses;
        }



        //remove remove non-lowest sequences (i.e. those with no child courses).
        for (var e = 0; e < this.condensedEnrollments.length; e++) {
            if (this.condensedEnrollments[e].childCourses == 0) {
                this.condensedEnrollments.splice(e, 1);
                e--;
            }
        }



        this.selectedSort = sessionStorage.getItem("courseSort");

        if (this.selectedSort == null) { this.selectedSort = "sortLP"; }

        this.SortChange();

        setTimeout(() => {
            this.checkcssVars();
            this.calcCardSize();
        });
    
        this.calMobileHeight();

    }

    SortChange(): void {
        //this.currentEnrollments[0]
        switch (this.selectedSort) {
            case "sortDueDT_EtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) { //if the values are equal do a second level sort 

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortDueDT_LtoE": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.hasDueDate;
                    var y = b.hasDueDate;
                    var w = a.dueDate;
                    var z = b.dueDate;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    if (x == y) {

                        if (w < z) { return 1; }
                        if (w > z) { return -1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortLP": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.learningPathName;
                    var y = b.learningPathName;
                    var w = a.sequence;
                    var z = b.sequence;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    if (x == y) {

                        if (w < z) { return -1; }
                        if (w > z) { return 1; }
                        return 0;
                    }
                });
                break;
            }
            case "sortPerCom_LtoM": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
                break;
            }
            case "sortPerCom_MtoL": {
                sessionStorage.setItem("courseSort", this.selectedSort);
                this.condensedEnrollments.sort((a: any, b: any) => {
                    var x = a.percentComplete;
                    var y = b.percentComplete;
                    if (x < y) { return 1; }
                    if (x > y) { return -1; }
                    return 0;
                });
                break;
            }
            default:
        }
    }


    showMoreCaretDown(someId: string) {
        $('#' + someId).fadeIn(300);
        document.getElementById(someId + 'ShowMoreButton').style.display = "none";
        document.getElementById(someId + 'ShowLessButton').style.display = "block";
        document.getElementById(someId + 'enrollmentsLargeBlock').style.display = "block";
        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    showLessCaretUp(someId: string) {
        $('#' + someId).slideToggle(300);
        document.getElementById(someId + 'ShowLessButton').style.display = "none";
        document.getElementById(someId + 'ShowMoreButton').style.display = "block";
        document.getElementById(someId + 'enrollmentsLargeBlock').style.display = "none";
        this.widgetContainer.onWidgetCollapse(this.widgetID);
    }

    checkcssVars() {
        cssVars({
            rootElement: document,
            variables: {
                "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                "--font-face": currenttheme.properties["--font-face"],
                "--page-background-color": currenttheme.properties["--page-background-color"],
                "--page-text-color": currenttheme.properties["--page-text-color"],
                "--page-separator-color": currenttheme.properties["--page-separator-color"],
                "--page-separator-width": currenttheme.properties["--page-separator-width"],
                "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                "--panel-border-color": currenttheme.properties["--panel-border-color"],
                "--panel-border-width": currenttheme.properties["--panel-border-width"],
                "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                "--button-background": currenttheme.properties["--button-background"],
                "--button-foreground": currenttheme.properties["--button-foreground"]
            }
        });
    }
}

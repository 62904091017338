import { Location, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { Apiv2Service } from '../../apiService/apiv2.service';
import { EL_Activity, EL_Attribute, EL_AttributeDataType, YesNoSelectionList, EL_Record, EL_RecordStatus, EL_ActivityAttribute, GuidEmpty } from '../../apiService/classFiles/v2-externallearning';
import { TempFileResult } from '../../apiService/classFiles/v2-temp-file';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { User_Mini } from 'src/app/apiService/classFiles/v2-users';
import { DynamicInputBase, StringInput, NumberInput, DateInput, SelectionSetInput } from '../../templates/external-learning/dynamic-attribute-input-field/dynamic-attribute-input-field.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserSelectionDataGridComponent } from 'src/app/templates/userselectiondatagrid/userselectiondatagrid.component';
import { ProdGenApi } from 'src/app/apiService/prodgen.api';
import { User } from 'src/app/apiService/classFiles/class.users';
import { TranslationService } from '../../services/TranslationService';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'submit-external-learning-record',
    templateUrl: './submit-external-learning-record.component.html',
    styleUrls: ['./submit-external-learning-record.component.css']
})
export class SubmitExternalLearningRecord implements OnInit {

    activities: EL_Activity[];
    selectedFileUploadDate: Date = new Date();
    selectedFileInfo: TempFileResult = null;
    selectedActivityId: string = "";

    attributes: EL_Attribute[];

    activityAttributes: DynamicInputBase<Date | string | number>[];
    form = new FormGroup({});

    formState: 'pickingActivity' | 'fillingDefinedActivity' | 'fillingOther' = 'pickingActivity';
    

    //UserSelectionGrid Stuff
    @ViewChild("userSelectionGrid", { static: false }) userGrid: UserSelectionDataGridComponent;
    selectableAssignors: Array<User_Mini> = new Array<User_Mini>();
    selectableAssignorLoaded: boolean = false;
    selectedAssignors: Array<User_Mini> = new Array<User_Mini>();

    currentUser: User = new User();

    errorModalTitle: string = "";
    errorModalBody: string = "";

    recordSubmittedSuccessfully: boolean = false;
    thankYouButonMessage: string = this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.ThankYouButtonMessage-Pinnacle')
    thankYouPageCompanyLogo: string = "../../../assets/images/ps_browser_logo.png"

    constructor(
        private api: Apiv2Service,
        private location: Location,
        private auth: BrowserAuthenticationService,
        private pinnacleService: ProdGenApi,
        private translationService: TranslationService
    ) {

        this.api.getAllExternalLearningActivities().pipe(
            map(activities => activities
                .filter(a => a.isActive)
                .sort((a, b) => a.trainingTitle.toLocaleLowerCase().localeCompare(b.trainingTitle.toLocaleLowerCase(), undefined, { numeric: true })))
        ).subscribe({
            next: res => this.activities = res,
            error: err => console.error('Could not fetch activities', err)
        });

        this.api.getExternalLearningAttributes().pipe(
            map(attributes => attributes
                .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            )
        ).subscribe({
            next: res => {
                this.attributes = res;
            },
            error: err => console.error('Could not fetch attributes', err)
        });

        this.api.getExternalLearningSelectableAssignors().subscribe(res => {
            this.selectableAssignors = res;
            this.selectableAssignorLoaded = true;

            this.pinnacleService.getCurrentUser().subscribe(u => {
                this.currentUser = u;
                
                let localStorageAssignorIds = new Array<string>();
                localStorageAssignorIds = JSON.parse(localStorage.getItem(this.currentUser.userId + "_elSelectedAssignors"));

                if (localStorageAssignorIds != null) {
                    const stringIds: Array<string> = new Array<string>();
                    localStorageAssignorIds.forEach(id => {
                        let tempUser = this.selectableAssignors.find(x => x.userId == id)
                        if (tempUser != undefined) {
                            this.selectedAssignors.push(tempUser);
                            stringIds.push(id);
                        }
                    });

                    //Update the local storage list of previous assignors.
                    //If we end up finding a user that is no longer a valid
                    //assignor, then let's update the list with the previous, valid
                    //assignors.
                    localStorage.setItem(this.currentUser.userId + "_elSelectedAssignors", JSON.stringify(stringIds));
                }
            });

        });
    }

    ngOnInit() {
        if (this.auth.AuthenticatePage() == true) {
            try {
                if (window.location.href.toLowerCase().includes("productivitynow")) {
                    // These might be useful if we have a loading state before the Activity picker.
                    //this.platformName = "ProductivityNOW";
                    //this.loadingLogo = "../../../assets/images/pnow_logo.png";

                    this.thankYouButonMessage = this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.ThankYouButtonMessage-ProductivityNOW');
                    this.thankYouPageCompanyLogo = "../../../assets/images/pnow_logo.png";
                }
            }
            catch (e) {
            }
        }
    }

    onCancel() {
        $('#confirm-cancel-modal').modal('show');
    }

    onContinue() {
        $('#confirm-cancel-modal').modal('hide');
        this.location.back();
    }

    onClose() {
        $('#confirm-cancel-modal').modal('hide');
    }

    onSubmit() {
        //When the user can actually submit we need to store the SelectedAssignorIds in LocalStorage
        let stringIds = new Array<string>();
        this.selectedAssignors.forEach(x => {
            stringIds.push(x.userId);
        });

        localStorage.setItem(this.currentUser.userId + "_elSelectedAssignors", JSON.stringify(stringIds));

        const newRecord = new EL_Record();

        newRecord.activityId = this.selectedActivityId;

        newRecord.attributes = new Array<EL_ActivityAttribute>();

        //Add all the attributes to the new record.
        this.attributes.forEach(attr => {
                let activityAttr: EL_ActivityAttribute = new EL_ActivityAttribute(attr);
                newRecord.attributes.push(activityAttr);
        });

        newRecord.attributes.forEach((attr: EL_ActivityAttribute) => {

            //Email is handled by the User Selection
            if (attr.attribute.attributeName == 'Email' && attr.attribute.attributeDataType == EL_AttributeDataType.Text) {
                attr.attributeStringValue = isNullOrUndefined(this.currentUser.email) ? null : this.currentUser.email.trim();
            }
            else if (attr.attribute.attributeName == 'Training Title' && attr.attribute.attributeDataType == EL_AttributeDataType.Text) {
                //If this was the "Other" activity selection, then we created a formControl.
                //If not the "Other" activity, then we need to auto-populate the Training Title attribute.
                if (this.selectedActivityId !== GuidEmpty) {
                    attr.attributeStringValue = isNullOrUndefined(this.getSelectedActivityTitle()) ? null : this.getSelectedActivityTitle().trim();
                }
                else {
                    attr.attributeStringValue = isNullOrUndefined(this.form.get(attr.attribute.attributeName).value) ? null : this.form.get(attr.attribute.attributeName).value.trim();
                }
            }
            else {
                switch (attr.attribute.attributeDataType) {
                    case EL_AttributeDataType.Text:
                    case EL_AttributeDataType.LongFormText:
                        attr.attributeStringValue = isNullOrUndefined(this.form.get(attr.attribute.attributeName).value) ? null : this.form.get(attr.attribute.attributeName).value.trim();
                        break;
                    case EL_AttributeDataType.YesNo:
                        if (this.form.get(attr.attribute.attributeName).value == YesNoSelectionList[0]) {
                            attr.attributeStringValue = '';
                        }
                        else {
                            attr.attributeStringValue = this.form.get(attr.attribute.attributeName).value;
                        }
                        break;
                    case EL_AttributeDataType.Date:
                        attr.attributeDateValue = this.form.get(attr.attribute.attributeName).value;
                        break;
                    case EL_AttributeDataType.Minutes:
                    case EL_AttributeDataType.Number:
                    case EL_AttributeDataType.OneToFiveRating:
                    case EL_AttributeDataType.Percentage:
                        attr.attributeIntValue = this.form.get(attr.attribute.attributeName).value;
                        break;
                    case EL_AttributeDataType.SelectionSet:
                        attr.attributeStringValue = this.form.get(attr.attribute.attributeName).value;
                        break;
                    case EL_AttributeDataType.Decimal:
                        attr.attributeFloatValue = this.form.get(attr.attribute.attributeName).value;
                        break;
                }
            }
        });

        const newRecords = new Array<EL_Record>();
        newRecords.push(newRecord);

        let sub = this.api.CreateLearningRecords(newRecords, this.selectedFileInfo, EL_RecordStatus.Pending, false,  this.selectedAssignors).subscribe({
            next: () => {
                this.recordSubmittedSuccessfully = true;
            },
            error: () => {
                this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Submission-Message'),
                    this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Submission-Title'));
            },
            complete: () => {
                sub.unsubscribe();
            }
        });
    }

    showUserSelectionGridModal() {
        this.userGrid.updateSelectedUsers(this.selectedAssignors);
        $('#userSelectionGridModal').modal('show');
    }

    commitUserGrid(e: any): void {
        let users: User_Mini[] = this.userGrid.getSelectedUsers_UserMini();

        this.selectedAssignors.push(...users);

        $("#userSelectionGridModal").modal("hide");
    }

    removeAssignor(user: User_Mini) {
        let index = this.selectedAssignors.findIndex(x => x.userId == user.userId);
        if (index > -1) {
            this.selectedAssignors.splice(index, 1);
        }

        this.userGrid.updateSelectedUsers(this.selectedAssignors);
    }

    displayInitials(name: string): string {
        let initials = '';
        if (name != "" || name != null) {
            const str = name;
            const matches = str.match(/\b(\w)/g);
            if (matches != null) {
                initials = matches.slice(0, 4).join('').toUpperCase();
            }
        }

        return initials;
    }

    generateForm() {

        const activity = this.activities.find(a => a.activityId === this.selectedActivityId);
        const existingAttributes = (activity && activity.attributes) ? activity.attributes : [];

        const isOther = this.selectedActivityId === '00000000-0000-0000-0000-000000000000';

        this.form = new FormGroup({});

        let baseItems: DynamicInputBase<Date | string | number>[] = [];
        const datePipe = new DatePipe('en-US');

        this.attributes.forEach(attr => {

            // Don't show training title if the user selected a predefined activity.
            // Do show training title if the user selected "Other".
            if (attr.attributeName === 'Training Title') {
                if (isOther) {
                    this.form.addControl('Training Title', new FormControl('', Validators.required));
                }
                else {
                    return;
                }
            }

            // Never show email.
            if (attr.attributeName === 'Email') {
                return;
            }

            const existingAttribute = isOther
                ? null
                : existingAttributes.find(a => a.attribute.attributeId === attr.attributeId);

            let existingValue;
            let validators;

            switch (attr.attributeDataType) {
                case EL_AttributeDataType.LongFormText:
                    existingValue = (existingAttribute && existingAttribute.attributeStringValue)
                        ? existingAttribute.attributeStringValue
                        : null;
                    validators = [Validators.maxLength(500)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new StringInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'LongFormText'));
                    break;
                case EL_AttributeDataType.OneToFiveRating:
                    existingValue = ((existingAttribute && existingAttribute.attributeIntValue) || (existingAttribute && existingAttribute.attributeIntValue == 0))
                        ? existingAttribute.attributeIntValue
                        : null;
                    validators = [Validators.max(5), Validators.min(1)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new NumberInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'RatingIntegerNumber'));
                    break;
                case EL_AttributeDataType.Date:
                    existingValue = (existingAttribute && existingAttribute.attributeDateValue)
                        ? existingAttribute.attributeDateValue
                        : null;
                    validators = attr.isRequired ? [Validators.required] : [];
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue ? datePipe.transform(existingValue, 'yyyy-MM-dd') : null,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new DateInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition));
                    break;
                case EL_AttributeDataType.Number:
                    existingValue = ((existingAttribute && existingAttribute.attributeIntValue) || (existingAttribute && existingAttribute.attributeIntValue == 0))
                        ? existingAttribute.attributeIntValue
                        : null;
                    validators = [Validators.max(2147483647), Validators.min(-2147483648)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new NumberInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition));
                    break;
                case EL_AttributeDataType.Percentage:
                    existingValue = ((existingAttribute && existingAttribute.attributeIntValue) || (existingAttribute && existingAttribute.attributeIntValue == 0))
                        ? existingAttribute.attributeIntValue
                        : null;
                    validators = [Validators.max(100), Validators.min(0)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new NumberInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'Percentage'));
                    break;
                case EL_AttributeDataType.Minutes:
                    existingValue = ((existingAttribute && existingAttribute.attributeIntValue) || (existingAttribute && existingAttribute.attributeIntValue == 0))
                        ? existingAttribute.attributeIntValue
                        : null;
                    validators = [Validators.max(2147483647), Validators.min(0)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push( new NumberInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'PositiveIntegerNumber'));
                    break;
                case EL_AttributeDataType.Text:
                    existingValue = (existingAttribute && existingAttribute.attributeStringValue)
                        ? existingAttribute.attributeStringValue
                        : null;
                    validators = [Validators.maxLength(255)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push( new StringInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'Text'));
                    break;
                case EL_AttributeDataType.YesNo:
                    existingValue = (existingAttribute && existingAttribute.attributeStringValue)
                        ? existingAttribute.attributeStringValue
                        : YesNoSelectionList[2];
                    validators = attr.isRequired ? [Validators.required] : [];
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push( new StringInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'YesNo'));
                    break;
                case EL_AttributeDataType.SelectionSet:
                    existingValue = (existingAttribute && existingAttribute.attributeStringValue)
                        ? existingAttribute.attributeStringValue
                        : null;
                    validators = attr.isRequired ? [Validators.required] : [];
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push( new SelectionSetInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.selectionSet,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition));
                    break;
                case EL_AttributeDataType.Decimal:
                    existingValue = ((existingAttribute && existingAttribute.attributeFloatValue) || (existingAttribute && existingAttribute.attributeFloatValue == 0))
                        ? existingAttribute.attributeFloatValue
                        : null;
                    validators = [Validators.max(2147483647), Validators.min(0)];
                    if (attr.isRequired) { validators.push(Validators.required); }
                    this.form.addControl(attr.attributeName, new FormControl({
                        value: existingValue,
                        disabled: !isOther && attr.isActivityDefinition
                    }, validators));
                    baseItems.push(new NumberInput(attr.sequenceNumber,
                        attr.attributeName,
                        attr.attributeName,
                        attr.isRequired,
                        isOther || !attr.isActivityDefinition,
                        'Decimal'));
                    break;

            }
        });

        this.activityAttributes = baseItems;
    }

    onFilesSelected(files: FileList) {
        if (files.length === 0) {
            this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-select-file'),
                this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            return;
        }

        if (files.length > 1) {
            this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-single-file-selection'),
                this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            return;
        }

        let selectedFile: File = files[0];

        //check file type - start
        let supportedFileType: string[] = ['pdf', 'jpeg', 'docx', 'png', 'jpg'];
        let fileName: string = selectedFile.name;
        let fileNameParts = fileName.split('.');

        if (fileNameParts.length < 2) {
            this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-invalid-file'),
                this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            return;
        }

        let fileExt = fileNameParts[fileNameParts.length - 1];
        if (!supportedFileType.includes(fileExt)) {
            this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-unsupported-file-type'),
                this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            return;
        }
        //check file type - end

        var sizeInMB = (selectedFile.size / (1024 * 1024));
        var maxSizeInMB = 5;
        if (sizeInMB > maxSizeInMB) {
            this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-file-too-large') ,
                this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            return;
        }

        //Upload to temp storage
        let tempStorageServiceSub = this.api.uploadFiles(files).subscribe({
            next: (response) => {
                this.selectedFileInfo = response[0];
            },
            error: () => {
                this.showErrorModal(this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-file-upload-error'),
                    this.translationService.getTranslationFileData('SUBMITEXTERNALLEARNINGRECORD.Error-On-Upload-Message'));
            },
            complete: () => {
                tempStorageServiceSub.unsubscribe();
            }
        });
    }

    getSelectedFileIcon(fileName: string): string {
        if (!fileName || fileName === '') {
            return '';
        }

        let fileNameParts = fileName.split('.');

        let fileExt = fileNameParts[fileNameParts.length - 1];

        switch (fileExt) {
            case 'pdf':
                return 'assets/images/file-type-icons/PDFDoc.svg';
            case 'docx':
                return 'assets/images/file-type-icons/WordDoc.svg';
            case 'xlsx':
                return 'assets/images/file-type-icons/XCLDoc.svg';
            case 'png':
            case 'jpeg':
            case 'jpg':
                return 'assets/images/file-type-icons/ImageFile.svg';
            default:
                return '';
        }
    }

    parseFileName(fileUrl: string): string {
        if (!fileUrl || fileUrl === '') {
            return '';
        }

        let fileSections: Array<string> = fileUrl.split('?');
        fileSections = fileSections[0].split('/');

        let originalFileName: string = fileSections[fileSections.length - 1];
        //Put the original spaces back in rather than showing "%20"s.
        originalFileName = originalFileName.replace(new RegExp('%20', 'g'), " ");

        //Returns the last section, which is the original file name.
        return originalFileName;
    }

    removeFileSelection() {
        this.selectedFileInfo = null;
    }

    showErrorModal(modalMessage: string, modalTitle: string = "") {
        this.errorModalTitle = modalTitle;
        this.errorModalBody = modalMessage;

        $('#errorModal').modal('show');
    }

    onCloseErrorModal() {
        this.errorModalBody = "";
        this.errorModalTitle = "";
    }

    private getSelectedActivityTitle(): string {
        if (this.selectedActivityId) {
            let selectedActivity: EL_Activity = this.activities.find(x => x.activityId == this.selectedActivityId);

            if (selectedActivity) {
                let title = selectedActivity.attributes.find(x => x.attribute.attributeName == 'Training Title' &&
                                                                  x.attribute.attributeDataType == EL_AttributeDataType.Text);

                return title ? title.attributeStringValue : '';
            }
        }

        return '';
    }

    cutOffActivityNameVal(value: string): string {//trying to make it so super long activities aren't overflowing the page in the dropdown
        if (value.length > 80) {
            return value.substring(0, 80) + "...";
        }
        else {
            return value;
        }
        
    }
}
import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetProperties, WidgetPropertyOptions, User } from '../../../apiService/classFiles/class.users';
import { AppComponent } from '../../../app.component';
import { PartnerNewsItem } from '../../../apiService/classFiles/class.partners';
declare var $: any;



@Component({
  selector: 'app-partner-news-widget',
  templateUrl: './partner-news-widget.component.html',
  styleUrls: ['./partner-news-widget.component.css']
})
export class PartnerNewsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "PartnerNewsWidgetComponent";

    partnerInfoImage: string = "";
    companyInfoLoaded: boolean = false;
    uniqueImageQuery: number = Date.now();
    partnerInfo: Array<PartnerNewsItem> = new Array<PartnerNewsItem>();

    displayType: string = ""
    overlayDisplayClass = "widget-overlay-style-page";
    overlayDisplayLinkClass = "";

    widgetItemHeight: number = 0;


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 7;
        this.widgetWidth = 2;
        this.widgetTitle = "News";

        this.widgetName = "Partner News"
        this.widgetDesc = "Displays news from your selling partner.";
        //End Widget Defaults


        this.pinnacleService.GetPartnerSettings().subscribe(res => {
            if (AppComponent.isPinnacleLite == true) {
                let infoIndex = res.findIndex(w => w.name == "LITE_NEWS");
                if (infoIndex != -1) {
                    this.partnerInfoImage = res[infoIndex].settingValue as string;
                    //this.adjustSize();
                }
                else {
                    this.partnerInfoImage = "";
                }


                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_lite_news_image", JSON.stringify(this.partnerInfoImage));
                }
                catch (err) {
                    console.log(err);
                }
                this.companyInfoLoaded = true;
            }
            else {
                let infoIndex = res.findIndex(w => w.name == "PARTNER_NEWS");
                if (infoIndex != -1) {
                    this.partnerInfoImage = res[infoIndex].settingValue as string;
                }
                else {
                    this.partnerInfoImage = "";
                }


                try {
                    localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image", JSON.stringify(this.partnerInfoImage));
                }
                catch (err) {
                    console.log(err);
                }
                this.companyInfoLoaded = true;
            }

        });


        try {
            let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news_image"));
            if (i != null) {
                this.partnerInfoImage = i;
            }
        }
        catch (err) {
            console.log(err)
        }

        this.pinnacleService.GetPartnerNewsItems().subscribe(res => {
            this.partnerInfo = res;
            //this.adjustSize();
            try {
                localStorage.setItem(ProdGenApi.getCurrentTenantId() + "_partner_news", JSON.stringify(this.partnerInfo));
            }
            catch (err) {
                console.log(err);
            }
        });

        //try {
        //    let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_partner_news"));
        //    if (i != null) {
        //        this.partnerInfo = i;
        //    }
        //}
        //catch (err) {
        //    console.log(err)
        //}
    }

    ngOnInit() {
        super.ngOnInit();

        this.widgetItemHeight = this.widgetContainer.calcWidgetHeight(this.widgetHeight);

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "overlay";
        }

        this.createProperties();
        this.Load();
  }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, PartnerNewsWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        super.onPropsChangedLocalStorage();
        super.widgetArePropertiesLoaded();
    }


    Load() {
        super.Load();

        //The rest of the load function will be widget specific

    }

    adjustSize() {
        this.widgetContainer.onWidgetExpand(this.widgetID);
    }

    createProperties() {

        super.createProperties();


        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        super.createPropertiesDisplayType();
        super.createPropertiesWidgetTitle("News");
        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS
    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }


    getUniqueImageSource(source: string): string {
        let newSource = "";
        if (source != null) {
            if (source.length > 0) {
                if (source.indexOf("?") == -1) {
                    newSource = source + "?" + this.uniqueImageQuery;
                }
                else {
                    newSource = source + "&" + this.uniqueImageQuery;
                }
            }
        }
        return newSource;
    }

    calcNewsItemHeight(): number {
        return (this.widgetItemHeight - $("#ptrNewsImg_" + this.widgetID).height());
    }

    onResize() {
        super.onResize();
        this.widgetItemHeight = this.widgetContainer.calcWidgetHeight(this.widgetHeight);
    }

    getLinkTarget(linkUrl: string): string {
        let target = "";

        if (linkUrl.includes(window.location.origin)) {
            target = "_self";
        }
        else {
            target = "_blank";
        }

        return target;
    }
}

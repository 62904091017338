import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DxChartComponent, DxDataGridComponent, DxPieChartComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import { WorkgroupAssetUsageData } from '../workgroup-asset-usage-widget/workgroup-asset-usage-widget.component';
import { TranslationService } from '../../../../app/services/TranslationService';

// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
    selector: 'workgroup-course-completion-widget',
    templateUrl: './workgroup-course-completion-widget.component.html',
    styleUrls: ['./workgroup-course-completion-widget.component.css']
})


export class WorkgroupCourseCompletionWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "WorkgroupCourseCompletionWidgetComponent"; // this key must be set to the name of the Angular component

    minDate: Date = new Date(1000); // Super low value because JS doesn't support DateTime.MinValue
    widgetLoaded: boolean = false;
    viewType: string = "pieView";
    chartData: Array<WorkgroupLearningUsageData2> = [];
    pieData: Array<WorkgroupLearningUsageData3> = [];
    tabData: Array<WorkgroupLearningUsageData> = [];
    chartHeight: number = 0;
    chartWidth: number = 0;
    chartTitle: string = "";
    pieHeight: number = 0;
    pieWidth: number = 0;
    chartBreaks: boolean = false;
    chartBreaksNum: number = 0;
    chartBreaksPossible: Array<number> = [0, 1, 2, 3, 4];
    tabHeight: string = "0px";
    tabWidth: string = "0px";
    prevRange: number = 0;
    totalLearning: number = 1; // Don't set to '0' - avoid x/0 errors

    @ViewChild(DxChartComponent, { static: false }) chartComponent: DxChartComponent;
    @ViewChild("chartContainer", { static: false }) chartContainer: ElementRef;

    @ViewChild(DxDataGridComponent, { static: false }) tabComponent: DxDataGridComponent;
    @ViewChild("tabContainer", { static: false }) tabContainer: ElementRef;

    @ViewChild(DxPieChartComponent, { static: false }) pieComponent: DxPieChartComponent;
    @ViewChild("pieContainer", { static: false }) pieContainer: ElementRef;


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private translationService: TranslationService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 6;
        this.widgetHeight = 8;

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetName = "Work Group Learning"
        this.widgetDesc = "View the progress and completion of training offered in the Work Group."; 
    }

    ngOnInit() {
        // call the base Widget class
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load(); 
    }

    Load() {
        // call the base Widget class
        super.Load();

        this.loadData();

        this.widgetLoaded = true;
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
        setTimeout(this.onResize.bind(this), 0);
    }

    onWidgetResized(): void {
        this.onResize();
    }

    onResize(): void {
        if (this.viewType == 'chartView' && this.chartContainer != undefined && this.chartComponent != undefined) {
            let container = (this.chartContainer.nativeElement as HTMLElement).getBoundingClientRect();
            this.chartHeight = container.height - 55;
            this.chartWidth = container.width - 20;
            this.chartComponent.instance.render();
        }
        if (this.viewType == 'tabView' && this.tabComponent != undefined) {
            this.tabComponent.instance.refresh();
        }
        if (this.viewType == 'pieView' && this.pieComponent != undefined) {
            let container = (this.pieContainer.nativeElement as HTMLElement).getBoundingClientRect();
            this.pieHeight = container.height - 100;
            this.pieWidth = container.width - 20;
            this.pieComponent.instance.render();
        }
    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);
        
        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(   this.widgetContainer.containerInfo.containerInstanceId,
                                                        this.widgetID,
                                                        newprops,
                                                        "WorkgroupAssetUsageWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)

        let displayType = this.widgetProps.findIndex(x => x.propID == "DISPLAY_TYPE");
        if (displayType != -1) {
            let prevValue = this.viewType;
            this.viewType = this.widgetProps[displayType].propValue;
            //console.log(prevValue, this.viewType);
            if (prevValue != this.viewType) {
                this.loadData(true);
            }
        }

        let chartRange = this.widgetProps.findIndex(x => x.propID == "USAGE_RANGE");
        if (chartRange != -1) {
            switch (this.widgetProps[chartRange].propValue) {
                case "days7":
                    this.chartTitle = "7";
                    break;
                case "days30":
                    this.chartTitle = "30";
                    break;
                case "days60":
                    this.chartTitle = "60";
                    break;
                case "days90":
                    this.chartTitle = "90";
                    break;
                default:
                    this.chartTitle = "";
                    break;
            }
        }

        let numBreaks = this.widgetProps.findIndex(x => x.propID == "NUM_BREAKS");
        if (numBreaks != -1) {
            switch (this.widgetProps[numBreaks].propValue) {
                case "1":
                case "2":
                case "3":
                case "4":
                    this.chartBreaks = true;
                    this.chartBreaksNum = +this.widgetProps[numBreaks].propValue;
                    break;
                case "0":
                default:
                    this.chartBreaks = false;
                    this.chartBreaksNum = 0;
                    break;
            }
        }

        super.onPropsChangedLocalStorage();

        this.loadData();
        super.widgetArePropertiesLoaded();
    }



    createProperties() {

        // call widget base class
        super.createProperties();

        // the width and height properties are already created by the base class.
        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        //let newProp = new WidgetProperties();
        //newProp.propName = "Custom Url"
        //newProp.propValue = "https://mycustomurl";
        //newProp.propType = "string";
        //newProp.propID = "WIDGET_CUSTOM_URL";
        //this.widgetProps.push(newProp);


        let chartView = new WidgetPropertyOptions();
        chartView.opName = "Chart View";
        chartView.opValue = "chartView";

        let tabView = new WidgetPropertyOptions();
        tabView.opName = "Tabular View";
        tabView.opValue = "tabView";

        let pieView = new WidgetPropertyOptions();
        pieView.opName = "Pie Chart View";
        pieView.opValue = "pieView";

        let viewType = new WidgetProperties();
        viewType.propName = "View Type";
        viewType.propValue = "pieView";
        viewType.propType = "select";
        viewType.propID = "DISPLAY_TYPE";
        viewType.propOptions = [pieView, tabView];

        this.widgetProps.push(viewType);


        let days7 = new WidgetPropertyOptions();
        days7.opName = "Last 7 Days";
        days7.opValue = "days7";

        let days30 = new WidgetPropertyOptions();
        days30.opName = "Last 30 Days";
        days30.opValue = "days30";

        let days60 = new WidgetPropertyOptions();
        days60.opName = "Last 60 Days";
        days60.opValue = "days60";

        let days90 = new WidgetPropertyOptions();
        days90.opName = "Last 90 Days";
        days90.opValue = "days90";

        let viewRange = new WidgetProperties();
        viewRange.propName = "Range";
        viewRange.propValue = "days7";
        viewRange.propType = "select";
        viewRange.propID = "USAGE_RANGE";
        viewRange.propOptions = [days7, days30, days60, days90];

        this.widgetProps.push(viewRange);


        let breaks0 = new WidgetPropertyOptions();
        breaks0.opName = "0";
        breaks0.opValue = "0";

        let breaks1 = new WidgetPropertyOptions();
        breaks1.opName = "1";
        breaks1.opValue = "1";

        let breaks2 = new WidgetPropertyOptions();
        breaks2.opName = "2";
        breaks2.opValue = "2";

        let breaks3 = new WidgetPropertyOptions();
        breaks3.opName = "3";
        breaks3.opValue = "3";

        let breaks4 = new WidgetPropertyOptions();
        breaks4.opName = "4";
        breaks4.opValue = "4";

        let numBreaks = new WidgetProperties();
        numBreaks.propName = "Maximum Chart Breaks";
        numBreaks.propValue = "0";
        numBreaks.propType = "select";
        numBreaks.propID = "NUM_BREAKS";
        numBreaks.propOptions = [breaks0, breaks1, breaks2, breaks3, breaks4];

        //this.widgetProps.push(numBreaks);
        super.createPropertiesWidgetTitle();

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS


    loadData(bypassRangeCheck: boolean = false): void {
        let index = this.widgetProps.findIndex(x => x.propID == "USAGE_RANGE");
        if (index != -1) {
            let range: number = 0;
            switch (this.widgetProps[index].propValue) {
                case "days7":
                    range = 7;
                    break;
                case "days30":
                    range = 30;
                    break;
                case "days60":
                    range = 60;
                    break;
                case "days90":
                    range = 90;
                    break;
                default:
                    break;
            }
            if (range != 0 && (range != this.prevRange || bypassRangeCheck)) {
                this.prevRange = range;

                if (this.viewType == 'chartView') {
                    this.pinnacleService.wgGetLearningChartUsage(this.widgetContainer.bindingId, this.prevRange).subscribe(res => {
                        this.chartData = res;
                    });
                }
                if (this.viewType == 'pieView') {
                    this.pinnacleService.wgGetLearningPieUsage(this.widgetContainer.bindingId, this.prevRange).subscribe(res => {
                        this.pieData = res;
                        this.totalLearning = 0;
                        for (let i = 0; i < this.pieData.length; i++) {
                            this.totalLearning += this.pieData[i].value;
                        }
                        if (this.totalLearning < 1) {
                            this.totalLearning = 1;
                        }
                    });
                }
                if (this.viewType == 'tabView') {
                    this.pinnacleService.wgGetLearningUsage(this.widgetContainer.bindingId, this.prevRange).subscribe(res => {
                        this.tabData = res;
                        for (let i = 0; i < this.tabData.length; i++) {
                            this.tabData[i].assignerEmail = this.tabData[i].assignerEmail.trim();
                            this.tabData[i].userEmail = this.tabData[i].userEmail.trim();
                            this.tabData[i].courseAssignedDate = new Date(this.tabData[i].courseAssignedDate);
                            this.tabData[i].courseDueDate = new Date(this.tabData[i].courseDueDate);
                            this.tabData[i].courseCompletedDate = new Date(this.tabData[i].courseCompletedDate);
                        }
                    });
                }
            }
        }
    }

    chartLabel = (arg: any) => {
        return {
            visible: true,
            font: {
                color: "#000"
            },
            backgroundColor: "rgba(255,255,255,0.5)",
            position: "inside",
            customizeText: function (e: any) {
                return (e.argumentText as string).substr(0, 20) + ((e.argumentText as string).length >= 20 ? '...' : '');
            }
        };
    }

    chartTooltip = (arg: any) => {
        let argument = (arg.argumentText as string).replace(/\\/g, "\\\\").replace(/\//g, "\\/").replace(/</g, "\\<").replace(/>/g, "\\>").replace(/'/g, "\\'").replace(/\"/g, "\\\"");
        let value = (arg.valueText as string).replace(/\\/g, "\\\\").replace(/\//g, "\\/").replace(/</g, "\\<").replace(/>/g, "\\>").replace(/'/g, "\\'").replace(/\"/g, "\\\"");
        return {
            html: "<div style='max-width:150px;'>" + argument + "</div><br/><div style='margin:auto;'>" + this.translationService.getTranslationFileData("WORKCENTER_LEARNINGUSAGE_WIDGET.Enrollments") + ": " + value + "</div>"
        };
    }

    chartAxisLabel = (arg: any) => {
        return {
            text: ""
        };
    }

    pieTooltip = (arg: any) => {
        return {
            text: arg.value + "/" + this.totalLearning + " - " + Math.round((arg.value / this.totalLearning) * 10000) / 100 + "%"
        }
    }

    //debug(arg: any): void {
        //console.log(arg);
    //}
}

export class WorkgroupLearningUsageData {
    courseLPName: string;
    courseLPID: string;
    courseName: string;
    courseID: string;
    userName: string;
    userID: string;
    userEmail: string;
    assignerName: string;
    assignerID: string;
    assignerEmail: string;
    courseAssignedDate: Date;
    courseDueDate: Date;
    courseCompletedDate: Date;
    courseStatus: string;
    courseIsSubscription: boolean;
}

export class WorkgroupLearningUsageData2 {
    courseName: string;
    courseID: string;
    coursesEnrolled: number;
    coursesDropped: number;
    coursesComplete: number;
}

export class WorkgroupLearningUsageData3 {
    status: string;
    value: number;
}
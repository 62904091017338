import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';

import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users'; 
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// TODO - Change the name of the selector and setup to use your widget html and css files
@Component({
    selector: 'button-panel-widget',
    templateUrl: './button-panel-widget.component.html',
    styleUrls: ['./button-panel-widget.component.css']
})


// TODO - Rename the component name and set the key variable to be the string representation of the component name
export class ButtonPanelWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "ButtonPanelWidgetComponent";     

    widgetLoaded: boolean;

    widgetTitleText: string = "[Title Text]";
    widgetTitleSize: string = "20";
    titleTextAlignment: string = "";

    widgetDescription: string = "[Description Text]";
    widgetDescriptionSize: string = "12";
    descriptionTextAlignment: string = "";

    textVerticalAlignment: string = "center";

    websiteUrl: string = "[Insert Link]";
    relativeLink: boolean = false;
    pinnacleCommnad: boolean = false;
    urlSafe: SafeResourceUrl = null;
    isValidUrl: boolean = false;

    selectedThemes: string = '';
    imageTopSelectedThemes: string = '';

    widgetBackgroundColor: string = '--panel-title-background-color';
    widgetTextColor: string = '--panel-title-foreground-color';


    widgetBorderColor: string = '--panel-border-color';
    widgetBorderWidth: string = '--panel-border-width';
    widgetBorderRadius: string = '--panel-border-radius';
    
    adjustmentImagePixels: string = '150px';

    imageFile: string = "../../../assets/images/default_project.jpg";
    imagePosition: string = "no_image";
    imageVerticalAlignment: string = "center";
    imageAlignment: string = "center";
    shouldTranslate: boolean = true;

    //Not in use currently
    //iconClass: string = "";
    //iconSize: string = "16"


    blobImageURL ="https://pinnacle.blob.core.windows.net/69993654-598f-4887-ae69-6592509f4066/WidgetImages/0F496F04-BE16-F10C-7CA7-A113F39F5454_IMAGE_FILE.png"


    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private sanitizer: DomSanitizer) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        // set defaults for your height and width.
        // these are logical units of the container
        // container is 12 units across and 1 unit of height is ~25px
        this.widgetWidth = 3
        this.widgetHeight = 4;

        // define the name and description for the Widget.
        // these will be used by the Widget Library
        this.widgetName = "Default Template Widget"
        this.widgetDesc = "Let this widget guide you...";

        this.widgetLoaded = false;
    }

    ngOnInit() {
        // call the base Widget class
        this.widgetLoaded = false;
        super.ngOnInit();

        // create widget specific properties that can be configured
        this.createProperties();

        // load the data needed for the widget
        this.Load();
    }

    Load() {
        // call the base Widget class
        super.Load();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
        this.changedetectorref.detectChanges();        
    }

    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        if (newprops.findIndex(x => x.propID == "TITLE") != -1) {
            newprops[newprops.findIndex(x => x.propID == "TITLE")].propValue = newprops[newprops.findIndex(x => x.propID == "TITLE")].propValue;
            this.widgetTitleText = newprops[newprops.findIndex(x => x.propID == "TITLE")].propValue;
        }
        if (newprops.findIndex(x => x.propID == "TITLE_SIZE") != -1)
        {newprops[newprops.findIndex(x => x.propID == "TITLE_SIZE")].propValue = this.checkValueLimits(newprops[newprops.findIndex(x => x.propID == "TITLE_SIZE")].propValue, 6, 40);
            this.widgetTitleSize = newprops[newprops.findIndex(x => x.propID == "TITLE_SIZE")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "DESCRIPTION") != -1) {
            newprops[newprops.findIndex(x => x.propID == "DESCRIPTION")].propValue = newprops[newprops.findIndex(x => x.propID == "DESCRIPTION")].propValue;
            this.widgetDescription = newprops[newprops.findIndex(x => x.propID == "DESCRIPTION")].propValue;
        }
        if (newprops.findIndex(x => x.propID == "DESCRIPTION_SIZE") != -1) {
            newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_SIZE")].propValue = this.checkValueLimits(newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_SIZE")].propValue, 6, 30);
            this.widgetDescriptionSize = newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_SIZE")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "TITLE_TEXT_ALIGN") != -1) {
            newprops[newprops.findIndex(x => x.propID == "TITLE_TEXT_ALIGN")].propValue = newprops[newprops.findIndex(x => x.propID == "TITLE_TEXT_ALIGN")].propValue;
            this.titleTextAlignment = newprops[newprops.findIndex(x => x.propID == "TITLE_TEXT_ALIGN")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "DESCRIPTION_TEXT_ALIGN") != -1) {
            newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_TEXT_ALIGN")].propValue = newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_TEXT_ALIGN")].propValue;
            this.descriptionTextAlignment = newprops[newprops.findIndex(x => x.propID == "DESCRIPTION_TEXT_ALIGN")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "TEXT_VERTICAL_ALIGN") != -1) {
            newprops[newprops.findIndex(x => x.propID == "TEXT_VERTICAL_ALIGN")].propValue = newprops[newprops.findIndex(x => x.propID == "TEXT_VERTICAL_ALIGN")].propValue;
            this.textVerticalAlignment = newprops[newprops.findIndex(x => x.propID == "TEXT_VERTICAL_ALIGN")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "IMAGE_VERTICAL_ALIGN") != -1) {
            newprops[newprops.findIndex(x => x.propID == "IMAGE_VERTICAL_ALIGN")].propValue = newprops[newprops.findIndex(x => x.propID == "IMAGE_VERTICAL_ALIGN")].propValue;
            this.imageVerticalAlignment = newprops[newprops.findIndex(x => x.propID == "IMAGE_VERTICAL_ALIGN")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "IMAGE_ALIGN") != -1) {
            newprops[newprops.findIndex(x => x.propID == "IMAGE_ALIGN")].propValue = newprops[newprops.findIndex(x => x.propID == "IMAGE_ALIGN")].propValue;
            this.imageAlignment = newprops[newprops.findIndex(x => x.propID == "IMAGE_ALIGN")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "SHOULD_TRANSLATE") != -1) {
            newprops[newprops.findIndex(x => x.propID == "SHOULD_TRANSLATE")].propValue = newprops[newprops.findIndex(x => x.propID == "SHOULD_TRANSLATE")].propValue;
            this.shouldTranslate = newprops[newprops.findIndex(x => x.propID == "SHOULD_TRANSLATE")].propValue.toLowerCase() == 'true';
        }


        //if (newprops.findIndex(x => x.propID == "ICON_CLASS") != -1) {
        //    newprops[newprops.findIndex(x => x.propID == "ICON_CLASS")].propValue = newprops[newprops.findIndex(x => x.propID == "ICON_CLASS")].propValue.replace(/'/g, "&apos;");
        //    this.iconClass = newprops[newprops.findIndex(x => x.propID == "ICON_CLASS")].propValue.replace(/&apos;/g, "'");
        //}

        //if (newprops.findIndex(x => x.propID == "ICON_SIZE") != -1) {
        //    newprops[newprops.findIndex(x => x.propID == "ICON_SIZE")].propValue = this.checkValueLimits(newprops[newprops.findIndex(x => x.propID == "ICON_SIZE")].propValue, 8, 40);
        //    this.iconSize = newprops[newprops.findIndex(x => x.propID == "ICON_SIZE")].propValue;
        //}

        
        if (newprops.findIndex(x => x.propID == "WEBSITE_URL") != -1) {
            this.websiteUrl = newprops[newprops.findIndex(x => x.propID == "WEBSITE_URL")].propValue;

            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.websiteUrl);
            this.isValidUrl = this.validOrRelativeURL(this.websiteUrl);

            if (this.relativeLink == false && this.pinnacleCommnad == false) {
                this.validateURLProtocol();
            }
        }
        if (newprops.findIndex(x => x.propID == "BACKGROUND_COLOR") != -1) {
            this.widgetBackgroundColor = newprops[newprops.findIndex(x => x.propID == "BACKGROUND_COLOR")].propValue;
            
        }
        if (newprops.findIndex(x => x.propID == "TEXT_COLOR") != -1) {
            this.widgetTextColor = newprops[newprops.findIndex(x => x.propID == "TEXT_COLOR")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "BORDER_COLOR") != -1) {
            this.widgetBorderColor = newprops[newprops.findIndex(x => x.propID == "BORDER_COLOR")].propValue;
        }
        if (newprops.findIndex(x => x.propID == "BORDER_WIDTH") != -1) {
            newprops[newprops.findIndex(x => x.propID == "BORDER_WIDTH")].propValue = this.checkValueLimits(newprops[newprops.findIndex(x => x.propID == "BORDER_WIDTH")].propValue, 0, 10);
            this.widgetBorderWidth = newprops[newprops.findIndex(x => x.propID == "BORDER_WIDTH")].propValue;

        }
        if (newprops.findIndex(x => x.propID == "BORDER_RADIUS") != -1) {
            newprops[newprops.findIndex(x => x.propID == "BORDER_RADIUS")].propValue = this.checkValueLimits(newprops[newprops.findIndex(x => x.propID == "BORDER_RADIUS")].propValue, 0, 200);
            this.widgetBorderRadius = newprops[newprops.findIndex(x => x.propID == "BORDER_RADIUS")].propValue;
        }

        if (newprops.findIndex(x => x.propID == "IMAGE_POSITION") != -1) {
            this.imagePosition = newprops[newprops.findIndex(x => x.propID == "IMAGE_POSITION")].propValue;
        }
        if (newprops.findIndex(x => x.propID == "IMAGE_FILE") != -1) {
            this.imageFile = newprops[newprops.findIndex(x => x.propID == "IMAGE_FILE")].propValue;
        }

        this.updateSelectedThemes();
        // write the new properties to the database
        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "ButtonPanel").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // if there are other properties we care about, retrieve them here
        // do the work this widget needs to do based on the changed properties (set values for bound variables, etc.)

        super.onPropsChangedLocalStorage();
        this.tryLocalStorage("_title", this.widgetTitleText);
        this.tryLocalStorage("_titleSize", this.widgetTitleSize);
        this.tryLocalStorage("_titleTextAlign", this.titleTextAlignment);
        this.tryLocalStorage("_description",     this.widgetDescription);
        this.tryLocalStorage("_descriptionSize", this.widgetDescriptionSize);
        this.tryLocalStorage("_descriptionTextAlign", this.descriptionTextAlignment);
        this.tryLocalStorage("_textVerticalAlign", this.textVerticalAlignment);
        //this.tryLocalStorage("_iconClass",  this.iconClass);
        //this.tryLocalStorage("_iconSize",   this.iconSize);
        this.tryLocalStorage("_imagePosition", this.imagePosition);
        this.tryLocalStorage("_imageVerticalAlign", this.imageVerticalAlignment);
        this.tryLocalStorage("_imageAlign", this.imageAlignment);
        this.tryLocalStorage("_shouldTranslate", this.shouldTranslate.toString());
        this.tryLocalStorage("_websiteUrl",      this.websiteUrl);
        this.tryLocalStorage("_backgroundColor", this.widgetBackgroundColor);
        this.tryLocalStorage("_textColor",       this.widgetTextColor);
        this.tryLocalStorage("_borderColor",     this.widgetBorderColor);
        this.tryLocalStorage("_borderWidth",     this.widgetBorderWidth);
        this.tryLocalStorage("_borderRadius",    this.widgetBorderRadius);
        this.tryLocalStorage("_imageFile",       this.imageFile);

        this.widgetLoaded = true;

        setTimeout(() => { this.calcImageSize(); });

        super.widgetArePropertiesLoaded();
    }


    tryLocalStorage(keyProp: string, widgetVar: string) {
        try {
            localStorage.setItem(this.widgetID + keyProp, JSON.stringify(widgetVar));
        }
        catch (err) {
            console.log(err);
        }
    }

    pushProperty(pName: string, pValue: string, pType: string, pID: string) {
        let tempProp = new WidgetProperties();
        tempProp.propName = pName;
        tempProp.propValue = pValue;
        tempProp.propType = pType;
        tempProp.propID = pID;
        this.widgetProps.push(tempProp);
    }

    createProperties() {

        // call widget base class
        super.createProperties();

        
        //pushProperty(pName: string, pValue: string, pType: string, pID: string)
        //Title
        

        

        this.pushProperty("Title", this.widgetTitleText, "textWithOptions", "TITLE");        
        this.pushProperty("Title Text Size", this.widgetTitleSize, "textSize", "TITLE_SIZE");
        this.pushProperty("Title Text Alignment", this.titleTextAlignment, "textAlignment", "TITLE_TEXT_ALIGN");        

        

        this.pushProperty("Description", this.widgetDescription, "textWithOptions", "DESCRIPTION");
        this.pushProperty("Description Text Size", this.widgetDescriptionSize, "textSize", "DESCRIPTION_SIZE");
        this.pushProperty("Description Text Alignment", this.descriptionTextAlignment, "textAlignment", "DESCRIPTION_TEXT_ALIGN");
        this.pushProperty("Should Translate", this.shouldTranslate.toString(), "shouldTranslate", "SHOULD_TRANSLATE");
        this.pushProperty("Text Vertical Alignment", this.textVerticalAlignment, "verticalAlign", "TEXT_VERTICAL_ALIGN");
        this.pushProperty("Link URL", this.websiteUrl, "string", "WEBSITE_URL");

        //this.pushProperty("Icon", this.iconClass, "iconSelect", "ICON_CLASS");
        //this.pushProperty("Icon Size", this.iconSize, "iconSize", "ICON_SIZE");   


       
     

        //Image Position
        let imagePosition = new WidgetProperties();

        let option0: WidgetPropertyOptions = new WidgetPropertyOptions();
        option0.opName = "No Image";
        option0.opValue = "no_image";
        imagePosition.propOptions.push(option0);

        let option5: WidgetPropertyOptions = new WidgetPropertyOptions();
        option5.opName = "Only Display Image";
        option5.opValue = "only_display_image";
        imagePosition.propOptions.push(option5);

        let option1: WidgetPropertyOptions = new WidgetPropertyOptions();
        option1.opName = "Above Text";
        option1.opValue = "top";
        imagePosition.propOptions.push(option1);

        let option2: WidgetPropertyOptions = new WidgetPropertyOptions();
        option2.opName = "Below Text";
        option2.opValue = "bottom";
        imagePosition.propOptions.push(option2);

        let option3: WidgetPropertyOptions = new WidgetPropertyOptions();
        option3.opName = "Left of Text";
        option3.opValue = "left";
        imagePosition.propOptions.push(option3);

        let option4: WidgetPropertyOptions = new WidgetPropertyOptions();
        option4.opName = "Right of Text";
        option4.opValue = "right";
        imagePosition.propOptions.push(option4);

        imagePosition.propName = "Image Position"
        imagePosition.propValue = this.imagePosition;
        imagePosition.propType = "select";
        imagePosition.propID = "IMAGE_POSITION";
        this.widgetProps.push(imagePosition);

        this.pushProperty("Image Vertical Alignment", this.imageVerticalAlignment, "verticalAlign", "IMAGE_VERTICAL_ALIGN");
        this.pushProperty("Image Alignment", this.imageAlignment, "itemAlign", "IMAGE_ALIGN");

        this.pushProperty("Image File", this.imageFile, "image", "IMAGE_FILE");  

        this.pushProperty("Color Border Label Section", "Color and Border Options", "dividerLabel", "COLOR_BORDER_OPTIONS_LABEL");

        this.pushProperty("Text Color", this.widgetTextColor, "themeColor", "TEXT_COLOR");
        this.pushProperty("Background Color", this.widgetBackgroundColor, "themeColor", "BACKGROUND_COLOR");

        this.pushProperty("Border Color", this.widgetBorderColor, "themeColor", "BORDER_COLOR");
        this.pushProperty("Border Width", this.widgetBorderWidth, "themeBorderWidth", "BORDER_WIDTH");
        this.pushProperty("Border Radius", this.widgetBorderRadius, "themeBorderRadius", "BORDER_RADIUS");  


        super.createPropertiesMobileOptions();

    }

    //END WIDGET REQUIRED CALLS

    validOrRelativeURL(str: string): boolean {
        var baseUrl = window.location.origin;

        this.relativeLink = false;
        this.pinnacleCommnad = false;

        if (str.indexOf(baseUrl) != -1 || str.indexOf('#') == 0 || str.indexOf('.') == 0 || str.indexOf('/') == 0) {
            this.relativeLink = true;
            return true;      
        }


        if (str.startsWith('pinnaclecommand://')) {
            this.pinnacleCommnad = true;
            return true;
        }

        if (str == '' || str == '[Insert Link]') {
            return false;
        }

        return true;

    }

    checkValueLimits(value:string, min:number, max:number): string {
        if (value.startsWith('--')) {
            return value;
        }
        if (value == "" || value == null || +value < min) {
            return min.toString();
        }
        if (+value > max) {
            return max.toString();
        }
        return value;
    }


    onResize() {
        this.calcImageSize();
    }

    calcImageSize() {
        if (this.imagePosition == 'top') {
            if (document.getElementById("text-top-image-container-" + this.widgetID) != null && this.widgetLoaded) {
                this.adjustmentImagePixels = "calc(100% - " + document.getElementById("text-top-image-container-" + this.widgetID).offsetHeight + "px)";
            }
            else {
                this.adjustmentImagePixels = "100px";
            }
        }
        else if( this.imagePosition == 'bottom') {
            if (document.getElementById("text-bottom-image-container-" + this.widgetID) != null && this.widgetLoaded) {
                this.adjustmentImagePixels = "calc(100% - " + document.getElementById("text-bottom-image-container-" + this.widgetID).offsetHeight + "px)";
            }
            else {
                this.adjustmentImagePixels = "100px";
            }
        }        
        
    }


    updateSelectedThemes() {
        this.selectedThemes = "";
        this.imageTopSelectedThemes = "";
        
        if (this.widgetBackgroundColor.startsWith('--')){
            this.selectedThemes += 'theme-' + this.widgetBackgroundColor.substring(2) + '-bgc-prop ';
            this.imageTopSelectedThemes += 'theme-' + this.widgetBackgroundColor.substring(2) + '-bgc-prop ';
        }
        if (this.widgetTextColor.startsWith('--')) {
            this.selectedThemes += 'theme-' + this.widgetTextColor.substring(2) + '-tc-prop ';   
            this.imageTopSelectedThemes += 'theme-' + this.widgetTextColor.substring(2) + '-tc-prop ';   
        }
        if (this.widgetBorderColor.startsWith('--')) {
            this.selectedThemes += 'theme-' + this.widgetBorderColor.substring(2) + '-bc-prop ';
        }
        if (this.widgetBorderWidth.startsWith('--')) {
            this.selectedThemes += 'theme-' + this.widgetBorderWidth.substring(2) + '-prop ';
        }
        if (this.widgetBorderRadius.startsWith('--')) {
            this.selectedThemes += 'theme-' + this.widgetBorderRadius.substring(2) + '-prop ';
        }

    }

    validateURLProtocol() {
        if (this.websiteUrl.startsWith("http://")) {
            this.websiteUrl = this.websiteUrl.replace("http://", "https://");
        }
        else if (this.websiteUrl.startsWith("https://") || this.websiteUrl.startsWith("mailto:")) {
            //do nothing
        }
        else {
            this.websiteUrl = "https://" + this.websiteUrl;
        }
    }


    getwebsiteUrl() {
        if (this.pinnacleCommnad == true) {
            return this.urlSafe;

        }
        return this.websiteUrl;

    }
}

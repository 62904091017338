import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';

import { SearchComponent } from '../../../search/search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.css']
})
export class SearchWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "SearchWidgetComponent";

    searchTerms: string = "";
    searchType: string = "";

    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private router: Router) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 2;
        this.widgetWidth = 6;
        this.widgetName = "Search Widget"
        this.widgetDesc = "Allows the user to search Pinnacle content.";
        this.searchType = "allassets";
        //End Widget Defaults



    }

    ngOnInit() {
        super.ngOnInit();

        // try to load up any previous information. this will keep the screen from flickering..?
        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.searchType = JSON.parse(localStorage.getItem(this.widgetID + "_searchType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.searchType == null) {
            this.searchType = "allassets";
        }


        this.createProperties();
        this.Load();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    onKeySearchPress(event: any) {
        if (event.keyCode == 13) {
            this.onSearch();
        }
    }
    onKeySearchInput(event: any) {
        if (event.data == null && event.composed != null && event.composed == false) {
            this.onSearch();
        }
    }

    onSearch(): void {
        let url: string = "";
        if (this.searchTerms.trim().length > 0) {
            if (this.searchType == "trainingonly") {
                url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim()) + "&contentType=PS_LearningPath&contentType=PS_Course";
            }
            else {
                url += "search?searchTerms=" + SearchComponent.encodeSearchParameters(this.searchTerms.trim());
            }
            this.router.navigateByUrl(url);
        }
    }
    onSearchTermsChanged() {
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        if (newprops.findIndex(x => x.propID == "SEARCH_TYPE") != -1) {
            this.searchType = newprops[newprops.findIndex(x => x.propID == "SEARCH_TYPE")].propValue;
        }

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, "PersonalResourceAccessWidgetComponent").subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        // write info to local storage
        try {
            localStorage.setItem(this.widgetID + "_searchType", JSON.stringify(this.searchType));
        }
        catch (err) {
            console.log(err);
        }

        super.onPropsChangedLocalStorage();

        super.widgetArePropertiesLoaded();

        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
    }


    Load() {
        super.Load();
        //The rest of the load function will be widget specific

    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property

        let searchType = new WidgetProperties();

        let option1: WidgetPropertyOptions = new WidgetPropertyOptions();
        option1.opName = "All Assets";
        option1.opValue = "allassets";
        searchType.propOptions.push(option1);

        let option2: WidgetPropertyOptions = new WidgetPropertyOptions();
        option2.opName = "Training Only";
        option2.opValue = "trainingonly";
        searchType.propOptions.push(option2);


        searchType.propName = "Content to Search"
        searchType.propValue = this.searchType;
        searchType.propType = "select";
        searchType.propID = "SEARCH_TYPE";
        this.widgetProps.push(searchType);

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS


    onBrowseAllClicked() {
        let url: string = "";
        url += "search?contentType=PS_LearningPath&contentType=PS_Course";
        this.router.navigateByUrl(url);
    }

}

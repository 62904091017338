import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-layouts',
  templateUrl: './page-layouts.component.html',
  styleUrls: ['./page-layouts.component.css']
})
export class PageLayoutsComponent implements OnInit {
    templateList = new Array<LayoutTemplate>();

  constructor() { }

    ngOnInit() {
        this.getTemplates();
  }

    getTemplates() {
        //this will be a database call to get template listing. for now i will stub things in
        let new1 = new LayoutTemplate();
        new1.containerid = "1";
        new1.name = "1st template";
        this.templateList.push(new1);

        let new2 = new LayoutTemplate();
        new2.containerid = "2";
        new2.name = "2nd template";
        this.templateList.push(new2);

        let new3 = new LayoutTemplate();
        new3.containerid = "3";
        new3.name = "3rd template";
        this.templateList.push(new3);
    }
}

export class LayoutTemplate {
    name: string = "";
    containerid: string = "";
}
import { Component, OnInit } from '@angular/core';
import { Enrollment } from '../apiService/classFiles/class.enrollments';
import { Organization, Tenant } from '../apiService/classFiles/class.organizations';
import { RoleLayoutAssignment } from '../apiService/classFiles/class.users';
import { Workgroup } from '../apiService/classFiles/class.workgroups';
import { AppComponent } from '../app.component';
import { AppControlService, MsgBxStringVals } from '../AppControlService';
import { BrandingUpdateServiceService } from '../services/branding-update-service.service';
import { Apiv2Service } from './../apiService/apiv2.service';
import { Language } from './../apiService/classFiles/class.authorization';
import { SettingType_T } from './../apiService/classFiles/class.users';
import { UserExpertise } from './../apiService/classFiles/v2-organizations';
import { UserPropertyType, V2User } from './../apiService/classFiles/v2-users';
import { ProdGenApi } from './../apiService/prodgen.api';
import { BrowserAuthenticationService } from './../BrowserAuthenticationService';
import { TranslationService } from './../services/TranslationService';

declare function doesFontExist(fontName: string): boolean;
declare var $: any;


// Xenotron - original font used
// Xenotron Broadstroke - version 2
// Xephyr Expanded Italic - Version 3 - used as neccessary check for some bug fixes (10/13/2019 - CR)

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  providers: [ProdGenApi],
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	isLoading: boolean = true;

  organization: Organization = new Organization();
  currentTenant: Tenant = new Tenant();
    user: V2User = new V2User();
  workGroups: Array<Workgroup> = new Array<Workgroup>();
  completedCourseCount: number = 0;
  currentEnrollments: Array<Enrollment> = new Array<Enrollment>();

  ssoSetting: boolean = false;
  passwordPolicyEnforcedSetting: boolean = false;
  complexPasswordSetting: boolean = false;
  passwordExpiresSetting: boolean = false;
  passwordExpiresCount: number = 0;
  passwordDifferentSetting: boolean = false;
  passwordDiferentCount: number = 0;


  public oldPassword: string = "";
  public newPassword: string = "";
  public passwordchangeValid = true;
   
    showAllowAppSupport: boolean = false;

  pswdChangeOpen: boolean = false;
  pswdChangeError: boolean = false;
  pswdErrorText: string = "";
  oldPasswordError: boolean = false;
  newPasswordError: boolean = false;
  passwordgGuidlinesError: boolean = false;
  isAuthenticated: boolean = false;

  emailNotifications: boolean = true;

  public selectedLanguage: string = "en";
  public languages: Array<Language> = new Array<Language>();

    canSetEmailSetting: boolean = false;

    editEmail: string = "";
    editDisplayName: string = "";

    isExternalDataLoaded: boolean = false;
    externalOrgName: string = "";
    externalOrgRole: string = "";
    externalPhone: string = "";

    editExternalOrgName: string = "";
    editExternalOrgRole: string = "";
    editExternalPhone: string = "";

    roleLayoutAssignments: Array<RoleLayoutAssignment> = new Array<RoleLayoutAssignment>();
    selectedRole: string = "";

    usewidgets: boolean = false;

    userProperties: UserProperty[];
    userManagers: UserManager[];

    // areas of expertise 
    expertiseFormatted: string = ""; // formatted list for the user
    canEditExpertise: boolean = false;

    tenantExpertiseList: CheckedExpertise[];
    
    
    constructor(private pinnacleService: ProdGenApi,
        private v2Service:Apiv2Service,
        private authenticationService: BrowserAuthenticationService,
        private tranService: TranslationService,
        private controlService: AppControlService,
        private brandingService: BrandingUpdateServiceService) {
	  if (authenticationService.AuthenticatePage() == true) {
		  this.isAuthenticated = true;
      }
      this.userProperties = [];
        this.userManagers = [];
        this.tenantExpertiseList = [];
  }

  ngOnInit() {
	  //this.pinnacleService

      if (ProdGenApi.GetUserIsExternal() == true) {
          this.pinnacleService.getCurrentUserExternalData().subscribe(res => {
              this.externalOrgName = res.organizationName;
              this.externalOrgRole = res.organizationRole;
              this.externalPhone = res.phone;
              this.isExternalDataLoaded = true;
          }, err => {
              this.isExternalDataLoaded = false;
          });
      }


      if (window.navigator.userAgent.indexOf("Windows ") != -1) {
          // check to see if they have installed protocol already. Done by checking if font exists that is installed with it
          if (doesFontExist("Xenotron") == true) {
              this.showAllowAppSupport = true;
          }
      }

      

      let userId = ProdGenApi.getSessionUser().userId;

      if (ProdGenApi.isImpersonating()) {
          const bearerToken = ProdGenApi.getAPIV2AccessKey();
          userId = bearerToken.userId;
      }      

      this.v2Service.getV2User(userId, "Full").subscribe(userRes => {
          this.user = userRes.user;
          if (this.user.imageUrl == "") {
              this.user.imageUrl = "../../assets/images/default_user.jpg" + "?" + Date.now();
          }

          this.user.areasOfExpertise = this.user.areasOfExpertise.sort((a: any, b: any) => {
              if (a.expertiseName > b.expertiseName)
                  return 1;
              else if (a.expertiseName < b.expertiseName)
                  return -1;
              return 0;
          });

          // build the formatted string for user expertise
          this.expertiseFormatted = this.getFormattedExpertiseList();

          // setup the visible user properties
          for (let prop of this.user.properties) {
              if (prop.isVisible) {
                  let newProp: UserProperty = new UserProperty();
                  newProp.PropertyName = prop.propertyName;

                  newProp.PropertyValue = prop.propertyValueText;

                  switch (prop.propertyType) {
                      case UserPropertyType.Date:
                          newProp.PropertyValue = prop.propertyValueText;
                          break;
                      case UserPropertyType.Number:
                          newProp.PropertyValue = '';
                          if (prop.propertyValueInt != null) {
                              newProp.PropertyValue = prop.propertyValueInt.toString();
                          }
                          break;
                      case UserPropertyType.TrueFalse:
                          newProp.PropertyValue = '';
                          if (prop.propertyValueBit != null) {
                              newProp.PropertyValue = prop.propertyValueBit.toString();
                          }
                          break;
                  }

                  if (newProp.PropertyValue != null && newProp.PropertyValue.length > 0) {

                      this.userProperties.push(newProp);
                  }

              }
          }

          for (let mgr of this.user.managers) {
              let newMgr: UserManager = new UserManager();
              newMgr.DisplayName = mgr.displayName;
              newMgr.Email = mgr.email;
              newMgr.ImageUrl = mgr.imageUrl;

              const matches = newMgr.DisplayName.match(/\b(\w)/g);
              newMgr.Initials  = matches.slice(0, 4).join('').toUpperCase();

              this.userManagers.push(newMgr);
          }


		  this.pinnacleService.GetCurrentTenant().subscribe(tenantRes => {

			  this.currentTenant = tenantRes;


			  this.pinnacleService.GetCurrentTenantSettings().subscribe(settingRes => {
				  settingRes.forEach((setting) => {
                      if (setting.name == "PWD_ENFORCE_POLICY") {
                          this.passwordPolicyEnforcedSetting = setting.settingValue as boolean;
                      }
                      else if (setting.name == "PWD_USE_COMPLEX_PASSWORDS") {
                          this.complexPasswordSetting = setting.settingValue as boolean;
                      }
                      else if (setting.name == "PWD_PASSWORD_EXPIRES") {
                          this.passwordExpiresSetting = setting.settingValue as boolean;
                      }
                      else if (setting.name == "PWD_EXPIRATION_DURATION") {
                          this.passwordExpiresCount = setting.settingValue as number;
                      }
                      else if (setting.name == "PWD_MUST_BE_UNIQUE") {
                          this.passwordDifferentSetting = setting.settingValue as boolean;
                      }
                      else if (setting.name == "PWD_UNIQUE_HISTORY_COUNT") {
                          this.passwordDiferentCount = setting.settingValue as number;
                      }
                      else if (setting.name == "PWD_DISABLE_WINDOWS_AUTH") {
                          this.ssoSetting = (setting.settingValue as boolean);
                      }
                      else if (setting.name == "ALLOW_EMAIL_NOTIFICATIONS") {
                          this.canSetEmailSetting = (setting.settingValue as boolean);
                      }
                      else if (setting.name == "DB_DECLAREEXPERT") {
                          this.canEditExpertise = (setting.settingValue as boolean);
                      }
                      else if (setting.name == "USE_WIDGETS") {
                          if (setting.settingValue && setting.settingValue.toString().toLowerCase() == "true") {
                              this.usewidgets = true;
                          }
                          else {
                              this.usewidgets = false;
                          }
                          //console.log(this.usewidgets);
                      }
                  });
                      if (this.usewidgets == true) {
                          this.pinnacleService.getUserLayoutAssignments().subscribe(res => {
                              this.roleLayoutAssignments = res;
                              this.roleLayoutAssignments.sort((a, b) => (a.rolename > b.rolename) ? 1 : -1);

                              if (this.roleLayoutAssignments && this.roleLayoutAssignments.length > 0) {
                                  this.pinnacleService.getCurrentUserSetting("ROLE_LAYOUT_PREFERENCE").subscribe(res2 => {
                                      if (res2) {
                                          if (this.roleLayoutAssignments.findIndex(x => x.roleid == res2.settingValue) != -1) {
                                              this.selectedRole = this.roleLayoutAssignments[this.roleLayoutAssignments.findIndex(x => x.roleid == res2.settingValue)].roleid;
                                          }
                                          else {
                                              this.selectedRole = this.roleLayoutAssignments[0].roleid;
                                          }
                                      }
                                      else {
                                          this.selectedRole = this.roleLayoutAssignments[0].roleid;
                                      }

                                      if (this.roleLayoutAssignments.length > 1) {
                                          let defaultRoleIDIndex = this.roleLayoutAssignments.findIndex(x => x.roleid == "00000000-0000-0000-0000-000000000000"); //get the index of the default layout role
                                          if (this.selectedRole == "00000000-0000-0000-0000-000000000000") {//is the selected role equal to an empty guid, which indicates a default layout selection
                                              if (defaultRoleIDIndex == 0) {
                                                  this.selectedRole = this.roleLayoutAssignments[1].roleid;
                                              }
                                              else {
                                                  this.selectedRole = this.roleLayoutAssignments[0].roleid;
                                              }
                                              this.pinnacleService.saveCurrentUserSetting("ROLE_LAYOUT_PREFERENCE", this.selectedRole, SettingType_T.string).subscribe();
                                          }
                                          else {
                                              //no work needed
                                          }
                                          this.roleLayoutAssignments.splice(defaultRoleIDIndex, 1);
                                      }
                                  });

                              }
                          });
                      }

				 

					

					this.pinnacleService.GetUserExcludedFromSSO(this.currentTenant.tenantId.toString(), this.user.email).subscribe(r => {

						if (r == true) {
							this.ssoSetting = false;
							
						}
						else {
							this.pinnacleService.IsSSOCompliantUser(this.currentTenant.tenantId.toString()).subscribe(r => {

								if (r["SSO_CONFIG_SETTING"] != null) {
									let ssoSettingName = r["SSO_CONFIG_SETTING"];

									if (ssoSettingName != "Pinnacle" && ssoSettingName != "" && ssoSettingName != "EWS (Exchange Web Services)") {
										this.ssoSetting = true;
										
									}
									/*
									else {
										
										this.ssoSetting = false;
									}
									*/
								}

								else {
									this.ssoSetting = false;
									
								}
							});
						}

					});
				  

			  });


          });

          this.v2Service.getTenantAreasOfExpertise().subscribe(res => {
              for (let e of res.expertise) {

                  let checkExpertise: CheckedExpertise = new CheckedExpertise();
                  checkExpertise.expertiseId = e.expertiseId;
                  checkExpertise.expertiseName = e.expertiseName;
                  checkExpertise.isChecked = this.doesUserHaveExpertise(e.expertiseId);
                  checkExpertise.isVisible = true;
                  this.tenantExpertiseList.push(checkExpertise);
              }
              this.tenantExpertiseList = this.tenantExpertiseList.sort((a: any, b: any) => {
                  if (a.expertiseName > b.expertiseName)
                      return 1;
                  else if (a.expertiseName < b.expertiseName)
                      return -1;
                  return 0;
              });
          });

      });
      this.pinnacleService.GetCurrentOrganization().subscribe(res => {this.organization = res; });
      

      //this.pinnacleService.getCurrentUserWorkgroups([PermissionLevel_T.owner, PermissionLevel_T.editor, PermissionLevel_T.user], 10).subscribe(res => {
      //    this.workGroups = res;});

      //this.pinnacleService.getCurrentUserEnrollments().subscribe(res => {
      //    this.currentEnrollments = res;});

      //this.pinnacleService.getCurrentUserEnrollments([EnrollmentFilter_T.completed]).subscribe(e => this.completedCourseCount = e.length);


         
      
      


	  this.pinnacleService.getLanguages().subscribe(res => {
		  this.languages = res;
		 // this.fillEmptyLanguageSet();
	  }, err => {
		 // this.fillEmptyLanguageSet();
	  })


	  this.selectedLanguage = "en";

      this.pinnacleService.getCurrentUserSetting("LanguagePreference").subscribe(res => {
          if (res.settingValue != null) {
              this.selectedLanguage = res.settingValue.toString();
          }
	  },
		  err => {
		  });

	  this.pinnacleService.getCurrentUserSetting("EmailNotifications").subscribe(res => {
          if (res.settingValue != null) {
              this.emailNotifications = res.settingValue.toString() == "True";
          }
	  },
		  err => {

          });
  }


    doesUserHaveExpertise(expertiseId: string): boolean {
        for (let e of this.user.areasOfExpertise) {
            if (e.expertiseId == expertiseId) {
                return true;
            }
        }
        return false;
    }

    applyExpertise() {
        let userExpertiseList: UserExpertise[] = [];
        let removeList: string[] = [];
        let addList: string[] = [];

        this.user.areasOfExpertise = [];
        for (let e of this.tenantExpertiseList) {
            if (e.isChecked) {
                let newExp: UserExpertise = new UserExpertise();
                newExp.expertiseId = e.expertiseId;
                newExp.expertiseName = e.expertiseName;
                userExpertiseList.push(newExp);
                addList.push(e.expertiseId);
            }
            else {
                removeList.push(e.expertiseId);
            }
        }

        this.user.areasOfExpertise = userExpertiseList;
        this.expertiseFormatted = this.getFormattedExpertiseList();

        this.v2Service.modifyUserAreasOfExpertise(removeList, addList).subscribe();

    }


    expertiseSearch(event: any) {
        const value = event.target.value;
        if (value != '') {
            for (let item of this.tenantExpertiseList) {
                if (item.expertiseName.toLowerCase().indexOf(value.toLowerCase()) == -1) {
                    item.isVisible = false;
                }
                else {
                    item.isVisible = true;
                }
            }
        }
        else {
            for (let item of this.tenantExpertiseList) {
                    item.isVisible = true;
            }

        }
    }

    getFormattedExpertiseList(): string {
        let text: string = "";
        for (let e of this.user.areasOfExpertise) {
            if (text.length > 0) {
                text += ", ";
            }
            text += e.expertiseName;
        }
        return text;
    }


    get isPinnacleLite(){
        return AppComponent.isPinnacleLite;
    }
    /*
  changePasswordClicked()
  {
	  this.pswdChangeOpen = true;
  }
    */

  onPasswordCancel()
  {
	  this.pswdChangeOpen = false;
	  this.pswdChangeError = false;
	  this.oldPassword = "";
	  this.newPassword = "";
	  this.oldPasswordError = false;
	  this.newPasswordError = false;
	  this.passwordgGuidlinesError = false;
	  this.pswdErrorText = "";
  }

  onPasswordSubmit() {

	  this.pswdChangeError = false;
	  this.newPasswordError = false;
	  this.oldPasswordError = false;
	  this.passwordgGuidlinesError = false;

	  if (this.oldPassword == "") {
		  this.oldPasswordError = true;
		  this.pswdChangeError = true;
	  }
	  else {
		  this.oldPasswordError = false;
	  }

	  if (this.newPassword == "") {
		  this.newPasswordError = true;
		  this.pswdChangeError = true;
	  }
	  else {
		  this.newPasswordError = false;
	  }

	  if (this.pswdChangeError == true) {
          this.pswdErrorText = this.tranService.getTranslationFileData("PROFILE.PWBlankError");// "Please fill in the password field";
		  return;
	  }

	  if (this.passwordPolicyEnforcedSetting == true) {
		  this.pinnacleService.isNewPasswordValid(this.newPassword).subscribe(res => {
			  if (res == true) {
				  this.pinnacleService.updateCurrentUserPassword(this.oldPassword, this.newPassword).subscribe(res2 => {
					  if (res2 == true) {
						  this.onPasswordCancel();
					  }
					  else {
						  this.pswdChangeError = true;
                          this.pswdErrorText = this.tranService.getTranslationFileData("PROFILE.PWGenericError");// "The given password could not be updated. Please try again";
						  this.oldPassword = "";
						  this.newPassword = "";
					  }
				  });
			  }
			  else {
				  this.pswdChangeError = true;
                  this.pswdErrorText = this.tranService.getTranslationFileData("PROFILE.PWGuidelinesError");//"The given password did not meet the enforced password guidelines";
				  this.passwordgGuidlinesError = true;
			  }
		  });
	  }
	  else {
		  this.pinnacleService.updateCurrentUserPassword(this.oldPassword, this.newPassword).subscribe(res2 => {
			  if (res2 == true) {
				  this.onPasswordCancel();
			  }
			  else {
				  this.pswdChangeError = true;
                  this.pswdErrorText = this.tranService.getTranslationFileData("PROFILE.PWGenericError");//"The given password could not be updated. Please try again";
				  this.oldPassword = "";
				  this.newPassword = "";
			  }
		  });
	  }
  }

  onImageChanged(event: any)
  {
      this.user.imageUrl= event.imageURL;
      if (this.user.imageUrl == "") {
          this.user.imageUrl = "../../assets/images/default_user.jpg" + "?" + Date.now();
      }
      this.brandingService.profilepictureemitter.emit(this.user.imageUrl);
  }

    applyLanguageChange() {
        this.onLanguageChanged();
    }

  onLanguageChanged() {

	  this.pinnacleService.saveCurrentUserSetting("LanguagePreference", this.selectedLanguage, SettingType_T.string).subscribe(res => {
          localStorage.setItem("selectedLanguage", this.selectedLanguage);
		  this.pinnacleService.getTranslatedFileFromLanguageCode(this.selectedLanguage).subscribe(dataFile => {

			  this.tranService.loadTranslationFileDataFromVariable(dataFile);
              this.pinnacleService.UpdateUserAccessKey(this.selectedLanguage).subscribe(key => {
                  
                  ProdGenApi.setUserAccessKey(key.userAccessKey);
                  ProdGenApi.setAPIV2BearerToken(key.apiV2AccessKey);


				  this.onTranslationLoaded();
			  }, error => {
				  console.log(error);
				  this.onTranslationLoaded();
			  });
			  
		  },
			  fileError => {
				  console.log("ERROR");
				  console.log(fileError);
				  this.tranService.loadTranslationFileLocal().subscribe(local => {
					  this.tranService.loadTranslationFileDataFromVariable(local);
					  this.onTranslationLoaded();
				  }, localerr => {
					  console.log(localerr);
				  });

			  });
      }, err => {
          //console.error('Language failed to update');
	  });
  }

  onTranslationLoaded() {
      //refresh page

	  //window.location.reload();
  }

  saveEmailNotificationSetting() {
	  this.emailNotifications = !this.emailNotifications;
	  this.pinnacleService.saveCurrentUserSetting("EmailNotifications", this.emailNotifications, SettingType_T.string).subscribe(res => {

	  }, err => {

	  });
  }

    onEnableAutodeskSearch(bEnable: boolean) {

        if (window.navigator.userAgent.indexOf("Windows ") != -1) {
            // check to see if they have installed protocol already. Done by checking if font exists that is installed with it
          if (doesFontExist("Xenotron Broadstroke") == true) {
            $('#enable').attr('data-target', '#ActivateModal');
            $('#disable').attr('data-target', '#DeactivateModal');
                if (bEnable == true) {
                    this.pinnacleService.getCurrentUser().subscribe(u => {
                        this.pinnacleService.GetCurrentPartner().subscribe(p => {
                            this.pinnacleService.GetCurrentTenant().subscribe(t => {
                                if (doesFontExist("Xephyr") == true) {

                                    let cmdUrl: string = "pinnaclecommand://ADESKSEARCHINITIALIZE?userid=" + u.userId + "&tenantid=" + t.tenantId + "&partnerid=" + p.partnerId;
                                    window.location.href = cmdUrl;
                                }
                                else {
                                    let cmdUrl: string = "pinnaclecommand://ADESKSEARCHINITIALIZE?userid=" + u.userId + "&tenantid=" + t.tenantId/* + "&partnerid=" + p.partnerId*/;
                                    window.location.href = cmdUrl;
                                }
                            });

                        });

                    });
                }
                else {
                    this.pinnacleService.getCurrentUser().subscribe(u => {
                        this.pinnacleService.GetCurrentPartner().subscribe(p => {
                            this.pinnacleService.GetCurrentTenant().subscribe(t => {
                                let cmdUrl: string = "pinnaclecommand://ADESKSEARCHDESTROY?userid=" + u.userId + "&tenantid=" + t.tenantId/* + "&partnerid=" + p.partnerId*/;
                                window.location.href = cmdUrl;
                            });
                        });
                    });
                }
          }
          if (doesFontExist("Xenotron Broadstroke") == false) {
            $('#enable').attr('data-target', '');
            $('#disable').attr('data-target', '');
            // if they have not, give them a message showing the download link

              var v_Msg = new MsgBxStringVals();
              v_Msg.body = (this.tranService.getTranslationFileData("PROFILE.UserToolsMessage"));
              v_Msg.title = ``;
              this.controlService.openMessageBoxPopUp(v_Msg);
            //              `The <b>Pinnacle User Tools</b> add-on is required to use this feature.<BR><BR>
            //<a href="https://www.eaglepoint.com/downloads/pinnacle/pinnacletools.msi" target="_blank"><b>Click Here</b></a> to download and install this add-on.<BR><BR>
            
            //<b>After the installation you must restart your browser.</b>`
              event.preventDefault();
            return;
          }
        }


    }

    saveUserInfo() {
        if (this.editEmail != "" && this.editDisplayName != "") {
            
            
            this.pinnacleService.saveCurrentUserData(this.editEmail, this.editDisplayName).subscribe(res => {
                this.user.email = res.email;
                this.user.displayName = res.name;
            }, err => {

            });
            
           // this.pinnacleService.saveCurrentUserData(this.editEmail, this.editDisplayName);
        }
        else {

        }

    }

    setUpdateData() {
        this.editDisplayName = this.user.displayName;
        this.editEmail = this.user.email;

    }

    setExternalData() {
        this.editExternalOrgName = this.externalOrgName;
        this.editExternalOrgRole = this.externalOrgRole;
        this.editExternalPhone = this.externalPhone;
    }

    saveUserExternalData() {
        if (this.editExternalOrgName != "" && this.editExternalOrgRole != "" && this.editExternalPhone != "") {
            this.pinnacleService.updateCurrentUserExternalData(this.editExternalOrgName, this.editExternalOrgRole, this.editExternalPhone).subscribe(res => {
                this.externalOrgName = res.organizationName;
                this.externalOrgRole = res.organizationRole;
                this.externalPhone = res.phone;
            });
        }
    }

    onSelectedRoleChanged() {
        this.pinnacleService.saveCurrentUserSetting("ROLE_LAYOUT_PREFERENCE", this.selectedRole, SettingType_T.string).subscribe();
    }
}

export class UserProperty {
    public PropertyName: string;
    public PropertyValue: string;
}

export class UserManager{
    public DisplayName: string;
    public Email: string;
    public ImageUrl: string;
    public Initials: string;
}

export class CheckedExpertise extends UserExpertise {

    public isChecked = false;
    public isVisible = true;
}

